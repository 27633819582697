import { useEffect, useRef, useState } from "react";
import Accordion from "../../../../../components/Accordion";
import FormInput from "../../../../../components/Form/FormInput";
import { useTipoTrabajo } from "../../../../../hooks/useTipoTrabajo";
import { TipoTrabajo } from "../../../../../types/TipoTrabajo";
import { FormTipoTrabajoErrors } from "..";



type TipoTrabajoProps = {
    tipoTrabajoForm: TipoTrabajo;
    errors: FormTipoTrabajoErrors;
    setFormErrors: (value: FormTipoTrabajoErrors) => void
    setTipoTrabajoForm: (value: TipoTrabajo) => void
    edit: boolean,
    view: boolean
}


const SeccionTipoTrabajo = ({ tipoTrabajoForm, setFormErrors, setTipoTrabajoForm, errors, edit, view }: TipoTrabajoProps) => {
    const { buscarLogo } = useTipoTrabajo();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [preview, setPreview] = useState<string | null>(null);

    useEffect(() => {
        if (tipoTrabajoForm.id !== null && tipoTrabajoForm.id !== 0) {
            buscarLogo(tipoTrabajoForm.id)
                .then(logo => {
                    if (logo) {
                        const objectUrl = URL.createObjectURL(logo); // create here
                        setPreview(objectUrl);
                    } else {
                        setPreview(null);
                    }
                })
        }

    }, [tipoTrabajoForm.id])

    const handleFileChange = () => {
        if (fileInputRef.current?.files && fileInputRef.current.files[0]) {
            const file = fileInputRef.current.files[0];
            setPreview(URL.createObjectURL(file));

            setTipoTrabajoForm({
                ...tipoTrabajoForm,
                logoNuevo: file
            })
        }
    };
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onCheckboxChangeTipoTrabajo = (e: any) => {
        const { name, checked } = e.target;
        setTipoTrabajoForm({
            ...tipoTrabajoForm,
            [name]: checked
        })
    }
    const onChangeTipoTrabajo = (e: any) => {
        const { name, value } = e.target;
        setTipoTrabajoForm({
            ...tipoTrabajoForm,
            [name]: value
        })
        const errors = validateForm({
            ...tipoTrabajoForm,
            [name]: value
        })
        setFormErrors(errors)
    }

    const validateForm = (tipoTrabajo: TipoTrabajo) => {
        const errors: FormTipoTrabajoErrors = {};
        if (!tipoTrabajoForm.nombre) {
            errors.nombre = "El campo nombre es obligatorio"
        }
        if (!tipoTrabajoForm.descripcion) {
            errors.descripcion = "El campo descripción es obligatorio"
        }
        setFormErrors(errors);
        return errors;

    }

    return (
        <Accordion id={'TipoTrabajo'} title="Tipo de Trabajo">
            <div>
                <div className="row mt-3">
                    <FormInput
                        id={'nombre'}
                        label={'Nombre Tipo Trabajo'}
                        name={'nombre'}
                        // placeholder={'Nro. Solicitud'}
                        value={tipoTrabajoForm.nombre || ''}
                        isDisabled={edit && view}
                        onChange={onChangeTipoTrabajo}
                        errorText={errors.nombre}
                        maxLength={50}
                    />
                </div>
                <div className="row mt-3">
                    <FormInput
                        id={'descripcion'}
                        label={'Descripción'}
                        name={'descripcion'}
                        // placeholder={'Nro. Solicitud'}
                        value={tipoTrabajoForm.descripcion || ''}
                        isDisabled={edit && view}
                        onChange={onChangeTipoTrabajo}
                        errorText={errors.descripcion}
                        maxLength={255}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <label className="col-form-label col-2 text-end">Logo:</label>

                {!view &&
                    <div className="col-2 col-form-label">
                        <a href="#" onClick={() => handleButtonClick()}>
                            <i className="fa fa-image mr-1" aria-hidden="true"></i>
                            Agregar Logo
                        </a>
                    </div>
                }

                <input
                    id="file"
                    type="file"
                    accept="image/png"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    disabled={edit && view}
                />

                {preview &&
                    <img src={preview} style={{ width: '120px', height: '120px', padding: '10px' }} />
                }
            </div>

            <div className="row mt-3 align-items-center">
                <label className="col-form-label col-2 text-end" style={{ color: "grey" }}>
                    Personal Afectado
                </label>

                <div className="col-2">
                    <div className="input-group">
                        <div className="form-check form-check-inline" style={{ marginTop: "5px" }}>

                            <input
                                name='personalAfectado'
                                style={{ appearance: "auto" }}
                                className="form-check-input"
                                type="checkbox"
                                checked={tipoTrabajoForm.personalAfectado === true}
                                onChange={onCheckboxChangeTipoTrabajo}
                                disabled={edit && view}
                            />

                        </div>
                    </div>
                </div>
                <label className="col-form-label col-2 text-end" style={{ color: "grey" }}>
                    Equipos / Maquinarias
                </label>

                <div className="col-2">
                    <div className="input-group">
                        <div className="form-check form-check-inline" style={{ marginTop: "5px" }}>

                            <input
                                name='equipoAfectado'
                                style={{ appearance: "auto" }}
                                className="form-check-input"
                                type="checkbox"
                                // checked={tipoTrabajoForm.equipoAfectado !== null ? tipoTrabajoForm.equipoAfectado : false}
                                checked={tipoTrabajoForm.equipoAfectado === true}
                                onChange={onCheckboxChangeTipoTrabajo}
                                disabled={edit && view}
                            />

                        </div>
                    </div>
                </div>


            </div>
            {
                edit && <div className="row mt-3">
                    <label className="col-form-label col-2 text-end" style={{ color: "grey" }}>
                        Activo
                    </label>

                    <div className="col-2">
                        <div className="input-group">
                            <div className="form-check form-check-inline" style={{ marginTop: "5px" }}>

                                <input
                                    name='activo'
                                    style={{ appearance: "auto" }}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={tipoTrabajoForm.activo === true}
                                    onChange={onCheckboxChangeTipoTrabajo}
                                    disabled={edit && view}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="row mt-3"></div>
        </Accordion>
    )
}

export default SeccionTipoTrabajo;