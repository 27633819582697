import { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../Home/Breadcrumb"
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTipoTrabajo } from "../../../../hooks/useTipoTrabajo";
import SeccionTipoTrabajo from "./TipoTrabajo";
import { TipoTrabajo } from "../../../../types/TipoTrabajo";
import { ToastContainer, toast } from "react-toastify";
import { cargarCombo, uploadFile } from "../../../../services/Service";
import SeccionActividades from "./Actividades";
import SeccionEquipos from "./Equipos";
import { Actividad } from "../../../../types/Actividad";
import { EquipoMaquinaria } from "../../../../types/EquipoMaquinaria";
import { DocumentoTrabajo } from "../../../../types/DocumentoTrabajo";
import SeccionTipoDocumentos from "./TipoDocumento";
import Confirm from "../../../../components/Confirm";


export type FormTipoTrabajoErrors = {
    nombre?: string;
    descripcion?: string;
};

export const CrearTipoTrabajo = () => {
    const { handleTipoTrabajoSelected, tipoTrabajoInitial, tipoTrabajoSelected, guardarTipoTrabajo, buscarTipoTrabajo,
        actividadList, buscarActividades, handleActividadList, buscarEquipos, handleEquipoList, equipoList, buscarDocumentos, documentoList, handleDocumentoList } = useTipoTrabajo();
    const [tipoTrabajoForm, setTipoTrabajoForm] = useState<TipoTrabajo>(tipoTrabajoInitial);
    const [edit, setEdit] = useState<boolean>(false);
    const [view, setView] = useState<boolean>(false);
    const [actividades, setActividades] = useState<Actividad[]>([]);
    const [equipos, setEquipos] = useState<EquipoMaquinaria[]>([]);
    const [documentos, setDocumentos] = useState<DocumentoTrabajo[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);

    const [formErrors, setFormErrors] = useState<FormTipoTrabajoErrors>({
        nombre: '',
        descripcion: '',
    });

    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        cargarCombo(`actividad`, setActividades);
        cargarCombo(`equipamiento`, setEquipos);
        cargarCombo(`documentoTrabajo`, setDocumentos);
        if (id) {
            buscarTipoTrabajo(id);
            buscarActividades(+id);
            buscarEquipos(+id);
            buscarDocumentos(+id);
            setEdit(true);
            const TipoAction = searchParams.get("action");
            if (TipoAction === 'view') {
                setView(true);
            }
        } else {
            handleTipoTrabajoSelected(tipoTrabajoInitial)
            handleActividadList([]);
            handleEquipoList([]);
            handleDocumentoList([])

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTipoTrabajoForm(tipoTrabajoSelected)

    }, [tipoTrabajoSelected])

    const validateForm = (tipoTrabajo: TipoTrabajo) => {
        const errors: FormTipoTrabajoErrors = {};
        if (!tipoTrabajoForm.nombre) {
            errors.nombre = "Debe informar el nombre"
        }
        if (!tipoTrabajoForm.descripcion) {
            errors.descripcion = "Debe informar la descripción"
        }
        setFormErrors(errors);
        return errors;

    }

    const handleGuardar = () => {
        const errores = validateForm(tipoTrabajoForm);
        if (Object.keys(errores).length === 0) {
            let logo: File | null = tipoTrabajoForm.logoNuevo;

            guardarTipoTrabajo({
                ...tipoTrabajoForm,
                activo: tipoTrabajoForm.activo,
                logo: null,
                actividades: actividadList,
                equipos: equipoList,
                documentos: documentoList
            })
                .then(response => {
                    if (response.data) {
                        if (logo) {

                            uploadFile('tiposTrabajos/subirLogo',
                                logo, { idTipoTrabajo: response.data.id, sizeMaximo: 2 }, true)
                                .then(result => {
                                    if (result) {
                                        toast.success("Se guardó el Tipo de Trabajo correctamente")
                                        navigate('/tipoTrabajo');
                                    }

                                }).catch((error) => {
                                    if (error.response.status === 413) {
                                        toast.error(error.response.data.message)
                                    }
                                });
                        } else {
                            toast.success("Se guardó el Tipo de Trabajo correctamente")
                            navigate('/tipoTrabajo');

                        }
                    }
                }
                ).catch(err => toast.error(err.response.data.message))
        }
    }

    const handleCancelar = (e: any) => {
        e.preventDefault();
        setShowModal(true)
    }
    const cancelar = () => {
        toggle();
        navigate('/tipoTrabajo')
    }
    const handleVolver = () => {
        navigate('/tipoTrabajo')
    }

    return (
        <>
            <ToastContainer />

            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title={edit ? (view ? 'Vista' : 'Editar') : 'Nuevo'}
                            titlePage={view ? 'Vista Tipos de Trabajo' : 'Tipos de Trabajo'}
                            nameFirst={'Configuración'}
                            urlFirst={'/'}
                            nameSecond={'Tipos de Trabajo'}
                            urlSecond={'/tipoTrabajo'}
                        />
                        <Confirm isOpen={showModal} toggle={toggle} title='Cancelar' yesFn={cancelar}>
                            <div className="form-horizontal">
                                <div className="row">
                                    {<p>Desea salir sin guardar el Tipo de Trabajo?</p>}
                                </div>
                            </div>
                        </Confirm>

                        <SeccionTipoTrabajo tipoTrabajoForm={tipoTrabajoForm}
                            setFormErrors={setFormErrors}
                            setTipoTrabajoForm={setTipoTrabajoForm}
                            errors={formErrors} edit={edit} view={view} />

                        <SeccionActividades
                            actividades={actividades}
                            setActividades={setActividades}
                            edit={edit}
                            view={view}
                        />
                        <SeccionEquipos
                            equipos={equipos}
                            setEquipos={setEquipos}
                            edit={edit} view={view} />
                        <SeccionTipoDocumentos
                            documentos={documentos}
                            setDocumentos={setDocumentos}
                            edit={edit}
                            view={view} />
                        <br />
                        <div className="text-center">
                            {!view &&
                                <>
                                    <button className="btn btn-secondary mx-2" onClick={handleCancelar}>Cancelar</button>
                                    <button className="btn btn-success mx-2" onClick={handleGuardar}>Guardar</button>
                                </>
                            }
                            {view &&
                                <button className="btn btn-secondary mx-2" onClick={handleVolver}>Volver</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}