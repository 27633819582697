import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { Contratista } from "../types/Contratista";
import { findAll, get, remove, save, update } from "../services/Service";
import { loadListContratista, contratistaInitial, loadDataContratista, loadDataFiltroContratista, loadListTrabajoEmpresa } from "../context/Contratista/ContratistaReducer";
import { TrabajoEmpresa } from "../types/TrabajoEmpresa";
import api from "../api/api";


export const useContratista = () => {
    const BASE_URL = 'contratistas';
    const { loading, contratistaSelected, errors, error, contratistaList, filtro, trabajoEmpresaList } = useSelector(
        (state: RootState) => state.contratista
    );

    const dispatch = useDispatch();

    const buscarContratistas = async (contratista: Contratista) => {
        let url = contratista.activo === null || contratista.activo === '0' ? `${BASE_URL}/findBy?razonSocial=${contratista.razonSocial}` : `${BASE_URL}/findBy?razonSocial=${contratista.razonSocial}&activo=${contratista.activo}`
        if (contratista.idEmpresaGestora !== null && contratista.idEmpresaGestora !== 0) {
            url = url + `&idEmpresaGestora=${contratista.idEmpresaGestora}`
        }

        if (contratista.idTipoTrabajo !== null && contratista.idTipoTrabajo !== 0) {
            url = url + `&idTipoTrabajo=${contratista.idTipoTrabajo}`
        }
        if (contratista.idTipoDocumento !== null && contratista.idTipoDocumento !== 0) {
            url = url + `&idTipoDocumento=${contratista.idTipoDocumento}`
        }
        if (contratista.nroIdentificacion !== null && contratista.nroIdentificacion !== '') {
            url = url + `&nroIdentificacion=${contratista.nroIdentificacion}`
        }

        await findAll(url)
            .then((response) => {
                dispatch(loadListContratista(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const guardarContratista = async (contratista: Contratista) => {
        let response;
        try {
            if (contratista.id === 0) {
                response = await save(BASE_URL, contratista);
                dispatch(loadDataContratista(response.data));
            } else {
                response = await update(BASE_URL, contratista);
                dispatch(loadDataContratista(response.data));
            }
            return response;
        } catch (error: any) {
            throw error;
        }
    }

    const buscarContratista = async (id: string) => {
        try {
            const response = await get(BASE_URL, id);
            dispatch(loadDataContratista(response.data));
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    const buscarTrabajosEmpresas = async (id: string) => {
        try {
            let response = await findAll(`trabajosEmpresa/contratista/${id}`);
            dispatch(loadListTrabajoEmpresa(response.data));
        } catch (error) {
            console.log(error);
        }

    }
    const eliminarContratista = async (id: string) => {
        try {
            const response = await remove(BASE_URL, id)
            return response;

        } catch (error) {
            console.log(error);
        }
    }

    const buscarLogo = async (id: number) => {
        try {
            const response = await api.get(`${BASE_URL}/${id}/logo`, { responseType: 'blob' });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    const handleContratistaSelected = (contratista: Contratista) => {
        dispatch(loadDataContratista(contratista))
    }

    const handleContratistaList = (contratistas: Contratista[]) => {
        dispatch(loadListContratista(contratistas))
    }

    const handleFiltroContratista = (contratista: Contratista) => {
        dispatch(loadDataFiltroContratista(contratista))
    }
    const handleTrabajoEmpresaList = (trabajosEmpresas: TrabajoEmpresa[]) => {
        dispatch(loadListTrabajoEmpresa(trabajosEmpresas));
    }

    return {
        buscarContratistas,
        guardarContratista,
        handleContratistaSelected,
        buscarContratista,
        eliminarContratista,
        handleContratistaList,
        handleFiltroContratista,
        buscarTrabajosEmpresas,
        handleTrabajoEmpresaList,
        buscarLogo,
        contratistaInitial,
        trabajoEmpresaList,
        contratistaSelected,
        contratistaList,
        loading,
        errors,
        error,
        filtro
    }



}