import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TipoDocumento } from "../../types/TipoDocumento";


interface TipoDocumentoState {
    tipoDocumentoList: TipoDocumento[];
    tipoDocumentoSelected: TipoDocumento;
    filtro: TipoDocumento;
    loading: boolean;
    errors: any;
    error: boolean,
    formatos: any[];
    formatosSelected: any[];
}

export const tipoDocumentoInitial: TipoDocumento = {
    id: 0,
    nombre: "",
    descripcion: "",
    activo: true,
    sizeMaximo: 0,
    formatoArchivo: "",
    contenidoPlantilla: null,
    nombrePlantilla: null,
    formatos: [],
}

const initialState: TipoDocumentoState = {

    tipoDocumentoList: [],
    tipoDocumentoSelected: tipoDocumentoInitial,
    filtro: {
        id: 0,
        nombre: "",
        descripcion: "",
        activo: null,
        sizeMaximo: 0,
        formatoArchivo: "",
        contenidoPlantilla: null,
        nombrePlantilla: null,
        formatos: []
    },
    loading: false,
    errors: [],
    error: false,
    formatos: [],
    formatosSelected: []

}

const tipoDocumentoSlice = createSlice({
    name: 'tipoDocumento',
    initialState,
    reducers: {
        addTipoDocumento: (state, data: PayloadAction<TipoDocumento>): void => {
            state.tipoDocumentoSelected = data.payload;
            state.error = false;
        },
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataTipoDocumento: (state, data: PayloadAction<TipoDocumento>): void => {
            state.tipoDocumentoSelected = data.payload;
            state.error = false;
        },
        loadDataFiltroTipoDocumento: (state, data: PayloadAction<TipoDocumento>): void => {
            state.filtro = data.payload;
        },

        loadListTipoDocumento: (state, data: PayloadAction<TipoDocumento[]>): void => {
            state.tipoDocumentoList = data.payload;
        },

        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        removeTipoDocumento: (state, data: PayloadAction<number>): void => {
            state.tipoDocumentoList = state.tipoDocumentoList.filter(p => p.id !== data.payload)
        },
        setFormatos: (state, data: PayloadAction<any[]>): void => {
            state.formatos = data.payload;
        },
        setFormatosSelected: (state, data: PayloadAction<any[]>): void => {
            state.formatosSelected = data.payload;
        },

    }
});
export const {
    addTipoDocumento,
    isLoading,
    loadDataTipoDocumento,
    loadListTipoDocumento,
    loadingError,
    visibleError,
    removeTipoDocumento,
    loadDataFiltroTipoDocumento,
    setFormatos,
    setFormatosSelected
} = tipoDocumentoSlice.actions;

export default tipoDocumentoSlice.reducer;