import { useSearchParams } from "react-router-dom";
import { useRol } from "../../../../hooks/useRol";
import { Breadcrumbs } from "../../../Home/Breadcrumb";
import { BuscarRol } from "./Buscar";
import TableRol from "./Table";
import { useEffect, useState } from "react";


export const Rol = () => {

    const { guardado, alta, handleGuardado } = useRol()
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const updated = searchParams.get("updated");
        if (updated === 'true') {
            handleGuardado(true)
        } else {
            handleGuardado(false);
        }
    }, []);


    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={'Roles'}
                        nameFirst={'Seguridad'}
                        urlFirst={'/'}
                    />
                    {guardado && alta &&
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <strong>Alta exitosa.</strong>
                        </div>
                    }
                    {guardado && !alta &&
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <strong>Actualización exitosa.</strong>
                        </div>
                    }
                    <BuscarRol />
                    <TableRol />

                </div>
            </div >
        </div >

    );
}
