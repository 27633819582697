import FormInput from "../../../../../../components/Form/FormInput";
import FormSelectBox from "../../../../../../components/Form/FormSelectBox"
import { DocumentoTrabajo } from "../../../../../../types/DocumentoTrabajo"

type ModalTipoDocumentoProp = {
    documentoForm: DocumentoTrabajo;
    setDocumentoForm: (value: DocumentoTrabajo) => void;
    formErrors: any;
    setFormErrors: (value: any) => void;
    documentosFiltrados: DocumentoTrabajo[]
}

const ModalTipoDocumento = ({ documentoForm, setDocumentoForm, formErrors, setFormErrors, documentosFiltrados }: ModalTipoDocumentoProp) => {

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setDocumentoForm({
            ...documentoForm,
            [name]: value
        })
    }

    const onCheckboxChange = (e: any) => {
        const { name, checked } = e.target;
        setDocumentoForm({
            ...documentoForm,
            [name]: checked
        })
    }

    const onChangeValue = (e: any) => {
        const { name, value } = e.target;
        setDocumentoForm({
            ...documentoForm,
            [name]: value
        })
    }
    return (
        <>

            <div className="row mt-2">
                <FormSelectBox
                    id={'doc'}
                    label={'Tipo de Documento'}
                    name={'tipoDocumentoId'}
                    value={documentoForm.tipoDocumentoId || ''}
                    title={'Tipo de Documento'}
                    onChange={(e) => handleInputChange(e)}
                    optionId={'tipoDocumentoId'}
                    optionLabel={'tipoDocumentoNombre'}
                    options={documentosFiltrados}
                    classLabel="col-form-label col-4 text-end"
                    classDivSelect="col-6"
                    errorText={formErrors.idTipoDocumento}
                />

            </div>
            <div className="row mt-2 align-items-cente">
                <label className="col-form-label col-4 text-end" style={{ color: "grey" }}>
                    Obligatorio
                </label>

                <div className="col-4">
                    <div className="input-group">
                        <div className="form-check form-check-inline" style={{ marginTop: "9px" }}>

                            <input
                                name='obligatorio'
                                style={{ appearance: "auto" }}
                                className="form-check-input"
                                type="checkbox"
                                checked={documentoForm.obligatorio === true}
                                onChange={onCheckboxChange}
                            />

                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2 align-items-cente">
                <label className="col-form-label col-4 text-end" style={{ color: "grey" }}>
                    Renovable
                </label>

                <div className="col-4">
                    <div className="input-group">
                        <div className="form-check form-check-inline" style={{ marginTop: "9px" }}>

                            <input
                                name='renovable'
                                style={{ appearance: "auto" }}
                                className="form-check-input"
                                type="checkbox"
                                checked={documentoForm.renovable === true}
                                onChange={onCheckboxChange}
                            />

                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <FormInput
                    id={'periodo'}
                    label={'Periodicidad'}
                    name={'periodo'}
                    // placeholder={'Nro. Solicitud'}
                    value={documentoForm.periodo || ''}
                    onChange={onChangeValue}
                    classLabel="col-form-label col-4 text-end"
                    classColumn="col-6"
                    showSpan={true}
                    textSpan="Días"
                    errorText={formErrors.periodo}

                // errorText={errors.descripcion}
                />
            </div>
        </>
    )
}

export default ModalTipoDocumento;

