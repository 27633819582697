// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amarillo {
    background-color: yellow;
}

.punto-amarillo {
    width: 10px;
    height: 10px;
    background-color: yellow;
    border: 1px solid black;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    margin-left: 25px
}

.verde {
    background-color: green;
}

.punto-verde {
    width: 10px;
    height: 10px;
    background-color: green;
    border: 1px solid black;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    margin-left: 25px
}

.rojo {
    background-color: red;
}

.punto-rojo {
    width: 10px;
    height: 10px;
    background-color: red;
    border: 1px solid black;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    margin-left: 25px
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
.checkbox-container label {
    margin-right: 10px; /* Ajusta el margen según sea necesario */
    display: flex;
    flex-direction: row;
    align-items: center;
  }

td.check-validacion-container {
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/TableRow/index.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,uBAAuB;IACvB,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB;AACJ;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,uBAAuB;IACvB,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB;AACJ;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,uBAAuB;IACvB,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;AAEF;IACI,kBAAkB,EAAE,yCAAyC;IAC7D,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;AAEF;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".amarillo {\n    background-color: yellow;\n}\n\n.punto-amarillo {\n    width: 10px;\n    height: 10px;\n    background-color: yellow;\n    border: 1px solid black;\n    border-radius: 50%;\n    display: inline-block;\n    margin-right: 15px;\n    margin-left: 25px\n}\n\n.verde {\n    background-color: green;\n}\n\n.punto-verde {\n    width: 10px;\n    height: 10px;\n    background-color: green;\n    border: 1px solid black;\n    border-radius: 50%;\n    display: inline-block;\n    margin-right: 15px;\n    margin-left: 25px\n}\n\n.rojo {\n    background-color: red;\n}\n\n.punto-rojo {\n    width: 10px;\n    height: 10px;\n    background-color: red;\n    border: 1px solid black;\n    border-radius: 50%;\n    display: inline-block;\n    margin-right: 15px;\n    margin-left: 25px\n}\n\n.checkbox-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n  \n.checkbox-container label {\n    margin-right: 10px; /* Ajusta el margen según sea necesario */\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\ntd.check-validacion-container {\n    height: 56px;\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
