

export const Home = () => {
  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
          </div>
        </div>
      </div>

    </>
  );
}