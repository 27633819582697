/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Table, { ColumnDefinitionType } from '../../../../../components/Table';
import moment from 'moment'
import { Link } from 'react-router-dom';
import MyModal from '../../../../../components/Modal';
import { EquipamientoSolicitud } from '../../../../../types/EquipamientoSolicitud';
import { useEquipoAndMaquinaria } from '../../../../../hooks/useEquipoAndMaquinaria';
import DetailEquipamientoSolicitud from '../DetailEquipamientoSolicitud';
import EditEquipamientoSolicitud from '../EditEquipamientoSolicitud';
import { useSolicitud } from '../../../../../hooks/useSolicitud';
import { uploadFiles } from '../../../../../services/Service';
import { APROBADA_STATUS_ID, EN_ACTUALIZACION_STATUS_ID, EN_PROCESO_STATUS_ID, OBSERVADA_STATUS_ID, REVISION_STATUS_ID } from '../../../../../constants/constants';
import { useUserLogin } from '../../../../../hooks/useUserLogin';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

interface TableEquipamientoSolicitudProps {
    onClose: () => void;
    onModalAction: (isOpen: boolean) => void;
    isEditing?: boolean;
    actionEdit?: boolean;
    modalOpen: boolean;
    onIsEditing: (updateEditable: boolean) => void;
    isEdit?: boolean;
}

const TableEquipamientoSolicitud: React.FC<TableEquipamientoSolicitudProps> = ({ onClose, isEditing, onIsEditing, isEdit, modalOpen,
    actionEdit, onModalAction }) => {
    const { solicitudSelected, cargarCombo, buscarSolicitud } = useSolicitud();
    const [isConfirmDeleteEquipSolicitudProp, setIsConfirmDeleteEquipSolicitudProp] = useState(false);
    const [isConfirmSaveEquipSolicitudProp, setIsConfirmSaveEquipSolicitudProp] = useState(false);
    const [isConfirmCancelEquipSolicitudProp, setIsConfirmCancelEquipSolicitudProp] = useState(false);
    const [isEditData, setIsEditData] = useState(false);
    const [dataPreSavedEquipamientoSolicitud, setDataPreSavedEquipamientoSolicitud] = useState<any>();
    const { equipamientoSolicitudes, setEquipamientoSolicitud, buscarDocEquipacionSolicitudesByIdEquipSolicitud,
        equipamientoSolicitudSelected, saveOrUpdateEquipamientoSolicitud, deleteDocEquipamientoById,
        documentEquipSolicitudes, deleteEquipamientSolicitudById } = useEquipoAndMaquinaria();
    const [equipamientos, setEquipamientos] = useState<any>([]);
    const { isContratistaTipoUsuario, isEmpresaGestoraTipoUsuario } = useUserLogin();
    const [equipamientoSolicitudIdSelect, setEquipamientoSolicitudIdSelect] = useState<number>();
    const [currentSave, setCurrentSave] = useState<HTMLButtonElement | null>();
    useEffect(() => {
        cargarCombo(`equipamiento/activasPorContratista/${solicitudSelected.idContratistaTrabajoEmpresa}`, setEquipamientos);
    }, [solicitudSelected.idContratistaTrabajoEmpresa]);

    const columns: ColumnDefinitionType<EquipamientoSolicitud, keyof EquipamientoSolicitud>[] = []
    columns.push({
        key: 'nombre',
        header: 'Equipo/Maquinaria',
    });
    columns.push({
        key: 'fechaIngreso',
        header: 'Fecha Ingreso',
        cell: (data: EquipamientoSolicitud) => <>{data.fechaIngreso ? moment(data.fechaIngreso).format('DD/MM/YYYY') : ''}</>
    });
    columns.push({
        key: 'fechaEgreso',
        header: 'Fecha Egreso',
        cell: (data: EquipamientoSolicitud) => <>{data.fechaEgreso ? moment(data.fechaEgreso).format('DD/MM/YYYY') : ''}</>
    });
    columns.push({
        key: 'id',
        header: 'Acciones',
        cell: (data: EquipamientoSolicitud) =>
            <>
                <Link title='Visualización' to="/exportar" onClick={(e) => {
                    //(data.id)
                    buscarDocEquipacionSolicitudesByIdEquipSolicitud(data.id)
                    setEquipamientoSolicitud(data)
                    onModalAction(true);
                    e.preventDefault();
                }} target="_blank" >
                    <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                </Link>

                {/* {(isEdit && (solicitudSelected.idTipoEstado === REVISION_STATUS_ID || ((solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID) && isContratistaTipoUsuario()))) && */}
                {isEdit && ((isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado == REVISION_STATUS_ID) || (isContratistaTipoUsuario() && (solicitudSelected.idTipoEstado == EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado == OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado == EN_ACTUALIZACION_STATUS_ID))) &&
                    <Link title='Editar' to="" onClick={(e) => {
                        buscarDocEquipacionSolicitudesByIdEquipSolicitud(data.id)
                        setEquipamientoSolicitud(data)
                        onIsEditing(true);
                        onModalAction(true);
                        e.preventDefault();
                    }} target="_blank" >
                        <i className="fa fa-pencil text-secondary me-2" aria-hidden="true"></i>
                    </Link>}
                {actionEdit && solicitudSelected.idTipoEstado !== APROBADA_STATUS_ID && solicitudSelected.idTipoEstado !== EN_ACTUALIZACION_STATUS_ID && <Link title='Eliminar' to="" onClick={(e) => {
                    setEquipamientoSolicitudIdSelect(data.id)
                    setIsConfirmDeleteEquipSolicitudProp(true)
                    e.preventDefault();
                }} target="_blank" >
                    <i className="fa fa-trash text-secondary me-2" aria-hidden="true"></i>
                </Link>}
            </>
    });
    if (isContratistaTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID) {
        columns.push({
            key: 'statusDocumento',
            header: '',
            // isEnabledCheckStatusDocument: isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID,
        });
    }


    const handleEquipamientoSolicitudSave = () => {
        let data = dataPreSavedEquipamientoSolicitud;
        let dataForm: EquipamientoSolicitud = {
            fechaEgreso: data.fechaEgreso,
            fechaIngreso: data.fechaIngreso,
            idEquipamiento: data.idEquipamiento,
            nombre: data.nombre,
            subido: data.subido,
            idSolicitud: solicitudSelected.id,
            id: data.id,
            statusDocumento: '',
            vigente: data.vigente
        }
        saveOrUpdateEquipamientoSolicitud('equipamiento-solicitud', dataForm)
            .then(result => {
                // Maneja el resultado si es necesario
                console.log('Operación completada:', result);
                if (result) {
                    buscarSolicitud(solicitudSelected.id.toString())
                    findDeletedDocuments(data.documentosEquipamiento).forEach(documentDelete => deleteDocEquipamientoById(documentDelete.id));
                    let documentUpload = data.documentosEquipamiento.filter((documentPersPropio: any) => documentPersPropio.archivoSeleccionado)
                    if (!documentUpload || documentUpload.length < 1) {
                        onClose()
                        return;
                    }
                    let idEquipamientoSolicitud = result.data.id;
                    let fileDataArray = documentUpload.map((documenEquipamientoSolicitud: any) => {
                        const uniqueId: string = uuidv4();
                        return {
                            file: documenEquipamientoSolicitud.archivoSeleccionado, parameters: {
                                idTipoDocumento: documenEquipamientoSolicitud.idTipoDocumento, idDocumento: documenEquipamientoSolicitud.id,
                                fileId: uniqueId, sizeMaximo: documenEquipamientoSolicitud.sizeMaximo
                            }
                        }
                    })
                    uploadFiles(`documento-equipamiento-solicitud/subirArchivo/${idEquipamientoSolicitud}`, fileDataArray)
                        .then(result => {
                            // console.log('currentSave')
                            // console.log(currentSave)
                            if (result) {
                                onClose()
                            }
                            currentSave!.disabled = false

                        }).catch((error) => {
                            currentSave!.disabled = false
                            if (error.response.status === 413) {
                                toast.error(error.response.data.message)
                            } else {
                                toast.error('No se pudo adjuntar el archivo seleccionado')
                            }
                        });
                }

                //uploadFiles(`${result}`, [])
            })
            .catch(error => {
                // Maneja los errores si ocurren
                console.error('Error:', error);
            });
    }
    const findDeletedDocuments = (newList: any[]): any[] => {
        const deletedDocuments: any[] = [];

        for (const existingDocument of documentEquipSolicitudes) {
            const existsInNewList = newList.some((newDocument) => newDocument.idTipoDocumento === existingDocument.idTipoDocumento &&
                newDocument.id === existingDocument.id);

            if (!existsInNewList) {
                deletedDocuments.push(existingDocument);
            }
        }

        return deletedDocuments;
    }

    const handleConfirmDeleteEquipamientoSolicitud = () => {
        deleteEquipamientSolicitudById(`${equipamientoSolicitudIdSelect}`).finally(() => setIsConfirmDeleteEquipSolicitudProp(false))
    }

    return (
        <>
            <MyModal isOpen={isConfirmDeleteEquipSolicitudProp} toggle={() => setIsConfirmDeleteEquipSolicitudProp(false)} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} secondButtonFn={handleConfirmDeleteEquipamientoSolicitud} >
                <label>Desea confirmar eliminar el Equipamiento de la Solicitud?</label>
            </MyModal>
            <MyModal isOpen={isConfirmSaveEquipSolicitudProp} toggle={() => setIsConfirmSaveEquipSolicitudProp(false)} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} secondButtonFn={() => { setIsConfirmSaveEquipSolicitudProp(false); handleEquipamientoSolicitudSave() }} >
                <label>Desea guardar los cambios realizados?</label>
            </MyModal>
            <MyModal isOpen={isConfirmCancelEquipSolicitudProp} toggle={() => setIsConfirmCancelEquipSolicitudProp(false)} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} secondButtonFn={() => { setIsConfirmCancelEquipSolicitudProp(false); onClose() }} >
                <label>Desea confirmar cancelar los cambios de Equipamiento de la Solicitud?</label>
            </MyModal>
            <MyModal size='lg' isOpen={modalOpen} toggle={() => { isEditData ? setIsConfirmSaveEquipSolicitudProp(true) : onClose(); setIsEditData(false) }} cancelButton={false} secondButton={false}
                title={(!isEditing ? 'Vista' : equipamientoSolicitudSelected.id === 0 ? 'Agregar' : 'Editar') + ' Equipo/Maquinaria'} >
                {!isEditing ? <DetailEquipamientoSolicitud onClose={() => { onClose(); setIsEditData(false) }} /> : <EditEquipamientoSolicitud equipamientos={equipamientos} isEditing={isEditing}
                    onClose={() => { isEditData ? setIsConfirmCancelEquipSolicitudProp(true) : onClose(); setIsEditData(false) }} onChangeEditData={setIsEditData}
                    onSave={(data, currentSave) => { setDataPreSavedEquipamientoSolicitud(data); setIsConfirmSaveEquipSolicitudProp(true); setCurrentSave(currentSave) }} />}
            </MyModal>
            <Table data={equipamientoSolicitudes} columns={columns} ></Table>
        </>
    )
}

export default TableEquipamientoSolicitud