import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Documento } from '../../types/Documento';

interface DocumentoState {
    historialDocumentos: Documento[];
    documentos: Documento[];
    documentoSelected: Documento;
    loading: boolean;
}
export const documentoInitial: Documento = {
    id: 0,
    idTipoDocumento: 0,
    nombreTipoDocumento: '',
    idSolicitud: 0,
    fileId: 0,
    subido: false,
    ubicacion: '',
    nombreArchivo: '',
    fechaVencimiento: '',
    formatoArchivo: '',
    statusDocumento: '',
    observacion: ''
}

const initialState: DocumentoState = {
    historialDocumentos: [],
    documentos: [],
    documentoSelected: documentoInitial,
    loading: false,
};

export const documentoSlice = createSlice({
    name: 'documento',
    initialState,
    reducers: {
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadData: (state, data: PayloadAction<Documento>): void => {
            state.documentoSelected = data.payload;
            state.loading = false;
        },
        loadList: (state, data: PayloadAction<Documento[]>): void => {
            state.documentos = data.payload;
            state.loading = false;
        },
        loadListHistorial: (state, data: PayloadAction<Documento[]>): void => {
            state.historialDocumentos = data.payload;
            state.loading = false;
        },

    },
})

export const { isLoading, loadData, loadList, loadListHistorial } = documentoSlice.actions;
export default documentoSlice.reducer;
