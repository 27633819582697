import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TipoUsuario } from "../../types/TipoUsuario";
import { Usuario } from "../../types/Usuario";

interface usuarioState {
    usuarioList: Usuario[];
    usuarioSelected: Usuario;
    loading: boolean;
    perfiles: any[];
    perfilesSelected: any[];
    errors: any;
    error: boolean,
    guardado: boolean,
    tiposUsuarios: TipoUsuario[]
}

export const usuarioInitial: Usuario = {
    id: 0,
    nombre: '',
    apellido: '',
    nombreUsuario: '',
    tipoDocumento: '',
    perfilesSelected: [],
    numeroDocumento: '',
    mail: '',
    telefono: '',
    tipoUsuarioValor: '',
    tipoUsuarioId: '',
    idContratista: 0,
    contratista: '',
    idEmpresaGestora: 0,
    idTipoDocumento: 0,
    idTipoUsuario: 0,
    empresaGestora: '',
    guardado: false,
    estado: true,
    fechaAlta: '',
    fechaBaja: '',
    fechaModificacion: '',
    url: ''
}

const initialState: usuarioState = {
    usuarioList: [],
    perfiles: [],
    perfilesSelected: [],
    usuarioSelected: usuarioInitial,
    loading: false,
    errors: [],
    error: false,
    guardado: false,
    tiposUsuarios: []
}

const UsuarioSlice = createSlice({
    name: 'usuario',
    initialState,
    reducers: {
        addUsuario: (state, data: PayloadAction<TipoUsuario>): void => {
            state.usuarioSelected = usuarioInitial;
            state.error = false;
            state.guardado = true;
            state.loading = false;
        },
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataUsuario: (state, data: PayloadAction<Usuario>): void => {
            state.usuarioSelected = data.payload;
            state.error = false;
        },
        loadListUsuario: (state, data: PayloadAction<Usuario[]>): void => {
            state.usuarioList = data.payload;
        },
        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
            state.loading = false;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        removeUsuario: (state, data: PayloadAction<number>): void => {
            state.usuarioList = state.usuarioList.filter(p => p.id !== data.payload)
        },
        setPerfiles: (state, data: PayloadAction<any[]>): void => {
            state.perfiles = data.payload;
        },
        setPerfilesSelected: (state, data: PayloadAction<any[]>): void => {
            state.perfilesSelected = data.payload;
        },
        setGuardado: (state, data: PayloadAction<boolean>): void => {
            state.guardado = data.payload;
        },
        setTiposUsuarios: (state, data: PayloadAction<TipoUsuario[]>): void => {
            state.tiposUsuarios = data.payload;
        }


    },
});

export const {
    isLoading,
    loadDataUsuario,
    loadListUsuario,
    addUsuario,
    loadingError,
    removeUsuario,
    visibleError,
    setPerfiles,
    setPerfilesSelected,
    setGuardado,
    setTiposUsuarios,
} = UsuarioSlice.actions;

export default UsuarioSlice.reducer;