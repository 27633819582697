import React, { useEffect, useState } from 'react'
import { Permiso } from '../../../../../types/Permiso';
import { usePermiso } from '../../../../../hooks/usePermiso';
import { useRol } from '../../../../../hooks/useRol';
import { Rol } from '../../../../../types/Rol';

export const BuscarRol = () => {
    const { rolInitial, buscarRoles, handleGuardado } = useRol();
    const [filtroRol, setFiltroRol] = useState<Rol>(rolInitial);
    const [newRol] = useState<Rol>(rolInitial);

    useEffect(() => {
        buscarRoles(filtroRol);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChange = (e: any) => {
        e.preventDefault();
        setFiltroRol({
            ...filtroRol,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        handleGuardado(false);
        buscarRoles(filtroRol);
    }

    const handleClean = () => {
        handleGuardado(false);
        setFiltroRol(newRol);
    };

    return (
        <div className="card">
            <form id="dto" className="form-horizontal" action="buscarPermisos" method="post">
                <div className="card-body">
                    <h4 className="m-t-0 header-title">
                        <i className="fa fa-search margin-right-7" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                        Buscar
                    </h4>
                    <hr />
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: "grey" }}>
                                Descripción
                            </label>
                            <input id="descripcion" name="descripcion" className="form-control" type="text" value={filtroRol.descripcion} onChange={handleChange} />

                        </div>

                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-center">

                            <button className="btn btn-secondary btn-rounded waves-effect waves-light mr-1" type="button" id="limpiarFiltro" onClick={handleClean}>
                                <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                                Limpiar
                            </button>
                            <button className="btn btn-success btn-rounded waves-effect waves-light " type="submit" onClick={handleSubmit}>
                                <i className="fa fa-search mr-1"></i>
                                Buscar
                            </button>

                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}
