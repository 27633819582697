import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import Table, { ColumnDefinitionType } from '../../../../../components/Table'
import { Loading } from '../../../../../components/Loading'
import { TipoUsuario } from '../../../../../types/TipoUsuario'
import { useTipoUsuario } from '../../../../../hooks/useTipoUsuario'


const TableTipoUsuario = () => {

    const { tipoUsuarioList, loading } = useTipoUsuario();
    const columns: ColumnDefinitionType<TipoUsuario, keyof TipoUsuario>[] = [
        {
            key: 'nombre',
            header: 'Nombre',
        },
        {
            key: 'valor',
            header: 'Acrónimo',
        },

        {
            key: 'estado',
            header: 'Estado',
            cell: (data: TipoUsuario) => <>{data.estado ? 'Activo' : 'Inactivo'}</>
        },

        {
            key: 'id',
            header: 'Acciones',
            cell: (data: TipoUsuario) =>
                <>
                    <Link to={`/tiposUsuarios/editar/${data.id}`} title="Editar" >
                        <i className="fa fa-pencil text-custom" aria-hidden="true"></i>
                    </Link>

                </>
        },
    ]

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h4 className="m-t-0 header-title">
                                        <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                        Listado de Tipos de Usuario

                                    </h4>

                                </div>

                                <div className="table-responsive">
                                    <br />
                                    {loading ? <Loading /> : <Table data={tipoUsuarioList || []} columns={columns}></Table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default TableTipoUsuario;