import { useEffect, useState } from "react";
import { usePermiso } from "../../../../hooks/usePermiso";
import { Breadcrumbs } from "../../../Home/Breadcrumb"
import TablePermiso from "../Table";
import { BuscarPermiso } from "./Buscar";
import { useParams, useSearchParams } from "react-router-dom";


export const Permiso = () => {
    const { alta, handleGuardado, guardado } = usePermiso()
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const updated = searchParams.get("updated");
        if (updated === 'true') {
            handleGuardado(true)
        } else {
            handleGuardado(false);
        }
    }, []);


    return (

        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={'Permisos'}
                        nameFirst={'Seguridad'}
                        urlFirst={'/'}
                    />
                    {
                        guardado && alta &&
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <strong>Alta exitosa.</strong>
                        </div>
                    }
                    {
                        guardado && !alta &&
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <strong>Actualización exitosa.</strong>
                        </div>
                    }

                    <BuscarPermiso />
                    <TablePermiso />


                </div>
            </div >
        </div >

    );

}