import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use((config: { headers: any; }) => {
    config.headers = {
        ...config.headers,
        'Authorization': localStorage.getItem('token'),
    }
    return config;
});

export default api;