import React, { useEffect, useState, useRef } from 'react';
import { Breadcrumbs } from '../../Home/Breadcrumb';
import DatosGenerales from './DatosGenerales';
import { useSolicitud } from '../../../hooks/useSolicitud';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DocumentosAdjuntos from './DocumentosAdjuntos';
import PersonalAfectado from './PersonalAfectado';
import EquiposAndMaquinaria from './EquiposAndMaquinaria';
import Observacion from './Observacion';
import Historial from './Historial';
import { useUserLogin } from '../../../hooks/useUserLogin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EN_PROCESO_STATUS_ID, INICIO_STATUS_ID, ANULADA_STATUS_ID, REVISION_STATUS_ID, OBSERVADA_STATUS_ID, EN_ACTUALIZACION_STATUS_ID, APROBADA_STATUS_ID, FINALIZADA_STATUS_ID } from '../../../constants/constants'
import MyModal from '../../../components/Modal';
import CalificarComponent from '../../../components/Calificar';
import { useObservacion } from '../../../hooks/useObservacion';
import Confirm from '../../../components/Confirm';
import { useAuth } from '../../../hooks/useAuth';
import { Loading } from '../../../components/Loading';

// import { BuscarEstado } from "./Buscar"


export const Solicitud = () => {
  const { buscarSolicitud, solicitudSelected, cargarCombo, updateSolicitud, sendSolicitud, validarCalificarSolicitud, handleShowMessage,
    handleAnulada, validarDocumentosAprobados, handleUrlVolver, urlVolver } = useSolicitud();
  const { isContratistaTipoUsuario, isEmpresaGestoraTipoUsuario } = useUserLogin()
  const { buscarObservacionByIdSolicitud, existeCalificacion } = useObservacion();
  const { redirect, handleRedirect } = useAuth();
  const [isContratista] = useState<boolean>(isContratistaTipoUsuario())
  const [isEmpresaGestora] = useState<boolean>(isEmpresaGestoraTipoUsuario())
  const [estados, setEstados] = useState<any>({});
  const [isEditSolicitud, setIsEditSolicitud] = useState<boolean>(false);
  const [isNewSolicitud, setIsNewSolicitud] = useState<boolean>(false);
  const [isEnviarInicioSolicitud, setEnviarInicioSolicitud] = useState<boolean>(false);
  const [actionAnular, setactionAnular] = useState<boolean>(false);
  const [disabledActionFinalizar, setDisabledActionFinalizar] = useState<boolean>(true);
  const [disabledActionCalificar, setDisabledActionCalificar] = useState<boolean>(true);
  const [disabledActionEnviar, setDisabledActionEnviar] = useState<boolean>(true);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const datosGeneralesRef = useRef<any>();
  const [isOpenModalCalificarDocument, setIsOpenModalCalificarDocument] = useState(false)
  const [todosDocsAprobados, setTodosDocsAprobados] = useState(false)
  const [showModalFinalizar, setShowModalFinalizar] = useState<boolean>(false);
  const [showModalAnular, setShowModalAnular] = useState<boolean>(false);
  const navigate = useNavigate();
  const toggle = () => setShowModalFinalizar(!showModalFinalizar);
  const toggleAnular = () => setShowModalAnular(!showModalAnular);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const puedeEditar =
      (isEmpresaGestora && (solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === INICIO_STATUS_ID || solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID)) ||
      (isContratista && (solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID ||
        solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID));
    cargarCombo('tiposEstados', setEstados);

    setIsEditSolicitud(puedeEditar)
    if (id) {
      buscarSolicitud(id);
      const TipoParam = searchParams.get("create");
      const TipoAction = searchParams.get("action");
      if (TipoParam === 'true' || TipoAction === 'edit') {
        setIsEditSolicitud(true);
      }
      if (TipoAction === 'view') {
        setIsEditSolicitud(false);
      }
      if (TipoParam === 'true') {
        setIsNewSolicitud(true);
      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const TipoAction = searchParams.get("action");
    if (solicitudSelected) {
      setactionAnular(((solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID) && isEmpresaGestora) || ((solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID || solicitudSelected.idTipoEstado === REVISION_STATUS_ID) && isContratista) || solicitudSelected.idTipoEstado === FINALIZADA_STATUS_ID);
      if (solicitudSelected.idTipoEstado === ANULADA_STATUS_ID) {
        setIsEditSolicitud(false)
      } else if (TipoAction === 'edit') {
        setIsEditSolicitud(true)
      }
    }
    (async () => {
      if (solicitudSelected.idTipoEstado !== 0) {
        if (solicitudSelected.id !== 0 && solicitudSelected.idTipoEstado === REVISION_STATUS_ID) {
          try {
            const existe = await existeCalificacion(solicitudSelected.id);

            if (existe) {
              setDisabledActionEnviar((isEmpresaGestora && (solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID)) || (isContratista && (solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID)));
            } else {
              setDisabledActionEnviar((isEmpresaGestora && (solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID)) || (isContratista && (solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID)));
            }
          } catch (error) {
            console.error('Error al verificar la existencia de calificacion:', error);
          }
        } else {
          setDisabledActionEnviar((isEmpresaGestora && (solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID || solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID)) || (isContratista && (solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID)) || (solicitudSelected.idTipoEstado === FINALIZADA_STATUS_ID));

        }
      }

    })();

    setDisabledActionCalificar((isEmpresaGestora && (solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === INICIO_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID)) || isContratista || solicitudSelected.idTipoEstado === FINALIZADA_STATUS_ID)
    setDisabledActionFinalizar(((solicitudSelected.idTipoEstado === INICIO_STATUS_ID || solicitudSelected.idTipoEstado === FINALIZADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID || solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID) && isEmpresaGestora) || ((solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID || solicitudSelected.idTipoEstado === REVISION_STATUS_ID) && isContratista))
    setIsLoading(false);
  }, [solicitudSelected]);


  const handleAnularSolicitud = () => {
    updateSolicitud({ id: solicitudSelected.id, idTipoEstado: ANULADA_STATUS_ID }).then(response => {
      if (response !== undefined) {
        // toast.success("Solicitud enviada correctamente")
        handleAnulada(true);
        navigateTo();
      }

    });
    setIsEditSolicitud(false);
    toggleAnular();
  }

  const handleEnviarSolicitud = async () => {

    if (isEmpresaGestora && solicitudSelected.idTipoEstado === INICIO_STATUS_ID) {
      setEnviarInicioSolicitud(true)
      if (datosGeneralesRef.current) {
        datosGeneralesRef.current.handleSubmitAndSend(); // Reemplaza 'guardarDatos' con el nombre de tu función en DatosGenerales.tsx
      }
    } else {
      const response = await sendSolicitud(solicitudSelected.id);

      if (response) {
        handleShowMessage(true);
        navigateTo();
      }
    }
  }

  const handleFinalizarSolicitud = () => {
    updateSolicitud({ id: solicitudSelected.id, idTipoEstado: FINALIZADA_STATUS_ID });
    setIsEditSolicitud(false);
    toggle();

  }

  const handleGuardarSolicitud = () => {
    // Realiza las acciones necesarias en Solicitud.tsx
    console.log('Se hizo clic en Guardar Solicitud en Solicitud.tsx');

    // Llama a una función en DatosGenerales.tsx si es necesario
    if (datosGeneralesRef.current) {
      datosGeneralesRef.current.handleSubmit(); // Reemplaza 'guardarDatos' con el nombre de tu función en DatosGenerales.tsx
    }
  };
  const handleCalificarSolicitud = async () => {
    let haySinRevisar: boolean = true;;
    haySinRevisar = await validarCalificarSolicitud(solicitudSelected.id);

    if (haySinRevisar !== undefined && !haySinRevisar) {
      let validarAprobados: boolean = false;
      validarAprobados = await validarDocumentosAprobados(solicitudSelected.id);
      setTodosDocsAprobados(validarAprobados)
      buscarObservacionByIdSolicitud(solicitudSelected.id);
      setIsOpenModalCalificarDocument(true)
    }
  }

  const handleVolver = () => {
    // navigate('/consultaSolicitudes')
    navigateTo();
  }

  const navigateTo = () => {
    const TipoParam = searchParams.get("create");

    if (TipoParam === 'true') {
      navigate('/consultaSolicitudes');
    } else {

      if (redirect) {
        navigate(`/solicitud/${solicitudSelected.id}/datos`);
      } else {
        navigate(urlVolver);
      }

      handleRedirect(null);
    }
  }
  const handleCancelar = () => {
    navigateTo();
  }
  const setDisableEnviar = (disabled: boolean) => {
    setDisabledActionEnviar(disabled);
  }

  return (
    <><ToastContainer />
      {isLoading || solicitudSelected.id === 0 ? (
        <Loading />

      ) : (
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <Breadcrumbs
                title={isNewSolicitud ? 'Alta de Solicitud' : (isEditSolicitud ? 'Edición de Solicitud' : 'Vista de la Solicitud')}
                nameFirst={'Consulta de Solicitudes'}
                urlFirst={'/consultaSolicitudes'}
              />
              <Confirm isOpen={showModalFinalizar} toggle={toggle} title='Confirmar' yesFn={handleFinalizarSolicitud}>
                <div className="form-horizontal">
                  <div className="row">
                    {<p>Está seguro que desea finalizar la solicitud?</p>}
                  </div>
                </div>
              </Confirm>
              <Confirm isOpen={showModalAnular} toggle={toggleAnular} title='Confirmar' yesFn={handleAnularSolicitud}>
                <div className="form-horizontal">
                  <div className="row">
                    {<p>Está seguro que desea Anular la solicitud?</p>}
                  </div>
                </div>
              </Confirm>
              <MyModal
                isOpen={isOpenModalCalificarDocument} toggle={() => setIsOpenModalCalificarDocument(false)} title='Calificar Solicitud' cancelButton={false} secondButton={false}
              >
                <CalificarComponent setDisabledActionEnviar={setDisableEnviar} todosDocsAprobados={todosDocsAprobados} enviarSolicitud={handleEnviarSolicitud}
                  cancelButtonFn={() => setIsOpenModalCalificarDocument(false)} returnButtonFn={() => setIsOpenModalCalificarDocument(false)}
                ></CalificarComponent>
              </MyModal>

              {/* <DatosGenerales ref={datosGeneralesRef} isDisabled={(solicitudSelected.idTipoEstado !== INICIO_STATUS_ID && isEmpresaGestora)
              || (isContratista && ((solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID && solicitudSelected.tipoUsuarioCreacion != 'CONTRATISTA') || (solicitudSelected.idTipoEstado !== INICIO_STATUS_ID)))} estados={estados} isEnviarInicioSolicitud={isEnviarInicioSolicitud} setDisabledActionEnviar={setDisabledActionEnviar} /> */}
              <DatosGenerales ref={datosGeneralesRef} isDisabled={!isEditSolicitud || ((solicitudSelected.idTipoEstado !== INICIO_STATUS_ID && isEmpresaGestora)
                || (isContratista && ((solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID && solicitudSelected.tipoUsuarioCreacion !== 'CONTRATISTA') || (solicitudSelected.idTipoEstado !== EN_PROCESO_STATUS_ID))))} estados={estados} isEnviarInicioSolicitud={isEnviarInicioSolicitud} setDisabledActionEnviar={setDisabledActionEnviar} handleCancelar={handleCancelar} />
              {solicitudSelected.idTipoEstado && solicitudSelected.idTipoEstado !== INICIO_STATUS_ID && (
                <>
                  <DocumentosAdjuntos isEdit={isEditSolicitud} isDisabled={!isEditSolicitud || ((solicitudSelected.idTipoEstado === INICIO_STATUS_ID || solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID)
                    && isEmpresaGestora) || (isContratista && (solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === INICIO_STATUS_ID))} />

                  <PersonalAfectado isEdit={isEditSolicitud} isDisabled={!isEditSolicitud || ((solicitudSelected.idTipoEstado == INICIO_STATUS_ID || solicitudSelected.idTipoEstado == REVISION_STATUS_ID || solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID)
                    && isEmpresaGestora) || (isContratista && solicitudSelected.idTipoEstado == REVISION_STATUS_ID)} />

                  <EquiposAndMaquinaria isEdit={isEditSolicitud} isDisabled={!isEditSolicitud || ((solicitudSelected.idTipoEstado == INICIO_STATUS_ID || solicitudSelected.idTipoEstado == REVISION_STATUS_ID || solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID)
                    && isEmpresaGestora) || (isContratista && solicitudSelected.idTipoEstado == REVISION_STATUS_ID)} />

                  <Observacion isDisabled={!isEditSolicitud || (solicitudSelected.idTipoEstado === INICIO_STATUS_ID
                    && isEmpresaGestora)} />

                  {/* <Mensaje isDisabled={!isEditSolicitud || (solicitudSelected.idTipoEstado === INICIO_STATUS_ID
              && isEmpresaGestora)} /> */}

                  <Historial isDisabled={!isEditSolicitud || (solicitudSelected.idTipoEstado === INICIO_STATUS_ID
                    && isEmpresaGestora)} />
                </>
              )
              }
              <br />
              {/* Botones */}
              {isEditSolicitud && (
                <div className="text-center">
                  {!actionAnular && <button className="btn btn-danger mx-2" onClick={toggleAnular}>Anular</button>}
                  {/*<button className="btn btn-success mx-2" disabled={disabledActionGuardar} onClick={handleGuardarSolicitud}>Guardar</button> */}
                  {!disabledActionCalificar && <button className="btn btn-primary mx-2" onClick={handleCalificarSolicitud}>Calificar</button>}
                  {!disabledActionEnviar && <button className="btn btn-info mx-2" onClick={handleEnviarSolicitud}>Enviar</button>}
                  {!disabledActionFinalizar && <button className="btn btn-info mx-2" onClick={toggle}>Finalizar</button>}
                  {(solicitudSelected.idTipoEstado === FINALIZADA_STATUS_ID) && <button className="btn btn-info mx-2" onClick={handleVolver}>Volver</button>}
                </div>
              )}
              {!isEditSolicitud && (
                <div className="text-center">
                  <button className="btn btn-info mx-2" onClick={handleVolver}>Volver</button>

                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
