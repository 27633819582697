import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, get, remove, save, update } from "../services/Service";
import { useNavigate } from "react-router-dom";
import { Rol } from "../types/Rol";
import { addRol, loadDataRol, loadListRol, loadingError, removeRol, visibleError, rolInitial, setPermisos, setPermisosSelected, setGuardado, isAlta } from "../context/Seguridad/RolReducer";

export const useRol = () => {
    const BASE_URL = 'roles';
    const { loading, rolSelected, rolList, permisos, errors, error, permisosSelected, guardado, alta } = useSelector(
        (state: RootState) => state.rol
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const buscarRoles = async (rol: Rol) => {

        await findAll(`${BASE_URL}/find?descripcion=${rol.descripcion}`)
            .then((response) => {
                console.log(response.data)
                dispatch(loadListRol(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const buscarPermisosNoAsignados = async () => {
        let response;
        try {
            response = await findAll('permisos/no-asignados');

            const treeData = [
                {
                    descripcion: 'Permisos',
                    id: 'parent',
                    child: [
                        ...response.data
                    ],
                },
            ];
            dispatch(setPermisos(treeData));

        } catch (error: any) {
            console.log(error);
        }
    }

    const guardarRol = async (rol: Rol) => {
        let response;
        try {
            if (rol.id === 0) {
                response = await save(BASE_URL, rol);
                dispatch(addRol(response.data));
                dispatch(isAlta(true));
            } else {
                response = await update(BASE_URL, rol);
                dispatch(addRol(response.data));
                dispatch(isAlta(false));
            }
            navigate('/roles?updated=true');
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                dispatch(loadingError(error.response.data.errors));
            } else if (error.response && error.response.status === 500) {
                dispatch(loadingError(error.response.data.errors));
            } else if (error.response?.status === 401) {
                //handlerLogout();
            } else {
                throw error;
            }
        }

    }
    const buscarRol = async (id: string) => {
        handleGuardado(false)
        try {
            const response = await get(BASE_URL, id)
            dispatch(loadDataRol(response.data));
            dispatch(setPermisosSelected(response.data.permisosSelected?.map((p: any) => (p.id))));
            const treeData = [
                {
                    descripcion: 'Permisos',
                    id: 'parent',
                    child: [
                        ...response.data.permisosNoAsignados
                    ],
                },
            ];
            dispatch(setPermisos(treeData));
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    const eliminarRol = async (id: string) => {
        handleGuardado(false)
        try {
            const response = await remove(BASE_URL, id)
            dispatch(removeRol(Number(id)));

        } catch (error) {
            console.log(error);
        }
    }

    const handleRolSelected = (rol: Rol) => {
        dispatch(loadDataRol(rol))
    }
    const showError = (load: boolean) => {
        dispatch(visibleError(load))
    }
    const setMsgError = (error: string[]) => {
        dispatch(loadingError(error));
    }
    const handlePermisosSelected = (permisos: any) => {
        dispatch(setPermisosSelected(permisos))
    }
    const handleGuardado = (guardado: boolean) => {
        dispatch(setGuardado(guardado))
    }

    return {
        buscarRoles,
        buscarPermisosNoAsignados,
        guardarRol,
        buscarRol,
        eliminarRol,
        handleRolSelected,
        showError,
        setMsgError,
        handlePermisosSelected,
        handleGuardado,
        isAlta,
        alta,
        rolInitial,
        rolList,
        rolSelected,
        permisos,
        permisosSelected,
        loading,
        errors,
        error,
        guardado
    }
}