import { useState } from "react";
import Accordion from "../../../../../components/Accordion";
import { Actividad } from "../../../../../types/Actividad";
import Table, { ColumnDefinitionType } from "../../../../../components/Table";
import { Link } from "react-router-dom";
import { useTipoTrabajo } from "../../../../../hooks/useTipoTrabajo";
import { Loading } from "../../../../../components/Loading";
import MyModal from "../../../../../components/Modal";
import FormSelectBox from "../../../../../components/Form/FormSelectBox";
import Confirm from "../../../../../components/Confirm";


type ActividadProps = {
    actividades: Actividad[],
    setActividades: (value: Actividad[]) => void,
    edit: boolean,
    view: boolean
}
type FormErrors = {
    idActividad?: string;
};

const SeccionActividades = ({ edit, view, actividades, setActividades }: ActividadProps) => {
    const [actividadForm, setActividadForm] = useState<Actividad>({
        id: 0,
        nombreActividad: '',
        descripcion: '',
        activo: null,
        documentos: []
    });

    const { tipoTrabajoSelected, actividadList, buscarActividades, loading, handleActividadList } = useTipoTrabajo()
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);
    const [data, setData] = useState<Actividad>({
        id: 0,
        nombreActividad: '',
        descripcion: '',
        activo: null,
        documentos: [],
    });
    const [formErrors, setFormErrors] = useState<any>({
        idActividad: '',
    });
    const [actividadesFiltradas, setActividadesFiltradas] = useState<Actividad[]>([]);

    const eliminar = () => {
        let activ: Actividad[] = actividadList.filter(act => act.id !== data.id)
        handleActividadList(activ);
        toggle();
    }

    const columns: ColumnDefinitionType<Actividad, keyof Actividad>[] = []
    columns.push({
        key: 'nombreActividad',
        header: 'Nombre',
    });

    columns.push({
        key: 'descripcion',
        header: 'Descripción',
    });
    if (!view) {
        columns.push({
            key: 'id',
            header: 'Acciones',
            cell: (data: Actividad) =>
                <>
                    <Link to="#" title="eliminar"
                        onClick={(e) => {
                            e.stopPropagation();
                            setData(data);
                            setShowModal(true)

                        }}
                    >
                        <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                    </Link>

                </>
        });
    }


    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setActividadForm({
            ...actividadForm,
            [name]: value
        })
        const errors = validateForm(
            {
                ...actividadForm,
                [name]: value
            });
        setFormErrors(errors);
    }

    const handleConfirm = () => {
        const errors = validateForm(actividadForm);

        if (Object.keys(errors).length === 0) {
            const act: Actividad | undefined = actividades.find(actividad => actividad.id == actividadForm.id);
            if (act) {
                let activFinal: Actividad[] = [];
                activFinal.push(...actividadList, act);
                handleActividadList(activFinal);
            }
            toggleModal();
        }
        setFormErrors(errors);
    }

    const validateForm = (actividadSelected: Actividad) => {
        const errors: FormErrors = {};
        if (!actividadSelected.id) {
            errors.idActividad = 'Debe seleccionar una actividad'
        }
        return errors;
    }

    const actualizarActividades = () => {
        let activFiltradas: Actividad[] = [];

        for (const actividadSelect of actividades) {
            const existsInActividades = actividadList.some((act) => actividadSelect.id == act.id)
            if (!existsInActividades) {
                activFiltradas.push(actividadSelect);
            }
        }
        setActividadesFiltradas(activFiltradas);
    }


    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar la actividad {data.nombreActividad}?</p>}
                    </div>
                </div>
            </Confirm>
            <Accordion id={'actividades'} title="Actividades">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <h4 className="m-t-0 header-title">
                                            <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                            Listado de Actividades
                                            {!view &&
                                                <Link to="#" title="Agregar" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        actualizarActividades();
                                                        setActividadForm({
                                                            id: 0,
                                                            nombreActividad: '',
                                                            descripcion: '',
                                                            activo: null,
                                                            documentos: []
                                                        })
                                                        setFormErrors({
                                                            idActividad: ''
                                                        })
                                                        toggleModal();

                                                    }}>
                                                    <i className="fa fa-plus margin-right-10"></i> Agregar
                                                </Link>}

                                        </h4>

                                    </div>

                                    <div className="table-responsive">
                                        <br />
                                        <MyModal isOpen={isOpen} toggle={toggleModal} title='Agregar Actividad' size="md"
                                            secondButton={true} secondButtonTitle={'Aceptar'} titleCancelar={'Cancelar'} secondButtonFn={handleConfirm}
                                        >
                                            <div className="row mt-2">
                                                <FormSelectBox
                                                    id={'actividad'}
                                                    label={'Actividad'}
                                                    name={'id'}
                                                    value={actividadForm.id}
                                                    title={'Actividad'}
                                                    onChange={(e) => handleInputChange(e)}
                                                    optionId={'id'}
                                                    optionLabel={'nombreActividad'}
                                                    options={actividadesFiltradas}
                                                    classLabel="col-form-label col-4 text-end"
                                                    classDivSelect="col-6"
                                                    errorText={formErrors.idActividad}
                                                />

                                            </div>

                                        </MyModal>
                                        {loading ? <Loading /> : <Table data={actividadList || []} columns={columns}></Table>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </Accordion>
        </>


    )
}

export default SeccionActividades;