import { Alert, Button } from 'react-bootstrap';
import FormInput from '../../../../components/Form/FormInput';
import FormSelectBox from '../../../../components/Form/FormSelectBox';
import Accordion from '../../../../components/Accordion';
import { useEffect, useState } from 'react';
import { useSolicitud } from '../../../../hooks/useSolicitud';
import moment from 'moment';

const solicitudFilterIni = {
  numeroSolicitud: '',
  numeroContrato: '',
  idTipoTrabajo: '',
  idTipoEstado: '',
  idEmpresaGestora: '',
  idContratista: '',
  fechaInicio: '',
  fechaFin: ''
}

export const BuscarSolicitud = () => {
  const { cargarCombo, buscarSolicitudes } = useSolicitud();
  const [filtroSolicitud, setFiltroSolicitud] = useState<any>(solicitudFilterIni);
  const [newSolicitud] = useState<any>(solicitudFilterIni);
  const [tiposTrabajo, setTiposTrabajo] = useState<any>({});
  const [estados, setEstados] = useState<any>({});
  const [empresasGestoras, setEmpresasGestoras] = useState<any>({});
  const [contratistas, setContratistas] = useState<any>({});
  const [msg, setMsg] = useState({
    txt: '',
    style: ''
  });
  const [show, setShow] = useState(false);

  useEffect(() => {
    cargarCombo('tiposTrabajos', setTiposTrabajo);
    cargarCombo('tiposEstados', setEstados);
    cargarCombo('empresasGestoras', setEmpresasGestoras);
    cargarCombo('contratistas', setContratistas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleChange = (e: any) => {
    e.preventDefault();
    setFiltroSolicitud({
      ...filtroSolicitud,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeNumber = (e: any) => {
    const value = e.target.value.replace(/\D/g, "");
    setFiltroSolicitud({
      ...filtroSolicitud,
      [e.target.name]: value,
    });
  };

  const handleClean = () => {
    buscarSolicitudes(newSolicitud);
    setFiltroSolicitud(newSolicitud);
  };

  const handleDateChange = (name: string, value: Date | string | null) => {
    let formattedValue = value;

    setFiltroSolicitud({
      ...filtroSolicitud,
      [name]: formattedValue,
    });

  };

  const handleSubmit = (e: any) => {
    setShow(false);
    e.preventDefault();

    if (filtroSolicitud.fechaInicio !== '' && filtroSolicitud.fechaFin !== '') {
      const fechaIni = moment(filtroSolicitud.fechaInicio);
      const fechaFin = moment(filtroSolicitud.fechaFin)

      if (fechaFin.isBefore(fechaIni)) {
        setShow(true);
        setMsg({
          txt: 'La Fecha Solicitud desde debe ser menor que la Fecha Solicitud Hasta',
          style: 'danger'
        });
        return;
      }
    }

    buscarSolicitudes(filtroSolicitud);
  };
  return (
    <>
      {show ?
        <Alert variant={msg.style} style={{ width: "100%" }} onClose={() => setShow(false)} dismissible>
          {msg.txt}
        </Alert>
        : ''
      }
      <Accordion id={'accordionSolicitudBuscar'} title="Consulta de Solicitudes" iconoClass='fa fa-search mr-1' >
        <div>
          <div className="row mt-3">
            <FormInput
              id={'numeroSolicitud'}
              label={'Nro. Solicitud'}
              name={'numeroSolicitud'}
              placeholder={'Nro. Solicitud'}
              onChange={handleChangeNumber}
              value={filtroSolicitud.numeroSolicitud}
            />
            <FormInput
              id={'numeroContrato'}
              label={'Nro. Contrato'}
              name={'numeroContrato'}
              placeholder={'Nro. Contrato'}
              onChange={handleChangeNumber}
              value={filtroSolicitud.numeroContrato}
            />
          </div>

          <div className="row mt-2">
            <FormSelectBox
              title={'Tipo de Trabajo'}
              label={'tipo de trabajo'}
              id={'tipoTrabajo'}
              name={'idTipoTrabajo'}
              onChange={handleChange}
              optionId={'id'}
              optionLabel={'descripcion'}
              value={filtroSolicitud.idTipoTrabajo}
              options={tiposTrabajo}
            />
            <FormSelectBox
              title={'Estado'}
              label={'estado'}
              id={'estado'}
              name={'idTipoEstado'}
              onChange={handleChange}
              optionId={'id'}
              optionLabel={'descripcion'}
              value={filtroSolicitud.idTipoEstado}
              options={estados}
            />
          </div>

          <div className="row mt-2">
            <FormSelectBox
              title={'Empresa Gestora'}
              label={'empresa gestora'}
              id={'empresaGestora'}
              name={'idEmpresaGestora'}
              onChange={handleChange}
              optionId={'id'}
              optionLabel={'nombreEmpresa'}
              value={filtroSolicitud.idEmpresaGestora}
              options={empresasGestoras}
            />
            <FormSelectBox
              title={'Contratista'}
              label={'contratista'}
              id={'contratista'}
              name={'idContratista'}
              onChange={handleChange}
              optionId={'id'}
              optionLabel={'razonSocial'}
              value={filtroSolicitud.idContratista}
              options={contratistas}
            />
          </div>
          <div className="row mt-2">
            <FormInput
              id={'fechaInicio'}
              label={'Fecha Solicitud Desde'}
              name={'fechaInicio'}
              placeholder={'dd/mm/yyyy'}
              onDateChange={(date: Date | null, name: string) => {
                handleDateChange(name, date);
              }}
              value={filtroSolicitud.fechaInicio}
              type='date'
            />
            <FormInput
              id={'fechaFin'}
              label={'Fecha Solicitud Hasta'}
              name={'fechaFin'}
              placeholder={'dd/mm/yyyy'}
              onDateChange={(date: Date | null, name: string) => {
                handleDateChange(name, date);
              }}
              value={filtroSolicitud.fechaFin}
              type='date'
            />

          </div>

          <div className="row mt-3"></div>
          <hr className="m-4" />
          <div className="row mt-3">
            <div className="col-md-12 text-center">
              <Button
                className="btn btn-secondary btn-rounded waves-effect waves-light"
                variant="secondary"
                onClick={handleClean}
              >
                <i className="fa fa-eraser mr-1"></i>
                Limpiar Filtros
              </Button>
              &nbsp;
              <Button
                className="btn btn-success btn-rounded waves-effect waves-light mr-1"
                variant="success"
                type="submit"
                onClick={handleSubmit}
              >
                <i
                  className="fa fa-search mr-1"
                  aria-hidden="true"
                ></i>
                Consultar
              </Button>
            </div>
          </div>
        </div>
      </Accordion>
    </>
  );
};
