import { useEffect, useState } from "react";
import { TipoTrabajo } from "../../../../types/TipoTrabajo";
import { useTipoTrabajo } from "../../../../hooks/useTipoTrabajo";
import FormSelectBox from "../../../../components/Form/FormSelectBox";
import FormInput from "../../../../components/Form/FormInput";


export const BuscarTipoTrabajo = () => {
    const { tipoTrabajoInitial, buscarTiposTrabajos, filtro, handleFiltroTipoTrabajo } = useTipoTrabajo();
    const [filtroTipoTrabajo, setFiltroTipoTrabajo] = useState<TipoTrabajo>(filtro);
    const [estados] = useState<any>(
        [

            {
                'id': 'true',
                'valor': 'ACTIVO'
            },
            {
                'id': 'false',
                'valor': 'INACTIVO'
            }
        ]);

    useEffect(() => {
        buscarTiposTrabajos(filtroTipoTrabajo);
    }, [])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault();
        setFiltroTipoTrabajo({
            ...filtroTipoTrabajo,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        buscarTiposTrabajos(filtroTipoTrabajo);
        handleFiltroTipoTrabajo(filtroTipoTrabajo)
    }

    const handleClean = () => {
        // setFiltroTipoTrabajo(newTipoUsuario);
        setFiltroTipoTrabajo(tipoTrabajoInitial);
        handleFiltroTipoTrabajo(tipoTrabajoInitial);
    };

    return (

        <div className="card">
            <form id="dto" className="form-horizontal" action="buscarPermisos" method="post">
                <div className="card-body">
                    <h4 className="m-t-0 header-title">
                        <i className="fa fa-search margin-right-7" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                        Buscar
                    </h4>
                    <hr />

                    <div className="row mt-2">


                        <FormInput
                            id={'nombre'}
                            label={'Nombre'}
                            name={'nombre'}
                            placeholder={'Nombre'}
                            onChange={handleChange}
                            value={filtroTipoTrabajo.nombre}


                        />

                        <FormSelectBox
                            title={'Estado'}
                            label={'estado'}
                            id={'activo'}
                            name={'activo'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'valor'}
                            value={filtroTipoTrabajo.activo || ''}
                            options={estados}
                            seleccionarText="TODOS"
                        />


                    </div>

                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-center">

                            <button className="btn btn-secondary btn-rounded waves-effect waves-light mr-1" type="button" id="limpiarFiltro" onClick={handleClean}>
                                <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                                Limpiar
                            </button>
                            <button className="btn btn-success btn-rounded waves-effect waves-light " type="submit" onClick={handleSubmit}>
                                <i className="fa fa-search mr-1"></i>
                                Buscar
                            </button>

                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}