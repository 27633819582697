import { Breadcrumbs } from "../../Home/Breadcrumb"
import { ToastContainer } from "react-toastify";

import { BuscarContratista } from "./Buscar";
import TableContratista from "./Table";

export const Contratista = () => {

    return (
        <>
            <ToastContainer />
            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title={'Contratistas'}
                            nameFirst={'Configuración'}
                            urlFirst={'/'}
                        />

                        <BuscarContratista />
                        <TableContratista />
                    </div>
                </div >
            </div >
        </>
    )
}
