import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../../../Home/Breadcrumb'
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Tree from 'rc-tree';
import "rc-tree/assets/index.css"
import { Perfil } from '../../../../../types/Perfil';
import { useUsuarios } from '../../../../../hooks/useUsuarios';
import { cargarCombo } from '../../../../../services/Service';
import FormSelectBox from '../../../../../components/Form/FormSelectBox';
import moment from 'moment'
import { Usuario } from '../../../../../types/Usuario';
import Confirm from '../../../../../components/Confirm';
import { Loading } from '../../../../../components/Loading';

const Icon = () => (
    <span className='fa fa-folder' style={{ color: 'gray' }} />
);

export const CrearUsuario = () => {
    const {
        usuarioSelected,
        perfiles,
        perfilesSelected,
        errors,
        error,
        usuarioInitial,
        loading,
        guardarUsuario,
        buscarUsuario,
        handleUsuarioSelected,
        showError,
        handlePerfilesSelected,
        handleGuardado,
        buscarArbolPerfiles
    } = useUsuarios();

    const [edit, setEdit] = useState<boolean>(false);
    const [validateError, setValidateError] = useState({
        nombre: '',
        apellido: '',
        nombreUsuario: '',
        numeroDocumento: '',
        idTipoDocumento: '',
        idTipoUsuario: '',
        perfiles: '',
        mail: '',
        idEmpresaGestora: '',
        idContratista: ''

    });
    const [tiposDocumentos, setTiposDocumentos] = useState<any>([]);
    const [tiposUsuarios, setTiposUsuarios] = useState<any>([]);
    const [empresasGestoras, setEmpresasGestoras] = useState<any>({});
    const [contratistas, setContratistas] = useState<any>({});
    const [disableEG, setDisableEG] = useState<boolean>(true);
    const [disableContratista, setDisableContratista] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        cargarCombo('tiposUsuarios', setTiposUsuarios);
        cargarCombo('tiposDocPersona', setTiposDocumentos);
        cargarCombo('empresasGestoras/current', setEmpresasGestoras);
        cargarCombo('contratistas/current', setContratistas);
        handleGuardado(false);
        if (id) {
            buscarUsuario(id, setCombosContratistaEG);
            setEdit(true);

        } else {
            handleUsuarioSelected(usuarioInitial);
            handlePerfilesSelected([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const perf: Perfil[] = perfilesSelected.map((p) => ({
            id: p,
            descripcion: '',
            acronimo: '',
            estado: false,
            permisosSelected: [],
            permisosNoAsignados: [],
            guardado: false,
            roles: []
        })).filter(p => p.id !== 'parent');

        let newErrors = validate(perf);

        handleUsuarioSelected({
            ...usuarioSelected,
            perfilesSelected: perf,
        });
        if (Object.keys(newErrors).length === 0) {
            const tipoUsuarioGuardar = {
                ...usuarioSelected,
                fechaAlta: usuarioSelected.fechaAlta === "" ? null : usuarioSelected.fechaAlta,
                fechaBaja: usuarioSelected.fechaBaja === "" ? null : usuarioSelected.fechaBaja,
                fechaModificacion: usuarioSelected.fechaModificacion === "" ? null : usuarioSelected.fechaModificacion,
                perfilesSelected: perf
            }
            guardarUsuario(tipoUsuarioGuardar);
        }
    }
    const validate = (perf: Perfil[]) => {
        let newErrors: any = {};
        const defaultMessage = 'Este campo es obligatorio'
        if (usuarioSelected.nombre === '') {
            newErrors.nombre = defaultMessage
        }
        if (usuarioSelected.idTipoDocumento === 0) {
            newErrors.idTipoDocumento = defaultMessage
        }
        if (usuarioSelected.numeroDocumento === '') {
            newErrors.numeroDocumento = defaultMessage
        }
        if (usuarioSelected.apellido === '') {
            newErrors.apellido = defaultMessage
        }
        if (usuarioSelected.nombreUsuario === '') {
            newErrors.nombreUsuario = defaultMessage
        }

        if (usuarioSelected.mail === '') {
            newErrors.mail = defaultMessage
        } else if (!isValidEmail(usuarioSelected.mail)) {
            newErrors.mail = 'Debe ingresar un Email valido'
        }

        if (usuarioSelected.idTipoUsuario == 0) {
            newErrors.idTipoUsuario = defaultMessage
        } else {
            const tipoUsuario = tiposUsuarios.filter((tu: any) => tu.id == usuarioSelected.idTipoUsuario);
            if (tipoUsuario[0]?.valor === 'EMPRESA_GESTORA') {

                if (usuarioSelected.idEmpresaGestora == 0) {
                    newErrors.idEmpresaGestora = defaultMessage
                }
            } else if (tipoUsuario[0]?.valor === 'CONTRATISTA') {
                if (usuarioSelected.idContratista == 0) {
                    newErrors.idContratista = defaultMessage
                }
            }
        }

        if (perf.length === 0) {
            newErrors.perfiles = 'Debe seleccionar un item de la lista'
        }
        setValidateError(newErrors);
        return newErrors;
    }
    const handleChange = (e: any) => {
        e.preventDefault();

        const { name, value } = e.target;
        handleUsuarioSelected({
            ...usuarioSelected,
            [name]: value,
        });
        handleTipoUsuarioSelected(e);
        if (name === 'idTipoUsuario') {
            setValidateError({
                ...validateError,
                [name]: '',
                idEmpresaGestora: '',
                idContratista: ''
            });

        } else {
            setValidateError({
                ...validateError,
                [name]: '',
            })
        }

    }
    const handleCancelar = (e: any) => {
        e.preventDefault();
        setShowModal(true)

    }

    const eliminar = () => {
        toggle();
        navigate('/usuarios')
    }
    // const onSelect = (selectedKeys: any, info: any) => {
    //     //console.log('selected', selectedKeys, info.checkedNodes);
    //     handlePermisosSelected([...selectedKeys])

    // };
    function handleTipoUsuarioSelected(e: any) {
        if (e.target.name === 'idTipoUsuario') {
            const perf: Perfil[] = perfilesSelected.map((p) => ({
                id: p,
                descripcion: '',
                acronimo: '',
                estado: false,
                permisosSelected: [],
                permisosNoAsignados: [],
                guardado: false,
                roles: []
            })).filter(p => p.id !== 'parent');

            handleUsuarioSelected({
                ...usuarioSelected,
                idEmpresaGestora: 0,
                idContratista: 0,
                [e.target.name]: e.target.value,
                perfilesSelected: perf,
            });
            let idTipoUsuario = e.target.value;
            if (idTipoUsuario == 0) {
                setDisableContratista(true);
                setDisableEG(true);
            }
            const tipoUsuario = tiposUsuarios.filter((tu: any) => tu.id == idTipoUsuario);

            if (tipoUsuario.length > 0) {
                setCombosContratistaEG(tipoUsuario[0].valor)
                buscarArbolPerfiles(idTipoUsuario)

            }
        }
    }

    const setCombosContratistaEG = (tipoUsuarioValor: string) => {
        setDisableContratista(true);
        setDisableEG(true);

        if (tipoUsuarioValor === 'EMPRESA_GESTORA') {
            setDisableContratista(true);
            setDisableEG(false);
        } else if (tipoUsuarioValor === 'CONTRATISTA') {
            setDisableContratista(false);
            setDisableEG(true);
        } else if (tipoUsuarioValor === 'ADMINISTRADOR') {
            setDisableContratista(true);
            setDisableEG(true);
        }

    }

    const onCheck = (checkedKeys: any, info: any) => {
        handlePerfilesSelected([...checkedKeys])
    };
    const fieldNames = {
        children: 'child',
        title: 'descripcion',
        key: 'id',
    };

    function isValidEmail(email: string) {
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        return isValidEmail.test(email);
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={edit ? 'Editar' : 'Nuevo'}
                        titlePage={'Usuario'}
                        nameFirst={'Seguridad'}
                        urlFirst={'/'}
                        nameSecond={'Usuario'}
                        urlSecond={'/usuarios'}
                    />
                    <Confirm isOpen={showModal} toggle={toggle} title='Cancelar' yesFn={eliminar}>
                        <div className="form-horizontal">
                            <div className="row">
                                {<p>Si cancela se perderán todos los datos. Está Ud. seguro?</p>}
                            </div>
                        </div>
                    </Confirm>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox">
                                        <div className="ibox-title">
                                            <h4 className="m-t-0 header-title">
                                                <i className="fa fa-plus-circle mr-2" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                {edit ? 'Editar' : 'Nuevo'}
                                            </h4>
                                            <hr />
                                        </div>
                                        <div className="ibox-content">
                                            {error ?
                                                <Alert variant='danger' style={{ width: "100%" }} onClose={() => showError(false)} dismissible>
                                                    {
                                                        errors?.map((e: any, index: number) => (
                                                            <p className="alert-danger" key={index}
                                                                style={{ height: "auto", wordWrap: "break-word" }}
                                                            > {e}
                                                            </p>
                                                        ))}
                                                </Alert>
                                                : ''
                                            }
                                            {loading ? <Loading /> : null}
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="nombre" style={{ color: "grey" }}>
                                                            <span className="obligatorio">(*) </span>
                                                        </label>
                                                        <FormSelectBox
                                                            title={'Tipo documento'}
                                                            // label={'tipo documento'}
                                                            id={'tipoDocumento'}
                                                            name={'idTipoDocumento'}
                                                            onChange={handleChange}
                                                            optionId={'id'}
                                                            optionLabel={'tipoDocumento'}
                                                            value={usuarioSelected.idTipoDocumento}
                                                            options={tiposDocumentos}
                                                            classLabel="col-form-label col-4 text-left"
                                                            classDivSelect="col-12"
                                                        />
                                                        {validateError.idTipoDocumento && <p className="text-danger">{validateError.idTipoDocumento}</p>}

                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="nombre" className='col-form-label col-4 text-start' style={{ color: "grey" }}>
                                                            <span className="obligatorio">(*) </span> Nro. Documento
                                                        </label>

                                                        <input id="numeroDocumento" name="numeroDocumento" className="form-control" onChange={handleChange}
                                                            value={usuarioSelected.numeroDocumento as string}
                                                        />
                                                        {validateError.numeroDocumento && <p className="text-danger">{validateError.numeroDocumento}</p>}

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="nombre" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Nombre
                                                            </label>
                                                            <input id="nombre" name="nombre" className="form-control" onChange={handleChange}
                                                                value={usuarioSelected.nombre as string}
                                                            />

                                                            {validateError.nombre && <p className="text-danger">{validateError.nombre}</p>}

                                                        </div>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="apellido" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Apellido
                                                            </label>
                                                            <input id="apellido" name="apellido" className="form-control" onChange={handleChange}
                                                                value={usuarioSelected.apellido as string}
                                                            />

                                                            {validateError.apellido && <p className="text-danger">{validateError.apellido}</p>}

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="nombreUsuario" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Nombre Usuario
                                                            </label>
                                                            <input id="nombreUsuario" name="nombreUsuario" className="form-control" onChange={handleChange}
                                                                value={usuarioSelected.nombreUsuario}
                                                            />

                                                            {validateError.nombreUsuario && <p className="text-danger">{validateError.nombreUsuario}</p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="estado" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Estado
                                                            </label>
                                                            <select id="estado" value={usuarioSelected.estado as unknown as string} name="estado" className="form-control select-listDos" onChange={handleChange}>
                                                                <option value="true">
                                                                    Activo
                                                                </option>
                                                                <option value="false">
                                                                    Inactivo
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="mail" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Email
                                                            </label>
                                                            <input id="mail" name="mail" placeholder='Ingrese Email' className="form-control" onChange={handleChange}
                                                                value={usuarioSelected.mail}
                                                            />

                                                            {validateError.mail && <p className="text-danger">{validateError.mail}</p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="nombreUsuario" style={{ color: "grey" }}>
                                                                Teléfono
                                                            </label>
                                                            <input id="telefono" type="number" name="telefono" className="form-control" onChange={handleChange}
                                                                value={usuarioSelected.telefono}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="fechaAlta" style={{ color: "grey" }}>
                                                                Fecha Alta
                                                            </label>
                                                            <input id="fechaAlta" name="fechaAlta" className="form-control" onChange={handleChange} disabled
                                                                value={usuarioSelected.fechaAlta ? moment(usuarioSelected.fechaAlta).format('DD/MM/YYYY') : ''}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="nombreUsuario" style={{ color: "grey" }}>
                                                                Fecha Modificación
                                                            </label>
                                                            <input id="fechaModificacion" name="fechaModificacion" className="form-control" onChange={handleChange} disabled
                                                                value={usuarioSelected.fechaModificacion ? moment(usuarioSelected.fechaModificacion).format('DD/MM/YYYY') : ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="nombre" style={{ color: "grey" }}>
                                                            <span className="obligatorio">(*) </span>
                                                        </label>
                                                        <FormSelectBox
                                                            title={'Tipo de Usuario'}
                                                            // label={'tipo de usuario'}
                                                            id={'tipoUsuario'}
                                                            name={'idTipoUsuario'}
                                                            onChange={handleChange}
                                                            optionId={'id'}
                                                            optionLabel={'nombre'}
                                                            value={usuarioSelected.idTipoUsuario}
                                                            options={tiposUsuarios}
                                                            classLabel="col-form-label col-4 text-left"
                                                            classDivSelect="col-12"
                                                        />

                                                        {validateError.idTipoUsuario && <p className="text-danger">{validateError.idTipoUsuario}</p>}

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="fechaBaja" className='col-form-label col-4 text-start' style={{ color: "grey" }}>
                                                                Fecha Baja
                                                            </label>
                                                            <input id="fechaBaja" name="fechaBaja" className="form-control" onChange={handleChange} disabled
                                                                value={usuarioSelected.fechaBaja ? moment(usuarioSelected.fechaBaja).format('DD/MM/YYYY') : ''}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                        <FormSelectBox
                                                            title={'Empresa Gestora'}
                                                            label={'empresa gestora'}
                                                            id={'empresaGestora'}
                                                            name={'idEmpresaGestora'}
                                                            onChange={handleChange}
                                                            optionId={'id'}
                                                            optionLabel={'nombreEmpresa'}
                                                            value={usuarioSelected.idEmpresaGestora || 0}
                                                            options={empresasGestoras}
                                                            classLabel="col-form-label col-4 text-left"
                                                            classDivSelect="col-12"
                                                            isDisabled={disableEG}
                                                        />
                                                        {validateError.idEmpresaGestora && <p className="text-danger">{validateError.idEmpresaGestora}</p>}

                                                    </div>
                                                    <div className="col-md-6">

                                                        <FormSelectBox
                                                            title={'Contratista'}
                                                            label={'contratista'}
                                                            id={'contratista'}
                                                            name={'idContratista'}
                                                            onChange={handleChange}
                                                            optionId={'id'}
                                                            optionLabel={'razonSocial'}
                                                            value={usuarioSelected.idContratista || 0}
                                                            options={contratistas}
                                                            classLabel="col-form-label col-4 text-left"
                                                            classDivSelect="col-12"
                                                            isDisabled={disableContratista}
                                                        />
                                                        {validateError.idContratista && <p className="text-danger">{validateError.idContratista}</p>}

                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="url" style={{ color: "grey" }}>
                                                                Perfiles
                                                            </label>
                                                            <Tree
                                                                checkable
                                                                fieldNames={fieldNames}
                                                                defaultExpandedKeys={['parent']}
                                                                // onSelect={onSelect}
                                                                onCheck={onCheck}
                                                                treeData={perfiles}
                                                                icon={Icon}
                                                                checkedKeys={perfilesSelected}
                                                                expandedKeys={['parent']}

                                                            />
                                                            {validateError.perfiles && <p className="text-danger">{validateError.perfiles}</p>}

                                                        </div>
                                                    </div>
                                                </div>

                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <div className="form-group margin-bottom-10">

                                                            <button className="btn btn-secondary btn-rounded waves-effect waves-light" onClick={handleCancelar}>
                                                                <i className="fa fa-times mr-1" aria-hidden="true"></i>
                                                                Cancelar
                                                            </button>

                                                            <button type="submit" id="btnClean" className="btn btn-success btn-rounded waves-effect waves-light">
                                                                <i className="fa fa-save"></i>&nbsp;
                                                                Guardar
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
