import { Link, useNavigate } from "react-router-dom";

const Accordion = ({
  id,
  title,
  eventKey = '0',
  children,
  secondary = false,
  titleSecondary = '',
  collapsed = false,
  iconoClass = 'fa fa-list mr-1',
  handleClick,
  handleSecondary,
  link = '/',

}: {
  id?: string;
  title: string;
  eventKey?: string;
  children: any;
  secondary?: boolean;
  titleSecondary?: string;
  collapsed?: boolean;
  iconoClass?: string;
  handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  //handleSecondary?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  handleSecondary?: () => void;
  link?: string;

}) => {

  function handleClickAccordeon(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    handleClick?.(event);
    event.stopPropagation()
  }

  const handleLink = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    handleSecondary?.();
  }


  return (
    <>
      <div
        className="accordion accordion-poncho"
        id={`buscar${id}`}
        style={
          { marginTop: "12px" }
        }
      >
        {secondary &&
          <Link to={link} onClick={handleLink} className="btn btn-secondary  bg-black  float-end btn-rounded">
            <i className="fa fa-file mr-1 me-2"></i>
            {titleSecondary}
          </Link>
        }
        <div
          className="card-header cursor bg-contratista headingAccordion"
          id={`heading${id}`}
          data-bs-toggle="collapse"
          data-bs-target={`#${id}`}
          aria-expanded="false"
          onClick={handleClickAccordeon}
        >
          <div className="d-flex justify-content-between">
            <span className="d-none d-sm-block text-white p-2">
              <i className={iconoClass}></i>
              {title}
            </span>

          </div>
        </div>
        <form id="formBuscar" className="form-horizontal">
          <div
            id={`${id}`}
            className={`collapse collapsed ps-3 pe-3 ${collapsed ? '' : 'show'}`}
            aria-labelledby={`heading${id}`}
            data-parent="#accordionBuscar"
            aria-expanded="false"
          >
            {children}
          </div>
        </form>
      </div >
    </>
  );
};

export default Accordion;
