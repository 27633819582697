import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ObservacionSolicitud } from '../../types/ObservacionSolicitud';

interface ObservacionState {
    observaciones: ObservacionSolicitud[];
    observacionSelected: ObservacionSolicitud;
    loading: boolean;
}
export const observacionInitial: ObservacionSolicitud = {
    id: 0,
    observacion: '',
    fechaObservacion: '',
    usuario: '',
    idSolicitud: 0,
    idTipoEstadoTemporal: 0
}

const initialState: ObservacionState = {
    observaciones: [],
    observacionSelected: observacionInitial,
    loading: false,
};

export const observacionSlice = createSlice({
    name: 'observacion',
    initialState,
    reducers: {
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadData: (state, data: PayloadAction<ObservacionSolicitud>): void => {
            state.observacionSelected = data.payload;
            state.loading = false;
        },
        loadList: (state, data: PayloadAction<ObservacionSolicitud[]>): void => {
            state.observaciones = data.payload;
            state.loading = false;
        }
    },
})

export const { isLoading, loadData, loadList } = observacionSlice.actions;
export default observacionSlice.reducer;
