import FormInput from "../../../../../../components/Form/FormInput"
import moment from 'moment'
import { usePersonalAfectado } from "../../../../../../hooks/usePersonalAfectado";
import { useDocumentoPersAfectado } from "../../../../../../hooks/useDocumentoPersAfectado";
import Table, { ColumnDefinitionType } from "../../../../../../components/Table";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import MyModal from "../../../../../../components/Modal";
import { useDocumento } from "../../../../../../hooks/useDocumento";
import { Historial } from "../../../DocumentosAdjuntos/historial";
import { downloadAndOpenFile, downloadFile, findPersonalByTipoAndNumDoc, puedeAdjuntar } from '../../../../../../services/Service';
import { Button } from "react-bootstrap";
import FormSelectBox from "../../../../../../components/Form/FormSelectBox";
import { useSolicitud } from "../../../../../../hooks/useSolicitud";
import { AgregarDocumento } from "../../DocumentoAdjunto/agregar";
import { toast } from 'react-toastify';
import { DocumentoPersAutonomo } from "../../../../../../types/DocumentoPersAutonomo";
import ObservacionComponent from "../../../../../../components/Observaciones";
import { APROBADA_STATUS_ID, EN_ACTUALIZACION_STATUS_ID, OBSERVADA_STATUS_ID, REVISION_STATUS_ID, SIN_VISAR_DOCUMENTO_STATUS } from "../../../../../../constants/constants";
import { useUserLogin } from "../../../../../../hooks/useUserLogin";

interface EditPersonalAutonomoProps {
    tiposDocumentosPers: any;
    onClose: () => void;
    onSave: (formData: FormData, currentSave: HTMLButtonElement | null) => void
    isEditing: boolean;
    actividades: any[];
}

interface FormData {
    id: number;
    tipoDocumento: string;
    nroDocumento: string;
    nombre: string;
    apellido: string;
    fechaIngreso: Date | null; // Puede ser una cadena o null
    actividad: string;
    documentosPersAutonomo: any[];
    vigente: boolean;
}


const EditPersonalAutonomo: React.FC<EditPersonalAutonomoProps> = ({ onClose, isEditing, tiposDocumentosPers, actividades, onSave }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAddDocumentPers, setIsOpenAddDocumentPers] = useState(false);
    const [isOpenModalConfirmDocumentDelete, setIsOpenModalConfirmDocumentDelete] = useState(false);
    const [isOpenModalActividadDocumentDelete, setIsOpenModalActividadDocumentDelete] = useState(false);
    const { solicitudSelected } = useSolicitud();
    const [selectedIdTipoDocumento, setSelectedIdTipoDocumento] = useState<number>(0); // Estado para mantener el ID seleccionado
    const toggle = () => setIsOpen(!isOpen);
    const { personalAutonomoSelected } = usePersonalAfectado();
    const [actividadSelect, setActividadSelect] = useState<string>('');
    const [documentoActividad, setDocumentoActividad] = useState<any[]>([]);
    const { documentoPersonalAutonomoList, documentoPersonalAutonomoSelected, handleDocumentoPersAutonomoSelected, actualizarDocumentos } = useDocumentoPersAfectado();
    const { historialDocumentos, buscarDocumentosByIdTipoDocumento, handleDocumentoSelected, documentoInitial } = useDocumento();
    const [listDocumentoPersonalAutonomo, setListDocumentoPersonalAutonomo] = useState<DocumentoPersAutonomo[]>([]);
    const [minDateFechaIngreso, setMinDateFechaIngreso] = useState<Date>();
    const [formData, setFormData] = useState<FormData>({
        id: 0,
        tipoDocumento: '',
        nroDocumento: '',
        nombre: '',
        apellido: '',
        fechaIngreso: null,
        actividad: '',
        documentosPersAutonomo: [],
        vigente: true

    });
    const [isOpenModalObservacionDocument, setIsOpenModalObservacionDocument] = useState(false);
    const [isOpenModalHistorial, setIsOpenModaHistorial] = useState(false);

    const { cargarCombo } = useSolicitud();
    const { isContratistaTipoUsuario, isEmpresaGestoraTipoUsuario } = useUserLogin();
    const [isEmpresaGestora] = useState<boolean>(isEmpresaGestoraTipoUsuario())
    const [isContratista] = useState<boolean>(isContratistaTipoUsuario())
    const [isDisabled, setDisabled] = useState<boolean>((isEmpresaGestora && solicitudSelected.idTipoEstado == REVISION_STATUS_ID) || (isContratista && solicitudSelected.idTipoEstado == REVISION_STATUS_ID));
    const [saveRevision, setSaveRevision] = useState(false);
    const [estados] = useState<any>(
        [
            {
                'id': true,
                'valor': 'Activo'
            },
            {
                'id': false,
                'valor': 'Inactivo'
            }
        ]);
    let btnRef = useRef<HTMLButtonElement>(null);

    const handleDocumento = async (id: number) => {
        setSelectedIdTipoDocumento(id);
        setIsOpenModaHistorial(true);
    }
    const getDocumentoSelected = (id: number) => {
        if (id) {
            const doc = historialDocumentos.find(d => d.idTipoDocumento === id) || documentoInitial;
            handleDocumentoSelected(doc);
            toggle();
            setSelectedIdTipoDocumento(0);
        }
    }

    useEffect(() => {
        if (solicitudSelected.fechaInicio) {
            let dateInicioValue = moment(solicitudSelected.fechaInicio).toDate()
            setMinDateFechaIngreso(dateInicioValue)
        }
        if (isEditing && personalAutonomoSelected.id !== 0) {
            // Si estás en modo edición, puedes cargar los datos existentes en el estado formData
            // Utiliza los datos cargados para inicializar formData
            // Ejemplo:
            setFormData({
                id: personalAutonomoSelected.id,
                tipoDocumento: personalAutonomoSelected.idTipoDocumento,
                nombre: personalAutonomoSelected.nombre,
                actividad: personalAutonomoSelected.idActividad,
                apellido: personalAutonomoSelected.apellido,
                fechaIngreso: personalAutonomoSelected.fechaIngreso !== '' ? moment(personalAutonomoSelected.fechaIngreso, 'YYYY-MM-DD').toDate() : null,
                nroDocumento: personalAutonomoSelected.nroDocumento,
                documentosPersAutonomo: documentoPersonalAutonomoList,
                vigente: personalAutonomoSelected.vigente
            });
            cargarCombo(`documentoActividad/actividad/${personalAutonomoSelected.idActividad}`, setDocumentoActividad);
        }
        setDisabled((isEmpresaGestora && solicitudSelected.idTipoEstado == REVISION_STATUS_ID) || (isContratista && solicitudSelected.idTipoEstado == REVISION_STATUS_ID))
    }, []);

    useEffect(() => {

        if (documentoPersonalAutonomoList.length > 0) {
            setListDocumentoPersonalAutonomo(documentoPersonalAutonomoList.map(documento => ({
                id: documento.id,
                idTipoDocumento: documento.idTipoDocumento,
                tipoDocumento: documento.tipoDocumento,
                nombreArchivo: documento.nombreArchivo,
                fileId: documento.fileId,
                subido: documento.subido,
                ubicacion: documento.ubicacion,
                fechaVencimiento: documento.fechaVencimiento,
                formatoArchivo: documento.formatoArchivo,
                nombreTipoDocumento: documento.nombreTipoDocumento,
                idSolicitud: solicitudSelected.id,
                archivoSeleccionado: null,
                statusDocumento: documento.statusDocumento,
                observacion: documento.observacion,
                sizeMaximo: documento.sizeMaximo,
            })));
        } else {
            setListDocumentoPersonalAutonomo([])
        }
    }, [documentoPersonalAutonomoList])

    useEffect(() => {
        // Dependiendo de cómo se use historialDocumentos, podrías necesitar un efecto para manejar su actualización
        // Realiza las acciones que necesites con el historialDocumentos actualizado aquí
        getDocumentoSelected(selectedIdTipoDocumento);
    }, [historialDocumentos]);
    const columns: ColumnDefinitionType<DocumentoPersAutonomo, keyof DocumentoPersAutonomo>[] = []
    columns.push({
        key: 'tipoDocumento',
        header: 'TIpo Documento',
    });
    columns.push({
        key: 'nombreArchivo',
        header: 'Nombre',
    });
    columns.push({
        key: 'fechaVencimiento',
        header: 'Fecha Vencimiento',
        cell: (data: DocumentoPersAutonomo) => <>{data.fechaVencimiento ? moment(data.fechaVencimiento).format('DD/MM/YYYY') : ''}</>
    });
    if ((isContratistaTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID)) {
        columns.push({
            key: 'observacion',
            header: 'Observaciones',
            cell: (data: DocumentoPersAutonomo) => <>
                {
                    data.observacion &&
                    <a href="#" title="Observaciones" onClick={() => {
                        handleDocumentoPersAutonomoSelected(data);
                        setIsOpenModalObservacionDocument(true)
                    }}>
                        <i className="fa fa-search me-2" style={{ color: 'red' }} aria-hidden="true"></i>
                    </a>
                }
            </>
        });

    }
    columns.push({
        key: 'id',
        header: 'Acciones',
        cell: (data: DocumentoPersAutonomo) =>
            <>
                <Link title="Visualizar" to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadAndOpenFile('documento-personal/visualizarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                </Link>
                <Link title="Descargar" to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadFile('documento-personal/descargarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-arrow-down text-secondary me-2" aria-hidden="true"></i>
                </Link>
                {!isDisabled && solicitudSelected.idTipoEstado !== OBSERVADA_STATUS_ID && solicitudSelected.idTipoEstado !== APROBADA_STATUS_ID && <Link title="Eliminar" to="/delete" onClick={(e) => {
                    e.preventDefault();
                    setIsOpenModalConfirmDocumentDelete(true)
                    setSelectedIdTipoDocumento(data.idTipoDocumento)
                }} target="_blank" >
                    <i className="fa fa-trash text-secondary me-2" aria-hidden="true"></i>
                </Link>}
                {((isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID)) &&
                    <a href="#" title="Observaciones" onClick={() => {
                        handleDocumentoPersAutonomoSelected(data);
                        setIsOpenModalObservacionDocument(true)
                    }}>
                        <i className="fa fa-search text-secondary me-2" aria-hidden="true"></i>
                    </a>}
                {
                    ((isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (isContratistaTipoUsuario() && (solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID))) &&
                    <a href="#" title="Historial de Documentos" onClick={() => handleDocumento(data.idTipoDocumento)}>
                        <i className="fa fa-history text-secondary " aria-hidden="true"></i>
                    </a>
                }
            </>
    });
    columns.push({
        key: 'statusDocumento',
        header: '',
        isEnabledCheckStatusDocument: isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID,
    });

    const handleChangeTipoDocumento = (e: any) => {
        e.preventDefault();
        const idTipoDocumento = e.target.value;
        personalAutonomoSelected.tipoDocumento = idTipoDocumento;
    }

    const handleInputChange = (name: string, value: any) => {
        // Actualiza el estado con el nuevo valor
        if (name === 'actividad') {
            if (listDocumentoPersonalAutonomo.length > 0) {
                setIsOpenModalActividadDocumentDelete(true)
                setActividadSelect(value);
                return;
            }
            cargarCombo(`documentoActividad/actividad/${value}`, setDocumentoActividad);

        }
        if (name === 'nroDocumento') {

        }
        setFormData({
            ...formData,
            [name]: value,
        });

    };

    const handleOpenAddDocumentPerson = () => {
        if (formData.actividad) {
            cargarCombo(`documentoActividad/actividad/${formData.actividad}`, setDocumentoActividad);
            setIsOpenAddDocumentPers(true)
        } else {
            toast.error("Por favor, seleccionar una Actividad.");
        }

    }

    const validationPreSavedPersonalAutonomo = () => {
        if (isEmpresaGestora && solicitudSelected.idTipoEstado == REVISION_STATUS_ID) {
            btnRef!.current!.disabled = true;
            saveRevisionButtonFn();
            onClose();

        } else {
            if (!formData.tipoDocumento) {
                toast.error("Por favor, seleccionar un Tipo de Documento.");
                return;
            }

            if (!formData.nroDocumento) {
                toast.error("Por favor, completar el número Documento.");
                return;
            }

            if (!formData.nombre) {
                toast.error("Por favor, completar el nombre.");
                return;
            }

            if (!formData.apellido) {
                toast.error("Por favor, completar el apellido.");
                return;
            }

            if (!formData.fechaIngreso) {
                toast.error("Por favor, completar la fecha de Ingreso.");
                return;
            }

            if (!formData.actividad) {
                toast.error("Por favor, seleccionar una Actividad.");
                return;
            }
            btnRef!.current!.disabled = true;
            formData.documentosPersAutonomo = listDocumentoPersonalAutonomo;
            if (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID) {
                // findDeletedDocuments(data.documentosPersSubContratado).forEach(documentDelete => deleteDocPersById('documento-personal-subcontratado', documentDelete.id));
                let documentUpload = formData.documentosPersAutonomo.filter((documentPersAutonomo: any) => documentPersAutonomo.archivoSeleccionado)
                if (documentUpload?.length >= 1) {
                    (async () => {
                        try {
                            let documentos = documentUpload.map((documentPersAutonomo: any) => {

                                return {
                                    ...documentPersAutonomo,
                                    archivoSeleccionado: null,
                                }
                            })
                            let puedeAdj: boolean = await puedeAdjuntar(`documento-personal-autonomo`, documentos);

                            if (!puedeAdj) {
                                toast.error("Se pueden adjuntar Tipos de Documentos vencidos, próximos a vencerse o no obligatorios");
                                btnRef!.current!.disabled = false;
                                return;

                            } else {
                                onSave(formData, btnRef!.current);
                                // onClose();
                            }

                        } catch (error) {
                            console.error('Error al verificar la fecha de vencimiento del documento:', error);
                        }

                    })();
                } else {
                    onSave(formData, btnRef!.current);
                    // onClose();
                }

            } else {
                onSave(formData, btnRef!.current);
                // onClose();

            }
        }
    }

    const addDocumentConfirmSave = (data: any) => {
        const documentoExistente = listDocumentoPersonalAutonomo.find((doc) => doc.idTipoDocumento === data.idTipoDocumento);

        if (documentoExistente) {
            // Si el documento ya existe, reemplaza el existente con el nuevo documento.
            let replaceDocument = { ...documentoExistente };
            replaceDocument.archivoSeleccionado = data.archivoSeleccionado;
            replaceDocument.idSolicitud = solicitudSelected.id;
            replaceDocument.tipoDocumento = data.tipoDocumento;
            replaceDocument.nombreArchivo = data.archivoSeleccionado.name;
            replaceDocument.statusDocumento = '';
            replaceDocument.observacion = '';
            replaceDocument.sizeMaximo = data.sizeMaximo;
            replaceDocumentoPersAutonomoByIdTipoDocumento(replaceDocument);
        } else {
            // Si el documento no existe, simplemente agrégalo al array.
            //let newDocumento = DocumentoPersAutonomoInitial;
            let DocumentoPersAutonomoInitial: DocumentoPersAutonomo = {
                id: 0,
                idTipoDocumento: data.idTipoDocumento,
                tipoDocumento: data.tipoDocumento,
                nombreArchivo: data.archivoSeleccionado.name,
                fileId: 0,
                subido: false,
                ubicacion: '',
                fechaVencimiento: '',
                formatoArchivo: '',
                nombreTipoDocumento: '',
                idSolicitud: solicitudSelected.id,
                archivoSeleccionado: data.archivoSeleccionado,
                statusDocumento: '',
                observacion: '',
                sizeMaximo: data.sizeMaximo,
            };
            setListDocumentoPersonalAutonomo(prevList => [...prevList, DocumentoPersAutonomoInitial]);
        }
    }

    const replaceDocumentoPersAutonomoByIdTipoDocumento = (nuevoElemento: DocumentoPersAutonomo) => {
        setListDocumentoPersonalAutonomo(prevList => {
            return prevList.map(item => {
                if (item.idTipoDocumento === nuevoElemento.idTipoDocumento) {
                    return nuevoElemento; // Reemplazar el elemento si se encuentra
                }
                return item; // Mantener el elemento sin cambios si no coincide el ID
            });
        });
    };

    const deleteDocumentoPersAutonomoByIdTipoDocumento = (idTipoDocumentoAEliminar: number) => {
        setListDocumentoPersonalAutonomo(prevList => {
            return prevList.filter(item => item.idTipoDocumento !== idTipoDocumentoAEliminar);
        });
    };

    const handleConfirmDeleteDocument = () => {
        deleteDocumentoPersAutonomoByIdTipoDocumento(selectedIdTipoDocumento)
        setIsOpenModalConfirmDocumentDelete(false)
    }

    const handleConfirmActividadDeleteAllDocument = () => {
        setListDocumentoPersonalAutonomo([]);
        setFormData({
            ...formData,
            'actividad': actividadSelect,
        });
        cargarCombo(`documentoActividad/actividad/${actividadSelect}`, setDocumentoActividad);
        setIsOpenModalActividadDocumentDelete(false)
    }

    const onCheckboxChange = (data: DocumentoPersAutonomo, status: string) => {
        setSaveRevision(true);
        setListDocumentoPersonalAutonomo((prevList: any) => {
            return prevList.map((item: any) => {
                if (data.id === item.id) {
                    // Modificamos solo el elemento deseado
                    return { ...item, statusDocumento: status };
                }
                // Mantenemos los demás elementos sin cambios
                return item;
            });
        });
    }

    const handleInputChangeNumDoc = (name: string, value: any) => {
        let tipoDoc: string = '';
        let numDocumento: string = '';

        if (name === 'nroDocumento') {
            numDocumento = value;
            tipoDoc = formData.tipoDocumento;

        } else if (name === 'tipoDocumento') {
            numDocumento = formData.nroDocumento;
            tipoDoc = value;
        }

        if (tipoDoc !== '' && numDocumento !== '') {
            findPersonalByTipoAndNumDoc(parseInt(tipoDoc), numDocumento).then(respuesta => {
                if (respuesta) {
                    setFormData({
                        ...formData,
                        [name]: value,
                        'nombre': respuesta.data.nombre,
                        'apellido': respuesta.data.apellido,
                    });

                } else {
                    setFormData({
                        ...formData,
                        [name]: value,
                    });
                }
            })

        } else {
            setFormData({
                ...formData,
                [name]: value,
            });

        }
        // console.log(formData);

    };

    const onObservacionChange = (observacion: string) => {
        setIsOpenModalObservacionDocument(false)
        setListDocumentoPersonalAutonomo((prevList: any) => {
            return prevList.map((item: any) => {
                if (documentoPersonalAutonomoSelected.id === item.id) {
                    // Modificamos solo el elemento deseado
                    return { ...item, observacion: observacion };
                }
                // Mantenemos los demás elementos sin cambios
                return item;
            });
        });
    }

    const saveRevisionButtonFn = () => {
        const documentoUpdateStatus = [];
        for (const documento of listDocumentoPersonalAutonomo) {
            documentoUpdateStatus.push({ id: documento.id, statusDocumento: documento.statusDocumento, observacion: documento.observacion });
        };

        actualizarDocumentos(documentoUpdateStatus, 'documento-personal-autonomo')
            .then(respuesta => {
                toast.success("Se actualizaron los documentos revisados");
            })
            .catch(error => {
                toast.error('Error en actualizar los documentos revisados');
            });

    }

    return (
        <>
            <MyModal
                isOpen={isOpenModalObservacionDocument} toggle={() => setIsOpenModalObservacionDocument(false)} title='Observaciones' cancelButton={false} secondButton={false}
            >
                <ObservacionComponent observacion={documentoPersonalAutonomoSelected.observacion} saveButtonFn={onObservacionChange} isEdit={!isContratista}
                    cancelButtonFn={() => setIsOpenModalObservacionDocument(false)} returnButtonFn={() => setIsOpenModalObservacionDocument(false)}
                ></ObservacionComponent>
            </MyModal>
            <MyModal isOpen={isOpenModalActividadDocumentDelete} toggle={() => setIsOpenModalActividadDocumentDelete(false)} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} titleCancelar={'Cancelar'} secondButtonFn={handleConfirmActividadDeleteAllDocument} >
                <label>Si cambia la Actividad se van a eliminar los documentos adjuntados. Desea confirmar?</label>
            </MyModal>
            <MyModal isOpen={isOpenModalConfirmDocumentDelete} toggle={() => setIsOpenModalConfirmDocumentDelete(false)} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} titleCancelar={'Cancelar'} secondButtonFn={handleConfirmDeleteDocument} >
                <label>Confirma la eliminación del registro?</label>
            </MyModal>
            <MyModal isOpen={isOpenModalHistorial} toggle={() => setIsOpenModaHistorial(false)} title='Historial de Documentos'>
                <Historial tipoDocumentoDisabled={true}
                    pathService={`documento-personal-autonomo/personal-autonomo/${personalAutonomoSelected.id}`}
                    idTipoDocumentoSelected={selectedIdTipoDocumento} />
            </MyModal>
            <MyModal isOpen={isOpenAddDocumentPers} toggle={() => setIsOpenAddDocumentPers(false)} title='Agregar Documentos'
                enabledFooter={false}>
                <AgregarDocumento tipoDocumentoDisabled={true} cancelButtonFn={() => setIsOpenAddDocumentPers(false)} listDocumentos={listDocumentoPersonalAutonomo}
                    saveCompletedButtonFn={(addDocument) => { addDocumentConfirmSave(addDocument) }} documentoActividad={documentoActividad} />
            </MyModal>
            <div>
                <div className="row mt-2">
                    <FormSelectBox
                        id={'tipoDocumento'}
                        label={'Tipo Doc Persona'}
                        name={'tipoDocumento'}
                        value={formData.tipoDocumento || ''}
                        isDisabled={(solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (formData.id !== 0 && (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID))}
                        title={'Tipo Doc Persona'}
                        onChange={(e) => handleInputChangeNumDoc(e.target.name, e.target.value)}
                        optionId={'id'}
                        optionLabel={'tipoDocumento'}
                        options={tiposDocumentosPers}
                        classLabel="col-form-label col-2 text-end"
                        classDivSelect="col-4"
                    />
                    <FormInput
                        id={'nroDocumento'}
                        label={'Nro. Documento'}
                        name={'nroDocumento'}
                        value={formData.nroDocumento || ''}
                        onChange={(e) => handleInputChangeNumDoc(e.target.name, e.target.value)}
                        isDisabled={(solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (formData.id !== 0 && (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID))}
                    />
                </div>
                <div className="row mt-2">
                    <FormInput
                        id={'nombre'}
                        label={'Nombre'}
                        name={'nombre'}
                        value={formData.nombre || ''}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        isDisabled={(solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (formData.id !== 0 && (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID))}
                    />
                    <FormInput
                        id={'apellido'}
                        label={'Apellido'}
                        name={'apellido'}
                        value={formData.apellido || ''}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        isDisabled={(solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (formData.id !== 0 && (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID))}
                    />
                </div>
                <div className="row mt-2">
                    <FormInput
                        id={'fechaIngreso'}
                        label={'Fecha Ingreso Trabajo'}
                        type="date"
                        name={'fechaIngreso'}
                        value={formData.fechaIngreso}
                        onDateChange={(date: Date | null, name: string) => {
                            handleInputChange(name, date);
                        }}
                        minDate={minDateFechaIngreso}
                        isDisabled={(solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (formData.id !== 0 && (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID))}
                    />
                </div>
                <div className="row mt-2">
                    <FormSelectBox
                        id={'actividad'}
                        label={'Actividad'}
                        name={'actividad'}
                        value={formData.actividad || ''}
                        isDisabled={(solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (formData.id !== 0 && (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID))}
                        title={'Actividad'}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        optionId={'id'}
                        optionLabel={'nombreActividad'}
                        options={actividades}
                        classLabel="col-form-label col-2 text-end"
                        classDivSelect="col-4"
                    />

                </div>
                {(solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID) &&
                    <div className="row mt-2">
                        <FormSelectBox
                            id={'vigente'}
                            label={'Estado'}
                            name={'vigente'}
                            value={formData.vigente || ''}
                            // isDisabled={formData.id !== 0}
                            title={'Estado'}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            optionId={'id'}
                            optionLabel={'valor'}
                            options={estados}
                            classLabel="col-form-label col-2 text-end"
                            classDivSelect="col-4"

                        />

                    </div>}
            </div>
            <div className='p-3'>
                <div className="row">
                    <div className="col-md-12 text-end">
                        {!isDisabled &&
                            <Button
                                disabled={isDisabled || !formData.vigente || documentoActividad.length === 0}
                                className="btn-secondary"
                                variant="secondary"
                                onClick={() => handleOpenAddDocumentPerson()}
                            > Agregar Documentos
                            </Button>
                        }
                    </div>
                </div>
                <div className="table-responsive ">
                    <Table data={listDocumentoPersonalAutonomo} columns={columns} onCheckboxChange={onCheckboxChange} ></Table>
                    <div className="col-md-12 d-flex justify-content-end mt-3">

                    </div>
                </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
                <Button color="secondary" onClick={validationPreSavedPersonalAutonomo} className="mr-2" ref={btnRef}>
                    Guardar
                </Button>
                <Button color="secondary" onClick={onClose} className="mr-2">
                    Salir
                </Button>
            </div>

        </>
    )
}

export default EditPersonalAutonomo