import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentoTrabajo } from "../../types/DocumentoTrabajo";

interface DocumentoTrabajoState {
  documentosTrabajo: DocumentoTrabajo[];
  documentoTrabajoSelected: DocumentoTrabajo;
  loading: boolean;
}
export const documentoTrabajoInitial: DocumentoTrabajo = {
  id: 0,
  obligatorio: false,
  tipoDocumentoNombre: null,
  tipoDocumentoDescripcion: '',
  tipoDocumentoId: 0,
  plantilla: '',
  formatoArchivo: '',
  sizeMaximo: 0,
  periodo: 0,
  renovable: false,
  nombrePlantilla: '',
  formatos: []
}

const initialState: DocumentoTrabajoState = {
  documentosTrabajo: [],
  documentoTrabajoSelected: documentoTrabajoInitial,
  loading: false,
};

const documentoTrabajoSlice = createSlice({
  name: 'documentoPersonalAfectado',
  initialState,
  reducers: {
    isLoading: (state, data: PayloadAction<boolean>): void => {
      state.loading = data.payload;
    },
    loadListDataDocumentoTrabajo: (state, action: PayloadAction<DocumentoTrabajo[]>) => {
      state.documentosTrabajo = action.payload;
    },
    loadDataDocumentoTrabajo: (state, action: PayloadAction<DocumentoTrabajo>) => {
      state.documentoTrabajoSelected = action.payload;
    }
  },
});

export const {
  loadListDataDocumentoTrabajo,
  loadDataDocumentoTrabajo
} = documentoTrabajoSlice.actions;

export default documentoTrabajoSlice.reducer;