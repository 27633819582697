import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../../../Home/Breadcrumb'
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useRol } from '../../../../../hooks/useRol';
import { Permiso } from '../../../../../types/Permiso';
import Tree from 'rc-tree';
import "rc-tree/assets/index.css"

const Icon = () => (
    <span className='fa fa-folder' style={{ color: 'gray' }} />
);

export const CrearRol = () => {
    const { rolInitial,
        rolSelected,
        permisos,
        permisosSelected,
        errors,
        error,
        guardarRol,
        buscarRol,
        handleRolSelected,
        buscarPermisosNoAsignados,
        showError,
        handlePermisosSelected,
        handleGuardado
    } = useRol();

    const [edit, setEdit] = useState<boolean>(false);
    const [validateError, setValidateError] = useState({
        descripcion: '',
        permisos: ''
    });
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        handleGuardado(false);
        if (id) {
            buscarRol(id);
            setEdit(true);
        } else {
            buscarPermisosNoAsignados();
            handleRolSelected(rolInitial);
            handlePermisosSelected([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault();

        let newErrors: any = {};
        const defaultMessage = 'Este campo es obligatorio'
        if (rolSelected.descripcion === '') {
            newErrors.descripcion = defaultMessage
        }

        const perm: Permiso[] = permisosSelected.map((p) => ({
            id: p,
            descripcion: '',
            acronimo: '',
            estado: false,
            visible: false,
            url: '',
            menuNombre: ''
        })).filter(p => p.id !== 'parent');


        if (perm.length === 0) {
            newErrors.permisos = 'Debe seleccionar un item de la lista'
        }
        setValidateError(newErrors);

        handleRolSelected({
            ...rolSelected,
            permisosSelected: perm,
        });
        if (Object.keys(newErrors).length === 0) {
            const rolGuardar = {
                ...rolSelected,
                permisosSelected: perm
            }
            guardarRol(rolGuardar);
        }
    }
    const handleChange = (e: any) => {
        e.preventDefault();
        const { name, value } = e.target;
        handleRolSelected({
            ...rolSelected,
            [name]: value,
        });
        setValidateError({
            ...validateError,
            [name]: '',
        })
    }
    const handleCancelar = (e: any) => {
        e.preventDefault();
        navigate('/roles')
    }
    // const onSelect = (selectedKeys: any, info: any) => {
    //     //console.log('selected', selectedKeys, info.checkedNodes);
    //     handlePermisosSelected([...selectedKeys])

    // };


    const onCheck = (checkedKeys: any, info: any) => {
        handlePermisosSelected([...checkedKeys])
    };
    const fieldNames = {
        children: 'child',
        title: 'descripcion',
        key: 'id',
    };

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={edit ? 'Editar' : 'Nuevo'}
                        titlePage={'Roles'}
                        nameFirst={'Seguridad'}
                        urlFirst={'/'}
                        nameSecond={'Roles'}
                        urlSecond={'/roles'}
                    />
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox">
                                        <div className="ibox-title">
                                            <h4 className="m-t-0 header-title">
                                                <i className="fa fa-plus-circle mr-2" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                {edit ? 'Editar' : 'Nuevo'}
                                            </h4>
                                            <hr />
                                        </div>
                                        <div className="ibox-content">
                                            {error ?
                                                <Alert variant='danger' style={{ width: "100%" }} onClose={() => showError(false)} dismissible>
                                                    {
                                                        errors?.map((e: any, index: number) => (
                                                            <p className="alert-danger" key={index}
                                                                style={{ height: "10px", display: "grid" }}
                                                            > {e}
                                                            </p>
                                                        ))}
                                                </Alert>
                                                : ''
                                            }
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="descripcion" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Descripcion
                                                            </label>
                                                            <input id="descripcion" name="descripcion" className="form-control" onChange={handleChange}
                                                                value={rolSelected.descripcion as string}
                                                            />

                                                            {validateError.descripcion && <p className="text-danger">{validateError.descripcion}</p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="estado" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Estado
                                                            </label>
                                                            <select id="estado" value={rolSelected.estado as unknown as string} name="estado" className="form-control select-listDos" onChange={handleChange}>
                                                                <option value="true">
                                                                    Activo
                                                                </option>
                                                                <option value="false">
                                                                    Inactivo
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="url" style={{ color: "grey" }}>
                                                                Permisos
                                                            </label>
                                                            <Tree
                                                                checkable
                                                                fieldNames={fieldNames}
                                                                defaultExpandedKeys={['parent']}
                                                                // onSelect={onSelect}
                                                                onCheck={onCheck}
                                                                treeData={permisos}
                                                                icon={Icon}
                                                                checkedKeys={permisosSelected}
                                                                expandedKeys={['parent']}

                                                            />
                                                            {validateError.permisos && <p className="text-danger">{validateError.permisos}</p>}

                                                        </div>
                                                    </div>
                                                </div>

                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <div className="form-group margin-bottom-10">

                                                            <button className="btn btn-secondary btn-rounded waves-effect waves-light" onClick={handleCancelar}>
                                                                <i className="fa fa-times mr-1" aria-hidden="true"></i>
                                                                Cancelar
                                                            </button>

                                                            <button type="submit" id="btnClean" className="btn btn-success btn-rounded waves-effect waves-light">
                                                                <i className="fa fa-save"></i>&nbsp;
                                                                Guardar
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
