import { useEffect, useState } from "react";
import FormSelectBox from "../../../../components/Form/FormSelectBox";
import FormInput from "../../../../components/Form/FormInput";
import { TipoDocumento } from "../../../../types/TipoDocumento";
import { useTipoDocumento } from "../../../../hooks/useTipoDocumento";

const filtroInitial: TipoDocumento = {
    id: 0,
    nombre: "",
    descripcion: "",
    activo: null,
    sizeMaximo: 0,
    formatoArchivo: "",
    contenidoPlantilla: null,
    nombrePlantilla: null,
    formatos: [],
}
export const BuscarTipoDocumento = () => {
    const { tipoDocumentoInitial, buscarTiposDocumentos, handleFiltroTipoDocumento, filtro } = useTipoDocumento();
    const [filtroTipoDocumento, setFiltroTipoDocumento] = useState<TipoDocumento>(filtro);
    const [estados] = useState<any>(
        [

            {
                'id': 'true',
                'valor': 'ACTIVO'
            },
            {
                'id': 'false',
                'valor': 'INACTIVO'
            }
        ]);

    useEffect(() => {
        buscarTiposDocumentos(filtro);
    }, [])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault();
        setFiltroTipoDocumento({
            ...filtroTipoDocumento,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        buscarTiposDocumentos(filtroTipoDocumento);
        handleFiltroTipoDocumento(filtroTipoDocumento)
    }

    const handleClean = () => {
        setFiltroTipoDocumento(filtroInitial);
        handleFiltroTipoDocumento(filtroInitial);
    };

    return (

        <div className="card">
            <form id="dto" className="form-horizontal" action="buscar" method="post">
                <div className="card-body">
                    <h4 className="m-t-0 header-title">
                        <i className="fa fa-search margin-right-7" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                        Buscar
                    </h4>
                    <hr />

                    <div className="row mt-2">


                        <FormInput
                            id={'nombre'}
                            label={'Nombre'}
                            name={'nombre'}
                            placeholder={'Nombre'}
                            onChange={handleChange}
                            value={filtroTipoDocumento.nombre}
                            maxLength={90}

                        />

                        <FormSelectBox
                            title={'Estado'}
                            label={'estado'}
                            id={'activo'}
                            name={'activo'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'valor'}
                            value={filtroTipoDocumento.activo || ''}
                            options={estados}
                            seleccionarText="TODOS"
                        />


                    </div>

                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-center">

                            <button className="btn btn-secondary btn-rounded waves-effect waves-light mr-1" type="button" id="limpiarFiltro" onClick={handleClean}>
                                <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                                Limpiar
                            </button>
                            <button className="btn btn-success btn-rounded waves-effect waves-light " type="submit" onClick={handleSubmit}>
                                <i className="fa fa-search mr-1"></i>
                                Buscar
                            </button>

                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}