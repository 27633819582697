import { ColumnDefinitionType } from "..";
import { APROBADO_DOCUMENTO_STATUS, NO_APROBADO_DOCUMENTO_STATUS, SIN_VISAR_DOCUMENTO_STATUS } from "../../../constants/constants";
import './index.css'

type TableRowsProps<T, K extends keyof T> = {
    data: Array<T>;
    columns: Array<ColumnDefinitionType<T, K>>;
    onCheckboxChange?: (row: T, value: string) => void;
}

const TableRows = <T, K extends keyof T>({ data, columns, onCheckboxChange }: TableRowsProps<T, K>): JSX.Element => {
    const getStatusClassName = (status: any): string => {
        if (!status) return "amarillo";
        switch (status) {
            case APROBADO_DOCUMENTO_STATUS:
                return "verde";
            case NO_APROBADO_DOCUMENTO_STATUS:
                return "rojo";
            case SIN_VISAR_DOCUMENTO_STATUS:
                return "amarillo";
            default:
                return "";
        }
    };

    const getStatusPointClassName = (status: any): string => {
        if (!status) return "punto-amarillo";
        switch (status) {
            case APROBADO_DOCUMENTO_STATUS:
                return "punto-verde";
            case NO_APROBADO_DOCUMENTO_STATUS:
                return "punto-rojo";
            case SIN_VISAR_DOCUMENTO_STATUS:
                return "punto-amarillo";
            default:
                return "";
        }
    };
    const columnRender = (row: T, column: ColumnDefinitionType<T, K>) => {
        if (column.cell) return column.cell(row);
        // Verificar si el tipo de columna es booleano y si hay una personalización definida
        const value = row[column.key];
        if (typeof value === "boolean" && column.customBooleanRender) {
            return column.customBooleanRender(value);
        }
        return row[column.key] as React.ReactNode;
    };

    const rows = data.map((row, index) => {

        return (
            <tr key={`row-${index}`}>
                {columns.map((column, index2) => {
                    if (column.key === "statusDocumento") {
                        const statusDocument = row[column.key];
                        return (
                            <td key={`cell-${index2}`} className={`check-validacion-container ${getStatusClassName(statusDocument)}`}>
                                <span className={getStatusPointClassName(statusDocument)}></span>
                                {column.isEnabledCheckStatusDocument && onCheckboxChange && <>
                                    <div className="form-check form-check-inline" >

                                        <input
                                            style={{ appearance: "auto" }}
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={statusDocument === APROBADO_DOCUMENTO_STATUS}
                                            onChange={() => onCheckboxChange(row, APROBADO_DOCUMENTO_STATUS)}
                                        />
                                        <label className="form-check-label" style={{ fontWeight: "300", fontSize: "13px" }}>
                                            Si
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline" >


                                        <input

                                            style={{ appearance: "auto" }}

                                            className="form-check-input"
                                            type="checkbox"
                                            checked={statusDocument === NO_APROBADO_DOCUMENTO_STATUS}
                                            onChange={() => onCheckboxChange(row, NO_APROBADO_DOCUMENTO_STATUS)}
                                        />
                                        <label className="form-check-label" style={{ fontWeight: "300", fontSize: "13px" }}>
                                            No
                                        </label>
                                    </div>
                                </>
                                }
                            </td>
                        );
                    }

                    return (
                        <td key={`cell-${index2}`} className={`${column.cellClassName || ''}`} >
                            {columnRender(row, column)}
                        </td>
                    );
                })}
            </tr>
        );
    });

    return (
        <tbody>
            {rows}
        </tbody>
    );
};

export default TableRows;