// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FormTextArea.module.css */

.custom-textarea {
    position: relative;
}

.custom-textarea label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    color: #6c757d;
    font-size: 0.8em;
}

.custom-textarea textarea {
    border: 1px solid #ced4da;
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Form/FormTextArea/FormTextArea.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,uBAAuB;IACvB,cAAc;IACd,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":["/* FormTextArea.module.css */\n\n.custom-textarea {\n    position: relative;\n}\n\n.custom-textarea label {\n    position: absolute;\n    top: -10px;\n    left: 10px;\n    background-color: white;\n    padding: 0 5px;\n    color: #6c757d;\n    font-size: 0.8em;\n}\n\n.custom-textarea textarea {\n    border: 1px solid #ced4da;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
