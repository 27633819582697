import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import MetisMenu from '@metismenu/react';
import '../../../node_modules/metismenujs/dist/metismenujs.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useAuth } from '../../hooks/useAuth';

const Menu = () => {

    const { permisos } = useSelector((state: RootState) => state.auth);
    const { handlePermisos } = useAuth()

    useEffect(() => {
        const authorities = localStorage.getItem('roles') || '{}';
        handlePermisos(authorities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userHasRole = (role: string) => {
        return permisos.find(nombre => nombre === role) !== undefined;
    }
    return (



        <React.Fragment>
            <div className="left side-menu">
                <div className="slimScrollDiv" style={{ position: "relative", overflow: "hidden", width: "auto", height: "782px" }}>
                    <div className="slimscroll-menu" id="remove-scroll" style={{ overflow: "hidden", width: "auto", height: "782px" }}>
                        <div id="sidebar-menu" className='mm-active'>
                            <MetisMenu className='list-unstyled' >
                                <li className='menu-title'>Navegacion</li>
                                {permisos ? <>
                                    {userHasRole('CONFIG_TIPO_TRABAJO') || userHasRole('CONFIG_TIPO_DOC') || userHasRole('CONFIG_EMP_GESTORA')
                                    || userHasRole('CONFIG_CONTRATISTA') || userHasRole('CONFIG_ACT')
                                    || userHasRole('CONFIG_EQUIPO') ?
                                        <li>
                                            <Link to="#" className="has-arrow" >
                                                <img
                                                    src={require('../../resources/images/ico-poncho.png')}
                                                    style={{ width: '15px' }} />
                                                <span className="nav-label"> Configuración</span>
                                            </Link>
                                            <ul className='nav-second-level nav mm-collapse mm-show ' style={{ flexFlow: 'row wrap' }} >
                                                {userHasRole('CONFIG_TIPO_TRABAJO') && <li><NavLink to="/tipoTrabajo">Tipo de Trabajo</NavLink></li>}
                                                {userHasRole('CONFIG_TIPO_DOC') && <li><NavLink to="/tipoDocumento">Tipos de Documentos</NavLink></li>}
                                                {userHasRole('CONFIG_EMP_GESTORA') && <li><NavLink to="/">Empresas Gestoras</NavLink></li>}
                                                {userHasRole('CONFIG_CONTRATISTA') && <li><NavLink to="/contratistas">Contratistas</NavLink></li>}
                                                {userHasRole('CONFIG_ACT') && <li><NavLink to="/actividades">Actividades</NavLink></li>}

                                            </ul>
                                        </li>
                                        : null}
                                    {userHasRole('CONFIG_USUARIO') || userHasRole('CONFIG_TIPO_USUARIO') || userHasRole('CONFIG_PERMISO')
                                    || userHasRole('CONFIG_ROL') || userHasRole('CONFIG_PERFIL') ?
                                        <li>
                                            <Link to="#" className="has-arrow">
                                                <img
                                                    src={require('../../resources/images/ico-poncho.png')}
                                                    style={{ width: '15px' }} />
                                                <span className="nav-label"> Seguridad</span>
                                            </Link>
                                            <ul className='nav-second-level nav mm-collapse mm-show ' style={{ flexFlow: 'row wrap' }} >
                                                {userHasRole('CONFIG_USUARIO') && <li><NavLink to="/usuarios">Usuarios</NavLink></li>}
                                                {userHasRole('CONFIG_TIPO_USUARIO') && <li><NavLink to="/tiposUsuarios">Tipos de Usuarios</NavLink></li>}
                                                {userHasRole('CONFIG_PERFIL') && <li><NavLink to="/perfiles">Perfiles</NavLink></li>}
                                                {userHasRole('CONFIG_PERMISO') && <li><NavLink to="/permisos">Permisos</NavLink></li>}
                                                {userHasRole('CONFIG_ROL') && <li><NavLink to="/roles">Roles</NavLink></li>}

                                            </ul>
                                        </li>
                                        : null}

                                    {userHasRole('CONSULTA_SOLICITUD') || userHasRole('VISTA_SOLICITUD')
                                    || userHasRole('ALTA_SOLICITUD') || userHasRole('EDICION_SOLICITUD')
                                    || userHasRole('BANDEJA_TRABAJO') ?
                                        <li>
                                            <NavLink to="/consultaSolicitudes">
                                                <i className="undefined"></i><span className='nav-label'>Gestion de Solicitudes de Trabajo</span>
                                            </NavLink>
                                        </li>
                                        : null}

                                    {userHasRole('VISTA_SOLICITUD')
                                    || userHasRole('EDICION_SOLICITUD')
                                    || userHasRole('BANDEJA_TRABAJO') ?
                                        <li>
                                            <NavLink to="/bandejaTrabajo">
                                                <i className="undefined"></i><span className="" style={{ marginLeft: '-37px' }}>Bandeja de Trabajo</span>
                                            </NavLink>
                                        </li>
                                        : null}


                                </> : null
                                }

                            </MetisMenu>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};

export default Menu;
