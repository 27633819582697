import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TipoTrabajo } from "../../types/TipoTrabajo";
import { Actividad } from "../../types/Actividad";
import { EquipoMaquinaria } from "../../types/EquipoMaquinaria";
import { DocumentoTrabajo } from "../../types/DocumentoTrabajo";


interface TipoTrabajoState {
    tipoTrabajoList: TipoTrabajo[];
    actividadList: Actividad[];
    equipoList: EquipoMaquinaria[];
    documentoList: DocumentoTrabajo[];
    tipoTrabajoSelected: TipoTrabajo;
    filtro: TipoTrabajo;
    loading: boolean;
    errors: any;
    error: boolean,
    guardado: boolean,
}

export const tipoTrabajoInitial: TipoTrabajo = {
    id: 0,
    nombre: "",
    descripcion: "",
    personalAfectado: false,
    equipoAfectado: false,
    actividades: [],
    equipos: [],
    documentos: [],
    activo: true,
    logo: null,
    logoNuevo: null
}


const initialState: TipoTrabajoState = {
    tipoTrabajoList: [],
    actividadList: [],
    equipoList: [],
    documentoList: [],
    tipoTrabajoSelected: tipoTrabajoInitial,
    filtro: { ...tipoTrabajoInitial, activo: null },
    loading: false,
    errors: [],
    error: false,
    guardado: false
}

const tipoTrabajoSlice = createSlice({
    name: 'tipoTrabajo',
    initialState,
    reducers: {
        addTipoTrabajo: (state, data: PayloadAction<TipoTrabajo>): void => {
            state.tipoTrabajoSelected = tipoTrabajoInitial;
            state.error = false;
            state.guardado = true;
        },
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataTipoTrabajo: (state, data: PayloadAction<TipoTrabajo>): void => {
            state.tipoTrabajoSelected = data.payload;
            state.error = false;
        },
        loadListTipoTrabajo: (state, data: PayloadAction<TipoTrabajo[]>): void => {
            state.tipoTrabajoList = data.payload;
        },
        loadListActividad: (state, data: PayloadAction<Actividad[]>): void => {
            state.actividadList = data.payload;
        },
        loadListEquipo: (state, data: PayloadAction<EquipoMaquinaria[]>): void => {
            state.equipoList = data.payload;
        },
        loadListDocumento: (state, data: PayloadAction<DocumentoTrabajo[]>): void => {
            state.documentoList = data.payload;
        },
        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        removeTipoTrabajo: (state, data: PayloadAction<number>): void => {
            state.tipoTrabajoList = state.tipoTrabajoList.filter(p => p.id !== data.payload)
        },
        setGuardado: (state, data: PayloadAction<boolean>): void => {
            state.guardado = data.payload;
        },
        loadDataFiltro: (state, data: PayloadAction<TipoTrabajo>): void => {
            state.filtro = data.payload;
        },
    }
});
export const {
    addTipoTrabajo,
    isLoading,
    loadDataTipoTrabajo,
    loadListTipoTrabajo,
    loadListActividad,
    loadListEquipo,
    loadListDocumento,
    loadingError,
    visibleError,
    removeTipoTrabajo,
    setGuardado,
    loadDataFiltro
} = tipoTrabajoSlice.actions;

export default tipoTrabajoSlice.reducer;