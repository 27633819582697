/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { usePersonalAfectado } from '../../../../../hooks/usePersonalAfectado';
import Table, { ColumnDefinitionType } from '../../../../../components/Table';
import { PersonalAutonomo } from '../../../../../types/PersonalAutonomo';
import moment from 'moment'
import { Link } from 'react-router-dom';
import DetailPersonalAutonomo from '../DetailPersonalAutonomo';
import MyModal from '../../../../../components/Modal';
import { useDocumentoPersAfectado } from '../../../../../hooks/useDocumentoPersAfectado';
import EditPersonalAutonomo from '../DetailPersonalAutonomo/EditPersonalAutonomo';
import { useSolicitud } from '../../../../../hooks/useSolicitud';
import { uploadFiles } from '../../../../../services/Service';
import { APROBADA_STATUS_ID, EN_ACTUALIZACION_STATUS, EN_ACTUALIZACION_STATUS_ID, OBSERVADA_STATUS_ID, REVISION_STATUS_ID } from '../../../../../constants/constants';
import { useUserLogin } from '../../../../../hooks/useUserLogin';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

interface TablePersonalAutonomoProps {
    tiposDocumentosPers: any;
    onClose: () => void;
    onModalAction: (isOpen: boolean) => void;
    isEditing: boolean;
    modalOpen: boolean;
    onIsEditing: (updateEditable: boolean) => void;
}

const TablePersonalAutonomo: React.FC<TablePersonalAutonomoProps> = ({ onClose, isEditing, onIsEditing, tiposDocumentosPers, modalOpen, onModalAction }) => {
    const { personalAutonomoList, handlePersonalAutonomoSelected, eliminarPersonalAutonomoById, personalAutonomoSelected, saveOrUpdatePersonalAfectado } = usePersonalAfectado();
    const { buscarDocPersAutonomoByIdPersAutonomo, setEmptyListAllDocumentoPersonalAfectado, documentoPersonalAutonomoList, deleteDocPersById } = useDocumentoPersAfectado();
    const [isConfirmDeletePersonalProp, setIsConfirmDeletePersonalProp] = useState(false);
    const [personIdSelect, setPersonIdSelect] = useState<number>();
    const [actividades, setActividades] = useState<any>([]);
    const { cargarCombo, solicitudSelected, buscarSolicitud } = useSolicitud();
    const [isVisualizar, setIsVisualizar] = useState(false);
    const { isContratistaTipoUsuario, isEmpresaGestoraTipoUsuario } = useUserLogin()

    useEffect(() => {
        cargarCombo(`actividad/activasPorContratista/${solicitudSelected.idContratistaTrabajoEmpresa}`, setActividades);
    }, []);

    const columns: ColumnDefinitionType<PersonalAutonomo, keyof PersonalAutonomo>[] = []
    columns.push({
        key: 'apellido',
        header: 'Apellido',
    });
    columns.push({
        key: 'nombre',
        header: 'Nombre',
    });
    columns.push({
        key: 'tipoDocumento',
        header: 'Tipo Doc Persona',
    });
    columns.push({
        key: 'nroDocumento',
        header: 'Nro Documento',
    });
    columns.push({
        key: 'fechaIngreso',
        header: 'Fecha Ingreso',
        cell: (data: PersonalAutonomo) => <>{data.fechaIngreso ? moment(data.fechaIngreso).format('DD/MM/YYYY') : ''}</>
    });
    columns.push({
        key: 'actividad',
        header: 'Actividad',
    });
    columns.push({
        key: 'id',
        header: 'Acciones',
        cell: (data: PersonalAutonomo) =>
            <>
                <Link title="Visualizar" to="/exportar" onClick={(e) => {
                    buscarDocPersAutonomoByIdPersAutonomo(data.id)
                    handlePersonalAutonomoSelected(data)
                    setIsVisualizar(true);
                    onModalAction(true);
                    e.preventDefault();
                }} target="_blank" >
                    <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                </Link>
                {isEditing && <Link title="Editar" to="" onClick={(e) => {
                    buscarDocPersAutonomoByIdPersAutonomo(data.id)
                    handlePersonalAutonomoSelected(data)
                    onIsEditing(true);
                    onModalAction(true);
                    e.preventDefault();
                }} target="_blank" >
                    <i className="fa fa-pencil text-secondary me-2" aria-hidden="true"></i>
                </Link>}
                {isEditing && solicitudSelected.idTipoEstado !== REVISION_STATUS_ID && solicitudSelected.idTipoEstado !== APROBADA_STATUS_ID && solicitudSelected.idTipoEstado !== EN_ACTUALIZACION_STATUS_ID && <Link title="Eliminar" to="" onClick={(e) => {
                    setPersonIdSelect(data.id)
                    setIsConfirmDeletePersonalProp(true)
                    e.preventDefault();
                }} target="_blank" >
                    <i className="fa fa-trash text-secondary me-2" aria-hidden="true"></i>
                </Link>}
            </>
    });
    if (isContratistaTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID) {
        columns.push({
            key: 'statusDocumento',
            header: '',
            // isEnabledCheckStatusDocument: isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID,
        });
    }


    const handleConfirmDeletePerson = () => {
        eliminarPersonalAutonomoById(`${personIdSelect}`).finally(() => setIsConfirmDeletePersonalProp(false))
    }

    const handlePersonalAutonomoSave = (data: any, currentSave: HTMLButtonElement | null) => {
        let dataForm = {
            actividad: data.actividad,
            apellido: data.apellido,
            fechaIngreso: data.fechaIngreso,
            nombre: data.nombre,
            idTipoDocumento: data.tipoDocumento,
            tipoDocumento: data.tipoDocumento,
            observaciones: data.observaciones,
            nroDocumento: data.nroDocumento,
            vigente: data.vigente,
            idActividad: data.actividad,
            idSolicitud: solicitudSelected.id,
            id: data.id,
            statusDocumento: ''
        }
        saveOrUpdatePersonalAfectado('personal-autonomo', dataForm)
            .then(result => {
                // Maneja el resultado si es necesario
                console.log('Operación completada:', result);
                if (result) {
                    buscarSolicitud(dataForm.idSolicitud.toString());
                    findDeletedDocuments(data.documentosPersAutonomo).forEach(documentDelete => deleteDocPersById('documento-personal-autonomo', documentDelete.id));
                    let documentUpload = data.documentosPersAutonomo.filter((documentPersPropio: any) => documentPersPropio.archivoSeleccionado)
                    if (!documentUpload || documentUpload.length < 1) {
                        onClose()
                        return;
                    }
                    let idPersonalPropio = result.data.id;
                    let fileDataArray = documentUpload.map((documentPersPropio: any) => {
                        const uniqueId: string = uuidv4();
                        return {
                            file: documentPersPropio.archivoSeleccionado, parameters: {
                                idTipoDocumento: documentPersPropio.idTipoDocumento, idDocumento: documentPersPropio.id,
                                fileId: uniqueId, sizeMaximo: documentPersPropio.sizeMaximo
                            }
                        }
                    })
                    uploadFiles(`documento-personal-autonomo/subirArchivoPersonal/${idPersonalPropio}`, fileDataArray)
                        .then(result => {
                            if (result) {
                                onClose();
                                setEmptyListAllDocumentoPersonalAfectado()
                            }
                            currentSave!.disabled = false
                        }).catch((error) => {
                            currentSave!.disabled = false
                            if (error.response.status === 413) {
                                toast.error(error.response.data.message)
                            } else {
                                toast.error('No se pudo adjuntar el archivo seleccionado')
                            }
                        });
                }

                //uploadFiles(`${result}`, [])
            })
            .catch(error => {
                // Maneja los errores si ocurren
                console.error('Error:', error);
            });
    }

    const findDeletedDocuments = (newList: any[]): any[] => {
        const deletedDocuments: any[] = [];

        for (const existingDocument of documentoPersonalAutonomoList) {
            const existsInNewList = newList.some((newDocument) => newDocument.idTipoDocumento === existingDocument.idTipoDocumento &&
                newDocument.id === existingDocument.id);

            if (!existsInNewList) {
                deletedDocuments.push(existingDocument);
            }
        }

        return deletedDocuments;
    }

    return (
        <>
            <MyModal isOpen={isConfirmDeletePersonalProp} toggle={() => setIsConfirmDeletePersonalProp(false)} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} secondButtonFn={handleConfirmDeletePerson} >
                <label>Desea confirmar eliminar el Personal?</label>
            </MyModal>
            <MyModal size="lg" isOpen={modalOpen} toggle={() => { onClose(); setEmptyListAllDocumentoPersonalAfectado(); setIsVisualizar(false); }} cancelButton={false} secondButton={false}
                title={(isVisualizar ? 'Vista' : personalAutonomoSelected.id === 0 ? 'Agregar' : 'Editar') + ' Personal Autónomo'} >
                {isVisualizar ? <DetailPersonalAutonomo onClose={() => { onModalAction(false); setEmptyListAllDocumentoPersonalAfectado(); setIsVisualizar(false); }} /> : <EditPersonalAutonomo tiposDocumentosPers={tiposDocumentosPers}
                    isEditing={isEditing} actividades={actividades} onClose={() => { onClose(); setEmptyListAllDocumentoPersonalAfectado() }}
                    onSave={(data, currentSave) => { handlePersonalAutonomoSave(data, currentSave); }} />}
            </MyModal>
            <Table data={personalAutonomoList} columns={columns} ></Table>
        </>
    )
}

export default TablePersonalAutonomo