import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { onLogout } from "../../context/Auth/UserLoginReducer";

export const NoAuthorized = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.clear()
    }, [])

    const handleLogout = () => {
        dispatch(onLogout());
    };

    return (
        <>
            <div className="content-page" >
                <div className="content">
                    <div className="container-fluid">
                        <div>
                            <div className="centrado-vertical-error">
                                <div className="col-md-12 text-center" style={{ paddingTop: "60px" }}>
                                    <h1>401</h1>
                                    <h3>Token Expirado</h3>
                                    <p>Por favor, ingrese de nuevo para renovar el token.</p>
                                    <button className="btn btn-primary text-white boton-nuevo" onClick={handleLogout}>Inicio</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};