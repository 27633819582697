import { Breadcrumbs } from "../../Home/Breadcrumb"
import { BuscarTipoTrabajo } from "./Buscar";
import TableTipoTrabajo from "./Table";
import { ToastContainer } from "react-toastify";



export const TipoTrabajo = () => {


    return (
        <>
            <ToastContainer />

            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title={'Tipos de Trabajo'}
                            nameFirst={'Configuración'}
                            urlFirst={'/'}
                        />

                        <BuscarTipoTrabajo />
                        <TableTipoTrabajo />


                    </div>
                </div >
            </div >
        </>
    )
}
