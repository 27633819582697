import React from 'react';
import './loading.css';

export const Loading = () => {
  return (

    <div className="loading-overlay">
      <div className="loading-div">
        <img
          src={require('../../resources/images/main_loading.gif')}
          alt="..."
          className="loading-gif"
        />
      </div>
    </div>
  )
};