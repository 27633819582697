import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonalPropio } from '../../types/PersonalPropio';
import { PersonalAutonomo } from '../../types/PersonalAutonomo';
import { PersonalSubContratado } from '../../types/PersonalSubContratado';


interface PersonalAfectadoState {
  personalPropioList: PersonalPropio[];
  personalAutonomoList: PersonalAutonomo[];
  personalSubContratadoList: PersonalSubContratado[];
  personalPropioSelected: PersonalPropio;
  personalAutonomoSelected: PersonalAutonomo;
  personalSubContratadoSelected: PersonalSubContratado;
  isPersonalAfectado: boolean;
  loading: boolean;
}

export const personalPropioInitial: PersonalPropio = {
  id: 0,
  apellido: '',
  nombre: '',
  tipoDocumento: '',
  idTipoDocumento: '',
  nroDocumento: '',
  actividad: '',
  idActividad: '',
  fechaIngreso: '',
  observaciones: '',
  vigente: true,
  idSolicitud: 0,
  statusDocumento: ''
};

export const personalAutonomoInitial: PersonalAutonomo = {
  id: 0,
  apellido: '',
  nombre: '',
  tipoDocumento: '',
  idTipoDocumento: '',
  nroDocumento: '',
  actividad: '',
  idActividad: '',
  fechaIngreso: '',
  vigente: true,
  idSolicitud: 0,
  statusDocumento: ''
};

export const personalSubContratadoInitial: PersonalSubContratado = {
  id: 0,
  apellido: '',
  nombre: '',
  tipoDocumento: '',
  nroDocumento: '',
  actividad: '',
  fechaIngreso: '',
  empresa: '',
  vigente: true,
  idSolicitud: 0,
  idTipoDocumento: '',
  idActividad: '',
  statusDocumento: ''
};

const initialState: PersonalAfectadoState = {
  personalPropioList: [],
  personalAutonomoList: [],
  personalSubContratadoList: [],
  personalPropioSelected: personalPropioInitial,
  personalAutonomoSelected: personalAutonomoInitial,
  personalSubContratadoSelected: personalSubContratadoInitial,
  isPersonalAfectado: false,
  loading: false,
};

const personalAfectadoSlice = createSlice({
  name: 'personalAfectado',
  initialState,
  reducers: {
    isLoading: (state, data: PayloadAction<boolean>): void => {
      state.loading = data.payload;
    },
    setPersonalPropio: (state, action: PayloadAction<PersonalPropio[]>) => {
      state.personalPropioList = action.payload;
    },
    setPersonalAutonomo: (state, action: PayloadAction<PersonalAutonomo[]>) => {
      state.personalAutonomoList = action.payload;
    },
    setPersonalSubContratado: (state, action: PayloadAction<PersonalSubContratado[]>) => {
      state.personalSubContratadoList = action.payload;
    },
    loadDataPersonalPropio: (state, action: PayloadAction<PersonalPropio>) => {
      state.personalPropioSelected = action.payload;
    },
    loadDataPersonalAutonomo: (state, action: PayloadAction<PersonalAutonomo>) => {
      state.personalAutonomoSelected = action.payload;
    },
    loadDataPersonalSubContratado: (state, action: PayloadAction<PersonalSubContratado>) => {
      state.personalSubContratadoSelected = action.payload;
    },
    setPersonalAfectado: (state, data: PayloadAction<boolean>): void => {
      state.isPersonalAfectado = data.payload;
    },
    setNotSelectPersonalPropio: (state) => {
      state.personalPropioSelected = personalPropioInitial;
    },
    setNotSelectPersonalAutonomo: (state) => {
      state.personalAutonomoSelected = personalAutonomoInitial;
    },
    setNotSelectPersonalSubContratado: (state) => {
      state.personalSubContratadoSelected = personalSubContratadoInitial;
    },
  },
});

export const {
  setPersonalPropio,
  setPersonalAutonomo,
  setPersonalSubContratado,
  loadDataPersonalPropio,
  loadDataPersonalAutonomo,
  loadDataPersonalSubContratado,
  setPersonalAfectado,
  setNotSelectPersonalPropio,
  setNotSelectPersonalAutonomo,
  setNotSelectPersonalSubContratado
} = personalAfectadoSlice.actions;

export default personalAfectadoSlice.reducer;