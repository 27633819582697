import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { Permiso } from "../types/Permiso";
import { findAll, get, remove, save, update } from "../services/Service";
import { addPermiso, loadListPermiso, loadingError, permisoInitial, loadDataPermiso, removePermiso, visibleError, setGuardado, isAlta } from "../context/Seguridad/PermisoReducer";
import { useNavigate } from "react-router-dom";




export const usePermiso = () => {
    const BASE_URL = 'permisos';
    const { loading, permisoSelected, permisoList, errors, error, guardado, alta } = useSelector(
        (state: RootState) => state.permiso
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const buscarPermisos = async (permiso: Permiso) => {
        // handleGuardado(false);

        await findAll(`${BASE_URL}/find?descripcion=${permiso.descripcion}&acronimo=${permiso.acronimo}&url=${permiso.url}`)
            .then((response) => {
                dispatch(loadListPermiso(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const guardarPermiso = async (permiso: Permiso) => {
        let response;
        try {
            if (permiso.id === 0) {
                response = await save(BASE_URL, permiso);
                dispatch(addPermiso(response.data));
                dispatch(isAlta(true));
            } else {
                response = await update(BASE_URL, permiso);
                dispatch(addPermiso(response.data));
                dispatch(isAlta(false));
            }
            navigate('/permisos?updated=true');
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                dispatch(loadingError(error.response.data.errors));
            } else if (error.response && error.response.status === 500) {
                dispatch(loadingError(error.response.data.errors));
            } else if (error.response?.status === 401) {
                //handlerLogout();
            } else {
                throw error;
            }
        }

    }
    const buscarPermiso = async (id: string) => {
        handleGuardado(false)
        try {
            const response = await get(BASE_URL, id)
            dispatch(loadDataPermiso(response.data));
        } catch (error) {
            console.log(error);
        }
    }
    const eliminarPermiso = async (id: string) => {
        handleGuardado(false)
        try {
            const response = await remove(BASE_URL, id)
            dispatch(removePermiso(Number(id)));

        } catch (error) {
            console.log(error);
        }
    }

    const handlePermisoSelected = (permiso: Permiso) => {
        dispatch(loadDataPermiso(permiso))
    }
    const showError = (load: boolean) => {
        dispatch(visibleError(load))
    }
    const setMsgError = (error: string[]) => {
        dispatch(loadingError(error));
    }
    const handleGuardado = (guardado: boolean) => {
        dispatch(setGuardado(guardado))
    }

    return {
        buscarPermisos,
        guardarPermiso,
        buscarPermiso,
        eliminarPermiso,
        handlePermisoSelected,
        showError,
        setMsgError,
        handleGuardado,
        isAlta,
        alta,
        permisoInitial,
        permisoList,
        permisoSelected,
        loading,
        errors,
        error,
        guardado

    }
}