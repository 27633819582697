// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilos personalizados para el contenedor del DatePicker */
.react-datepicker-wrapper {
    width: 100%; /* Asigna un ancho del 100% al contenedor del DatePicker */
  }`, "",{"version":3,"sources":["webpack://./src/components/Form/FormInput/custom-datepicker.css"],"names":[],"mappings":"AAAA,6DAA6D;AAC7D;IACI,WAAW,EAAE,0DAA0D;EACzE","sourcesContent":["/* Estilos personalizados para el contenedor del DatePicker */\n.react-datepicker-wrapper {\n    width: 100%; /* Asigna un ancho del 100% al contenedor del DatePicker */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
