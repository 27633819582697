import React, { useState, useRef } from 'react'
import FormSelectBox from '../../../../../../components/Form/FormSelectBox'
import { useDocumentoTrabajo } from '../../../../../../hooks/useDocumentoTrabajo'
import { Button } from 'react-bootstrap'
import { uploadFile } from '../../../../../../services/Service';
import { useSolicitud } from '../../../../../../hooks/useSolicitud';
import MyModal from '../../../../../../components/Modal';
import { useDocumento } from '../../../../../../hooks/useDocumento';
import { toast } from 'react-toastify';

type AgregarDocumentoProps = {
  tipoDocumentoDisabled?: boolean
  documentoEquipamiento: any[]
  cancelButtonFn?: () => void;
  saveCompletedButtonFn: (addDocument: DocumentoPersonal) => void;
  listDocumentos?: any[]
};

type DocumentoPersonal = {
  archivoSeleccionado: File | null
  tipoDocumento: string
  nombreArchivo: string
  idDocumentoActividad: number
  idTipoDocumento: number,
  sizeMaximo: number,
}

export const AgregarDocumento: React.FC<AgregarDocumentoProps> = ({ tipoDocumentoDisabled, cancelButtonFn, saveCompletedButtonFn, documentoEquipamiento, listDocumentos }) => {
  const [existDocumentModal, setExistDocumentModal] = useState(false);
  const toggleExistDocument = () => setExistDocumentModal(!existDocumentModal);
  const [notSaveDocumentModal, setNotSaveDocumentModal] = useState(false);
  const toggleNotSaveDocument = () => setNotSaveDocumentModal(!notSaveDocumentModal);
  const [idDocumentoActividad, setIdDocumentoActividad] = useState<number>();
  const { documentosTrabajo, handleDocumentoTrabajoSelected, documentoTrabajoSelected, getFormatFile } = useDocumentoTrabajo();
  const { solicitudSelected } = useSolicitud();
  const { existDocumentoByIdTypeDocumentAndIdSolicitud, handleLoading, buscarDocumentosByIdSolicitud } = useDocumento();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = () => {
    if (fileInputRef.current?.files && fileInputRef.current.files[0]) {
      const file = fileInputRef.current.files[0];
      setSelectedFile(file);
    }
  };


  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Simula hacer clic en el input file
    }
  };

  const handleSaveButtonClick = () => {
    if (!idDocumentoActividad) {
      toast.error("Por favor, seleccione un tipo de documento.");
      return; // Detener la función si no se seleccionó un tipo de documento
    }

    // Validar que se haya cargado un documento válido
    if (!selectedFile) {
      toast.error('Por favor, cargue un documento.');
      return; // Detener la función si no se cargó un documento
    }
    if (!documentoTrabajoSelected.formatos.includes(getFormatFile(selectedFile.type))) {
      toast.error(`Por favor adjunte un archivo con el formato correcto: ${getAcceptFormat()}`);
      return;
    }
    const idTipoDocu = parseInt(documentoEquipamiento.find(da => da.id === idDocumentoActividad).tipoDocumentoId)
    const documentoExistente = listDocumentos?.find((doc) => doc.idTipoDocumento === idTipoDocu);

    if (documentoExistente) {
      setExistDocumentModal(true);
    } else {
      saveCompletedButtonFn({
        archivoSeleccionado: selectedFile, idDocumentoActividad: idDocumentoActividad, nombreArchivo: selectedFile.name,
        idTipoDocumento: parseInt(documentoEquipamiento.find(da => da.id === idDocumentoActividad).tipoDocumentoId),
        tipoDocumento: documentoEquipamiento.find(da => da.id === idDocumentoActividad).tipoDocumentoNombre,
        sizeMaximo: parseInt(documentoEquipamiento.find(da => da.id === idDocumentoActividad).sizeMaximo),
      })
      cancelButtonFn?.();

    }


  };

  const reloadDocumentoSolicitud = () => {
    handleLoading(true);
    buscarDocumentosByIdSolicitud(solicitudSelected.id);
  }

  const handleConfirmSaveFileClick = () => {
    saveCompletedButtonFn({
      archivoSeleccionado: selectedFile, idDocumentoActividad: idDocumentoActividad!, nombreArchivo: selectedFile!.name,
      idTipoDocumento: parseInt(documentoEquipamiento.find(da => da.id === idDocumentoActividad).tipoDocumentoId),
      tipoDocumento: documentoEquipamiento.find(da => da.id === idDocumentoActividad).tipoDocumentoNombre,
      sizeMaximo: parseInt(documentoEquipamiento.find(da => da.id === idDocumentoActividad).sizeMaximo),
    })
    toggleExistDocument();
    cancelButtonFn?.()
  }

  const handleExitButtonClick = () => {
    if (selectedFile) {
      setNotSaveDocumentModal(true);
      return; // Detener la función si no se cargó un documento
    }

    cancelButtonFn?.()
  };

  const handleConfirmCloseModalesAll = () => {
    toggleNotSaveDocument();
    cancelButtonFn?.()
  }


  const getAcceptFormat = () => {
    if (!documentoTrabajoSelected || !documentoTrabajoSelected.formatos || !Array.isArray(documentoTrabajoSelected.formatos)) {
      return '*/*'; // Permitir cualquier tipo de archivo si no se define correctamente el formato
    }
    const formatos = documentoTrabajoSelected.formatos.map(formato => {
      const lowerCaseFormat = formato.toLowerCase();
      return `.${lowerCaseFormat}`;
    });
    return formatos.join(',');
  };

  return (
    <>
      <MyModal isOpen={notSaveDocumentModal} toggle={toggleNotSaveDocument} title='Advertencia' cancelButton={true}
        secondButton={true} secondButtonTitle={'Aceptar'} secondButtonFn={handleConfirmCloseModalesAll} >
        <label>Desea Salir sin guardar los cambios?</label>
      </MyModal>
      <MyModal isOpen={existDocumentModal} toggle={toggleExistDocument} title='Advertencia' cancelButton={false}
        secondButton={true} secondButtonTitle={'Aceptar'} secondButtonFn={handleConfirmSaveFileClick}>
        <label>Va a subir un tipo de documento ya anexado</label>
      </MyModal>
      <div className="form-horizontal">
        <div className="row mt-2">
          <FormSelectBox
            title={'Tipo de Documento'}
            label={'tipo de documento'}
            id={'tipoDocumento'}
            name={'idTipoDocumento'}
            optionId={'id'}
            optionLabel={'tipoDocumentoNombre'}
            value={idDocumentoActividad}
            options={documentoEquipamiento}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              // Obtener el valor seleccionado como cadena
              const selectedValue = event.target.value;
              // Convertir la cadena a número (si es válido)
              const parsedValue = parseInt(selectedValue, 10); // Base 10
              const documentoEncontrado = documentoEquipamiento.find(dt => dt.id === parsedValue);

              if (documentoEncontrado) {
                // Si documentoEncontrado no es undefined, entonces puedes pasarlo como argumento
                handleDocumentoTrabajoSelected(documentoEncontrado);
              }
              // Asignar el número a idTipoDocumento
              setIdDocumentoActividad(parsedValue);
            }}
            //isDisabled={tipoDocumentoDisabled}
            classLabel={"col-form-label col-4 text-end"}
            classDivSelect="col-8"
          />
        </div>
        <div className="row mt-2">
          <div className="col-md-4 text-end">
            <label className={`col-form-label`}>
              Documento:
            </label>
            <input
              type="file"
              accept={getAcceptFormat()}
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
          </div>
          <div className="col-md-8">
            <div className="d-flex align-items-center justify-content-between">
              <Button
                className="btn-secondary"
                variant="secondary"
                onClick={handleButtonClick}
              >
                Examinar
              </Button>
              {selectedFile ? (
                <div>
                  <input
                    type="text"
                    style={{ width: '200px', height: '35px' }}
                    value={selectedFile.name}
                    readOnly // Esto hace que el campo sea no editable
                  />
                </div>
              ) : (
                <div>
                  <input
                    type="text"
                    style={{ width: '200px', height: '35px' }}
                    value="Ningún archivo seleccionado" // Texto por defecto
                    readOnly // Esto hace que el campo sea no editable
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-9"></div> {/* Espacio en blanco para empujar los botones hacia la derecha */}
          <div className="col-md-3 d-flex justify-content-end"> {/* Coloca los botones a la derecha */}
            <Button color="secondary" onClick={handleSaveButtonClick} className="mr-2">
              Guardar
            </Button>
            <Button color="secondary" onClick={handleExitButtonClick} className="mr-2">
              Salir
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}