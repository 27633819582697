import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MensajeSolicitud } from '../../types/MensajeSolicitud';

interface MensajeState {
    mensajes: MensajeSolicitud[];
    mensajeSelected: MensajeSolicitud;
    loading: boolean;
}
export const mensajeInitial: MensajeSolicitud = {
    id: 0,
    mensaje: '',
    fechaMensaje: '',
    usuario: ''
}

const initialState: MensajeState = {
    mensajes: [],
    mensajeSelected: mensajeInitial,
    loading: false,
};

export const mensajeSlice = createSlice({
    name: 'mensaje',
    initialState,
    reducers: {
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadData: (state, data: PayloadAction<MensajeSolicitud>): void => {
            state.mensajeSelected = data.payload;
            state.loading = false;
        },
        loadList: (state, data: PayloadAction<MensajeSolicitud[]>): void => {
            state.mensajes = data.payload;
            state.loading = false;
        }
    },
})

export const { isLoading, loadData, loadList } = mensajeSlice.actions;
export default mensajeSlice.reducer;
