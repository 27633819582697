import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../../../Home/Breadcrumb'
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Tree from 'rc-tree';
import "rc-tree/assets/index.css"
import { useTipoUsuario } from '../../../../../hooks/useTipoUsuario';
import { Perfil } from '../../../../../types/Perfil';

const Icon = () => (
    <span className='fa fa-folder' style={{ color: 'gray' }} />
);

export const CrearTipoUsuario = () => {
    const {
        tipoUsuarioSelected,
        perfiles,
        perfilesSelected,
        errors,
        error,
        guardarTipoUsuario,
        buscarTipoUsuario,
        handleTipoUsuarioSelected,
        showError,
        handlePerfilesSelected,
        handleGuardado
    } = useTipoUsuario();

    const [edit, setEdit] = useState<boolean>(false);
    const [validateError, setValidateError] = useState({
        nombre: '',
        perfiles: '',
        acronimo: ''
    });
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        handleGuardado(false);
        if (id) {
            buscarTipoUsuario(id);
            setEdit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault();

        let newErrors: any = {};
        const defaultMessage = 'Este campo es obligatorio'
        if (tipoUsuarioSelected.nombre === '') {
            newErrors.descripcion = defaultMessage
        }

        const perf: Perfil[] = perfilesSelected.map((p) => ({
            id: p,
            descripcion: '',
            acronimo: '',
            estado: false,
            permisosSelected: [],
            permisosNoAsignados: [],
            guardado: false,
            roles: []


        })).filter(p => p.id !== 'parent');

        if (perf.length === 0) {
            newErrors.perfiles = 'Debe seleccionar un item de la lista'
        }
        setValidateError(newErrors);

        handleTipoUsuarioSelected({
            ...tipoUsuarioSelected,
            perfilesSelected: perf,
        });
        if (Object.keys(newErrors).length === 0) {
            const tipoUsuarioGuardar = {
                ...tipoUsuarioSelected,
                perfilesSelected: perf
            }

            guardarTipoUsuario(tipoUsuarioGuardar);
        }
    }
    const handleChange = (e: any) => {
        e.preventDefault();
        const { name, value } = e.target;
        handleTipoUsuarioSelected({
            ...tipoUsuarioSelected,
            [name]: value,
        });
        setValidateError({
            ...validateError,
            [name]: '',
        })
    }
    const handleCancelar = (e: any) => {
        e.preventDefault();
        navigate('/tiposUsuarios')
    }
    // const onSelect = (selectedKeys: any, info: any) => {
    //     //console.log('selected', selectedKeys, info.checkedNodes);
    //     handlePermisosSelected([...selectedKeys])

    // };


    const onCheck = (checkedKeys: any, info: any) => {
        handlePerfilesSelected([...checkedKeys])
    };
    const fieldNames = {
        children: 'child',
        title: 'descripcion',
        key: 'id',
    };

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={edit ? 'Editar' : 'Nuevo'}
                        titlePage={'Tipo de Usuario'}
                        nameFirst={'Seguridad'}
                        urlFirst={'/'}
                        nameSecond={'Tipo de Usuario'}
                        urlSecond={'/tiposUsuarios'}
                    />
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox">
                                        <div className="ibox-title">
                                            <h4 className="m-t-0 header-title">
                                                <i className="fa fa-plus-circle mr-2" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                {edit ? 'Editar' : 'Nuevo'}
                                            </h4>
                                            <hr />
                                        </div>
                                        <div className="ibox-content">
                                            {error ?
                                                <Alert variant='danger' style={{ width: "100%" }} onClose={() => showError(false)} dismissible>
                                                    {
                                                        errors?.map((e: any, index: number) => (
                                                            <p className="alert-danger" key={index}
                                                                style={{ height: "10px", display: "grid" }}
                                                            > {e}
                                                            </p>
                                                        ))}
                                                </Alert>
                                                : ''
                                            }
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="nombre" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Nombre
                                                            </label>
                                                            <input id="nombre" name="nombre" className="form-control" onChange={handleChange} disabled
                                                                value={tipoUsuarioSelected.nombre as string}
                                                            />

                                                            {validateError.nombre && <p className="text-danger">{validateError.nombre}</p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="valor" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Acrónimo
                                                            </label>
                                                            <input id="valor" name="valor" className="form-control" onChange={handleChange} disabled
                                                                value={tipoUsuarioSelected.valor as string}
                                                            />

                                                            {validateError.acronimo && <p className="text-danger">{validateError.acronimo}</p>}

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="estado" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Estado
                                                            </label>
                                                            <select id="estado" value={tipoUsuarioSelected.estado as unknown as string} name="estado" className="form-control select-listDos" onChange={handleChange}>
                                                                <option value="true">
                                                                    Activo
                                                                </option>
                                                                <option value="false">
                                                                    Inactivo
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="url" style={{ color: "grey" }}>
                                                                Perfiles
                                                            </label>
                                                            <Tree
                                                                checkable
                                                                fieldNames={fieldNames}
                                                                defaultExpandedKeys={['parent']}
                                                                // onSelect={onSelect}
                                                                onCheck={onCheck}
                                                                treeData={perfiles}
                                                                icon={Icon}
                                                                checkedKeys={perfilesSelected}
                                                                expandedKeys={['parent']}

                                                            />
                                                            {validateError.perfiles && <p className="text-danger">{validateError.perfiles}</p>}

                                                        </div>
                                                    </div>
                                                </div>

                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <div className="form-group margin-bottom-10">

                                                            <button className="btn btn-secondary btn-rounded waves-effect waves-light" onClick={handleCancelar}>
                                                                <i className="fa fa-times mr-1" aria-hidden="true"></i>
                                                                Cancelar
                                                            </button>

                                                            <button type="submit" id="btnClean" className="btn btn-success btn-rounded waves-effect waves-light">
                                                                <i className="fa fa-save"></i>&nbsp;
                                                                Guardar
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
