import { Breadcrumbs } from "../../Home/Breadcrumb"
import { ToastContainer } from "react-toastify";
import { BuscarTipoDocumento } from "./Buscar";
import TableTipoDocumento from "./Table";



export const TipoDocumento = () => {

    return (
        <>
            <ToastContainer />

            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title={'Tipos de Documentos'}
                            nameFirst={'Configuración'}
                            urlFirst={'/'}
                        />
                        <BuscarTipoDocumento />
                        <TableTipoDocumento />

                    </div>
                </div >
            </div >
        </>
    )
}
