import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, get, remove, save, update } from "../services/Service";
import { loadData, loadList, isLoading, documentList, setEquipoAfectado, equipamientoSolicitudInitial } from "../context/EquiposAndMaquinaria/EquipoAndMaquinariaReducer";
import { EquipamientoSolicitud } from "../types/EquipamientoSolicitud";
import { useSolicitud } from "./useSolicitud";

export const useEquipoAndMaquinaria = () => {
    const BASE_URL = 'equipamiento-solicitud';

    const { equipamientoSolicitudSelected, equipamientoSolicitudes, loading, documentEquipSolicitudes, isEquipoAfectado,} = useSelector(
        (state: RootState) => state.equipoAndMaquinaria
    );
    const {solicitudSelected} = useSolicitud();
    const dispatch = useDispatch();

    const buscarEquipacionSolicitudesByIdSolicitud = async (id: number) => {
        dispatch(isLoading(true))
        await findAll(`${BASE_URL}/solicitud/${id}`)
            .then((response) => {
                dispatch(loadList(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const buscarDocEquipacionSolicitudesByIdEquipSolicitud = async (id: number) => {
        dispatch(isLoading(true))
        await findAll(`documento-equipamiento-solicitud/equipamiento-solicitud/${id}`)
            .then((response) => {
                dispatch(documentList(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const setDocumentEquipamientSolicitudList = (documents: []) => {
        dispatch(documentList(documents));
    }

    const setEquipamientoSolicitud = (load: EquipamientoSolicitud) => {
        dispatch(loadData(load))
    }

    const handleLoading = (load: boolean) => {
        dispatch(isLoading(load))
    }

    const existeEquipoAfectadoByIdContTrabajoEmpresa = async (id: number) => {
        await findAll(`contratistasTrabajosEmpresa/existeEquipoAfectado?idContTrabajoEmpresa=${id}`)
            .then((response) => {
                dispatch(setEquipoAfectado(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
                dispatch(setEquipoAfectado(false));
            })
    }

    const saveOrUpdateEquipamientoSolicitud = async (path: string, equipamientoSolicitud: EquipamientoSolicitud) => {
        try {
          if (equipamientoSolicitud.id === 0) {
            // Llama a la función 'save' si el 'id' es igual a 0
            const result = await save(path, equipamientoSolicitud);
            return result;
          } else {
            // Llama a la función 'update' si el 'id' no es igual a 0
            const result = await update(path, equipamientoSolicitud);
            return result;
          }
        } catch (error) {
          console.log(error);
        }
      };

      const deleteDocEquipamientoById = async (id: string) => {
        await remove('documento-equipamiento-solicitud',id)
            .then((response) => {
            
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
        }

        const deleteEquipamientSolicitudById = async (id: string) => {
            await remove('equipamiento-solicitud',id)
                .then((response) => {
                    buscarEquipacionSolicitudesByIdSolicitud(solicitudSelected.id)
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
    
                    }
                })
        }

    return {
        buscarDocEquipacionSolicitudesByIdEquipSolicitud,
        buscarEquipacionSolicitudesByIdSolicitud,
        equipamientoSolicitudes,
        equipamientoSolicitudSelected,
        setEquipamientoSolicitud,
        handleLoading,
        loading,
        documentEquipSolicitudes,
        existeEquipoAfectadoByIdContTrabajoEmpresa,
        isEquipoAfectado,
        saveOrUpdateEquipamientoSolicitud,
        deleteDocEquipamientoById,
        equipamientoSolicitudInitial,
        setDocumentEquipamientSolicitudList,
        deleteEquipamientSolicitudById
    }

}