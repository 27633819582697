import React from 'react'
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ tienePermiso, children }: { tienePermiso: boolean, children: any }) => {

    return (
        <>
            {

                tienePermiso ? children
                    : <Navigate to="/error403" />
            }
        </>
    )
}

export default ProtectedRoutes