import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, get, remove, update } from "../services/Service";
import {
    loadDataDocumentoPersAutonomo, loadDataDocumentoPersPropio, loadDataDocumentoPersSubContratado, loadListDocumentoPersAutonomo,
    loadListDocumentoPersPropio, loadListDocumentoPersSubContratado, setEmptyListDocumentoPersonalAutonomo, setEmptyListDocumentoPersonalPropio, setEmptyListDocumentotPersonalSubContratado
} from "../context/DocumentoPersonalAfectado/DocumentoPersonalAfectadoReducer";
import { DocumentoPersPropio } from "../types/DocumentoPersPropio";
import { DocumentoPersAutonomo } from "../types/DocumentoPersAutonomo";
import { DocumentoPersSubContratado } from "../types/DocumentoPersSubContratado";

export const useDocumentoPersAfectado = () => {

    const { documentoPersonalAutonomoList, documentoPersonalAutonomoSelected, documentoPersonalPropioList,
        documentoPersonalPropioSelected, documentoPersonalSubContratadoList, documentoPersonalSubContratadoSelected } = useSelector(
            (state: RootState) => state.documentoPersAfectado
        );
    const dispatch = useDispatch();


    const buscarDocPersByIdPersPropio = async (id: number) => {
        await findAll(`documento-personal/personal-propio/${id}`)
            .then((response) => {
                dispatch(loadListDocumentoPersPropio(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const buscarDocPersAutonomoByIdPersAutonomo = async (id: number) => {
        await findAll(`documento-personal-autonomo/personal-autonomo/${id}`)
            .then((response) => {
                dispatch(loadListDocumentoPersAutonomo(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const buscarDocPersSubContratadoByIdSubContratado = async (id: number) => {
        await findAll(`documento-personal-subcontratado/personal-subcontratado/${id}`)
            .then((response) => {
                dispatch(loadListDocumentoPersSubContratado(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const deleteDocPersById = async (path: string, id: string) => {
        await remove(path, id)
            .then((response) => {
                //dispatch(loadListDocumentoPersPropio(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const updateDocumento = async (path: string, documento: any) => {
        try {
            const result = await update(path, documento);
            return result;
        } catch (error) {
            console.log(error);
        }
    };

    const actualizarDocumentos = async (documentosListLocal: any[], path: string) => {
        try {
            const promesas = documentosListLocal.map(documento => updateDocumento(path, documento));

            // Esperar a que todas las actualizaciones se completen
            const resultados = await Promise.all(promesas);

            // Hacer algo con los resultados, si es necesario
            console.log('Resultados de las actualizaciones:', resultados);

            // Devolver una respuesta única o realizar otras operaciones
            return 'Todas las actualizaciones se completaron correctamente';
        } catch (error) {
            // Manejar errores generales aquí
            console.error('Error al actualizar documentos:', error);
            return 'Ocurrió un error al actualizar documentos';
        }
    };


    const handleDocumentoPersPropioSelected = (documentoPersPropio: DocumentoPersPropio) => {
        dispatch(loadDataDocumentoPersPropio(documentoPersPropio))
    }

    const handleDocumentoPersAutonomoSelected = (documentoPersAutonomo: DocumentoPersAutonomo) => {
        dispatch(loadDataDocumentoPersAutonomo(documentoPersAutonomo));
    };

    const handleDocumentoPersSubContratadoSelected = (documentoPersSubContratado: DocumentoPersSubContratado) => {
        dispatch(loadDataDocumentoPersSubContratado(documentoPersSubContratado));
    };

    const setListDocumentoPersonalPropio = (list: any) => {
        dispatch(loadListDocumentoPersPropio(list));
    }

    const setListDocumentoPersonalAutonomo = (list: any) => {
        dispatch(loadListDocumentoPersAutonomo(list));
    }

    const setListDocumentoPersonalSubContratado = (list: any) => {
        dispatch(loadListDocumentoPersSubContratado(list));
    }

    const setEmptyListAllDocumentoPersonalAfectado = () => {
        dispatch(setEmptyListDocumentoPersonalPropio())
        dispatch(setEmptyListDocumentoPersonalAutonomo())
        dispatch(setEmptyListDocumentotPersonalSubContratado())
    }

    return {
        documentoPersonalAutonomoList,
        documentoPersonalAutonomoSelected,
        documentoPersonalPropioList,
        buscarDocPersByIdPersPropio,
        buscarDocPersAutonomoByIdPersAutonomo,
        buscarDocPersSubContratadoByIdSubContratado,
        handleDocumentoPersPropioSelected,
        handleDocumentoPersAutonomoSelected,
        handleDocumentoPersSubContratadoSelected,
        documentoPersonalSubContratadoList,
        documentoPersonalPropioSelected,
        documentoPersonalSubContratadoSelected,
        setEmptyListAllDocumentoPersonalAfectado,
        deleteDocPersById,
        setListDocumentoPersonalPropio,
        setListDocumentoPersonalAutonomo,
        setListDocumentoPersonalSubContratado,
        actualizarDocumentos
    }

}