/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import Accordion from '../../../../components/Accordion'
import { useSolicitud } from '../../../../hooks/useSolicitud'
import { useHistorial } from '../../../../hooks/useHistorial'
import { HistorialSolicitud } from '../../../../types/HistorialSolicitud'
import Table, { ColumnDefinitionType } from '../../../../components/Table';
import moment from 'moment'
import { Link } from 'react-router-dom'

import './row.css'

type HistorialProps = {
    isDisabled?: boolean
};


const Historial : React.FC<HistorialProps> = ({ isDisabled = false}) =>{
    const { solicitudSelected } = useSolicitud();
    const { buscarHistorialesByIdSolicitud, setHistorialSelected, historiales, historialSelected } = useHistorial();
    const [cargarHitorialSolicitud, setHistorialSolicitud] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (cargarHitorialSolicitud) {
            buscarHistorialesByIdSolicitud(solicitudSelected.id);
            setHistorialSolicitud(false);
        }
    }

    const columns: ColumnDefinitionType<HistorialSolicitud, keyof HistorialSolicitud>[] = [
        {
            key: 'tipoEstado',
            header: 'Estado Solicitud',
            cellClassName: 'max-width-cell'
            
        },
        {
            key: 'fechaInicio',
            header: 'Fecha Inicio',
            cell: (data: HistorialSolicitud) => <>{data.fechaInicio ? moment(data.fechaInicio).format('DD/MM/YYYY') : ''}</>
        },
        {
            key: 'fechaFin',
            header: 'Fecha Fin',
            cell: (data: HistorialSolicitud) => <>{data.fechaFin ? moment(data.fechaFin).format('DD/MM/YYYY') : ''}</>
        },
        
        {
            key: 'usuario',
            header: 'Usuario'
        }
    ]
    return  (
        <>
        <Accordion id={'historial'} title="Historial" collapsed={true} handleClick={handleClick}>
                <div className='p-3'>
                    <div className="table-responsive ">
                    <Table data={historiales} columns={columns} ></Table>
                    </div>
                </div>
            </Accordion>
        </>
    )
}

export default Historial