import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Solicitud } from '../../classes/Solicitud';

interface SolicitudState {
    solicitudes: Solicitud[];
    solicitudSelected: Solicitud;
    loading: boolean;
    message: boolean;
    anulada: boolean;
    urlVolver: string;
}
export const solicitudInitial: Solicitud = {
    id: 0,
    tipoTrabajo: '',
    estado: '',
    empresaGestora: '',
    contratista: '',
    usuarioAlta: '',
    fechaAlta: '',
    numeroSolicitud: 0,
    numeroContrato: 0,
    idTipoTrabajo: 0,
    idTipoEstado: 0,
    idEmpresaGestora: 0,
    idContratista: 0,
    fechaInicio: null,
    fechaFin: null,
    idInstalacion: 0,
    instalacion: '',
    fechaAsignacion: null,
    infoAdicional: '',
    idContratistaTrabajoEmpresa: 0,
    tipoUsuarioCreacion: '',
    existeDocumentoVencido: null
}

const initialState: SolicitudState = {
    solicitudes: [],
    solicitudSelected: solicitudInitial,
    loading: false,
    message: false,
    anulada: false,
    urlVolver: "/consultaSolicitudes"
};

export const solicitudSlice = createSlice({
    name: 'solicitud',
    initialState,
    reducers: {
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataSolicitud: (state, data: PayloadAction<Solicitud>): void => {
            state.solicitudSelected = data.payload;
            state.loading = false;
        },
        loadListSolicitud: (state, data: PayloadAction<Solicitud[]>): void => {
            state.solicitudes = data.payload;
            state.loading = false;
        },
        showMessage: (state, data: PayloadAction<boolean>): void => {
            state.message = data.payload;
        },
        isAnulada: (state, data: PayloadAction<boolean>): void => {
            state.anulada = data.payload;
        },
        setVolver: (state, data: PayloadAction<string>): void => {
            state.urlVolver = data.payload;
        },

    },
})

export const { isLoading, loadDataSolicitud, loadListSolicitud, showMessage, isAnulada, setVolver } = solicitudSlice.actions;
export default solicitudSlice.reducer;
