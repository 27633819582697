import { usePerfil } from "../../../../hooks/usePerfil";
import { useRol } from "../../../../hooks/useRol";
import { Breadcrumbs } from "../../../Home/Breadcrumb";
import { BuscarPerfil } from "./Buscar";
import TablePerfil from "./Table";




export const Perfil = () => {

    const { guardado } = usePerfil()
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={'Perfiles'}
                        nameFirst={'Seguridad'}
                        urlFirst={'/'}
                    />
                    {
                        guardado &&
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <strong>Actualización exitosa.</strong>
                        </div>
                    }
                    <BuscarPerfil />
                    <TablePerfil />

                </div>
            </div >
        </div >

    );
}
