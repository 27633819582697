import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { isAnulada, isLoading, loadDataSolicitud, loadListSolicitud, setVolver, showMessage } from "../context/Solicitud/SolicitudReducer";
import solicitudInitial from "../context/Solicitud/SolicitudReducer"
import { Solicitud } from "../classes/Solicitud";
import { Dispatch } from "react";
import { findAll, get, save, update, cargarCombo } from "../services/Service";
import { useNavigate } from "react-router-dom";
import { DatosGeneralesForm } from "../types/DatosGenerales";
import moment from "moment";
import { toast } from 'react-toastify';

export const useSolicitud = () => {
    const BASE_URL = 'solicitudes';
    const navigate = useNavigate();

    const { solicitudes, solicitudSelected, loading, message, anulada, urlVolver } = useSelector(
        (state: RootState) => state.solicitud
    );
    const dispatch = useDispatch();

    const buscarSolicitudes = async (solicitud: Solicitud) => {
        await search(solicitud)
            .then((response) => {
                dispatch(loadListSolicitud(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const bandejaTrabajo = async () => {
        await findAll(`${BASE_URL}/bandeja-trabajo`)
            .then((response) => {
                dispatch(loadListSolicitud(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const buscarSolicitud = async (id: string) => {
        await get(BASE_URL, id)
            .then((response) => {
                dispatch(loadDataSolicitud(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const cargarTipoTrabajo = async (path: string, idEmpresaGestora: string, idContratista: string, setStateFunc: Dispatch<React.SetStateAction<any>>) => {
        await findAll(`${path}/find?idEmpresaGestora=${idEmpresaGestora}&idContratista=${idContratista}`)
            .then((response) => {
                setStateFunc(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const cargarEmpresasGestoras = async (path: string, idContratista: string, setStateFunc: Dispatch<React.SetStateAction<any>>) => {
        await findAll(`${path}/find?idContratista=${idContratista}`)
            .then((response) => {
                setStateFunc(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const cargarContratistas = async (path: string, idEmpresaGestora: string, setStateFunc: Dispatch<React.SetStateAction<any>>) => {
        await findAll(`${path}/find?idEmpresaGestora=${idEmpresaGestora}`)
            .then((response) => {
                setStateFunc(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleInitSolicitud = async (solicitud: Solicitud) => {
        try {
            const result = await save(BASE_URL, solicitud)
            dispatch(loadDataSolicitud(result.data));
            navigate(`/solicitud/${result.data.id}?create=true`)
        } catch (error) {
            console.log(error);
        }
    }

    const handleSolicitudSelected = (solicitud: Solicitud) => {
        dispatch(loadDataSolicitud(solicitud))
    }
    const handleLoading = (load: boolean) => {
        dispatch(isLoading(load))
    }
    const handleShowMessage = (load: boolean) => {
        dispatch(showMessage(load))
    }
    const handleAnulada = (load: boolean) => {
        dispatch(isAnulada(load))
    }

    const search = async (entity: any) => {
        try {
            const fechaInicio = entity.fechaInicio === '' || entity.fechaInicio === null ? '' : moment(entity.fechaInicio).format('YYYY-MM-DD');
            const fechaFin = entity.fechaFin === '' || entity.fechaFin === null ? '' : moment(entity.fechaFin).format('YYYY-MM-DD');
            const response = await findAll(`${BASE_URL}/find?numeroSolicitud=${entity.numeroSolicitud}&numeroContrato=${entity.numeroContrato}&idTipoTrabajo=${entity.idTipoTrabajo}&idTipoEstado=${entity.idTipoEstado}&idEmpresaGestora=${entity.idEmpresaGestora}&idContratista=${entity.idContratista}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const mapSolicitudToDatosGenerales = (solicitud: Solicitud): DatosGeneralesForm => {
        // Mapear los campos que tengan el mismo nombre
        const datosGenerales: DatosGeneralesForm = {
            id: solicitud.id,
            numeroContrato: solicitud.numeroContrato ? solicitud.numeroContrato.toString() : '', // Asegurarse de que sea un string
            idInstalacion: solicitud.idInstalacion ? solicitud.idInstalacion.toString() : '', // Asegurarse de que sea un string
            infoAdicional: solicitud.infoAdicional || '',
            fechaInicio: solicitud.fechaInicio ? moment(solicitud.fechaInicio, "YYYY-MM-DD").toDate() : null, // Manejar nulos si es necesario
            fechaFin: solicitud.fechaFin ? moment(solicitud.fechaFin, "YYYY-MM-DD").toDate() : null, // Manejar nulos si es necesario
            fechaAsignacion: solicitud.fechaAsignacion ? moment(solicitud.fechaAsignacion, "YYYY-MM-DD").toDate() : null, // Manejar nulos si es necesario
        };
        return datosGenerales;
    }

    const sendSolicitud = async (idSolicitud: number) => {
        try {
            const response = await get(`${BASE_URL}/send`, idSolicitud.toString())
            handleSolicitudSelected(response.data)
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message);
            } else if (error.response && error.response.status === 500) {
                toast.error(error.response.data.message);
            } else if (error.response?.status === 401) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.response.data.message);
                throw error;
            }
            console.error(error);
        }
    }

    const updateSolicitud = async (solicitud: any) => {
        try {
            const response = await update(`${BASE_URL}`, solicitud)
            handleSolicitudSelected(response.data)
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    const validarCalificarSolicitud = async (idSolicitud: number) => {
        try {
            const response = await get(`${BASE_URL}/validar-calificar`, idSolicitud.toString())
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message);
            } else if (error.response && error.response.status === 500) {
                toast.error(error.response.data.message);
            } else if (error.response?.status === 401) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.response.data.message);
                throw error;
            }
            console.error(error);
        }
    }

    const validarDocumentosAprobados = async (idSolicitud: number) => {
        try {
            const response = await get(`${BASE_URL}/validar-aprobados`, idSolicitud.toString())
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message);
            } else if (error.response && error.response.status === 500) {
                toast.error(error.response.data.message);
            } else if (error.response?.status === 401) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.response.data.message);
                throw error;
            }
            console.error(error);
        }
    }

    const cargarInstalaciones = async (path: string, idEmpresaGestora: number, setStateFunc: Dispatch<React.SetStateAction<any>>, mapFn?: (value: any, index: number, array: any[]) => any) => {
        await findAll(`${path}/find?idEmpresaGestora=${idEmpresaGestora}`)
            .then((response) => {
                const resp = mapFn != null ? response.data.map(mapFn) : response.data;
                setStateFunc(resp);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const handleUrlVolver = (url: string) => {
        dispatch(setVolver(url))
    }

    return {
        solicitudInitial,
        solicitudes,
        buscarSolicitudes,
        buscarSolicitud,
        cargarCombo,
        solicitudSelected,
        handleSolicitudSelected,
        loading,
        handleLoading,
        cargarTipoTrabajo,
        handleInitSolicitud,
        updateSolicitud,
        mapSolicitudToDatosGenerales,
        sendSolicitud,
        validarCalificarSolicitud,
        message,
        handleShowMessage,
        anulada,
        handleAnulada,
        cargarInstalaciones,
        cargarEmpresasGestoras,
        cargarContratistas,
        validarDocumentosAprobados,
        bandejaTrabajo,
        urlVolver,
        handleUrlVolver
    }

}