import Menu from "../../components/Menu"
import { FooterComponent } from "../../modules/Home/Footer"
import { HeaderComponent } from "../../modules/Home/Header"
import { Rutas } from "../routes"

export const UserRutas = () => {

    return (
        <>
            <HeaderComponent />
            <Menu />
            <Rutas />
            <FooterComponent />
        </>
    )
}