import FormInput from "../../../../../components/Form/FormInput"
import moment from 'moment'
import Table, { ColumnDefinitionType } from "../../../../../components/Table";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import MyModal from "../../../../../components/Modal";
import { useDocumento } from "../../../../../hooks/useDocumento";
import { Historial } from "../../DocumentosAdjuntos/historial";
import { Button } from "react-bootstrap";
import { useEquipoAndMaquinaria } from "../../../../../hooks/useEquipoAndMaquinaria";
import { DocEquipamientoSolicitud } from "../../../../../types/DocEquipamientoSolicitud";
import { downloadAndOpenFile, downloadFile, puedeAdjuntar } from '../../../../../services/Service'
import { useSolicitud } from "../../../../../hooks/useSolicitud";
import FormSelectBox from "../../../../../components/Form/FormSelectBox";
import { AgregarDocumento } from "../DocumentoAdjunto/agregar";
import { toast } from 'react-toastify';
import { APROBADA_STATUS_ID, EN_ACTUALIZACION_STATUS_ID, EN_PROCESO_STATUS_ID, OBSERVADA_STATUS_ID, REVISION_STATUS_ID, SIN_VISAR_DOCUMENTO_STATUS } from "../../../../../constants/constants";
import ObservacionComponent from "../../../../../components/Observaciones";
import { useUserLogin } from "../../../../../hooks/useUserLogin";
interface FormData {
    id: number;
    equipamiento: string;
    idEquipamiento: number;
    fechaIngreso: Date | null; // Puede ser una cadena o null
    fechaEgreso: Date | null;
    documentosEquipamiento: any[];
    vigente: boolean;
}

interface EquipamientoSolicitudProps {
    equipamientos: [];
    onClose: () => void;
    onSave: (formData: FormData, currentSave: HTMLButtonElement | null) => void
    isEditing: boolean
    onChangeEditData: (isEditData: boolean) => void
}
const EditEquipamientoSolicitud: React.FC<EquipamientoSolicitudProps> = ({ onClose, isEditing, equipamientos, onSave, onChangeEditData }) => {
    const { cargarCombo, solicitudSelected } = useSolicitud();
    const [isOpenModalConfirmDocumentDelete, setIsOpenModalConfirmDocumentDelete] = useState(false);
    const [isOpenAddDocumentEquipamiento, setIsOpenAddDocumentEquipamiento] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModalObservacionDocument, setIsOpenModalObservacionDocument] = useState(false)
    const [equipoSelect, setEquipoSelect] = useState<number>(0);
    const [isOpenModalEquipoMaquinariaDocumentDelete, setIsOpenModalEquipoMaquinariaDocumentDelete] = useState(false);
    const [selectedIdTipoDocumento, setSelectedIdTipoDocumento] = useState<number>(0); // Estado para mantener el ID seleccionado
    const toggle = () => setIsOpen(!isOpen);
    const { documentEquipSolicitudes, equipamientoSolicitudSelected, setDocumentEquipamientSolicitudList,
        buscarDocEquipacionSolicitudesByIdEquipSolicitud } = useEquipoAndMaquinaria();
    const { historialDocumentos, buscarDocumentosByIdTipoDocumento, handleDocumentoSelected,
        documentoInitial, documentoSelected, actualizarDocumentos } = useDocumento();
    const [documentoEquipamiento, setdocumentoEquipamiento] = useState<any[]>([]);
    const [listDocumentoEquipamientoSolicitud, setListDocumentoEquipamientoSolicitud] = useState<DocEquipamientoSolicitud[]>([]);
    const [maxDateFechaIngreso, setMaxDateFechaIngreso] = useState<Date>();
    const [minDateFechaIngreso, setMinDateFechaIngreso] = useState<Date>();
    const [maxDateFechaEgreso, setMaxDateFechaEgreso] = useState<Date>();
    const [minDateFechaEgreso, setMinDateFechaEgreso] = useState<Date>();
    const { isContratistaTipoUsuario, isEmpresaGestoraTipoUsuario } = useUserLogin()
    const [formData, setFormData] = useState<FormData>({
        id: 0,
        equipamiento: '',
        idEquipamiento: 0,
        fechaIngreso: null,
        fechaEgreso: null,
        documentosEquipamiento: [],
        vigente: true

    });

    const [isEmpresaGestora] = useState<boolean>(isEmpresaGestoraTipoUsuario())
    const [isContratista] = useState<boolean>(isContratistaTipoUsuario())
    const [isDisabled, setDisabled] = useState<boolean>((isEmpresaGestora && (solicitudSelected.idTipoEstado == REVISION_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID)) || (isContratista && solicitudSelected.idTipoEstado == REVISION_STATUS_ID));
    const [saveRevision, setSaveRevision] = useState(false);
    const [isOpenModalHistorial, setIsOpenModaHistorial] = useState(false);
    const [estados] = useState<any>(
        [
            {
                'id': true,
                'valor': 'Activo'
            },
            {
                'id': false,
                'valor': 'Inactivo'
            }
        ]);
    let btnRef = useRef<HTMLButtonElement>(null);

    const handleDocumento = async (id: number) => {
        setSelectedIdTipoDocumento(id);
        setIsOpenModaHistorial(true);
    }
    const getDocumentoSelected = (id: number) => {
        if (id) {
            const doc = historialDocumentos.find(d => d.idTipoDocumento === id) || documentoInitial;
            handleDocumentoSelected(doc);
            toggle();
            setSelectedIdTipoDocumento(0);
        }
    }

    const handleInputChange = (name: string, value: any) => {
        // Actualiza el estado con el nuevo valor
        onChangeEditData(true);
        if (name === 'idEquipamiento') {
            if (listDocumentoEquipamientoSolicitud.length > 0) {
                setIsOpenModalEquipoMaquinariaDocumentDelete(true)
                setEquipoSelect(value);
                return;
            }
            cargarCombo(`documentoEquipamiento/equipamiento/${value}`, setdocumentoEquipamiento);
        }

        if (name === 'fechaIngreso') {
            if (value) {
                setMinDateFechaEgreso(value)
            } else {
                setMinDateFechaIngreso(moment(solicitudSelected.fechaInicio).toDate())
                setMinDateFechaEgreso(moment(solicitudSelected.fechaInicio).toDate())
            }
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        // Dependiendo de cómo se use historialDocumentos, podrías necesitar un efecto para manejar su actualización
        // Realiza las acciones que necesites con el historialDocumentos actualizado aquí
        getDocumentoSelected(selectedIdTipoDocumento);
    }, [historialDocumentos]);

    useEffect(() => {
        if (solicitudSelected.fechaInicio) {
            let dateInicioValue = moment(solicitudSelected.fechaInicio).toDate()
            setMinDateFechaIngreso(dateInicioValue)
            setMinDateFechaEgreso(dateInicioValue)
        }
        if (solicitudSelected.fechaFin) {
            let dateFinValue = moment(solicitudSelected.fechaFin).toDate()
            setMaxDateFechaEgreso(dateFinValue)
            setMaxDateFechaIngreso(dateFinValue)
        }
        if (isEditing && equipamientoSolicitudSelected.id !== 0) {
            // Si estás en modo edición, puedes cargar los datos existentes en el estado formData
            // Utiliza los datos cargados para inicializar formData
            // Ejemplo:
            if (equipamientoSolicitudSelected.fechaIngreso !== '') {
                let dateInicioValue = moment(equipamientoSolicitudSelected.fechaIngreso).toDate()
                setMinDateFechaEgreso(dateInicioValue)
            }
            setFormData({
                ...formData,
                id: equipamientoSolicitudSelected.id,
                idEquipamiento: equipamientoSolicitudSelected.idEquipamiento,
                equipamiento: equipamientoSolicitudSelected.nombre,
                fechaEgreso: equipamientoSolicitudSelected.fechaEgreso !== '' ? moment(equipamientoSolicitudSelected.fechaEgreso, 'YYYY-MM-DD').toDate() : null,
                fechaIngreso: equipamientoSolicitudSelected.fechaIngreso !== '' ? moment(equipamientoSolicitudSelected.fechaIngreso, 'YYYY-MM-DD').toDate() : null,
                vigente: equipamientoSolicitudSelected.vigente
            });
            buscarDocEquipacionSolicitudesByIdEquipSolicitud(equipamientoSolicitudSelected.id)
            cargarCombo(`documentoEquipamiento/equipamiento/${equipamientoSolicitudSelected.idEquipamiento}`, setdocumentoEquipamiento);
        } else {
            setDocumentEquipamientSolicitudList([])
            setListDocumentoEquipamientoSolicitud([])
        }
        setDisabled((isEmpresaGestora && (solicitudSelected.idTipoEstado == REVISION_STATUS_ID || solicitudSelected.idTipoEstado == EN_ACTUALIZACION_STATUS_ID)) || (isContratista && solicitudSelected.idTipoEstado == REVISION_STATUS_ID))
    }, []);

    useEffect(() => {
        if (documentEquipSolicitudes.length > 0) {
            setListDocumentoEquipamientoSolicitud(documentEquipSolicitudes.map(documento => ({
                id: documento.id,
                idTipoDocumento: documento.idTipoDocumento,
                tipoDocumento: documento.tipoDocumento,
                nombreArchivo: documento.nombreArchivo,
                fileId: documento.fileId,
                subido: documento.subido,
                ubicacion: documento.ubicacion,
                fechaVencimiento: documento.fechaVencimiento,
                formatoArchivo: documento.formatoArchivo,
                nombreTipoDocumento: documento.nombreTipoDocumento,
                idSolicitud: solicitudSelected.id,
                archivoSeleccionado: null,
                statusDocumento: documento.statusDocumento,
                observacion: documento.observacion,
                sizeMaximo: documento.sizeMaximo,
            })));
        } else {
            setListDocumentoEquipamientoSolicitud([])
        }
    }, [documentEquipSolicitudes])

    const columns: ColumnDefinitionType<DocEquipamientoSolicitud, keyof DocEquipamientoSolicitud>[] = [];
    columns.push({
        key: 'tipoDocumento',
        header: 'Tipo de Documento',
    });
    columns.push({
        key: 'nombreArchivo',
        header: 'Nombre',
    });
    columns.push({
        key: 'fechaVencimiento',
        header: 'F. Vencimiento',
        cell: (data: DocEquipamientoSolicitud) => <>{data.fechaVencimiento ? moment(data.fechaVencimiento).format('DD/MM/YYYY') : ''}</>
    });
    if ((isContratistaTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID)) {
        columns.push({
            key: 'observacion',
            header: 'Observaciones',
            cell: (data: DocEquipamientoSolicitud) => <>
                {data.observacion &&
                    <a href="#" title="Observaciones" onClick={() => {
                        handleDocumentoSelected(data);
                        setIsOpenModalObservacionDocument(true)
                    }}>
                        <i className="fa fa-search me-2" style={{ color: 'red' }} aria-hidden="true"></i>
                    </a>
                }
            </>
        });
    }

    columns.push({
        key: 'id',
        header: 'Acciones',
        cell: (data: DocEquipamientoSolicitud) =>
            <>
                <Link title="Visualizar" to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadAndOpenFile('documento-equipamiento-solicitud/visualizarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                </Link>
                <Link title="Descargar" to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadFile('documento-equipamiento-solicitud/descargarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-arrow-down text-secondary me-2" aria-hidden="true"></i>
                </Link>
                {!isDisabled && solicitudSelected.idTipoEstado !== OBSERVADA_STATUS_ID && solicitudSelected.idTipoEstado !== APROBADA_STATUS_ID && <Link title="Eliminar" to="/delete" onClick={(e) => {
                    e.preventDefault();
                    setIsOpenModalConfirmDocumentDelete(true)
                    setSelectedIdTipoDocumento(data.idTipoDocumento)
                }} target="_blank" >
                    <i className="fa fa-trash text-secondary me-2" aria-hidden="true"></i>
                </Link>}
                {isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID && <a href="#" title="Observaciones" onClick={() => {
                    handleDocumentoSelected(data);
                    setIsOpenModalObservacionDocument(true)
                }}>
                    <i className="fa fa-search text-secondary me-2" aria-hidden="true"></i>
                </a>}
                {
                    ((isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (isContratistaTipoUsuario() && (solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID))) &&
                    <a href="#" title="Historial de Documentos" onClick={() => handleDocumento(data.idTipoDocumento)}>
                        <i className="fa fa-history text-secondary " aria-hidden="true"></i>
                    </a>
                }
            </>
    });

    columns.push({
        key: 'statusDocumento',
        header: '',
        isEnabledCheckStatusDocument: isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID,
    });

    const validationPreSavedEquipamiento = () => {
        if (isEmpresaGestora && solicitudSelected.idTipoEstado == REVISION_STATUS_ID) {
            btnRef!.current!.disabled = true;
            saveRevisionButtonFn();
            onClose();

        } else {
            if (!formData.idEquipamiento) {
                toast.error("Por favor, seleccionar un Equipo/Maquinaria");
                return;
            }

            if (!formData.fechaIngreso) {
                toast.error("Por favor, completar la Fecha Ingreso.");
                return;
            }

            if (!formData.fechaEgreso) {
                toast.error("Por favor, completar la Fecha Egreso.");
                return;
            }
            btnRef!.current!.disabled = true;

            formData.documentosEquipamiento = listDocumentoEquipamientoSolicitud;

            if (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID) {
                let documentUpload = formData.documentosEquipamiento.filter((documentPersPropio: any) => documentPersPropio.archivoSeleccionado)
                if (documentUpload!.length >= 1) {
                    (async () => {
                        try {
                            let documentos = documentUpload.map((documentoEquipamiento: any) => {

                                return {
                                    ...documentoEquipamiento,
                                    archivoSeleccionado: null,
                                }
                            })

                            const puedeAdj: boolean = await puedeAdjuntar(`documento-equipamiento-solicitud`, documentos);

                            if (!puedeAdj) {
                                toast.error("Se pueden adjuntar Tipos de Documentos vencidos, próximos a vencerse o no obligatorios");
                                btnRef!.current!.disabled = false;
                                return;

                            } else {
                                onSave(formData, btnRef!.current);
                                // onClose();
                            }


                        } catch (error) {
                            console.error('Error al verificar la fecha de vencimiento del documento:', error);
                        }

                    })();

                } else {
                    onSave(formData, btnRef!.current);
                    // onClose();
                }

            } else {
                onSave(formData, btnRef!.current)
                // onClose();
            }

        }
        // if (isEmpresaGestora && solicitudSelected.idTipoEstado == REVISION_STATUS_ID) {
        //     onClose();
        // }

    }

    const addDocumentConfirmSave = (data: any) => {
        onChangeEditData(true);
        const documentoExistente = listDocumentoEquipamientoSolicitud.find((doc) => doc.idTipoDocumento === data.idTipoDocumento);
        debugger;
        if (documentoExistente) {
            // Si el documento ya existe, reemplaza el existente con el nuevo documento.
            let replaceDocument = { ...documentoExistente };
            replaceDocument.archivoSeleccionado = data.archivoSeleccionado;
            replaceDocument.idSolicitud = solicitudSelected.id;
            replaceDocument.tipoDocumento = data.tipoDocumento;
            replaceDocument.nombreArchivo = data.archivoSeleccionado.name;
            replaceDocument.statusDocumento = '';
            replaceDocument.observacion = '';
            replaceDocument.fechaVencimiento = '';
            replaceDocument.sizeMaximo = data.sizeMaximo;
            replaceDocumentoEquipSolicitudByIdTipoDocumento(replaceDocument);
            setIsOpenAddDocumentEquipamiento(true);

        } else {
            // Si el documento no existe, simplemente agrégalo al array.
            //let newDocumento = documentoPersPropioInitial;
            let documentoPersPropioInitial: DocEquipamientoSolicitud = {
                id: 0,
                idTipoDocumento: data.idTipoDocumento,
                tipoDocumento: data.tipoDocumento,
                nombreArchivo: data.archivoSeleccionado.name,
                fileId: 0,
                subido: false,
                ubicacion: '',
                fechaVencimiento: '',
                formatoArchivo: '',
                nombreTipoDocumento: '',
                idSolicitud: solicitudSelected.id,
                archivoSeleccionado: data.archivoSeleccionado,
                statusDocumento: '',
                observacion: '',
                sizeMaximo: data.sizeMaximo,
            };
            setListDocumentoEquipamientoSolicitud(prevList => [...prevList, documentoPersPropioInitial]);
        }
    }

    const replaceDocumentoEquipSolicitudByIdTipoDocumento = (nuevoElemento: DocEquipamientoSolicitud) => {
        setListDocumentoEquipamientoSolicitud(prevList => {
            return prevList.map(item => {
                if (item.idTipoDocumento === nuevoElemento.idTipoDocumento) {
                    return nuevoElemento; // Reemplazar el elemento si se encuentra
                }
                return item; // Mantener el elemento sin cambios si no coincide el ID
            });
        });
    };

    const handleConfirmDeleteDocument = () => {
        deleteDocumentoEquipSolicitudByIdTipoDocumento(selectedIdTipoDocumento)
        setIsOpenModalConfirmDocumentDelete(false)
        onChangeEditData(true);
    }

    const deleteDocumentoEquipSolicitudByIdTipoDocumento = (idTipoDocumentoAEliminar: number) => {
        setListDocumentoEquipamientoSolicitud(prevList => {
            return prevList.filter(item => item.idTipoDocumento !== idTipoDocumentoAEliminar);
        });
    };

    const handleOpenAddDocumentPerson = () => {
        if (!formData.idEquipamiento || formData.idEquipamiento === 0) {
            toast.error("Por favor, seleccionar un Equipo/Maquinaria");
            return
        }
        cargarCombo(`documentoEquipamiento/equipamiento/${formData.idEquipamiento}`, setdocumentoEquipamiento);
        setIsOpenAddDocumentEquipamiento(true)
    }

    const handleConfirmEquipoMaquinariaDeleteAllDocument = () => {
        setListDocumentoEquipamientoSolicitud([]);
        setFormData({
            ...formData,
            'idEquipamiento': equipoSelect,
        });
        cargarCombo(`documentoEquipamiento/equipamiento/${equipoSelect}`, setdocumentoEquipamiento);
        setIsOpenModalEquipoMaquinariaDocumentDelete(false)
    }

    const onCheckboxChange = (data: DocEquipamientoSolicitud, status: string) => {
        setSaveRevision(true);
        setListDocumentoEquipamientoSolicitud((prevList: any) => {
            return prevList.map((item: any) => {
                if (data.id === item.id) {
                    // Modificamos solo el elemento deseado
                    return { ...item, statusDocumento: status };
                }
                // Mantenemos los demás elementos sin cambios
                return item;
            });
        });
    }

    const onObservacionChange = (observacion: string) => {
        setIsOpenModalObservacionDocument(false)
        setListDocumentoEquipamientoSolicitud((prevList: any) => {
            return prevList.map((item: any) => {
                if (documentoSelected.id === item.id) {
                    // Modificamos solo el elemento deseado
                    return { ...item, observacion: observacion };
                }
                // Mantenemos los demás elementos sin cambios
                return item;
            });
        });
    }

    const saveRevisionButtonFn = () => {
        const documentoUpdateStatus = [];

        for (const documento of listDocumentoEquipamientoSolicitud) {
            documentoUpdateStatus.push({ id: documento.id, statusDocumento: documento.statusDocumento, observacion: documento.observacion });
        };

        actualizarDocumentos(documentoUpdateStatus, 'documento-equipamiento-solicitud')
            .then(respuesta => {
                toast.success("Se actualizaron los documentos revisados");
            })
            .catch(error => {
                toast.error('Error al actualizar los documentos revisados');
            });

    }
    const cancelRevisionButtonFn = () => {

    }

    return (
        <>
            <MyModal
                isOpen={isOpenModalObservacionDocument} toggle={() => setIsOpenModalObservacionDocument(false)} title='Observaciones' cancelButton={false} secondButton={false}
            >
                <ObservacionComponent isEdit={isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID} observacion={documentoSelected.observacion} saveButtonFn={onObservacionChange}
                    cancelButtonFn={() => setIsOpenModalObservacionDocument(false)} returnButtonFn={() => setIsOpenModalObservacionDocument(false)}
                ></ObservacionComponent>
            </MyModal>
            <MyModal isOpen={isOpenModalEquipoMaquinariaDocumentDelete} toggle={() => setIsOpenModalEquipoMaquinariaDocumentDelete(false)} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} titleCancelar={'Cancelar'} secondButtonFn={handleConfirmEquipoMaquinariaDeleteAllDocument} >
                <label>Si cambia el Equipo/Maquinaria se van a eliminar los documentos adjuntados. Desea confirmar?</label>
            </MyModal>
            <MyModal isOpen={isOpenModalConfirmDocumentDelete} toggle={() => setIsOpenModalConfirmDocumentDelete(false)} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} titleCancelar={'Cancelar'} secondButtonFn={handleConfirmDeleteDocument} >
                <label>Confirma la eliminación del registro?</label>
            </MyModal>
            <MyModal isOpen={isOpenModalHistorial} toggle={() => setIsOpenModaHistorial(false)} title='Historial de Documentos'>
                <Historial tipoDocumentoDisabled={true}
                    pathService={`documento-equipamiento-solicitud/equipamiento-solicitud/${equipamientoSolicitudSelected.id}`}
                    idTipoDocumentoSelected={selectedIdTipoDocumento} />
            </MyModal>
            <MyModal isOpen={isOpenAddDocumentEquipamiento} toggle={() => setIsOpenAddDocumentEquipamiento(false)} title='Agregar Documentos'
                enabledFooter={false}>
                <AgregarDocumento tipoDocumentoDisabled={true} cancelButtonFn={() => setIsOpenAddDocumentEquipamiento(false)} listDocumentos={listDocumentoEquipamientoSolicitud}
                    saveCompletedButtonFn={(addDocument) => { addDocumentConfirmSave(addDocument) }}
                    documentoEquipamiento={documentoEquipamiento} />
            </MyModal>
            <div>
                <div className="row mt-12">
                    {formData.id !== 0 ?
                        <FormInput
                            id={'nombre'}
                            label={'Equipo / Maquinaria'}
                            name={'nombre'}
                            value={equipamientoSolicitudSelected.nombre || ''}
                            isDisabled={true}
                            classColumn={'col-4'}
                        /> :
                        <FormSelectBox
                            id={'idEquipamiento'}
                            label={'Equipo / Maquinaria'}
                            name={'idEquipamiento'}
                            value={formData.idEquipamiento || ''}
                            isDisabled={(solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (formData.id !== 0 && (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID))}
                            title={'Equipo / Maquinaria'}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            optionId={'id'}
                            optionLabel={'nombre'}
                            options={equipamientos}
                            classLabel="col-form-label col-2 text-left"
                            classDivSelect="col-4"
                        />
                    }
                </div>
                <div className="row mt-2">
                    <FormInput
                        id={'fechaIngreso'}
                        label={'Fecha Ingreso'}
                        name={'fechaIngreso'}
                        type="date"
                        value={formData.fechaIngreso}
                        onDateChange={(date: Date | null, name: string) => {
                            handleInputChange(name, date);
                        }}
                        minDate={minDateFechaIngreso}
                        maxDate={maxDateFechaIngreso}
                        isDisabled={(solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (formData.id !== 0 && (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID))}
                    />
                    <FormInput
                        id={'fechaEgreso'}
                        label={'Fecha Egreso'}
                        name={'fechaEgreso'}
                        type="date"
                        value={formData.fechaEgreso}
                        onDateChange={(date: Date | null, name: string) => {
                            handleInputChange(name, date);
                        }}
                        minDate={minDateFechaEgreso}
                        maxDate={maxDateFechaEgreso}
                        isDisabled={(solicitudSelected.idTipoEstado === REVISION_STATUS_ID) || (formData.id !== 0 && (solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID))}
                    />
                </div>
                {(solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID) &&
                    <div className="row mt-2">
                        <FormSelectBox
                            id={'vigente'}
                            label={'Estado'}
                            name={'vigente'}
                            value={formData.vigente || ''}
                            // isDisabled={formData.id !== 0}
                            title={'Estado'}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            optionId={'id'}
                            optionLabel={'valor'}
                            options={estados}
                            classLabel="col-form-label col-2 text-end"
                            classDivSelect="col-4"

                        />

                    </div>
                }
            </div>
            <div className='p-3'>
                <div className="row">
                    <div className="col-md-12 text-end">
                        {!isDisabled && <Button
                            disabled={isDisabled || !formData.vigente || documentoEquipamiento.length === 0}
                            className="btn-secondary"
                            variant="secondary"
                            onClick={handleOpenAddDocumentPerson}
                        > Agregar Documentos
                        </Button>}
                    </div>
                </div>
                <div className="table-responsive ">
                    <Table data={listDocumentoEquipamientoSolicitud} columns={columns} onCheckboxChange={onCheckboxChange}></Table>

                </div>
            </div>
            {(solicitudSelected.idTipoEstado === EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === REVISION_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado === EN_ACTUALIZACION_STATUS_ID) && <div className="col-md-12 d-flex justify-content-center">
                <Button color="secondary" onClick={validationPreSavedEquipamiento} className="mr-2" ref={btnRef}>
                    Guardar
                </Button>
                <Button color="secondary" onClick={onClose} className="mr-2">
                    Salir
                </Button>
            </div>}
        </>
    )
}

export default EditEquipamientoSolicitud