import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentoPersPropio } from '../../types/DocumentoPersPropio';
import { DocumentoPersAutonomo } from '../../types/DocumentoPersAutonomo';
import { DocumentoPersSubContratado } from '../../types/DocumentoPersSubContratado';


interface DocumentoPersonalAfectadoState {
  documentoPersonalPropioList: DocumentoPersPropio[];
  documentoPersonalAutonomoList: DocumentoPersAutonomo[];
  documentoPersonalSubContratadoList: DocumentoPersSubContratado[];
  documentoPersonalPropioSelected: DocumentoPersPropio;
  documentoPersonalAutonomoSelected: DocumentoPersAutonomo;
  documentoPersonalSubContratadoSelected: DocumentoPersSubContratado;
  loading: boolean;
}

export const documentoPersPropioInitial: DocumentoPersPropio = {
  id: 0,
  idTipoDocumento: 0,
  tipoDocumento: '',
  nombreArchivo: '',
  fileId: 0,
  subido: false,
  ubicacion: '',
  fechaVencimiento: '',
  formatoArchivo: '',
  nombreTipoDocumento: '',
  idSolicitud: 0,
  archivoSeleccionado: null,
  statusDocumento: '',
  observacion: '',
  sizeMaximo: null,
};

export const documentoPersAutonomoInitial: DocumentoPersAutonomo = {
  id: 0,
  idTipoDocumento: 0,
  tipoDocumento: '',
  nombreArchivo: '',
  fileId: 0,
  subido: false,
  ubicacion: '',
  fechaVencimiento: '',
  formatoArchivo: '',
  nombreTipoDocumento: '',
  idSolicitud: 0,
  archivoSeleccionado: null,
  statusDocumento: '',
  observacion: '',
  sizeMaximo: null,
};

export const documentoPersSubContratadoInitial: DocumentoPersSubContratado = {
  id: 0,
  idTipoDocumento: 0,
  tipoDocumento: '',
  nombreArchivo: '',
  fileId: 0,
  subido: false,
  ubicacion: '',
  fechaVencimiento: '',
  formatoArchivo: '',
  nombreTipoDocumento: '',
  idSolicitud: 0,
  archivoSeleccionado: null,
  statusDocumento: '',
  observacion: '',
  sizeMaximo: null,
};

const initialState: DocumentoPersonalAfectadoState = {
  documentoPersonalPropioList: [],
  documentoPersonalAutonomoList: [],
  documentoPersonalSubContratadoList: [],
  documentoPersonalPropioSelected: documentoPersPropioInitial,
  documentoPersonalAutonomoSelected: documentoPersAutonomoInitial,
  documentoPersonalSubContratadoSelected: documentoPersSubContratadoInitial,
  loading: false,
};

const documentoPersonalAfectadoSlice = createSlice({
  name: 'documentoPersonalAfectado',
  initialState,
  reducers: {
    isLoading: (state, data: PayloadAction<boolean>): void => {
      state.loading = data.payload;
    },
    loadListDocumentoPersPropio: (state, action: PayloadAction<DocumentoPersPropio[]>) => {
      state.documentoPersonalPropioList = action.payload;
    },
    loadListDocumentoPersAutonomo: (state, action: PayloadAction<DocumentoPersAutonomo[]>) => {
      state.documentoPersonalAutonomoList = action.payload;
    },
    loadListDocumentoPersSubContratado: (state, action: PayloadAction<DocumentoPersSubContratado[]>) => {
      state.documentoPersonalSubContratadoList = action.payload;
    },
    loadDataDocumentoPersPropio: (state, action: PayloadAction<DocumentoPersPropio>) => {
      state.documentoPersonalPropioSelected = action.payload;
    },
    loadDataDocumentoPersAutonomo: (state, action: PayloadAction<DocumentoPersAutonomo>) => {
      state.documentoPersonalAutonomoSelected = action.payload;
    },
    loadDataDocumentoPersSubContratado: (state, action: PayloadAction<DocumentoPersSubContratado>) => {
      state.documentoPersonalSubContratadoSelected = action.payload;
    },
    setEmptyListDocumentoPersonalPropio: (state) => {
      state.documentoPersonalPropioList = [];
    },
    setEmptyListDocumentoPersonalAutonomo: (state) => {
      state.documentoPersonalAutonomoList = [];
    },
    setEmptyListDocumentotPersonalSubContratado: (state) => {
      state.documentoPersonalSubContratadoList = [];
    },
  },
});

export const {
  loadListDocumentoPersPropio,
  loadListDocumentoPersAutonomo,
  loadListDocumentoPersSubContratado,
  loadDataDocumentoPersPropio,
  loadDataDocumentoPersAutonomo,
  loadDataDocumentoPersSubContratado,
  setEmptyListDocumentoPersonalPropio,
  setEmptyListDocumentoPersonalAutonomo,
  setEmptyListDocumentotPersonalSubContratado
} = documentoPersonalAfectadoSlice.actions;

export default documentoPersonalAfectadoSlice.reducer;