import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, get, remove, save, update } from "../services/Service";
import { useNavigate } from "react-router-dom";

import { TipoTrabajo } from "../types/TipoTrabajo";
import { addTipoTrabajo, loadDataTipoTrabajo, loadListTipoTrabajo, removeTipoTrabajo, tipoTrabajoInitial, loadingError, visibleError, setGuardado, loadListActividad, loadListEquipo, loadListDocumento, loadDataFiltro } from "../context/TipoTrabajo/TipoTrabajoReducer";
import { Actividad } from "../types/Actividad";
import { EquipoMaquinaria } from "../types/EquipoMaquinaria";
import { DocumentoTrabajo } from "../types/DocumentoTrabajo";
import api from "../api/api";

export const useTipoTrabajo = () => {
    const BASE_URL = 'tiposTrabajos';
    const { loading, tipoTrabajoSelected, tipoTrabajoList, errors, error, guardado, actividadList, equipoList, documentoList, filtro } = useSelector(
        (state: RootState) => state.tipoTrabajo
    );

    const dispatch = useDispatch();

    const buscarTiposTrabajos = async (tipoTrabajo: TipoTrabajo) => {
        let url = tipoTrabajo.activo === null || tipoTrabajo.activo === '0' ? `${BASE_URL}/findBy?nombre=${tipoTrabajo.nombre}` : `${BASE_URL}/findBy?nombre=${tipoTrabajo.nombre}&activo=${tipoTrabajo.activo}`

        await findAll(url)
            .then((response) => {
                dispatch(loadListTipoTrabajo(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const buscarActividades = async (idTipoTrabajo: number) => {
        try {
            let response = await findAll(`${BASE_URL}/${idTipoTrabajo}/actividades`);
            dispatch(loadListActividad(response.data));
        } catch (error) {
            console.log(error);
        }
    }
    const buscarEquipos = async (idTipoTrabajo: number) => {
        try {
            let response = await findAll(`${BASE_URL}/${idTipoTrabajo}/equipos`);
            dispatch(loadListEquipo(response.data));
        } catch (error) {
            console.log(error);
        }
    }
    const buscarDocumentos = async (idTipoTrabajo: number) => {
        try {
            let response = await findAll(`${BASE_URL}/${idTipoTrabajo}/documentos`);
            dispatch(loadListDocumento(response.data));
        } catch (error) {
            console.log(error);
        }
    }
    const buscarDocumentosActividades = async (idActividad: number) => {
        try {
            let response = await findAll(`documentoActividad/actividad/${idActividad}`);
            dispatch(loadListDocumento(response.data));
        } catch (error) {
            console.log(error);
        }
    }

    const guardarTipoTrabajo = async (tipoTrabajo: TipoTrabajo) => {
        let response;
        try {
            if (tipoTrabajo.id === 0) {
                response = await save(BASE_URL, tipoTrabajo);
                dispatch(addTipoTrabajo(response.data));
            } else {
                response = await update(BASE_URL, tipoTrabajo);
                dispatch(addTipoTrabajo(response.data));
            }
            // navigate('/tipoTrabajo');
            return response;
        } catch (error: any) {
            throw error;
        }

    }
    const buscarTipoTrabajo = async (id: string) => {
        try {
            const response = await get(BASE_URL, id)
            dispatch(loadDataTipoTrabajo(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    const buscarLogo = async (id: number) => {
        try {
            const response = await api.get(`${BASE_URL}/${id}/logo`, { responseType: 'blob' });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    const eliminarTipoTrabajo = async (id: string) => {
        try {
            const response = await remove(BASE_URL, id)
            // dispatch(removeTipoTrabajo(Number(id)));
            return response;

        } catch (error) {
            console.log(error);
        }
    }

    const handleTipoTrabajoSelected = (tipoTrabajo: TipoTrabajo) => {
        dispatch(loadDataTipoTrabajo(tipoTrabajo))
    }
    const showError = (load: boolean) => {
        dispatch(visibleError(load))
    }
    const setMsgError = (error: string[]) => {
        dispatch(loadingError(error));
    }

    const handleGuardado = (guardado: boolean) => {
        dispatch(setGuardado(guardado))
    }
    const handleActividadList = (actividades: Actividad[]) => {
        dispatch(loadListActividad(actividades));
    }
    const handleEquipoList = (equipos: EquipoMaquinaria[]) => {
        dispatch(loadListEquipo(equipos));
    }
    const handleDocumentoList = (documentos: DocumentoTrabajo[]) => {
        dispatch(loadListDocumento(documentos));
    }
    const handleFiltroTipoTrabajo = (tipoTrabajo: TipoTrabajo) => {
        dispatch(loadDataFiltro(tipoTrabajo))
    }
    return {
        buscarTiposTrabajos,
        guardarTipoTrabajo,
        buscarTipoTrabajo,
        eliminarTipoTrabajo,
        handleTipoTrabajoSelected,
        showError,
        setMsgError,
        handleGuardado,
        buscarActividades,
        handleActividadList,
        handleEquipoList,
        buscarEquipos,
        handleDocumentoList,
        buscarDocumentos,
        buscarDocumentosActividades,
        handleFiltroTipoTrabajo,
        buscarLogo,
        loadDataFiltro,
        filtro,
        tipoTrabajoInitial,
        tipoTrabajoList,
        actividadList,
        equipoList,
        documentoList,
        tipoTrabajoSelected,
        loading,
        errors,
        error,
        guardado
    }
}