import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Perfil } from "../../types/Perfil";

interface perfilState {
    perfilList: Perfil[];
    perfilSelected: Perfil;
    loading: boolean;
    permisos: any[];
    permisosSelected: any[];
    errors: any;
    error: boolean,
    guardado: boolean,
    roles: any[],
    expandedKeys: any[]

}

export const perfilInitial: Perfil = {
    id: 0,
    descripcion: '',
    estado: false,
    permisosSelected: [],
    permisosNoAsignados: [],
    guardado: false,
    roles: [],
}

const initialState: perfilState = {
    perfilList: [],
    permisos: [],
    permisosSelected: [],
    perfilSelected: perfilInitial,
    loading: false,
    errors: [],
    error: false,
    guardado: false,
    roles: [],
    expandedKeys: []
}

const perfilSlice = createSlice({
    name: 'perfil',
    initialState,
    reducers: {
        addPerfil: (state, data: PayloadAction<Perfil>): void => {
            state.perfilSelected = perfilInitial;
            state.error = false;
            state.guardado = true;
        },
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataPerfil: (state, data: PayloadAction<Perfil>): void => {
            state.perfilSelected = data.payload;
            state.error = false;
        },
        loadListPerfil: (state, data: PayloadAction<Perfil[]>): void => {
            state.perfilList = data.payload;
        },
        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        removePerfil: (state, data: PayloadAction<number>): void => {
            state.perfilList = state.perfilList.filter(p => p.id !== data.payload)
        },
        setPermisos: (state, data: PayloadAction<any[]>): void => {
            state.permisos = data.payload;
        },
        setPermisosSelected: (state, data: PayloadAction<any[]>): void => {
            state.permisosSelected = data.payload;
        },
        setGuardado: (state, data: PayloadAction<boolean>): void => {
            state.guardado = data.payload;
        },
        setRoles: (state, data: PayloadAction<any[]>): void => {
            state.roles = data.payload;
        },
        setExpandedKeys: (state, data: PayloadAction<any[]>): void => {
            state.expandedKeys = data.payload;
        },

    },
});

export const {
    isLoading,
    loadDataPerfil,
    loadListPerfil,
    addPerfil,
    loadingError,
    removePerfil,
    visibleError,
    setPermisos,
    setPermisosSelected,
    setGuardado,
    setRoles,
    setExpandedKeys
} = perfilSlice.actions;

export default perfilSlice.reducer;