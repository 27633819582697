import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

type MyModalProps = {
    isOpen: boolean;
    toggle: () => void;
    children: any,
    title: string,
    titleCancelar?: string,
    enabledFooter?: boolean,
    cancelButton?: boolean,
    secondButton?: boolean,
    secondButtonTitle?: string,
    secondButtonFn?: () => void;
    size?: string;
};

const MyModal: React.FC<MyModalProps> = ({ children, isOpen, toggle, title, titleCancelar = "Volver", secondButton = false, secondButtonFn,
    secondButtonTitle, size = "", cancelButton = true, enabledFooter = true }) => {
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const handleSecondButton = () => {
        // setDisabledButton(true);
        secondButtonFn?.();

    }
    return (
        <Modal isOpen={isOpen} toggle={toggle} size={size}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            {enabledFooter &&
                <ModalFooter>
                    {secondButton &&
                        <Button color="primary" onClick={handleSecondButton} disabled={disabledButton}>
                            {secondButtonTitle}
                        </Button>
                    }
                    {cancelButton &&
                        <Button color="secondary" onClick={toggle}>
                            {titleCancelar}
                        </Button>
                    }
                </ModalFooter>
            }

        </Modal>
    );
};

export default MyModal;