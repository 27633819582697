import { Link } from "react-router-dom";
import Table, { ColumnDefinitionType } from '../../../../components/Table'
import { Loading } from "../../../../components/Loading";
import { useState } from "react";
import Confirm from "../../../../components/Confirm";
import { useActividad } from "../../../../hooks/useActividad";
import { Actividad } from "../../../../types/Actividad";
import { toast } from "react-toastify";




const TableActividad = () => {
    const { actividadList, loading, actividadInitial, buscarActividades, eliminarActividad, handleActividadList } = useActividad();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [data, setData] = useState<Actividad>(actividadInitial);
    const toggle = () => setShowModal(!showModal);


    const eliminar = () => {
        eliminarActividad(data.id as unknown as string)
            .then(response => {
                buscarActividades({
                    id: 0,
                    nombreActividad: "",
                    descripcion: "",
                    activo: null,
                    documentos: []

                })
            })
            .catch(err => toast.error("Hubo un error al eliminar el Tipo de Trabajo"))
        toggle();
    }
    const columns: ColumnDefinitionType<Actividad, keyof Actividad>[] = [
        {
            key: 'nombreActividad',
            header: 'Nombre',
        },
        {
            key: 'descripcion',
            header: 'Descripción',
        },

        {
            key: 'activo',
            header: 'Estado',
            cell: (data: Actividad) => <>{data.activo ? 'Activo' : 'Inactivo'}</>
        },

        {
            key: 'id',
            header: 'Acciones',
            cell: (data: Actividad) =>
                <>
                    <Link to={`/actividades/editar/${data.id}?action=view`} title="Visualizar">
                        <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                    </Link>
                    <Link to={`/actividades/editar/${data.id}`} title="Editar" >
                        <i className="fa fa-pencil text-custom" aria-hidden="true"></i>
                    </Link>
                    {data.activo &&
                        <Link to="#" title="eliminar"
                            onClick={(e) => {
                                e.stopPropagation();
                                setData(data);
                                setShowModal(true)

                            }}
                        >
                            <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                        </Link>
                    }

                </>
        },
    ]

    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar la Actividad {data.nombreActividad}?</p>}
                    </div>
                </div>
            </Confirm>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h4 className="m-t-0 header-title">
                                        <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                        Listado de Actividades
                                        <Link to={`/actividades/nuevo`} title="Nuevo" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}>
                                            <i className="fa fa-plus margin-right-10"></i> Nuevo
                                        </Link>

                                    </h4>

                                </div>

                                <div className="table-responsive">
                                    <br />

                                    {loading ? <Loading /> : <Table data={actividadList || []} columns={columns} tabLayoutFixed={true}></Table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default TableActividad;