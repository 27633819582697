import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../../../Home/Breadcrumb'
import { usePermiso } from '../../../../../hooks/usePermiso'
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

export const CrearPermiso = () => {
    const { permisoInitial,
        permisoSelected,
        errors,
        error,
        guardarPermiso,
        buscarPermiso,
        handlePermisoSelected,
        showError,
        handleGuardado
    } = usePermiso();

    const [edit, setEdit] = useState<boolean>(false);
    const [validateError, setValidateError] = useState({
        descripcion: '',
        acronimo: '',
        url: '',
        menuNombre: '',
    });
    const navigate = useNavigate();

    const { id } = useParams();
    useEffect(() => {
        handleGuardado(false);
        if (id) {
            buscarPermiso(id);
            setEdit(true);
        } else {
            handlePermisoSelected(permisoInitial);
        }
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let newErrors: any = {};
        const defaultMessage = 'Este campo es obligatorio'
        if (permisoSelected.descripcion === '') {
            newErrors.descripcion = defaultMessage
        }
        if (permisoSelected.acronimo === '') {
            newErrors.acronimo = defaultMessage
        }
        if (permisoSelected.menuNombre === '') {
            newErrors.menuNombre = defaultMessage
        }
        if (permisoSelected.url === '') {
            newErrors.url = defaultMessage
        }
        setValidateError(newErrors);
        if (Object.keys(newErrors).length === 0) {
            guardarPermiso(permisoSelected);
        }
    }
    const handleChange = (e: any) => {
        e.preventDefault();
        const { name, value } = e.target;
        handlePermisoSelected({
            ...permisoSelected,
            [name]: value,
        });
        setValidateError({
            ...validateError,
            [name]: '',
        })
    }
    const handleCancelar = (e: any) => {
        e.preventDefault();
        navigate('/permisos')
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={edit ? 'Editar' : 'Nuevo'}
                        titlePage={'Permisos'}
                        nameFirst={'Seguridad'}
                        urlFirst={'/'}
                        nameSecond={'Permisos'}
                        urlSecond={'/permisos'}
                    />
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox">
                                        <div className="ibox-title">
                                            <h4 className="m-t-0 header-title">
                                                <i className="fa fa-plus-circle mr-2" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                {edit ? 'Editar' : 'Nuevo'}
                                            </h4>
                                            <hr />
                                        </div>
                                        <div className="ibox-content">
                                            {error ?
                                                <Alert variant='danger' style={{ width: "100%" }} onClose={() => showError(false)} dismissible>
                                                    {
                                                        errors?.map((e: any, index: number) => (
                                                            <p className="alert-danger" key={index}
                                                                style={{ height: "10px", display: "grid" }}
                                                            > {e}
                                                            </p>
                                                        ))}
                                                </Alert>
                                                : ''
                                            }
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="descripcion" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Descripción
                                                            </label>
                                                            <input id="descripcion" name="descripcion" className="form-control" onChange={handleChange}
                                                                value={permisoSelected.descripcion as string}
                                                            />

                                                            {validateError.descripcion && <p className="text-danger">{validateError.descripcion}</p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="acronimo" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Acrónimo
                                                            </label>
                                                            <input id="acronimo" name="acronimo" className="form-control" onChange={handleChange}
                                                                value={permisoSelected.acronimo}
                                                            />
                                                            {validateError.acronimo && <p className="text-danger">{validateError.acronimo}</p>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="url" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Url
                                                            </label>
                                                            <input id="url" name="url" className="form-control" onChange={handleChange}
                                                                value={permisoSelected.url}
                                                            />
                                                            {validateError.url && <p className="text-danger">{validateError.url}</p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="nombreMenu" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Nombre Menú
                                                            </label>
                                                            <input id="nombreMenu" name="menuNombre" className="form-control" onChange={handleChange}
                                                                value={permisoSelected.menuNombre}
                                                            />
                                                            {validateError.menuNombre && <p className="text-danger">{validateError.menuNombre}</p>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="estado" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Estado
                                                            </label>
                                                            <select id="estado" value={permisoSelected.estado as unknown as string} name="estado" className="form-control select-listDos" onChange={handleChange}>
                                                                <option value="true">
                                                                    Activo
                                                                </option>
                                                                <option value="false">
                                                                    Inactivo
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="visible" style={{ color: "grey" }}>
                                                                <span className="obligatorio">(*) </span>Visibilidad en el menú
                                                            </label>
                                                            <select id="visible" name="visible" className="form-control select-listDos" value={permisoSelected.visible as unknown as string} onChange={handleChange}>
                                                                <option value="true">
                                                                    Visible
                                                                </option>
                                                                <option value="false">
                                                                    No Visible
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <div className="form-group margin-bottom-10">

                                                            <button className="btn btn-secondary btn-rounded waves-effect waves-light" onClick={handleCancelar}>
                                                                <i className="fa fa-times mr-1" aria-hidden="true"></i>
                                                                Cancelar
                                                            </button>

                                                            <button type="submit" id="btnClean" className="btn btn-success btn-rounded waves-effect waves-light">
                                                                <i className="fa fa-save"></i>&nbsp;
                                                                Guardar
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
