import React, { useEffect, useState } from 'react'
import FormInput from '../../../../components/Form/FormInput'
import './index.css'
import { findDatosSolicitud } from '../../../../services/Service'
import { Link, useParams } from 'react-router-dom'
import { Solicitud } from '../../../../classes/Solicitud'
import { useSolicitud } from '../../../../hooks/useSolicitud'
import { ANULADA_STATUS_ID, APROBADA_STATUS_ID, EN_ACTUALIZACION_STATUS_ID, EN_PROCESO_STATUS_ID, FINALIZADA_STATUS_ID, INICIO_STATUS_ID, OBSERVADA_STATUS_ID, RECHAZADA_STATUS_ID, REVISION_STATUS_ID } from '../../../../constants/constants'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { urlRedirect } from '../../../../context/Auth/UserLoginReducer'
import { useAuth } from '../../../../hooks/useAuth'

type DatoSolicitud = {
    id: number,
    numeroSolicitud: number,
    numeroContrato: number,
    tipoTrabajo: string,
    idTipoEstado: number,
    estado: string,
    empresaGestora: string,
    contratista: string,
    fechaInicio: string | null,
    fechaFin: string | null,
    instalacion: string,
    existeDocumentoVencido: boolean | null;
}

const DatosSolicitud = () => {
    const { handleUrlVolver } = useSolicitud();
    const { isAuth, handleRedirect } = useAuth();
    const [solicitud, setSolicitud] = useState<DatoSolicitud>({
        id: 0,
        numeroSolicitud: 0,
        numeroContrato: 0,
        tipoTrabajo: '',
        idTipoEstado: 0,
        estado: '',
        empresaGestora: '',
        contratista: '',
        fechaInicio: null,
        fechaFin: null,
        instalacion: '',
        existeDocumentoVencido: null
    });
    const { id } = useParams();
    useEffect(() => {

        if (id) {

            findDatosSolicitud(id)
                .then((response) => {
                    setSolicitud(response.data);
                    updateRedirect(id)

                }).catch((error) => {
                    console.log(error);
                })
        }

    }, [id])

    const updateRedirect = (id: string) => {
        const url: string = `/solicitud/${id}?action=view`;
        if (isAuth) {
            handleRedirect(null);
        } else {
            handleRedirect(url);
        }
    }

    return (
        <>{!isAuth &&
            <div className="mainmenu-area">
                <div className="container text-center">

                    <img id="imageLogoApp" alt="LogoContratistas" style={{ marginLeft: 0 }}
                        // src={require('../../../resources/images/poncho-instalaciones.png')}
                        src={
                            process.env.PUBLIC_URL + '/poncho-instalaciones.png'
                        }
                        className="logo-img" />

                </div>
            </div>
        }
            <div className="content-page" style={{ marginLeft: isAuth ? '' : '0px', paddingTop: isAuth ? '28px' : '' }}>
                <div className="content">
                    <div className="container-fluid">

                        <div className="row">

                            <div className="col-md-12 text-center">

                                <h1>Solicitud Nro. {solicitud?.numeroSolicitud}</h1>
                                <div className="semaforo">
                                    {(solicitud?.idTipoEstado === OBSERVADA_STATUS_ID || solicitud?.idTipoEstado === ANULADA_STATUS_ID ||
                                        solicitud?.idTipoEstado === RECHAZADA_STATUS_ID || solicitud?.idTipoEstado === FINALIZADA_STATUS_ID) &&
                                        <> <div className="light red"></div>

                                        </>
                                    }
                                    {(solicitud?.idTipoEstado === INICIO_STATUS_ID || solicitud?.idTipoEstado === EN_PROCESO_STATUS_ID ||
                                        solicitud?.idTipoEstado === REVISION_STATUS_ID) &&
                                        <><div className="light yellow"></div>

                                        </>}
                                    {(solicitud?.idTipoEstado === APROBADA_STATUS_ID || solicitud?.idTipoEstado === EN_ACTUALIZACION_STATUS_ID) &&
                                        <>
                                            <div className="light green"></div>

                                        </>}
                                    {<h4>{solicitud?.estado} {solicitud?.existeDocumentoVencido} {solicitud?.existeDocumentoVencido ? <span style={{ color: 'red' }}>(Con Documentación Vencida)</span> : ''} </h4>}

                                </div>

                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6">
                                <FormInput
                                    id={'empresaGestora'}
                                    label={'Empresa Gestora'}
                                    name={'numeroSolicitud'}
                                    value={solicitud?.empresaGestora}
                                    isDisabled={true}
                                    classColumn=''
                                    classLabel={'col-form-label'}
                                />

                                <FormInput
                                    id={'nroContrato'}
                                    label={'Nro. Contrato'}
                                    name={'numeroContrato'}
                                    value={solicitud?.numeroContrato}
                                    isDisabled={true}
                                    classColumn=''
                                    classLabel={'col-form-label'}
                                />
                                <FormInput
                                    id={'fechaInicio'}
                                    label={'Fecha Inicio Trabajo'}
                                    name={'fechaInicio'}
                                    type="date"
                                    value={solicitud?.fechaInicio ? moment(solicitud.fechaInicio, "YYYY-MM-DD").toDate() : null}
                                    isDisabled={true}
                                    classColumn=''
                                    classLabel="col-form-label"
                                />
                                <FormInput
                                    id={'instalaciones'}
                                    label={'Instalación'}
                                    name={'instalacion'}
                                    value={solicitud?.instalacion}
                                    isDisabled={true}
                                    classColumn=''
                                    classLabel={'col-form-label'}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    id={'contratista'}
                                    label={'Contratista'}
                                    name={'contratista'}
                                    value={solicitud?.contratista}
                                    isDisabled={true}
                                    classColumn=''
                                    classLabel={'col-form-label'}
                                />

                                <FormInput
                                    id={'tipoTrabajo'}
                                    label={'Tipo de Trabajo'}
                                    name={'tipoTrabajo'}
                                    value={solicitud?.tipoTrabajo}
                                    isDisabled={true}
                                    classColumn=''
                                    classLabel={'col-form-label'}
                                />
                                <FormInput
                                    id={'fechaFin'}
                                    label={'Fecha Fin Trabajo'}
                                    name={'fechaFin'}
                                    type="date"
                                    value={solicitud?.fechaFin ? moment(solicitud.fechaFin, "YYYY-MM-DD").toDate() : null}
                                    isDisabled={true}
                                    classColumn=''
                                    classLabel={'col-form-label'}
                                />
                                <div className="text-center" style={{ paddingTop: '45px' }}>
                                    <Link to={`/solicitud/${solicitud?.id}?action=view`} title="Editar" className="btn btn-custom btn-block" onClick={(e) => {
                                        e.stopPropagation();
                                        handleUrlVolver(`/solicitud/${solicitud?.id}/datos`);
                                    }}>

                                        <u>Ver más</u></Link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <br />
            <br />
            {!isAuth &&

                <>
                    <hr />

                    <footer className="footer-area relative" id="contact-page">

                        <div className="footer-middle">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-4 text-right-log pt-4">
                                        <img
                                            src={require('../../../../resources/images/logo-poncho.png')}
                                            alt="" style={{ width: "215px" }} />
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="text-footer" style={{ color: "#51565" }}>Plataforma <strong>COMPLIANCE LEGAL y CORPORATIVO</strong> en materia de <strong>SEGURIDAD INDUSTRIAL, SEGURIDAD OPERATIVA y MEDIO AMBIENTE</strong>, 100% configurable.</p>
                                        <p style={{ margin: "0px", padding: "0px" }}><a href="https://poncho.grupomost.com/" style={{ color: "#007cb3", fontSize: "12px" }} target="_blank" rel="noreferrer">poncho.grupomost.com</a></p>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 text-center">
                                        <p style={{ fontSize: "12px" }}>
                                            &copy; Copyright 2020 GrupoMost
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </>
            }
        </>
    )
}

export default DatosSolicitud;
