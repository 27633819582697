import { useEffect, useState } from "react";
import FormSelectBox from "../../../../components/Form/FormSelectBox";
import FormInput from "../../../../components/Form/FormInput";
import { useActividad } from "../../../../hooks/useActividad";
import { Actividad } from "../../../../types/Actividad";

const filtroInitial: Actividad = {
    id: 0,
    nombreActividad: "",
    descripcion: "",
    activo: null,
    documentos: []
}
export const BuscarActividad = () => {
    const { actividadInitial, buscarActividades, handleFiltroActividad, filtro } = useActividad();
    const [filtroActividad, setFiltroActividad] = useState<Actividad>(filtro);
    const [estados] = useState<any>(
        [

            {
                'id': 'true',
                'valor': 'ACTIVO'
            },
            {
                'id': 'false',
                'valor': 'INACTIVO'
            }
        ]);

    useEffect(() => {
        buscarActividades(filtro);
    }, [])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault();
        setFiltroActividad({
            ...filtroActividad,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        buscarActividades(filtroActividad);
        handleFiltroActividad(filtroActividad)
    }

    const handleClean = () => {
        setFiltroActividad(filtroInitial);
        handleFiltroActividad(filtroInitial);
    };

    return (

        <div className="card">
            <form id="dto" className="form-horizontal" action="buscar" method="post">
                <div className="card-body">
                    <h4 className="m-t-0 header-title">
                        <i className="fa fa-search margin-right-7" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                        Buscar
                    </h4>
                    <hr />

                    <div className="row mt-2">


                        <FormInput
                            id={'nombre'}
                            label={'Nombre'}
                            name={'nombreActividad'}
                            placeholder={'Nombre'}
                            onChange={handleChange}
                            value={filtroActividad.nombreActividad}


                        />

                        <FormSelectBox
                            title={'Estado'}
                            label={'estado'}
                            id={'activo'}
                            name={'activo'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'valor'}
                            value={filtroActividad.activo || ''}
                            options={estados}
                            seleccionarText="TODOS"
                        />


                    </div>

                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-center">

                            <button className="btn btn-secondary btn-rounded waves-effect waves-light mr-1" type="button" id="limpiarFiltro" onClick={handleClean}>
                                <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                                Limpiar
                            </button>
                            <button className="btn btn-success btn-rounded waves-effect waves-light " type="submit" onClick={handleSubmit}>
                                <i className="fa fa-search mr-1"></i>
                                Buscar
                            </button>

                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}