// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.semaforo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
    background-color: white;
    border: 1px solid #cecece;
    border-radius: 13px;
  }
  .light {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px;
  }
  .red {
    background-color: red;
  }
  .yellow {
    background-color: yellow;
  }
  .green {
    background-color: green;
  }

`, "",{"version":3,"sources":["webpack://./src/modules/Pages/Solicitud/Datos/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,qBAAqB;EACvB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,uBAAuB;EACzB","sourcesContent":[".semaforo {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 85px;\n    background-color: white;\n    border: 1px solid #cecece;\n    border-radius: 13px;\n  }\n  .light {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    margin: 10px;\n  }\n  .red {\n    background-color: red;\n  }\n  .yellow {\n    background-color: yellow;\n  }\n  .green {\n    background-color: green;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
