import React, { useState } from 'react'
import { usePermiso } from '../../../../hooks/usePermiso'
import { Permiso } from '../../../../types/Permiso'
import { Link } from 'react-router-dom'
import Table, { ColumnDefinitionType } from '../../../../components/Table'
import { Loading } from '../../../../components/Loading'
import Confirm from '../../../../components/Confirm'
import { permisoInitial } from '../../../../context/Seguridad/PermisoReducer'


const TablePermiso = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [data, setData] = useState<Permiso>(permisoInitial);
    const toggle = () => setShowModal(!showModal);
    const eliminar = () => {
        eliminarPermiso(data.id as unknown as string);
        toggle();
    }


    const { eliminarPermiso } = usePermiso();
    const columns: ColumnDefinitionType<Permiso, keyof Permiso>[] = [
        {
            key: 'descripcion',
            header: 'Descripción',
        },
        {
            key: 'acronimo',
            header: 'Acrónimo',
        },
        {
            key: 'estado',
            header: 'Estado',
            cell: (data: Permiso) => <>{data.estado ? 'Activo' : 'Inactivo'}</>
        },
        {
            key: 'url',
            header: 'Url',
        },
        {
            key: 'id',
            header: 'Acciones',
            cell: (data: Permiso) =>
                <>
                    <Link to={`/permisos/editar/${data.id}`} title="Editar" >
                        <i className="fa fa-pencil text-custom" aria-hidden="true"></i>
                    </Link>
                    <Link to="#" title="eliminar"
                        onClick={(e) => {
                            e.stopPropagation();
                            setData(data);
                            setShowModal(true)
                            // navigate(`/permisos/editar/${data.id}`);
                        }}
                    >
                        <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                    </Link>
                </>
        },
    ]

    const { permisoList, loading } = usePermiso()
    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar el permiso {data.descripcion}?</p>}
                    </div>
                </div>
            </Confirm><div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h4 className="m-t-0 header-title">
                                        <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                        Lista

                                        <Link to={`/permisos/nuevo`} title="Nuevo" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}>
                                            <i className="fa fa-plus margin-right-10"></i> Nuevo
                                        </Link>
                                    </h4>

                                </div>

                                <div className="table-responsive">
                                    <br />
                                    {loading ? <Loading /> : <Table data={permisoList || []} columns={columns}></Table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default TablePermiso