import { useState } from "react";
import { TipoTrabajo } from "../../../../../types/TipoTrabajo";
import { EquipoMaquinaria } from "../../../../../types/EquipoMaquinaria";
import { Link } from "react-router-dom";
import Table, { ColumnDefinitionType } from "../../../../../components/Table";
import Accordion from "../../../../../components/Accordion";
import MyModal from "../../../../../components/Modal";
import FormSelectBox from "../../../../../components/Form/FormSelectBox";
import { Loading } from "../../../../../components/Loading";
import { useTipoTrabajo } from "../../../../../hooks/useTipoTrabajo";
import Confirm from "../../../../../components/Confirm";


type EquiposProps = {
    equipos: EquipoMaquinaria[];
    setEquipos: (value: EquipoMaquinaria[]) => void;
    edit: boolean,
    view: boolean
}
type FormErrors = {
    idEquipo?: string;
};

const SeccionEquipos = ({ edit, equipos, setEquipos, view }: EquiposProps) => {
    const [equipoForm, setEquipoForm] = useState<EquipoMaquinaria>({
        id: 0,
        nombre: '',
        descripcion: ''
    });

    const { loading, equipoList, handleEquipoList } = useTipoTrabajo()
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);
    const [data, setData] = useState<EquipoMaquinaria>({
        id: 0,
        nombre: '',
        descripcion: ''
    });
    const [equiposFiltrados, setEquiposFiltrados] = useState<EquipoMaquinaria[]>([]);
    const eliminar = () => {
        let equipos: EquipoMaquinaria[] = equipoList.filter(eq => eq.id !== data.id)
        handleEquipoList(equipos);
        toggle();
    }
    const [formErrors, setFormErrors] = useState<any>({
        idEquipo: '',
    });

    const columns: ColumnDefinitionType<EquipoMaquinaria, keyof EquipoMaquinaria>[] = []
    columns.push({
        key: 'nombre',
        header: 'Nombre',
    });

    columns.push({
        key: 'descripcion',
        header: 'Descripción',
    });
    if (!view) {
        columns.push({
            key: 'id',
            header: 'Acciones',
            cell: (data: EquipoMaquinaria) =>
                <>

                    <Link to="#" title="eliminar"
                        onClick={(e) => {
                            e.stopPropagation();
                            setData(data);
                            setShowModal(true);
                        }}
                    >
                        <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                    </Link>

                </>
        });
    }


    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setEquipoForm({
            ...equipoForm,
            [name]: value
        });
        const errors = validateForm(
            {
                ...equipoForm,
                [name]: value
            });
        setFormErrors(errors);
    }

    const handleConfirm = () => {
        const errors = validateForm(equipoForm);
        if (Object.keys(errors).length === 0) {

            const equipo: EquipoMaquinaria | undefined = equipos.find(equi => equi.id == equipoForm.id);
            if (equipo) {
                let equipFinal: EquipoMaquinaria[] = [];
                equipFinal.push(...equipoList, equipo);

                handleEquipoList(equipFinal);
            }
            toggleModal();
        }
        setFormErrors(errors);
    }

    const actualizarEquipos = () => {
        let equipFiltradas: EquipoMaquinaria[] = [];
        for (const equipoSelect of equipos) {

            const existsInEquipos = equipoList.some((eq) => equipoSelect.id == eq.id)
            if (!existsInEquipos) {
                equipFiltradas.push(equipoSelect);
            }
        }
        setEquiposFiltrados(equipFiltradas);
    }
    const validateForm = (equipoSelected: EquipoMaquinaria) => {
        const errors: FormErrors = {};

        if (!equipoSelected.id) {
            errors.idEquipo = 'Debe seleccionar un equipo/maquinaria'
        }
        return errors;
    }

    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar el equipo/maquinaria {data.nombre}?</p>}
                    </div>
                </div>
            </Confirm>
            <Accordion id={'equipos'} title="Equipos y Maquinarias">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <h4 className="m-t-0 header-title">
                                            <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                            Listado de Equipos y Maquinarias
                                            {!view &&
                                                <Link to="#" title="Agregar" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        actualizarEquipos();
                                                        setFormErrors({ idEquipo: '' })
                                                        setEquipoForm({
                                                            id: 0,
                                                            nombre: '',
                                                            descripcion: ''
                                                        })
                                                        toggleModal();
                                                    }}>
                                                    <i className="fa fa-plus margin-right-10"></i> Agregar
                                                </Link>
                                            }
                                        </h4>
                                    </div>

                                    <div className="table-responsive">
                                        <br />
                                        <MyModal isOpen={isOpen} toggle={toggleModal} title='Agregar Equipo/Maquinaria' size="md"
                                            secondButton={true} secondButtonTitle={'Aceptar'} titleCancelar={'Cancelar'} secondButtonFn={handleConfirm}
                                        >
                                            <div className="row mt-2">
                                                <FormSelectBox
                                                    id={'equipo'}
                                                    label={'Equipo/Maquinaria'}
                                                    name={'id'}
                                                    value={equipoForm.id || ''}
                                                    title={'Equipo/Maquinaria'}
                                                    onChange={(e) => handleInputChange(e)}
                                                    optionId={'id'}
                                                    optionLabel={'nombre'}
                                                    options={equiposFiltrados}
                                                    classLabel="col-form-label col-4 text-end"
                                                    classDivSelect="col-8"
                                                    errorText={formErrors.idEquipo}
                                                />

                                            </div>

                                        </MyModal>
                                        {loading ? <Loading /> : <Table data={equipoList || []} columns={columns}></Table>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Accordion>
        </>
    )
}

export default SeccionEquipos;



