import { Navigate, Route, Routes } from "react-router-dom"
import { LoginPage } from "../../modules/Pages/Login"
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { RecuperarClave } from "../../modules/Pages/Recuperar";
import DatosSolicitud from "../../modules/Pages/Solicitud/Datos";


export const AppRutas = () => {
    const { isAuth } = useSelector((state: RootState) => state.auth);

    return (
        <> {
            !isAuth
                ?
                <Routes>
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/recuperarClave' element={<RecuperarClave />} />
                    <Route path='/*' element={<Navigate to="/login" />} />
                    <Route path='/solicitud/:id/datos' element={<DatosSolicitud />} />
                </Routes>

                : null
        }
        </>
    )
}