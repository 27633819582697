import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Agrega los estilos CSS predeterminados de react-datepicker
import './custom-datepicker.css';
const FormInput = ({
  id,
  name,
  value,
  label,
  placeholder,
  onChange, // Mantenemos el onChange original
  onDateChange, // Agregamos un onChange personalizado para campos de fecha
  type = 'text',
  inputProps,
  isDisabled = false,
  classColumn = 'col-4',
  classLabel = 'col-form-label col-2 text-end',
  errorText, // Texto adicional para mostrar junto al error,
  minDate = undefined,
  maxDate = undefined,
  maxLength = 12,
  showSpan = false,
  textSpan = ''
}: {
  id: string;
  name: string;
  value: any;
  label: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDateChange?: (date: Date | null, name: string) => void; // Nuevo onChange para campos de fecha
  type?: string;
  inputProps?: {};
  isDisabled?: boolean;
  classColumn?: string;
  classLabel?: string;
  minDate?: Date;
  maxDate?: Date;
  errorText?: string; // Texto adicional para mostrar junto al error
  maxLength?: number;
  showSpan?: boolean,
  textSpan?: string
}) => {
  const hasError = !!errorText;

  // Si el tipo es "date", renderiza el DatePicker
  if (type === 'date') {
    return (
      <>
        <label htmlFor={id} className={classLabel} style={{ color: "grey" }}>
          {label}
        </label>
        <div className={classColumn}>
          <div className={`input-group ${hasError ? "is-invalid" : ""}`}>
            <DatePicker
              id={id}
              dateFormat="dd/MM/yyyy"
              name={name}
              selected={value} // Establece la fecha seleccionada
              onChange={(date: Date | null) => onDateChange?.(date, name)}
              className={`form-control ${hasError ? "is-invalid" : ""}`}
              placeholderText={placeholder}
              disabled={isDisabled}
              minDate={minDate || undefined}
              maxDate={maxDate || undefined}
            />

          </div>
          {hasError && (
            <div className="invalid-feedback">
              {errorText} {/* Muestra el texto adicional */}
            </div>
          )}
        </div>
      </>
    );
  }

  // Si no es de tipo "date", renderiza el input normal
  return (
    <>
      <label htmlFor={id} className={classLabel} style={{ color: "grey" }}>
        {label}
      </label>
      <div className={classColumn}>
        <div className="input-group">
          <input
            id={id}
            name={name}
            className={`form-control ${hasError ? "is-invalid" : ""}`}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
            disabled={isDisabled}
            maxLength={maxLength}
          />
          {showSpan && <span className="form-control-plaintext ms-1" style={{ width: '75px' }}>{textSpan}</span>}
          {hasError && (
            <div className="invalid-feedback">
              {errorText} {/* Muestra el texto adicional */}
            </div>
          )}
        </div>

      </div>
    </>
  );
};

export default FormInput;
