import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useAuth } from "./useAuth";
import { Usuario } from "../types/Usuario";


export const useUserLogin = () => {
  const { login } = useAuth();
  const { usuarioLogin } = useSelector((state: RootState) => state.auth);

  const isContratistaTipoUsuario = () => {
    const login = JSON.parse(localStorage.getItem('login') || '{}')
    let usuario : Usuario = login?.usuario;
    return usuario.tipoUsuarioValor === 'CONTRATISTA';
  }

  const isEmpresaGestoraTipoUsuario = (): boolean => {
    const login = JSON.parse(localStorage.getItem('login') || '{}')
    let usuario : Usuario = login?.usuario;
    return usuario.tipoUsuarioValor === 'EMPRESA_GESTORA';
  }

  const isSistemaTipoUsuario = (): boolean => {
    const login = JSON.parse(localStorage.getItem('login') || '{}')
    let usuario : Usuario = login?.usuario;
    return usuario.tipoUsuarioValor === 'SISTEMA';
  }

  const getUsuarioLocalStorage = (): Usuario => {
    const login = JSON.parse(localStorage.getItem('login') || '{}')
    let usuario : Usuario = login?.usuario;
    return usuario;
  }

  return {
    login,
    usuarioLogin,
    isContratistaTipoUsuario,
    isEmpresaGestoraTipoUsuario,
    isSistemaTipoUsuario,
    getUsuarioLocalStorage
    // Otras funciones relacionadas con usuarios
  };
};
