import { Link } from "react-router-dom";
import Table, { ColumnDefinitionType } from '../../../../components/Table'
import { useTipoTrabajo } from "../../../../hooks/useTipoTrabajo";
import { TipoTrabajo } from "../../../../types/TipoTrabajo";
import { Loading } from "../../../../components/Loading";
import { useEffect, useState } from "react";
import Confirm from "../../../../components/Confirm";
import { toast } from "react-toastify";
import MyModal from "../../../../components/Modal";



const TableTipoTrabajo = () => {
    const { tipoTrabajoList, loading, tipoTrabajoInitial, eliminarTipoTrabajo, buscarTiposTrabajos } = useTipoTrabajo();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [data, setData] = useState<TipoTrabajo>(tipoTrabajoInitial);
    const toggle = () => setShowModal(!showModal);


    const eliminar = () => {
        eliminarTipoTrabajo(data.id as unknown as string)
            .then(response => { buscarTiposTrabajos(tipoTrabajoInitial) })
            .catch(err => toast.error("Hubo un error al eliminar el Tipo de Trabajo"))
        toggle();
    }
    const columns: ColumnDefinitionType<TipoTrabajo, keyof TipoTrabajo>[] = [
        {
            key: 'nombre',
            header: 'Nombre',
        },

        {
            key: 'activo',
            header: 'Estado',
            cell: (data: TipoTrabajo) => <>{data.activo ? 'Activo' : 'Inactivo'}</>
        },

        {
            key: 'id',
            header: 'Acciones',
            cell: (data: TipoTrabajo) =>
                <>
                    <Link to={`/tipoTrabajo/editar/${data.id}?action=view`} title="Visualizar">
                        <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                    </Link>
                    <Link to={`/tipoTrabajo/editar/${data.id}`} title="Editar" >
                        <i className="fa fa-pencil text-custom" aria-hidden="true"></i>
                    </Link>
                    <Link to="#" title="eliminar"
                        onClick={(e) => {
                            e.stopPropagation();
                            setData(data);
                            setShowModal(true)

                        }}
                    >
                        <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                    </Link>

                </>
        },
    ]

    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar el Tipo de Trabajo {data.nombre}?</p>}
                    </div>
                </div>
            </Confirm>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h4 className="m-t-0 header-title">
                                        <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                        Listado de Tipos de Trabajo
                                        <Link to={`/tipoTrabajo/nuevo`} title="Nuevo" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}>
                                            <i className="fa fa-plus margin-right-10"></i> Nuevo
                                        </Link>

                                    </h4>

                                </div>

                                <div className="table-responsive">
                                    <br />

                                    {loading ? <Loading /> : <Table data={tipoTrabajoList || []} columns={columns}></Table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default TableTipoTrabajo;