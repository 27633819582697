import { Breadcrumbs } from "../../Home/Breadcrumb"
import { ToastContainer } from "react-toastify";
import { BuscarActividad } from "./Buscar";
import TableActividad from "./Table";


export const Actividad = () => {


    return (
        <>
            <ToastContainer />

            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title={'Actividades'}
                            nameFirst={'Configuración'}
                            urlFirst={'/'}
                        />

                        <BuscarActividad />
                        <TableActividad />


                    </div>
                </div >
            </div >
        </>
    )
}
