import TableHeader from "./TableHeader";
import TableRows from "./TableRow";

export type ColumnDefinitionType<T, K extends keyof T> = {
    key: K;
    header: string;
    width?: number;
    cell?: (data: T) => React.ReactNode | string | undefined;
    cellClassName?: string;
    customBooleanRender?: (value: boolean) => React.ReactNode;
    isEnabledCheckStatusDocument?: boolean;
}

type TableProps<T, K extends keyof T> = {
    data: Array<T>;
    columns: Array<ColumnDefinitionType<T, K>>;
    onCheckboxChange?: (row: T, value: string) => void;
    notResults?: string;
    tabLayoutFixed?: boolean;
}

const Table = <T, K extends keyof T>({ data, columns, onCheckboxChange, notResults = 'Ningún dato disponible en esta tabla', tabLayoutFixed = false }: TableProps<T, K>): JSX.Element => {
    return (
        <table className="table table-hover mb-0" style={{ tableLayout: `${tabLayoutFixed ? 'fixed' : 'auto'}` }}>
            <TableHeader columns={columns} />
            {
                data.length === 0
                    ? <tbody><tr className="odd"><td valign="top" colSpan={10} className="dataTables_empty" style={{ textAlign: 'center' }}>{notResults}</td></tr></tbody>
                    :
                    <TableRows
                        data={data}
                        columns={columns}
                        onCheckboxChange={onCheckboxChange}
                    />
            }
        </table>
    );
};

export default Table;