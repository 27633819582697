import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, save, update, remove } from "../services/Service";
import {
    loadDataPersonalAutonomo, loadDataPersonalPropio, loadDataPersonalSubContratado, setPersonalAutonomo,
    setPersonalPropio, setPersonalSubContratado, setPersonalAfectado, setNotSelectPersonalPropio, setNotSelectPersonalAutonomo, setNotSelectPersonalSubContratado
} from "../context/PersonalAfectado/PersonalAfectadoReducer";
import { PersonalPropio } from "../types/PersonalPropio";
import { PersonalSubContratado } from "../types/PersonalSubContratado";
import { PersonalAutonomo } from "../types/PersonalAutonomo";
import { useSolicitud } from "./useSolicitud";

export const usePersonalAfectado = () => {

    const { personalAutonomoList, personalPropioList, personalSubContratadoList, personalAutonomoSelected
        , personalPropioSelected, personalSubContratadoSelected, isPersonalAfectado } = useSelector(
            (state: RootState) => state.personalAfectado
        );
    const { solicitudSelected, buscarSolicitud } = useSolicitud();
    const dispatch = useDispatch();


    const buscarPersonalPropioByIdSolicitud = async (id: number) => {
        await findAll(`personal-propio/solicitud/${id}`)
            .then((response) => {
                dispatch(setPersonalPropio(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const buscarPersonalAutonomaByIdSolicitud = async (id: number) => {
        await findAll(`personal-autonomo/solicitud/${id}`)
            .then((response) => {
                dispatch(setPersonalAutonomo(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const buscarPersonalByDocumento = async (idTipoDocumento: number, nroDocumento: number) => {
        await findAll(`personal-autonomo/documento/find?idTipoDocumento=${idTipoDocumento}&nroDocumento=${nroDocumento}`)
            .then((response) => {
                dispatch(setPersonalAutonomo(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const buscarPersonalSubContratadoByIdSolicitud = async (id: number) => {
        await findAll(`personal-subcontratado/solicitud/${id}`)
            .then((response) => {
                dispatch(setPersonalSubContratado(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const eliminarPersonalPropioById = async (id: string) => {
        await remove('personal-propio', id)
            .then((response) => {
                buscarPersonalPropioByIdSolicitud(solicitudSelected.id)
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const eliminarPersonalAutonomoById = async (id: string) => {
        await remove('personal-autonomo', id)
            .then((response) => {
                buscarPersonalAutonomaByIdSolicitud(solicitudSelected.id)
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const eliminarPersonalSubContratadoById = async (id: string) => {
        await remove('personal-subcontratado', id)
            .then((response) => {
                buscarPersonalSubContratadoByIdSolicitud(solicitudSelected.id)
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const existePersonalAfectadoByIdContTrabajoEmpresa = async (id: number) => {
        await findAll(`contratistasTrabajosEmpresa/existePersonalAfectado?idContTrabajoEmpresa=${id}`)
            .then((response) => {
                dispatch(setPersonalAfectado(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
                dispatch(setPersonalAfectado(false));
            })
    }

    const handlePersonalPropioSelected = (personalPropioSelected: PersonalPropio) => {
        dispatch(loadDataPersonalPropio(personalPropioSelected))
    }

    const handlePersonalAutonomoSelected = (personalAutonomoSelected: PersonalAutonomo) => {
        dispatch(loadDataPersonalAutonomo(personalAutonomoSelected));
    };

    const handlePersonalSubContratadoSelected = (personalSubContratadoSelected: PersonalSubContratado) => {
        dispatch(loadDataPersonalSubContratado(personalSubContratadoSelected));
    };

    const notSelectAllPersonalAfectado = () => {
        dispatch(setNotSelectPersonalPropio())
        dispatch(setNotSelectPersonalAutonomo())
        dispatch(setNotSelectPersonalSubContratado())
    }

    const saveOrUpdatePersonalAfectado = async (path: string, personalAfectado: PersonalPropio | PersonalAutonomo | PersonalSubContratado) => {
        try {
            if (personalAfectado.id === 0) {
                // Llama a la función 'save' si el 'id' es igual a 0
                const result = await save(path, personalAfectado);
                return result;
            } else {
                // Llama a la función 'update' si el 'id' no es igual a 0
                const result = await update(path, personalAfectado);
                return result;
            }

        } catch (error) {
            console.log(error);
        }
    };


    return {
        personalAutonomoList,
        personalPropioList,
        personalSubContratadoList,
        buscarPersonalPropioByIdSolicitud,
        buscarPersonalAutonomaByIdSolicitud,
        buscarPersonalSubContratadoByIdSolicitud,
        handlePersonalPropioSelected,
        handlePersonalAutonomoSelected,
        handlePersonalSubContratadoSelected,
        personalPropioSelected,
        personalAutonomoSelected,
        personalSubContratadoSelected,
        isPersonalAfectado,
        existePersonalAfectadoByIdContTrabajoEmpresa,
        notSelectAllPersonalAfectado,
        saveOrUpdatePersonalAfectado,
        eliminarPersonalPropioById,
        eliminarPersonalAutonomoById,
        eliminarPersonalSubContratadoById,
        buscarPersonalByDocumento
    }

}