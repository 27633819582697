import { useState } from "react";
import { DocumentoTrabajo } from "../../../../../types/DocumentoTrabajo";
import { useDocumentoTrabajo } from "../../../../../hooks/useDocumentoTrabajo";
import { useTipoTrabajo } from "../../../../../hooks/useTipoTrabajo";
import Table, { ColumnDefinitionType } from "../../../../../components/Table";
import { Link } from "react-router-dom";
import Confirm from "../../../../../components/Confirm";
import Accordion from "../../../../../components/Accordion";
import MyModal from "../../../../../components/Modal";
import { Loading } from "../../../../../components/Loading";
import ModalTipoDocumento from "./ModalTipoDocumento";


type DocumentosProps = {
    documentos: DocumentoTrabajo[];
    setDocumentos: (value: DocumentoTrabajo[]) => void;
    edit: boolean,
    view: boolean
}

type FormErrors = {
    idTipoDocumento?: string;
    periodo?: string;
};

const SeccionTipoDocumentos = ({ edit, documentos, setDocumentos, view }: DocumentosProps) => {
    const { documentoTrabajoInitial } = useDocumentoTrabajo();

    const [documentoForm, setDocumentoForm] = useState<DocumentoTrabajo>(documentoTrabajoInitial);
    const [documentoFormUpdated, setDocumentoFormUpdated] = useState<DocumentoTrabajo>(documentoTrabajoInitial);
    const { loading, documentoList, handleDocumentoList } = useTipoTrabajo()
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isEditDoc, setIsEditDoc] = useState(false);
    const toggle = () => setShowModal(!showModal);
    const [data, setData] = useState<DocumentoTrabajo>(documentoTrabajoInitial);
    const eliminar = () => {
        let documentos: DocumentoTrabajo[] = documentoList.filter(eq => eq.tipoDocumentoId !== data.tipoDocumentoId)
        handleDocumentoList(documentos);
        toggle();
    }
    const [formErrors, setFormErrors] = useState<any>({
        idTipoDocumento: '',
        periodo: ''
    });
    const [documentosFiltrados, setDocumentosFiltrados] = useState<DocumentoTrabajo[]>([]);

    const columns: ColumnDefinitionType<DocumentoTrabajo, keyof DocumentoTrabajo>[] = []
    columns.push({
        key: 'tipoDocumentoNombre',
        header: 'Tipo de Documento',
    });

    // columns.push({
    //     key: 'tipoDocumentoDescripcion',
    //     header: 'Descripción',
    // });
    columns.push({
        key: 'obligatorio',
        header: 'Obligatorio',
        cell: (data: DocumentoTrabajo) =>
            <>
                {data.obligatorio ? 'Sí' : 'No'}
            </>
    });

    columns.push({
        key: 'renovable',
        header: 'Renovable',
        cell: (data: DocumentoTrabajo) =>
            <>
                {data.renovable ? 'Sí' : 'No'}
            </>
    });
    columns.push({
        key: 'periodo',
        header: 'Periodicidad (Días)',
    });

    if (!view) {
        columns.push({
            key: 'id',
            header: 'Acciones',
            cell: (data: DocumentoTrabajo) =>
                <>
                    <Link to="#" title="Editar"
                        onClick={(e) => {
                            e.stopPropagation();
                            setDocumentoForm(data);
                            setDocumentoFormUpdated(data)
                            actualizarDocumentos(data);
                            setIsEditDoc(true)
                            toggleModal();
                        }}
                    >
                        <i className="fa fa-pencil text-custom" aria-hidden="true"></i>
                    </Link>

                    <Link to="#" title="eliminar"
                        onClick={(e) => {
                            e.stopPropagation();
                            setData(data);
                            setShowModal(true)

                        }}
                    >
                        <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                    </Link>

                </>
        });
    }


    const handleConfirm = () => {
        const errors = validateForm(documentoForm);
        if (Object.keys(errors).length === 0) {

            let docFinal: DocumentoTrabajo[] = [];
            console.log(documentoForm);

            let documentoExistente: DocumentoTrabajo | undefined = documentoList.find(equi => equi.tipoDocumentoId === documentoFormUpdated.tipoDocumentoId);
            if (documentoExistente) {
                let replaceDoc: DocumentoTrabajo = { ...documentoExistente }

                replaceDoc.obligatorio = documentoForm.obligatorio;
                replaceDoc.renovable = documentoForm.renovable;
                replaceDoc.periodo = documentoForm.periodo;
                replaceDoc.tipoDocumentoId = documentoForm.tipoDocumentoId;
                replaceDoc.tipoDocumentoNombre = getNombreTipoDocumento(documentoForm.tipoDocumentoId);
                docFinal = documentoList.map(item => {

                    if (item.tipoDocumentoId === documentoFormUpdated.tipoDocumentoId) {
                        return replaceDoc
                    }
                    return item;
                })

            } else {
                let documento: DocumentoTrabajo | undefined = documentos.find(equi => equi.tipoDocumentoId == documentoForm.tipoDocumentoId);
                if (documento) {
                    documento.obligatorio = documentoForm.obligatorio;
                    documento.renovable = documentoForm.renovable;
                    documento.periodo = documentoForm.periodo;
                    documento.id = documentoForm.id;

                    docFinal.push(...documentoList, documento);
                }
            }

            handleDocumentoList(docFinal);
            toggleModal();
        }
        setFormErrors(errors)
    }

    const getNombreTipoDocumento = (tipoDocumentoId: number | null) => {
        let docu = documentos.find(equi => equi.tipoDocumentoId == tipoDocumentoId);
        if (docu) {
            return docu.tipoDocumentoNombre;
        }
        return null;
    }

    const validateForm = (documentoSelected: DocumentoTrabajo) => {
        const errors: FormErrors = {};
        if (!documentoSelected.tipoDocumentoId) {
            errors.idTipoDocumento = 'Debe seleccionar un Tipo de Documento'
        }
        if (documentoSelected.renovable) {
            if (!documentoSelected.periodo) {
                errors.periodo = 'Debe definir el periodo de renovación (en cantidad de días)'
            } else {
                if (documentoSelected.periodo < 0) {
                    errors.periodo = 'El periodo cargado debe ser mayor que cero'
                }
            }
        }
        return errors;
    }

    const actualizarDocumentos = (data: DocumentoTrabajo | null) => {
        let docFiltradas: DocumentoTrabajo[] = [];
        if (data) {
            docFiltradas.push(data);
        }

        for (const docSelect of documentos) {
            const existsInDocs = documentoList.some((eq) => docSelect.tipoDocumentoId == eq.tipoDocumentoId)
            if (!existsInDocs) {
                docFiltradas.push(docSelect);
            }
        }


        setDocumentosFiltrados(docFiltradas);
    }


    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar el tipo de documento {data.tipoDocumentoNombre}?</p>}
                    </div>
                </div>
            </Confirm>
            <Accordion id={'documentos'} title="Tipos de Documento">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <h4 className="m-t-0 header-title">
                                            <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                            Listado de Tipos de Documento
                                            {!view &&
                                                <Link to="#" title="Agregar" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDocumentoForm(documentoTrabajoInitial);
                                                        actualizarDocumentos(null);
                                                        setIsEditDoc(false)
                                                        toggleModal();
                                                    }}>
                                                    <i className="fa fa-plus margin-right-10"></i> Agregar
                                                </Link>
                                            }
                                        </h4>
                                    </div>

                                    <div className="table-responsive">
                                        <br />
                                        <MyModal isOpen={isOpen} toggle={toggleModal} title={isEditDoc ? 'Editar Tipo de Documento' : 'Agregar Tipo de Documento'} size="md"
                                            secondButton={true} secondButtonTitle={'Aceptar'} titleCancelar={'Cancelar'} secondButtonFn={handleConfirm}
                                        >
                                            <ModalTipoDocumento documentoForm={documentoForm} setDocumentoForm={setDocumentoForm} formErrors={formErrors}
                                                setFormErrors={setFormErrors} documentosFiltrados={documentosFiltrados} />

                                        </MyModal>
                                        {loading ? <Loading /> : <Table data={documentoList || []} columns={columns}></Table>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Accordion>
        </>
    )
}
export default SeccionTipoDocumentos;


