import { Link } from "react-router-dom";
import Table, { ColumnDefinitionType } from '../../../../components/Table'
import { Loading } from "../../../../components/Loading";
import { useState } from "react";
import Confirm from "../../../../components/Confirm";
import { toast } from "react-toastify";
import { useContratista } from "../../../../hooks/useContratista";
import { Contratista } from "../../../../types/Contratista";



const TableContratista = () => {
    const { contratistaList, loading, contratistaInitial, buscarContratistas, eliminarContratista, handleContratistaList } = useContratista();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [data, setData] = useState<Contratista>(contratistaInitial);
    const toggle = () => setShowModal(!showModal);


    const eliminar = () => {
        eliminarContratista(data.id as unknown as string)
            .then(response => {
                buscarContratistas({
                    id: 0,
                    razonSocial: "",
                    direccion: "",
                    email: "",
                    activo: null,
                    idEmpresaGestora: 0,
                    idTipoTrabajo: 0,
                    idTipoDocumento: 0,
                    tipoDocumento: "",
                    nroIdentificacion: "",
                    telefono: "",
                    logo: null,
                    logoNuevo: null,
                    trabajosEmpresas: []

                })
            })
            .catch(err => toast.error("Hubo un error al eliminar el Contratista"))
        toggle();
    }
    const columns: ColumnDefinitionType<Contratista, keyof Contratista>[] = [
        {
            key: 'razonSocial',
            header: 'Razón Social',
        },
        {
            key: 'nroIdentificacion',
            header: 'Tipo/Nro Identificación',
            cell: (data: Contratista) => <>{data.tipoDocumento + ' ' + data.nroIdentificacion}</>
        },

        {
            key: 'activo',
            header: 'Estado',
            cell: (data: Contratista) => <>{data.activo ? 'Activo' : 'Inactivo'}</>
        },
        {
            key: 'email',
            header: 'Mail',
        },

        {
            key: 'id',
            header: 'Acciones',
            cell: (data: Contratista) =>
                <>
                    <Link to={`/contratistas/editar/${data.id}?action=view`} title="Visualizar">
                        <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                    </Link>
                    <Link to={`/contratistas/editar/${data.id}`} title="Editar" >
                        <i className="fa fa-pencil text-custom" aria-hidden="true"></i>
                    </Link>
                    {data.activo &&
                        <Link to="#" title="eliminar"
                            onClick={(e) => {
                                e.stopPropagation();
                                setData(data);
                                setShowModal(true)

                            }}
                        >
                            <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                        </Link>
                    }

                </>
        },
    ]

    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar el contratista {data.razonSocial}?</p>}
                    </div>
                </div>
            </Confirm>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h4 className="m-t-0 header-title">
                                        <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                        Listado de Contratistas
                                        <Link to={`/contratistas/nuevo`} title="Nuevo" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}>
                                            <i className="fa fa-plus margin-right-10"></i> Nuevo
                                        </Link>

                                    </h4>

                                </div>

                                <div className="table-responsive">
                                    <br />

                                    {loading ? <Loading /> : <Table data={contratistaList || []} columns={columns} tabLayoutFixed={true} ></Table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default TableContratista;