import { v4 as uuidv4 } from 'uuid';

const FormSelectBox = (
  {
    title,
    label,
    id,
    name,
    value,
    isDisabled = false,
    onChange,
    options,
    optionId,
    optionLabel,
    classLabel = "col-form-label col-2 text-end",
    classDivSelect = "col-4",
    errorText = '',
    styleLabelColor = 'grey',
    seleccionarText = 'Seleccionar'
  }:
    {
      title: string,
      label?: string,
      id: string,
      name: string,
      value: any,
      isDisabled?: boolean,
      onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
      options: any[],
      optionId?: string,
      optionLabel?: string,
      classLabel?: string,
      classDivSelect?: string,
      errorText?: string,
      styleLabelColor?: string,
      seleccionarText?: string
    }
) => {

  // En caso de que se usen keys alternas a id - name
  const getMenuItem = (option: any, optionId?: string, optionLabel?: string) => {
    const key = uuidv4();
    let itemValue = optionId ? option[optionId] : option.id;
    itemValue = itemValue ? itemValue : '';
    let itemLabel = optionLabel ? option[optionLabel] : option.name;
    itemLabel = itemLabel ? itemLabel : label;

    return (
      <option key={key} value={itemValue}>
        {itemLabel}
      </option>
    )
  }
  const hasError = !!errorText;
  const st: any = isDisabled ? { appearance: 'none' } : {};

  return (
    <>
      <label htmlFor={id} className={classLabel} style={{ color: styleLabelColor }}>{title}</label>


      <div className={`${classDivSelect} ${hasError ? 'has-error' : ''}`}>
        <select
          id={id}
          name={name}
          className={`form-control select-list ${hasError ? 'is-invalid' : ''}`}
          value={value}
          style={st}
          onChange={onChange}
          disabled={isDisabled}
        >
          <option value={0}>{seleccionarText}</option>
          {options?.length > 0 ? options.map((option: any) => (
            getMenuItem(option, optionId, optionLabel)
          ))
            : (
              <option value=''>
                {label}
              </option>
            )
          }
        </select>

        {errorText && ( // Mostrar el texto de error opcional si está presente
          <div className="invalid-feedback">{errorText}</div>
        )}
      </div >
    </>
  );
}

export default FormSelectBox;
