import moment from "moment";
import Accordion from "../../../../components/Accordion";
import FormInput from "../../../../components/Form/FormInput";
import FormSelectBox from "../../../../components/Form/FormSelectBox";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSolicitud } from "../../../../hooks/useSolicitud";
import { Solicitud } from "../../../../classes/Solicitud";
import "react-datepicker/dist/react-datepicker.css";
import { DatosGeneralesForm } from "../../../../types/DatosGenerales";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Confirm from "../../../../components/Confirm";

type FormErrors = {
  fechaInicio?: string;
  fechaFin?: string;
  fechaAsignacion?: string;
  numeroContrato?: string;
  idInstalacion?: string;
};

type DatosGeneralesProps = {
  isDisabled: boolean;
  estados?: any;
  isEnviarInicioSolicitud: boolean;
  setDisabledActionEnviar: React.Dispatch<React.SetStateAction<any>>;
  handleCancelar: () => void;
};


const DatosGenerales = ({ isDisabled, estados, isEnviarInicioSolicitud, setDisabledActionEnviar, handleCancelar }: DatosGeneralesProps, ref: any) => {
  const { solicitudSelected, cargarCombo, handleSolicitudSelected, updateSolicitud, mapSolicitudToDatosGenerales, sendSolicitud, handleShowMessage, cargarInstalaciones } = useSolicitud();

  const [tiposTrabajo, setTiposTrabajo] = useState<any>({});
  //const [estados, setEstados] = useState<any>({});
  const [empresasGestoras, setEmpresasGestoras] = useState<any>({});
  const [contratistas, setContratistas] = useState<any>({});
  const [instalaciones, setInstalaciones] = useState<any>({});
  const [datosGeneralesForm, setDatosGeneralesForm] = useState<any>({});
  const navigate = useNavigate();
  const [showModalCancelar, setShowModalCancelar] = useState<boolean>(false);
  const toggleCancelar = () => setShowModalCancelar(!showModalCancelar);

  const mapEG = (eg: { id: number; nombreEmpresa: string; }) => ({ id: eg.id, descripcion: eg.nombreEmpresa })
  const mapContratista = (contratista: { id: number; razonSocial: string; }) => ({ id: contratista.id, descripcion: contratista.razonSocial })
  const mapInstalaciones = (inst: { id: number; nombre: string; }) => ({ id: inst.id, descripcion: inst.nombre })

  useEffect(() => {
    handleSolicitudSelected(solicitudSelected);
    setDatosGeneralesForm(mapSolicitudToDatosGenerales(solicitudSelected));

    if (solicitudSelected.idEmpresaGestora) {
      cargarInstalaciones('instalaciones', solicitudSelected.idEmpresaGestora, setInstalaciones, mapInstalaciones);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solicitudSelected]);

  useEffect(() => {
    cargarCombo('tiposTrabajos', setTiposTrabajo);
    //cargarCombo('tiposEstados', setEstados);
    cargarCombo('empresasGestoras', setEmpresasGestoras, mapEG);
    cargarCombo('contratistas', setContratistas, mapContratista);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formErrors, setFormErrors] = useState<any>({
    fechaInicio: '',
    fechaFin: '',
    fechaAsignacion: '',
    numeroContrato: '',
    idInstalacion: '',
  });

  const validateForm = (solicitudSelected: DatosGeneralesForm) => {
    const errors: FormErrors = {};

    // if (!solicitudSelected.fechaInicio) {
    //    errors.fechaInicio = 'Debe informar el campo Fecha Inicio Trabajo';
    // }

    if (!solicitudSelected.fechaFin) {
      // errors.fechaFin = 'Debe informar el campo Fecha Fin Trabajo';
    } else if (moment(solicitudSelected.fechaFin).isBefore(solicitudSelected.fechaInicio)) {
      errors.fechaFin = 'La Fecha Fin Trabajo debe ser mayor que la Fecha Inicio Trabajo';
    }

    if (!solicitudSelected.fechaAsignacion) {
      // errors.fechaAsignacion = 'Debe ingresar una fecha de asignación de contrato';
    } else if (moment(solicitudSelected.fechaAsignacion).isAfter(solicitudSelected.fechaInicio)) {
      errors.fechaAsignacion = '“La Fecha de Asignación de contrato debe ser menor a la Fecha Inicio Trabajo';
    }

    if (!solicitudSelected.numeroContrato) {
      errors.numeroContrato = 'Debe informar el ‘Número de Contrato’';
    }

    if (!solicitudSelected.idInstalacion) {
      errors.idInstalacion = 'Debe seleccionar una instalación';
    }

    return errors;
  };

  const isValidSubmit = (): boolean => {
    return Object.keys(formErrors).length === 0;
  };

  useImperativeHandle(ref, () => ({
    handleSubmitAndSend,
    isValidSubmit
  }));

  const handleSubmitAndSend = () => {
    //e.preventDefault();
    const errors = validateForm(datosGeneralesForm);


    if (Object.keys(errors).length === 0) {
      const datosGeneralesUpdate = {
        id: datosGeneralesForm.id,
        numeroContrato: datosGeneralesForm.numeroContrato,
        idInstalacion: datosGeneralesForm.idInstalacion,
        fechaInicio: datosGeneralesForm.fechaInicio ? moment(datosGeneralesForm.fechaInicio).format('DD/MM/YYYY') : null,
        fechaFin: datosGeneralesForm.fechaFin ? moment(datosGeneralesForm.fechaFin).format('DD/MM/YYYY') : null,
        fechaAsignacion: datosGeneralesForm.fechaFin ? moment(datosGeneralesForm.fechaAsignacion).format('DD/MM/YYYY') : null,
        infoAdicional: datosGeneralesForm.infoAdicional
      };
      updateSolicitud(datosGeneralesUpdate)
        .then(response => {

          sendSolicitud(datosGeneralesUpdate.id).then(response => {
            if (response !== undefined) {
              setDisabledActionEnviar(true)
              // toast.success("Solicitud enviada correctamente")
              handleShowMessage(true);
              navigate('/consultaSolicitudes')
            }
          })



          //toast.success("Se Guardo la solicitud correctamente")
        }

        )
        .catch(err => toast.error("Hubo un error al guardar la solicitud"))
    }
    setFormErrors(errors);
  };

  const handleSubmit = () => {
    //e.preventDefault();
    const errors = validateForm(datosGeneralesForm);

    if (Object.keys(errors).length === 0) {
      const datosGeneralesUpdate = {
        id: datosGeneralesForm.id,
        numeroContrato: datosGeneralesForm.numeroContrato,
        idInstalacion: datosGeneralesForm.idInstalacion,
        fechaInicio: datosGeneralesForm.fechaInicio ? moment(datosGeneralesForm.fechaInicio).format('DD/MM/YYYY') : null,
        fechaFin: datosGeneralesForm.fechaFin ? moment(datosGeneralesForm.fechaFin).format('DD/MM/YYYY') : null,
        fechaAsignacion: datosGeneralesForm.fechaAsignacion ? moment(datosGeneralesForm.fechaAsignacion).format('DD/MM/YYYY') : null,
        infoAdicional: datosGeneralesForm.infoAdicional
      };
      updateSolicitud(datosGeneralesUpdate)
        .then(response => {
          toast.success("Se guardó la solicitud correctamente")
        }

        )
        .catch(err => toast.error("Hubo un error al guardar la solicitud"))
    }
    setFormErrors(errors);
  };

  const handleInputChange = (name: string, value: Date | string | null) => {
    let formattedValue = value;



    // Actualiza el estado con el nuevo valor
    setDatosGeneralesForm({
      ...datosGeneralesForm,
      [name]: formattedValue,
    });

    // Validar el formulario después de cada cambio
    const errors = validateForm({
      ...datosGeneralesForm,
      [name]: formattedValue,
    });
    setFormErrors(errors);
  };

  const cancelar = (e: any) => {
    e.preventDefault();
    setShowModalCancelar(true)
  }


  return (
    <>
      <Confirm isOpen={showModalCancelar} toggle={toggleCancelar} title='Confirmar' yesFn={handleCancelar}>
        <div className="form-horizontal">
          <div className="row">
            {<p>Desea salir sin guardar los cambios?</p>}
          </div>
        </div>
      </Confirm>
      <Accordion id={'datosGenerales'} title="Datos Generales">
        <div>
          <div className="row mt-3">
            <FormInput
              id={'numeroSolicitud'}
              label={'Nro. Solicitud'}
              name={'numeroSolicitud'}
              // placeholder={'Nro. Solicitud'}
              value={solicitudSelected.numeroSolicitud || ''}
              isDisabled={true}
            />
            <FormSelectBox
              title={'Tipo de Trabajo'}
              label={'tipo de trabajo'}
              id={'tipoTrabajo'}
              name={'idTipoTrabajo'}
              optionId={'id'}
              optionLabel={'descripcion'}
              value={solicitudSelected.idTipoTrabajo || ''}
              options={tiposTrabajo}
              isDisabled={true}
            />


          </div>
          <div className="row mt-2">
            <FormSelectBox
              title={'Empresa Gestora'}
              label={'empresa gestora'}
              id={'empresaGestora'}
              name={'idEmpresaGestora'}
              optionId={'id'}
              optionLabel={'descripcion'}
              value={solicitudSelected.idEmpresaGestora || ''}
              options={empresasGestoras}
              isDisabled={true}
            />
            <FormSelectBox
              title={'Contratista'}
              label={'contratista'}
              id={'contratista'}
              name={'idContratista'}
              optionId={'id'}
              optionLabel={'descripcion'}
              value={solicitudSelected.idContratista || ''}
              options={contratistas}
              isDisabled={true}
            />


          </div>
          <div className="row mt-2">
            <FormSelectBox
              title={'Estado'}
              label={'estado'}
              id={'estado'}
              name={'idTipoEstado'}
              optionId={'id'}
              optionLabel={'nombre'}
              value={solicitudSelected.idTipoEstado || ''}
              options={estados}
              isDisabled={true}
            />
            <FormSelectBox
              title={'Instalación'}
              label={'instalacion'}
              id={'instalacion'}
              name={'idInstalacion'}
              optionId={'id'}
              optionLabel={'descripcion'}
              value={datosGeneralesForm.idInstalacion || ''}
              options={instalaciones}
              isDisabled={isDisabled}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              errorText={formErrors.idInstalacion}
            />

          </div>
          <div className="row mt-2">
            <FormInput
              id={'numeroContrato'}
              label={'Nro. Contrato'}
              name={'numeroContrato'}
              // placeholder={'Nro. Contrato'}
              value={datosGeneralesForm.numeroContrato || ''}
              isDisabled={isDisabled}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              errorText={formErrors.numeroContrato}
            />
            <FormInput
              id={'fechaAsignacion'}
              label={'Fecha Asignación Contrato'}
              name={'fechaAsignacion'}
              type="date"
              // placeholder={'dd/mm/yyyy'}
              value={datosGeneralesForm.fechaAsignacion}
              isDisabled={isDisabled}
              onDateChange={(date: Date | null, name: string) => {
                handleInputChange(name, date);
              }}
              errorText={formErrors.fechaAsignacion}
            />
          </div>

          <div className="row mt-2">
            <FormInput
              id={'fechaInicio'}
              label={'Fecha Inicio Trabajo'}
              name={'fechaInicio'}
              type="date"
              // placeholder={'dd/mm/yyyy'}
              value={datosGeneralesForm.fechaInicio}
              isDisabled={isDisabled}
              onDateChange={(date: Date | null, name: string) => {
                handleInputChange(name, date);
              }}
              errorText={formErrors.fechaInicio}
            />

            <FormInput
              id={'fechaFin'}
              label={'Fecha Fin Trabajo'}
              name={'fechaFin'}
              type="date"
              // placeholder={'dd/mm/yyyy'}
              value={datosGeneralesForm.fechaFin}
              isDisabled={isDisabled}
              onDateChange={(date: Date | null, name: string) => {
                handleInputChange(name, date);
              }}
              errorText={formErrors.fechaFin}
            />
          </div>
          <div className="row mt-2">
            <FormInput
              id={'infoAdicional'}
              label={'Información Adicional'}
              name={'infoAdicional'}
              // placeholder={'Nro. Contrato'}
              value={datosGeneralesForm.infoAdicional || ''}
              isDisabled={isDisabled}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              classColumn={'col-10'}
              maxLength={140}
            />
          </div>
          <div className="row mt-2">
            <FormInput
              id={'fechaCreacion'}
              label={'Fecha Creación'}
              name={'fechaCreacion'}
              // placeholder={'dd/mm/yyyy'}
              value={solicitudSelected.fechaAlta ? moment(solicitudSelected.fechaAlta).format('DD/MM/YYYY') : ''}
              isDisabled={true}
            />
            <FormInput
              id={'usuarioCreacion'}
              label={'Usuario Creación'}
              name={'usuarioCreacion'}
              // placeholder={'Usuario Creación'}
              value={solicitudSelected.usuarioAlta ? solicitudSelected.usuarioAlta : ''}
              isDisabled={true}
            />
          </div>
          <div className="row mt-3"></div>
          {!isDisabled && (
            <div className="text-center">
              <button type="button" className="btn btn-danger mx-2" onClick={cancelar}>Cancelar</button>
              <button type="button" className="btn btn-info mx-2" onClick={handleSubmit}>Guardar</button>
            </div>
          )}
          <div className="row mt-3"></div>
        </div>

      </Accordion>
    </>
  )
};

export default forwardRef(DatosGenerales);