/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import Accordion from '../../../../components/Accordion'
import { useSolicitud } from '../../../../hooks/useSolicitud'
import { useObservacion } from '../../../../hooks/useObservacion'
import { ObservacionSolicitud } from '../../../../types/ObservacionSolicitud'
import Table, { ColumnDefinitionType } from '../../../../components/Table';
import moment from 'moment'
import { Link } from 'react-router-dom'
import MyModal from '../../../../components/Modal'
import FormInput from '../../../../components/Form/FormInput'
import FormTextArea from '../../../../components/Form/FormTextArea'
import './row.css'

type ObservacionProps = {
    isDisabled?: boolean
};

const Observacion: React.FC<ObservacionProps> = ({ isDisabled = false }) => {
    const { solicitudSelected } = useSolicitud();
    const { buscarObservacionesByIdSolicitud, setObservacionSelected, observaciones, observacionSelected } = useObservacion();
    const [cargarObservacionSolicitud, setObservacionSolicitud] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (cargarObservacionSolicitud) {
            buscarObservacionesByIdSolicitud(solicitudSelected.id);
            setObservacionSolicitud(false);
        }
    }

    const columns: ColumnDefinitionType<ObservacionSolicitud, keyof ObservacionSolicitud>[] = [
        {
            key: 'fechaObservacion',
            header: 'Fecha',
            cell: (data: ObservacionSolicitud) => <>{data.fechaObservacion ? moment(data.fechaObservacion).format('DD/MM/YYYY') : ''}</>
        },
        {
            key: 'observacion',
            header: 'Observacion',
            cellClassName: 'max-width-cell'

        },
        {
            key: 'usuario',
            header: 'Usuario'
        },
        {
            key: 'id',
            header: 'Acciones',
            cell: (data: ObservacionSolicitud) =>
                <>
                    <Link title="Visualizar" to="/exportar" onClick={(e) => {
                        setObservacionSelected(data)
                        toggle();
                        e.preventDefault();
                    }} target="_blank" >
                        <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                    </Link>
                </>
        },
    ]
    return (
        <>
            <Accordion id={'Observaciones'} title="Observaciones" collapsed={true} handleClick={handleClick}>
                <div className='p-3'>
                    <div className="table-responsive ">
                        <MyModal isOpen={isOpen} toggle={toggle} title='Vista Observación'>
                            <div>
                                <div className="row mt-12 mb-2">
                                    <FormInput
                                        id={'fechaObservacion'}
                                        label={'Fecha Observación'}
                                        name={'fechaObservacion'}
                                        value={observacionSelected.fechaObservacion ? moment(observacionSelected.fechaObservacion).format('DD/MM/YYYY') : ''}
                                        isDisabled={true}
                                        classColumn={'col-4'}
                                        classLabel={'col-form-label col-4 text-end'}
                                    />

                                </div>
                                <div className="row mt-12 mb-2">
                                    <FormInput
                                        id={'usuario'}
                                        label={'Usuario'}
                                        name={'usuario'}
                                        value={observacionSelected.usuario || ''}
                                        isDisabled={true}
                                        classColumn={'col-4'}
                                        classLabel={'col-form-label col-4 text-end'}
                                    />
                                </div>
                                <div className="row mt-12 justify-content-center">
                                    <FormTextArea
                                        id={'observacion'}
                                        title={'Observación'}
                                        name={'observacion'}
                                        value={observacionSelected.observacion || ''}
                                        isDisabled={true}
                                        classDivTextArea={'col-12'}
                                    ></FormTextArea>
                                </div>
                            </div>
                        </MyModal>
                        <Table data={observaciones} columns={columns} ></Table>
                    </div>
                </div>
            </Accordion>
        </>
    )
}

export default Observacion