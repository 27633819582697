import api from '../api/api';
import { Documento } from '../types/Documento';
import { toast } from 'react-toastify';
import { Dispatch } from "react";


const toBytes: number = 1024 * 1024;

export const findAll = async (path: string) => {
  try {
    let response: any = {}
    response = await api.get(`${path}`);
    return response;
  } catch (error: any) {
    handleErrors(error);
  }
}

export const get = async (path: string, id: string) => {
  try {
    let response: any = {}
    response = await api.get(`${path}/${id}`);
    return response;
  } catch (error: any) {
    handleErrors(error);
  }
}

export const save = async (path: string, entity: any) => {
  try {
    let response: any = {}
    response = await api.post(
      path,
      entity
    );
    return response;
  } catch (error) {
    handleErrors(error);
  }
}

export const update = async (path: string, entity: any) => {
  try {
    let response: any = {}
    response = await api.put(`${path}/${entity.id}`, entity);
    return response;
  } catch (error) {
    handleErrors(error);
  }
}

export const remove = async (path: string, id: string) => {
  try {
    await api.delete(`${path}/${id}`);
  } catch (error) {
    handleErrors(error);
  }
}

export const downloadFileParam = async (path: string, fileId: string, nombreArchivo: string) => {
  try {
    const response = await api.get(`${path}?keyFileName=${fileId}&fileName=${nombreArchivo}`, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', nombreArchivo);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const downloadFile = async <T extends Documento>(path: string, documento: T) => {
  try {
    const response = await api.get(`${path}?keyFileName=${documento.fileId}&fileName=${documento.nombreArchivo}`, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', documento.nombreArchivo);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const downloadAndOpenFile = async <T extends Documento>(path: string, documento: T) => {
  try {
    const response = await api.get(`${path}?keyFileName=${documento.fileId}&fileName=${documento.nombreArchivo}`, { responseType: 'blob' });
    console.log(documento);

    const url = window.URL.createObjectURL(new Blob([response.data], {
      type: getType(documento.nombreArchivo.split('.')[1])
    }));
    window.open(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const downloadAndOpenQR = async (path: string) => {
  try {
    const response = await api.get(`${path}`, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data], {
      type: 'application/pdf'
    }));
    window.open(url);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'CodigoQR');
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const uploadFile = async (path: string, selectedFile: File | null, parametros: any, mostrarMsj = true) => {
  if (!selectedFile) {
    toast.error('No selecciono un archivo');
    return false;
  }

  let maxFileLimit: boolean = false;
  const maxFileSize = toBytes * (parametros.sizeMaximo != null ? parametros.sizeMaximo : 20);

  if (selectedFile.size > maxFileSize) {
    maxFileLimit = true;
    toast.error(`El tamaño del archivo ${selectedFile.name} no puede superar los ${parametros.sizeMaximo} MB`)
    return false;
  }

  if (!maxFileLimit) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data', // Asegúrate de establecer el encabezado adecuado
      },
      //data: parametros,
    };
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Combina los datos del archivo y los parámetros en un objeto FormData
      for (const key in parametros) {
        formData.append(key, JSON.stringify(parametros[key]));
      }

      // Realiza una solicitud POST para cargar el archivo al servidor
      const response = await api.post(`${path}`, formData, config);

      if (mostrarMsj) {
        if (response.status === 201) {
          toast.success('Archivo subido exitosamente');
        } else {
          toast.success('Error al subir el archivo');
        }
      }
      return true;
    } catch (error: any) {

      if (error.response.status === 413) {
        toast.error(error.response.data.message)
      } else {
        toast.error('No se pudo adjuntar el archivo seleccionado')
      }


    }
  }
};

export const uploadFiles = async (path: string, fileDataArray: { file: File; parameters: any }[]) => {
  if (!fileDataArray || fileDataArray.length === 0) {
    toast.error('No seleccionó archivos');
    return false;
  }
  let maxFileLimit: boolean = false;

  for (const fileData of fileDataArray) {
    const { file, parameters } = fileData;
    const maxFileSize = toBytes * (parameters.sizeMaximo != null ? parameters.sizeMaximo : 20);

    if (file.size > maxFileSize) {
      maxFileLimit = true;
      toast.error(`El tamaño del archivo ${file.name} no puede superar los ${parameters.sizeMaximo} MB`)
      return;
    }

  }
  if (!maxFileLimit) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {

      for (const fileData of fileDataArray) {
        const { file, parameters } = fileData;

        if (file) {
          const formData = new FormData();
          formData.append('file', file);

          for (const key in parameters) {
            formData.append(key, JSON.stringify(parameters[key]));
          }

          const response = await api.post(`${path}`, formData, config);

          if (response.status === 201) {
            //toast.success(`Archivo ${file.name} subido exitosamente`);
          } else {
            //toast.error(`Error al subir el archivo ${file.name}`);
          }
        }
      }
      toast.success("Se completo exitosamente")
      return true;
    } catch (error) {
      //toast.error("Hubo un error")
      throw error;
      //console.error('Error:', error);
    }
  } else {
    return false;
  }
};




export const loginUser = async ({ username, password }: { username: string, password: string }) => {
  try {

    return await api.post('login', {
      "nombreUsuario": username,
      "claveUsuario": password
    });
  } catch (error) {
    throw error;
  }
}

export const recuperarClave = async (path: string, email: string) => {
  try {
    const url: string = window.location.protocol + "//" + window.location.host;

    return await api.post(`${path}`, {
      "email": email,
      "url": url
    });
  } catch (error) {
    throw error;
  }
}

export const puedeAdjuntar = async (path: string, documentos: any[]) => {
  // idDocumento: number, tipoDocumentoId: number
  try {
    let puedeAdj: boolean = false;
    let n: number = 0
    while (!puedeAdj || n < documentos.length) {
      const response = await get(path, `puedeAdjuntar?idTipoDocumento=${documentos[n].idTipoDocumento}&idDocumento=${documentos[n].id}`);
      puedeAdj = response.data;
      if (!puedeAdj) {
        return false;
      }
      n++;
    }

    return true;
  } catch (error) {
    return false;
  }

}

const getType = (param: string) => {
  switch (param.toUpperCase()) {
    case 'PDF':
      return 'application/pdf';
    case 'PNG':
      return 'image/png';
    case 'JPEG':
      return 'image/jpeg';
    case 'JPG':
      return 'image/jpg';
    case 'TXT':
      return 'text/plain';
    default:
      return 'application/octet-stream';
  }


}

export const cargarCombo = async (path: string, setStateFunc: Dispatch<React.SetStateAction<any>>, mapFn?: (value: any, index: number, array: any[]) => any) => {

  await findAll(path)
    .then((response) => {
      const resp = mapFn != null ? response.data.map(mapFn) : response.data;
      setStateFunc(resp);
    })
    .catch((error) => {
      console.log(error);
    })
}

export const findPersonalByTipoAndNumDoc = async (tipoDocId: number, numeroDocumento: string) => {
  try {
    const response = await get("personal", `find?tipoDocPersonaId=${tipoDocId}&numeroDocumento=${numeroDocumento}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const findDatosSolicitud = async (idSolicitud: string) => {
  try {
    // const response = await get(`solicitudes/${idSolicitud}/datos`, '');
    const response = await api.get(`solicitudes/${idSolicitud}/datos`);
    return response;
  } catch (error) {
    throw error;
  }
}




const handleErrors = (error: any) => {
  switch (error.response?.status) {
    case 401:
      window.location.replace('/error401');
      break;
    case 403:
      window.location.replace('/error403');
      break;
    case 404:
      window.location.replace('/error404');
      break;
    case 500:
      window.location.replace('/error500');
      break;
    default:
      throw error;
  }
}

