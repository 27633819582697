import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Usuario } from '../../types/Usuario';

interface UsuarioLoginState {
    usuarioLogin: Usuario;
    isAuth: boolean | null,
    errors: any;
    error: boolean,
    token: string | null,
    roles: boolean | null,
    permisos: string[],
    linkPermisos: string[],
    redirect: string | null,

}
export const usuarioLoginInitial: Usuario = {
    id: 0,
    apellido: '',
    nombre: '',
    nombreUsuario: '',
    tipoUsuarioValor: '',
    tipoUsuarioId: "",
    tipoDocumento: '',
    telefono: '',
    perfilesSelected: [],
    numeroDocumento: '',
    mail: '',
    idContratista: 0,
    contratista: '',
    idEmpresaGestora: 0,
    idTipoDocumento: 0,
    idTipoUsuario: 0,
    empresaGestora: '',
    guardado: false,
    estado: false,
    fechaAlta: '',
    fechaBaja: '',
    fechaModificacion: '',
    url: ''

}
export const UsuarioLoginStateInitial: UsuarioLoginState = {
    usuarioLogin: usuarioLoginInitial,
    isAuth: null,
    errors: [],
    error: false,
    token: localStorage.getItem('token') || null,
    roles: false,
    permisos: [],
    linkPermisos: [],
    redirect: null
}

const initialState: UsuarioLoginState = JSON.parse(sessionStorage.getItem('login') || JSON.stringify(UsuarioLoginStateInitial))

export const userLoginSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userLoginData: (state, data: PayloadAction<Usuario>): void => {
            state.usuarioLogin = data.payload;
        },
        onLogin: (state, data: PayloadAction<Usuario>): void => {
            state.isAuth = true;
            state.usuarioLogin = data.payload;
        },
        onLogout: (state) => {
            state.isAuth = false;
            state.usuarioLogin = usuarioLoginInitial;
            state.token = null;
            state.roles = null;
            state.error = false;
            state.errors = []
            localStorage.removeItem('token');
            localStorage.removeItem('roles');
            localStorage.removeItem('login');
        },
        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        setAuth: (state, data: PayloadAction<boolean>): void => {
            state.isAuth = data.payload;
        },
        setToken: (state, data: PayloadAction<string>): void => {
            state.token = data.payload;
        },
        clearToken: (state) => {
            state.token = null;
        },
        setRoles: (state, data: PayloadAction<boolean>): void => {
            state.roles = data.payload;
        },
        clearRoles: (state) => {
            state.roles = null;
        },
        setPermisos: (state, data: PayloadAction<string[]>): void => {
            state.permisos = data.payload;
        },
        setLinkPermisos: (state, data: PayloadAction<string[]>): void => {
            state.linkPermisos = data.payload;
        },
        clearPermisos: (state) => {
            state.permisos = [];
        },
        clearLinkPermisos: (state) => {
            state.linkPermisos = [];
        },
        setUsuario: (state, data: PayloadAction<Usuario>): void => {
            state.usuarioLogin = data.payload
        },
        clearUsuario: (state) => {
            state.usuarioLogin = usuarioLoginInitial;
        },
        urlRedirect: (state, data: PayloadAction<string | null>): void => {
            state.redirect = data.payload;
        },

    },
})

export const { userLoginData, onLogin, onLogout, loadingError, visibleError, setAuth, setToken, clearToken, setRoles, clearRoles, setPermisos, setLinkPermisos, clearPermisos, clearLinkPermisos, setUsuario, clearUsuario, urlRedirect } = userLoginSlice.actions;
export default userLoginSlice.reducer;
