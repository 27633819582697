import React, { useEffect, useState } from 'react'
import Table, { ColumnDefinitionType } from '../../../../../components/Table'
import { Documento } from '../../../../../types/Documento'
import { Link } from 'react-router-dom'
import { useDocumento } from '../../../../../hooks/useDocumento'
import FormSelectBox from '../../../../../components/Form/FormSelectBox'
import { useSolicitud } from '../../../../../hooks/useSolicitud'
import moment from 'moment'
import { downloadAndOpenFile, downloadFile } from '../../../../../services/Service'
import MyModal from '../../../../../components/Modal'
import ObservacionComponent from '../../../../../components/Observaciones'

type HistorialProps = {
    tipoDocumentoDisabled?: boolean
    pathService?: string
    idTipoDocumentoSelected?: number
};

export const Historial: React.FC<HistorialProps> = ({ tipoDocumentoDisabled, pathService, idTipoDocumentoSelected }) => {
    const [idTipoDocumento, setIdTipoDocumento] = useState<number>();
    const [tiposDocumentos, setTiposDocumentos] = useState<any>({});
    const { historialDocumentos, documentoSelected, buscarDocumentosByIdTipoDocumento, buscarDocumentosByPathAndIdTipoDocumento, handleDocumentoSelected } = useDocumento();
    const { cargarCombo } = useSolicitud();
    const [isOpenModalObservacionDocument, setIsOpenModalObservacionDocument] = useState(false)


    const columns: ColumnDefinitionType<Documento, keyof Documento>[] = [
        {
            key: 'nombreArchivo',
            header: 'Nombre',
        },
        {
            key: 'fechaVencimiento',
            header: 'Vencimiento',
            cell: (data: Documento) => <>{data.fechaVencimiento ? moment(data.fechaVencimiento).format('DD/MM/YYYY') : ''}</>
        },
        {
            key: 'observacion',
            header: 'Observaciones',
            cell: (data: Documento) => <>
                {
                    data.observacion &&
                    <a href="#" title="Observaciones" onClick={() => {
                        handleDocumentoSelected(data);
                        setIsOpenModalObservacionDocument(true)
                    }}>
                        <i className="fa fa-search me-2" style={{ color: 'red' }} aria-hidden="true"></i>
                    </a>
                }
            </>

        },
        {
            key: 'id',
            header: 'Acciones',
            cell: (data: Documento) =>
                <>
                    <Link title='Visualizar' to="/exportar" onClick={(e) => {
                        e.preventDefault();
                        downloadAndOpenFile('documentos/visualizarArchivo', data)
                    }} target="_blank" >
                        <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                    </Link>
                    <Link title='Descargar' to="/exportar" onClick={(e) => {
                        e.preventDefault();
                        downloadFile('documentos/descargarArchivo', data)
                    }} target="_blank" >
                        <i className="fa fa-arrow-down text-secondary me-2" aria-hidden="true"></i>
                    </Link>
                </>
        },
    ]

    useEffect(() => {
        cargarCombo('tiposDocumentos', setTiposDocumentos);
        setIdTipoDocumento(documentoSelected.idTipoDocumento);
        if (pathService && idTipoDocumentoSelected) {

            setIdTipoDocumento(idTipoDocumentoSelected)
            buscarDocumentosByPathAndIdTipoDocumento(pathService, idTipoDocumentoSelected)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (idTipoDocumentoSelected) {

        }
    }, [tiposDocumentos])

    const handleChange = (e: any) => {
        e.preventDefault();
        const id: number = e.target.value;
        setIdTipoDocumento(id);
        if (id) {
            buscarDocumentosByIdTipoDocumento(id)
        }
    };

    return (
        <>
            <MyModal
                isOpen={isOpenModalObservacionDocument} toggle={() => setIsOpenModalObservacionDocument(false)} title='Observaciones' cancelButton={false} secondButton={false}
            >
                <ObservacionComponent observacion={documentoSelected.observacion} isEdit={false}
                    cancelButtonFn={() => setIsOpenModalObservacionDocument(false)} returnButtonFn={() => setIsOpenModalObservacionDocument(false)}
                ></ObservacionComponent>
            </MyModal>
            <div className="form-horizontal">
                <div className="row">

                    <FormSelectBox
                        title={'Tipo de Documento'}
                        label={'tipo de documento'}
                        id={'tipoDocumento'}
                        name={'idTipoDocumento'}
                        optionId={'id'}
                        optionLabel={'nombre'}
                        value={idTipoDocumento}
                        options={tiposDocumentos}
                        onChange={handleChange}
                        isDisabled={tipoDocumentoDisabled}
                        classLabel={"col-form-label col-4 text-end"}
                        classDivSelect="col-7"
                    />

                </div>
                <div className="row mt-4"></div>
            </div>


            <div className="table-responsive ">
                <Table data={historialDocumentos} columns={columns}></Table>
            </div>

        </>
    )
}

Historial.defaultProps = {
    tipoDocumentoDisabled: false,
};