import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSolicitud } from '../../../hooks/useSolicitud';
import { Breadcrumbs } from '../../Home/Breadcrumb';
import { TablaBandeja } from './Table';
import { useAuth } from '../../../hooks/useAuth';



export const BandejaTrabajo = () => {
  const { handleRedirect } = useAuth();

  const { handleShowMessage, handleAnulada, message, anulada } = useSolicitud()
  useEffect(() => {

    if (message) {
      toast.success("Solicitud enviada correctamente");
      handleShowMessage(false);
    }
    if (anulada) {
      toast.success("Solicitud anulada correctamente");
      handleAnulada(false)
    }
    handleRedirect(null);


  });
  return (
    <>
      <ToastContainer />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Breadcrumbs
              title={'Bandeja de Trabajo'}
            />
            <TablaBandeja />
          </div>
        </div>
      </div>

    </>
  );
};
