import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll } from "../services/Service";
import { loadListDataDocumentoTrabajo, loadDataDocumentoTrabajo, documentoTrabajoInitial } from "../context/DocumentoTrabajo/DocumentoTrabajoReducer";
import { DocumentoTrabajo } from "../types/DocumentoTrabajo";

export const useDocumentoTrabajo = () => {
    const BASE_URL = 'documentoTrabajo';

    const { documentosTrabajo, documentoTrabajoSelected } = useSelector(
        (state: RootState) => state.documentoTrabajo
    );
    const dispatch = useDispatch();


    const buscarDocumentosTrabajoByTipoTrabajo = async (id: number) => {
        await findAll(`${BASE_URL}/tipoTrabajo/${id}`)
            .then((response) => {
                dispatch(loadListDataDocumentoTrabajo(response.data));
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const handleDocumentoTrabajoSelected = (documento: DocumentoTrabajo) => {
        dispatch(loadDataDocumentoTrabajo(documento))
    }
    const getFormatFile = (param: string) => {
        param = param === 'image/jpeg' ? 'image/jpg' : param;
        switch (param) {
            case 'application/pdf':
                return 'PDF';
            case 'image/png':
                return 'PNG';
            case 'image/jpeg':
                return 'JPEG';
            case 'image/jpg':
                return 'JPG';
            case 'text/plain':
                return 'TXT';
            case 'application/msword':
                return 'DOC';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'DOCX';
            case 'application/vnd.ms-excel':
                return 'XLS';
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'XLSX';
            default:
                return 'UNKNOWN';
        }
    }

    return {
        handleDocumentoTrabajoSelected,
        buscarDocumentosTrabajoByTipoTrabajo,
        documentosTrabajo,
        documentoTrabajoSelected,
        documentoTrabajoInitial,
        getFormatFile
    }

}