
import { Solicitud } from "../../../../classes/Solicitud";
import Accordion from "../../../../components/Accordion";
import { useEffect, useState } from "react";
import { useSolicitud } from "../../../../hooks/useSolicitud";
import Table, { ColumnDefinitionType } from "../../../../components/Table";
import { Loading } from "../../../../components/Loading";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import MyModal from "../../../../components/Modal";
import { CrearSolicitud } from "../../CrearSolicitud/Index";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { APROBADA_STATUS_ID, EN_ACTUALIZACION_STATUS_ID, EN_PROCESO_STATUS_ID, INICIO_STATUS_ID, OBSERVADA_STATUS_ID, REVISION_STATUS_ID } from "../../../../constants/constants";
import { useUserLogin } from "../../../../hooks/useUserLogin";
import { Usuario } from "../../../../types/Usuario";
import { downloadAndOpenQR } from "../../../../services/Service";


export const TablaSolicitud = () => {
    const { isContratistaTipoUsuario, isEmpresaGestoraTipoUsuario, getUsuarioLocalStorage } = useUserLogin()
    const [isContratista, setIsContratista] = useState<boolean>()
    const [usuarioLogin, setUsuarioLogin] = useState<Usuario>()
    const [isEmpresaGestora, setIsEmpresaGestora] = useState<boolean>()
    const { permisos } = useSelector((state: RootState) => state.auth);
    const { solicitudes, buscarSolicitudes, handleLoading, loading, handleUrlVolver } = useSolicitud();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [secondaryButton] = useState(permisos.find(nombre => nombre === 'ALTA_SOLICITUD') !== undefined);

    const [filtroSolicitudIni] = useState<any>({
        numeroSolicitud: '',
        numeroContrato: '',
        idTipoTrabajo: '',
        idTipoEstado: '',
        idEmpresaGestora: '',
        idContratista: '',
        fechaInicio: '',
        fechaFin: ''
    });
    useEffect(() => {
        setUsuarioLogin(getUsuarioLocalStorage())
        setIsContratista(isContratistaTipoUsuario());
        setIsEmpresaGestora(isEmpresaGestoraTipoUsuario());
        handleLoading(true);
        buscarSolicitudes(filtroSolicitudIni);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroSolicitudIni]);

    let columns: ColumnDefinitionType<Solicitud, keyof Solicitud>[] = [
        {
            key: 'numeroSolicitud',
            header: 'Número de Solicitud',
        },
        {
            key: 'tipoTrabajo',
            header: 'Tipo de Trabajo',
        },
        {
            key: 'fechaInicio',
            header: 'Fecha Inicio',
            cell: (data: Solicitud) => <>{data.fechaInicio ? moment(data.fechaInicio).format('DD/MM/YYYY') : ''}</>
        },
        {
            key: 'fechaFin',
            header: 'Fecha Fin',
            cell: (data: Solicitud) => <>{data.fechaFin ? moment(data.fechaFin).format('DD/MM/YYYY') : ''}</>
        },
        {
            key: 'estado',
            header: 'Estado',
        },

        {
            key: 'empresaGestora',
            header: 'Empresa Gestora',
        },
        {
            key: 'contratista',
            header: 'Contratista',
        },

        {
            key: 'id',
            header: 'Acciones',
            cell: (data: Solicitud) => {
                const puedeEditar =
                    (isEmpresaGestora && (data.idTipoEstado === INICIO_STATUS_ID || data.idTipoEstado === REVISION_STATUS_ID || data.idTipoEstado === APROBADA_STATUS_ID)) ||
                    (isContratista && (data.idTipoEstado === EN_PROCESO_STATUS_ID || data.idTipoEstado === OBSERVADA_STATUS_ID || data.idTipoEstado === APROBADA_STATUS_ID ||
                        data.idTipoEstado === EN_ACTUALIZACION_STATUS_ID));
                return (
                    <>
                        {permisos.find(nombre => nombre === 'VISTA_SOLICITUD') !== undefined &&

                            <Link to={`/solicitud/${data.id}?action=view`} title="Visualizar" onClick={(e) => {
                                e.stopPropagation();
                                handleUrlVolver('/consultaSolicitudes')
                            }}>
                                <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                            </Link>
                        }
                        {puedeEditar && (permisos.find(nombre => nombre === 'EDICION_SOLICITUD') !== undefined && (
                            // <Link to={`/solicitud/${data.id}?action=edit`} title="Editar">
                            //     <i className="fa fa-pencil text-secondary" aria-hidden="true"></i>
                            // </Link>
                            <Link to={`/solicitud/${data.id}?action=edit`} title="Editar" onClick={(e) => {
                                e.stopPropagation();
                                handleUrlVolver('/consultaSolicitudes')
                            }}>
                                <i className="fa fa-pencil text-secondary" aria-hidden="true"></i>
                            </Link>
                        )
                        )}
                        {permisos.find(nombre => nombre === 'CONSULTA_SOLICITUD') !== undefined &&

                            <Link to={"#"} title="QR" onClick={(e) => {
                                e.stopPropagation();
                                downloadAndOpenQR(`/solicitudes/${data.id}/QRCode`);

                            }} >
                                <i className="fa fa-qrcode fa-lg mx-1 text-success" aria-hidden="true"></i>
                            </Link>
                        }



                    </>)
            }

        },
    ]
    if (permisos.find(nombre => nombre === 'VISTA_SOLICITUD') === undefined && permisos.find(nombre => nombre === 'EDICION_SOLICITUD') === undefined) {
        columns = columns.filter(c => c.key !== 'id')
    }
    const navigate = useNavigate();

    const handleSecondary = () => {
        // setIsOpen(true);
        navigate('/crearSolicitud')
    }

    return (
        <>
            {/* <MyModal isOpen={isOpen} toggle={toggle} title='Crear Solicitud' secondButton={true} secondButtonTitle="Aceptar" titleCancelar="Cancelar" size="lg">
                <CrearSolicitud />
            </MyModal> */}
            <Accordion id={'accordionSolicitudListado'} title="Listado de Solicitudes" secondary={secondaryButton} titleSecondary="Crear Solicitud" iconoClass='fa fa-search mr-1' handleSecondary={handleSecondary} link="/crearSolicitud">
                <div className="table-responsive ">
                    {loading ? <Loading /> : (solicitudes && <Table data={solicitudes || []} columns={columns} notResults='No existen solicitudes para el criterio ingresado' ></Table>)}
                </div>
            </Accordion>
        </>

    );
}