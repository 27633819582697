import React, { useEffect, useRef, useState } from 'react'
import FormInput from '../../../../components/Form/FormInput'
import { ToastContainer, toast } from 'react-toastify'
import { Breadcrumbs } from '../../../Home/Breadcrumb'
import FormSelectBox from '../../../../components/Form/FormSelectBox'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { cargarCombo, uploadFile } from '../../../../services/Service'
import Accordion from '../../../../components/Accordion'
import { useContratista } from '../../../../hooks/useContratista'
import { Contratista } from '../../../../types/Contratista'
import { SeccionTrabajoEmpresa } from './TrabajoEmpresa/Index'
import { TrabajoEmpresa } from '../../../../types/TrabajoEmpresa'
import Confirm from '../../../../components/Confirm'


export type FormContratistaErrors = {
    razonSocial?: string;
    direccion?: string,
    email?: string,
    telefono?: string,
    idTipoDocumento?: string,
    nroIdentificacion?: string,
};

export const CrearContratista = (props: FormContratistaErrors) => {
    const { contratistaInitial, contratistaSelected, guardarContratista, buscarContratista, handleContratistaSelected, trabajoEmpresaList, buscarTrabajosEmpresas, handleTrabajoEmpresaList, buscarLogo } = useContratista();

    const [contratistaForm, setContratistaForm] = useState<Contratista>(contratistaInitial);
    const [formErrors, setFormErrors] = useState<FormContratistaErrors>({
        razonSocial: '',
        direccion: '',
        email: '',
        telefono: '',
        idTipoDocumento: '',
        nroIdentificacion: '',
    });
    const [tiposDocumentos, setTiposDocumentos] = useState<any>({});
    const [edit, setEdit] = useState<boolean>(false);
    const [view, setView] = useState<boolean>(false);
    const [trabajosEmpresas, setTrabajosEmpresas] = useState<TrabajoEmpresa[]>(trabajoEmpresaList);
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);
    const [preview, setPreview] = useState<string | null>(null);
    const [estados] = useState<any>(
        [

            {
                'id': true,
                'valor': 'ACTIVO'
            },
            {
                'id': false,
                'valor': 'INACTIVO'
            }
        ]);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        cargarCombo('tiposDocPersona', setTiposDocumentos)

        if (id) {
            buscarContratista(id);
            buscarTrabajosEmpresas(id)
            setEdit(true);
            const TipoAction = searchParams.get("action");
            if (TipoAction === 'view') {
                setView(true);
            }
            buscarLogo(parseInt(id))
                .then(logo => {
                    if (logo) {
                        const objectUrl = URL.createObjectURL(logo); // create here
                        setPreview(objectUrl);
                    } else {
                        setPreview(null);
                    }
                })

        } else {
            handleContratistaSelected(contratistaInitial);
            handleTrabajoEmpresaList([]);
        }

    }, [])

    useEffect(() => {
        setContratistaForm(contratistaSelected);

    }, [contratistaSelected])

    useEffect(() => {
        setTrabajosEmpresas(trabajoEmpresaList)
    }, [trabajoEmpresaList])



    const onChange = (e: any) => {
        const { name, value } = e.target;
        setContratistaForm({
            ...contratistaForm,
            [name]: value
        })
        const errors = validateForm({
            ...contratistaForm,
            [name]: value
        })
        setFormErrors(errors)
    }

    const validateForm = (contratista: Contratista, isGuardar: boolean = false) => {
        const errors: FormContratistaErrors = {};
        if (!contratista.razonSocial) {
            errors.razonSocial = "El campo Razón Social es obligatorio"
        }
        if (!contratista.nroIdentificacion) {
            errors.nroIdentificacion = "El campo Nro Identificación es obligatorio"
        }
        if (!contratista.idTipoDocumento) {
            errors.idTipoDocumento = "El campo Tipo de Identificación es obligatorio"
        }
        if (!contratista.direccion) {
            errors.direccion = "El campo Dirección es obligatorio"
        }
        if (!contratista.email) {
            errors.email = "El campo Mail es obligatorio"
        } else if (!isValidEmail(contratista.email)) {
            errors.email = "Debe ingresar un email válido"
        }
        if (isGuardar) {
            if (trabajosEmpresas.length === 0) {
                toast.error('Se debe ingresar al menos un Tipo de Trabajo para una Empresa Gestora')
            }
        }

        setFormErrors(errors);
        return errors;
    }

    const handleGuardar = () => {
        const errores = validateForm({
            ...contratistaForm,
        }, true);
        if (Object.keys(errores).length === 0 && trabajosEmpresas.length !== 0) {
            let logo: File | null = contratistaForm.logoNuevo;
            guardarContratista({
                ...contratistaForm,
                trabajosEmpresas: trabajosEmpresas,
                logo: null
            })
                .then(response => {
                    if (response.data) {
                        if (logo) {
                            uploadFile('contratistas/subirLogo',
                                logo, { idContratista: response.data.id, sizeMaximo: 2 }, true)
                                .then((result: any) => {
                                    if (result) {
                                        toast.success("Se guardó el Contratista correctamente")
                                        navigate('/contratistas');
                                    }

                                }).catch((error: any) => {
                                    if (error.response.status === 413) {
                                        toast.error(error.response.data.message)
                                    }
                                });
                        } else {
                            toast.success("Se guardó el Contratista correctamente")
                            navigate('/contratistas');

                        }
                    }
                }
                ).catch(err => toast.error(err.response.data.message))
        }
    }
    const handleCancelar = (e: any) => {
        e.preventDefault();
        setShowModal(true)
    }
    const handleVolver = () => {
        navigate('/contratistas')
    }
    const cancelar = () => {
        toggle();
        navigate('/contratistas')
    }
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Simula hacer clic en el input file
        }
    };
    const handleFileChange = () => {
        if (fileInputRef.current?.files && fileInputRef.current.files[0]) {
            const file = fileInputRef.current.files[0];
            setPreview(URL.createObjectURL(file));
            setContratistaForm({
                ...contratistaForm,
                logoNuevo: file
            })
        }
    };

    //mover carpeta common
    function isValidEmail(email: string) {
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        return isValidEmail.test(email);
    }

    return (
        <>
            <ToastContainer />

            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title={edit ? (view ? 'Vista' : 'Editar') : 'Nuevo'}
                            titlePage={view ? 'Vista Contratista' : 'Contratista'}
                            nameFirst={'Configuración'}
                            urlFirst={'/'}
                            nameSecond={'Contratista'}
                            urlSecond={'/contratistas'}
                        />
                        <Confirm isOpen={showModal} toggle={toggle} title='Cancelar' yesFn={cancelar}>
                            <div className="form-horizontal">
                                <div className="row">
                                    {<p>Desea salir sin guardar el Contratista?</p>}
                                </div>
                            </div>
                        </Confirm>
                        <Accordion id={'contratista'} title="Contratista">
                            <div className="row mt-4">
                                <FormInput
                                    id={'razonSocial'}
                                    label={'Razón Social'}
                                    name={'razonSocial'}
                                    // placeholder={'Razón Social'}
                                    value={contratistaForm.razonSocial || ''}
                                    isDisabled={edit && view}
                                    onChange={onChange}
                                    errorText={formErrors.razonSocial}
                                    maxLength={255}
                                />
                                <FormSelectBox
                                    title={'Estado'}
                                    label={'estado'}
                                    id={'activo'}
                                    name={'activo'}
                                    onChange={onChange}
                                    optionId={'id'}
                                    optionLabel={'valor'}
                                    value={contratistaForm.activo || ''}
                                    options={estados}
                                    isDisabled={edit && view}
                                />
                            </div>
                            <div className="row mt-2">
                                <FormSelectBox
                                    title={'Tipo de Identificación'}
                                    label={'Tipo de Identificación'}
                                    id={'tipoDocumento'}
                                    name={'idTipoDocumento'}
                                    onChange={onChange}
                                    optionId={'id'}
                                    optionLabel={'tipoDocumento'}
                                    value={contratistaForm.idTipoDocumento || ''}
                                    options={tiposDocumentos}
                                    errorText={formErrors.idTipoDocumento}
                                    isDisabled={edit && view}

                                />
                                <FormInput
                                    id={'nroIdentificacion'}
                                    label={'Nro de Identificación'}
                                    name={'nroIdentificacion'}
                                    // placeholder={'Nro de Identificación'}
                                    onChange={onChange}
                                    value={contratistaForm.nroIdentificacion}
                                    errorText={formErrors.nroIdentificacion}
                                    isDisabled={edit && view}
                                    type='number'
                                />

                            </div>
                            <div className="row mt-2">
                                <FormInput
                                    id={'direccion'}
                                    label={'Dirección'}
                                    name={'direccion'}
                                    // placeholder={'Razón Social'}
                                    value={contratistaForm.direccion || ''}
                                    isDisabled={edit && view}
                                    onChange={onChange}
                                    classColumn={'col-10'}
                                    maxLength={140}
                                    errorText={formErrors.direccion}
                                />
                            </div>
                            <div className="row mt-2">
                                <FormInput
                                    id={'mail'}
                                    label={'Mail'}
                                    name={'email'}
                                    value={contratistaForm.email || ''}
                                    isDisabled={edit && view}
                                    onChange={onChange}
                                    errorText={formErrors.email}
                                    type='email'
                                    maxLength={64}
                                />
                                <FormInput
                                    id={'telefono'}
                                    label={'Teléfono'}
                                    name={'telefono'}
                                    value={contratistaForm.telefono || ''}
                                    isDisabled={edit && view}
                                    onChange={onChange}
                                    errorText={formErrors.telefono}
                                    type='number'
                                    maxLength={24}
                                />
                            </div>
                            <div className="row mt-2">
                                <label className="col-form-label col-2 text-end">Logo:</label>
                                <div className="col-2 col-form-label">
                                    {!view &&
                                        <a href="#" onClick={() => handleButtonClick()}>
                                            <i className="fa fa-image mr-1" aria-hidden="true"></i>
                                            Agregar Logo
                                        </a>
                                    }
                                    <input
                                        id="file"
                                        type="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                        ref={fileInputRef}
                                        disabled={edit && view}
                                    />
                                    {/* {fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files?.length !== 0 &&
                                        <img src={URL.createObjectURL(fileInputRef.current.files[0])} style={{ width: '120px', height: '120px', padding: '10px' }} />} */}
                                    {preview &&
                                        <img src={preview} style={{ width: '120px', height: '120px', padding: '10px' }} />
                                    }

                                </div>

                            </div>
                            <div className="row mt-2">
                                <br />


                            </div>
                            <SeccionTrabajoEmpresa edit={edit}
                                view={view} trabajosEmpresas={trabajosEmpresas} setTrabajosEmpresas={setTrabajosEmpresas} />

                        </Accordion>

                        <div className="text-center">
                            {!view &&
                                <>
                                    <button className="btn btn-secondary mx-2" onClick={handleCancelar}>Cancelar</button>
                                    <button className="btn btn-success mx-2" onClick={handleGuardar}>Guardar</button>
                                </>
                            }
                            {view &&
                                <button className="btn btn-secondary mx-2" onClick={handleVolver}>Volver</button>
                            }
                        </div>

                    </div>
                </div>

            </div>

        </>
    )

}