import { ColumnDefinitionType } from "..";

type TableHeaderProps<T, K extends keyof T> = {
    columns: Array<ColumnDefinitionType<T, K>>;
}

const TableHeader = <T, K extends keyof T>({ columns }: TableHeaderProps<T, K>): JSX.Element => {
     const styleStatusDocument = {
        width: 100
    };
    const headers = columns.map((column, index) => {
        const style = {
            width: column.width ?? 100, // 100 is our default value if width is not defined
            borderBottom: '2px solid black'
        };
        if (column.key === "statusDocumento"){
            return (<th key="outCell"
            style={styleStatusDocument}>
                {column.isEnabledCheckStatusDocument && 'Documento Ok'}
            </th>)
        }

        return (
            <th
                key={`headCell-${index}`}
                style={style}
            >
                {column.header}
            </th>
        );
    });
   

    return (
        <thead>
            <tr>{headers}</tr>
        </thead>
    );
};

export default TableHeader;