import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EquipamientoSolicitud } from '../../types/EquipamientoSolicitud';
import { DocEquipamientoSolicitud } from '../../types/DocEquipamientoSolicitud';

interface EquipoAndMaquinariaState {
    equipamientoSolicitudes: EquipamientoSolicitud[];
    equipamientoSolicitudSelected: EquipamientoSolicitud;
    documentEquipSolicitudes: DocEquipamientoSolicitud[];
    loading: boolean;
    isEquipoAfectado: boolean;
}
export const equipamientoSolicitudInitial: EquipamientoSolicitud = {
    id: 0,
    idSolicitud: 0,
    nombre: '',
    fechaIngreso: '',
    fechaEgreso: '',
    subido: false,
    idEquipamiento: 0,
    statusDocumento: '',
    vigente: true
}

const initialState: EquipoAndMaquinariaState = {
    equipamientoSolicitudes: [],
    equipamientoSolicitudSelected: equipamientoSolicitudInitial,
    documentEquipSolicitudes: [],
    loading: false,
    isEquipoAfectado: false
};

export const equipoAndMaquinariaSlice = createSlice({
    name: 'equipoAndMaquinaria',
    initialState,
    reducers: {
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadData: (state, data: PayloadAction<EquipamientoSolicitud>): void => {
            state.equipamientoSolicitudSelected = data.payload;
            state.loading = false;
        },
        loadList: (state, data: PayloadAction<EquipamientoSolicitud[]>): void => {
            state.equipamientoSolicitudes = data.payload;
            state.loading = false;
        },
        documentList: (state, data: PayloadAction<DocEquipamientoSolicitud[]>): void => {
            state.documentEquipSolicitudes = data.payload;
            state.loading = false;
        },
        setEquipoAfectado: (state, data: PayloadAction<boolean>): void => {
            state.isEquipoAfectado = data.payload;
        },
    },
})

export const { isLoading, loadData, loadList, documentList, setEquipoAfectado } = equipoAndMaquinariaSlice.actions;
export default equipoAndMaquinariaSlice.reducer;
