import { useState } from "react";
import { useUsuarios } from "../../../hooks/useUsuarios";


export const RecuperarClave = () => {

    const { recuperaClave, error, errors } = useUsuarios();
    const [filterEmail, setFilterEmail] = useState('');

    const handleChange = (e: any) => {
        e.preventDefault();
        setFilterEmail(e.target.value);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        recuperaClave(filterEmail)

    }

    return (
        <>
            <div className="mainmenu-area">
                <div className="container text-left">

                    <img id="imageLogoApp" alt="LogoContratistas"
                        // src={require('../../../resources/images/poncho-instalaciones.png')}
                        src={
                            process.env.PUBLIC_URL + '/poncho-instalaciones.png'
                        }
                        className="logo-img" />

                </div>
            </div>

            <section className="login-area overlay center-block" id="login-page">
                <div className="container" style={{ position: 'relative' }}>
                    <div className="row">
                        <div className="col-md-10 col-md-offset-2 pt-5">
                            <form onSubmit={handleSubmit} className="login-box" >
                                <div className="row" style={{ textAlign: "center", marginBottom: "25px" }}>
                                    <label style={{ fontSize: "large" }}>Recuperar Clave</label>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-sm-offset-3 text-center">
                                        <div className="form-group">

                                            <label htmlFor="email">Email</label>
                                            <input id="email" name="email" placeholder="Email" type="email" className="form-control" required onChange={handleChange} value={filterEmail} autoFocus />

                                        </div>
                                    </div>
                                </div>
                                {error ?

                                    errors?.map((e: any, index: number) => (
                                        <div key={index}>
                                            <label style={{ color: "orangered", fontSize: "14px" }}>{e}</label>

                                        </div>
                                    ))

                                    : ''
                                }
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <br />

                                        <div className="form-group text-center">
                                            <input type="submit" className="btn btn-primary" value="Enviar" />
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div >
            </section >

            <footer className="footer-area relative" id="contact-page">

                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 text-right-log pt-4">
                                <img
                                    src={require('../../../resources/images/logo-poncho.png')}
                                    alt="" style={{ width: "215px" }} />
                            </div>
                            <div className="col-sm-6">
                                <p className="text-footer" style={{ color: "#51565" }}>Plataforma <strong>COMPLIANCE LEGAL y CORPORATIVO</strong> en materia de <strong>SEGURIDAD INDUSTRIAL, SEGURIDAD OPERATIVA y MEDIO AMBIENTE</strong>, 100% configurable.</p>
                                <p style={{ margin: "0px", padding: "0px" }}><a href="https://poncho.grupomost.com/" style={{ color: "#007cb3", fontSize: "12px" }} target="_blank" rel="noreferrer">poncho.grupomost.com</a></p>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 text-center">
                                <p style={{ fontSize: "12px" }}>
                                    &copy; Copyright 2020 GrupoMost
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>





    );

}