import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import FormTextArea from "../Form/FormTextArea";
import { APROBADA_STATUS_ID, OBSERVADA_STATUS_ID, RECHAZADA_STATUS_ID, REVISION_STATUS_ID } from "../../constants/constants";
import { useSolicitud } from "../../hooks/useSolicitud";
import { toast } from "react-toastify";
import { useObservacion } from "../../hooks/useObservacion";
import { ObservacionSolicitud } from "../../types/ObservacionSolicitud";
import moment from "moment";
import Confirm from "../Confirm";
import { useNavigate } from "react-router-dom";

interface FormData {
    id: number | null;
    idTipoEstadoTemporal: string;
    motivo: string;
    idSolicitud: number;
    fechaObservacion: string;
}

type CalificarProps = {
    isEdit?: boolean;
    motivo?: string;
    todosDocsAprobados?: boolean;
    cancelButtonFn?: () => void;
    returnButtonFn?: () => void;
    setDisabledActionEnviar: (disabled: boolean) => void;
    enviarSolicitud: () => void;

};

const CalificarComponent: React.FC<CalificarProps> = ({ isEdit = true, motivo = '', todosDocsAprobados = false,
    cancelButtonFn = () => true,
    returnButtonFn = () => true,
    setDisabledActionEnviar,
    enviarSolicitud
}
) => {
    const { solicitudSelected, validarDocumentosAprobados } = useSolicitud();
    const { observacionSelected, saveObservacion } = useObservacion();
    const [motivoForm, setMotivoForm] = useState<FormData>({
        idTipoEstadoTemporal: '',
        motivo: '',
        idSolicitud: solicitudSelected.id,
        fechaObservacion: '',
        id: 0,
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);
    const [showModalMotivo, setShowModalMotivo] = useState<boolean>(false);
    const toggleMotivo = () => setShowModalMotivo(!showModalMotivo);
    const navigate = useNavigate();
    const onChangeMotivo = (name: string, value: string | null) => {
        setMotivoForm({
            ...motivoForm,
            [name]: value
        })
    }
    useEffect(() => {
        if (observacionSelected) {

            setMotivoForm({
                id: observacionSelected.id,
                idTipoEstadoTemporal: observacionSelected.idTipoEstadoTemporal !== null && observacionSelected.idTipoEstadoTemporal !== 0 ? '' + observacionSelected.idTipoEstadoTemporal : (todosDocsAprobados ? '' + APROBADA_STATUS_ID : '' + OBSERVADA_STATUS_ID), // ,
                motivo: observacionSelected.observacion,
                idSolicitud: solicitudSelected.id,
                fechaObservacion: observacionSelected.fechaObservacion,
            })

        }
    }, [observacionSelected])

    useEffect(() => {
        setDisabledActionEnviar(true);
    }, [])

    const validateMotivoForm = (): boolean => {
        if (motivoForm.idTipoEstadoTemporal === null || motivoForm.idTipoEstadoTemporal === '') {
            toast.error("Por favor, seleccionar un Estado de la Solicitud.");
            return false;
        }


        if (parseInt(motivoForm.idTipoEstadoTemporal) === OBSERVADA_STATUS_ID || parseInt(motivoForm.idTipoEstadoTemporal) === RECHAZADA_STATUS_ID || parseInt(motivoForm.idTipoEstadoTemporal) === REVISION_STATUS_ID) {
            if (motivoForm.motivo === null || motivoForm.motivo!.trim() === '') {
                toast.error("El campo motivo no puede estar vacío");
                return false;
            }
        }

        return true;
    }
    const validar = () => {
        if (!validateMotivoForm()) {
            return;
        }
        if (motivoForm.motivo === observacionSelected.observacion && observacionSelected.idTipoEstadoTemporal === null) {
            toggleMotivo();
        } else {
            toggle();
        }

    }
    const saveCalificar = (enviar: boolean = false) => {

        let observacion: ObservacionSolicitud = {
            id: null,
            idSolicitud: motivoForm.idSolicitud,
            observacion: motivoForm.motivo,
            idTipoEstadoTemporal: parseInt(motivoForm.idTipoEstadoTemporal),
            fechaObservacion: moment().format('YYYY-MM-DD'),
            usuario: ''
        }
        saveObservacion(observacion)
            .then(respuesta => {

                if (respuesta) {
                    toast.success("Solicitud calificada correctamente");
                    setDisabledActionEnviar(false)
                    returnButtonFn();

                    if (enviar) {

                        setTimeout(() => {
                            enviarSolicitud();
                        }, 500);
                    }
                }
            })
            .catch(error => {
                toast.error('Error al calificar la solicitud');
            });
    }
    const aceptar = () => {

        saveCalificar(true);
        toggle();
    }

    const cancelar = () => {
        saveCalificar();
        toggle();
        setDisabledActionEnviar(false);
    }
    const aceptarMotivo = () => {
        toggle();
        toggleMotivo();

    }

    const cancelarMotivo = () => {
        toggleMotivo();
    }
    return (

        <div>
            <Confirm isOpen={showModalMotivo} toggle={cancelarMotivo} title='Motivo' yesFn={aceptarMotivo}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Desea mantener el mismo motivo de la observación anterior?</p>}
                    </div>
                </div>
            </Confirm>
            <Confirm isOpen={showModal} toggle={cancelar} title='Enviar Solicitud' yesFn={aceptar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Desea enviar la Solicitud al siguiente estado?</p>}
                    </div>
                </div>
            </Confirm>
            <div className="row" >
                <div className="col-md-12" style={{ marginLeft: "18px", marginTop: "2px" }}>
                    <div className="form-check" >
                        <input type="radio" name="idTipoEstadoTemporal" value={APROBADA_STATUS_ID} id="aprobada" className="form-check-input"
                            onChange={(e) => onChangeMotivo(e.target.name, e.target.value)} checked={parseInt(motivoForm.idTipoEstadoTemporal) === APROBADA_STATUS_ID} />
                        <label htmlFor="aprobada" className="form-check-label" style={{ fontSize: "13px", fontWeight: "500" }}>Aprobada</label>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginLeft: "18px", marginTop: "-12px" }}>
                    <div className="form-check" >
                        <input type="radio" name="idTipoEstadoTemporal" value={OBSERVADA_STATUS_ID} id="observada" className="form-check-input"
                            onChange={(e) => onChangeMotivo(e.target.name, e.target.value)} checked={parseInt(motivoForm.idTipoEstadoTemporal) === OBSERVADA_STATUS_ID} />
                        <label htmlFor="regular" className="form-check-label" style={{ fontSize: "13px", fontWeight: "500" }}>Observada</label>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginLeft: "18px", marginTop: "-12px" }}>
                    <div className="form-check" >
                        <input type="radio" name="idTipoEstadoTemporal" value={RECHAZADA_STATUS_ID} id="rechazada" className="form-check-input"
                            onChange={(e) => onChangeMotivo(e.target.name, e.target.value)} checked={parseInt(motivoForm.idTipoEstadoTemporal) === RECHAZADA_STATUS_ID} />
                        <label htmlFor="regular" className="form-check-label" style={{ fontSize: "13px", fontWeight: "500" }}>Rechazada</label>
                    </div>
                </div>
            </div>

            <div className="row mt-14 justify-content-center">
                <FormTextArea
                    id={'motivo'}
                    title={'Agregar Motivo'}
                    name={'motivo'}
                    value={motivoForm.motivo || ''}
                    isDisabled={!isEdit}
                    onChange={(e: any) => onChangeMotivo(e.target.name, e.target.value)}
                    classDivTextArea={'col-14'}
                    classLabel={'col-14'}
                ></FormTextArea>
            </div>
            <br />

            <div className="col-md-12 d-flex justify-content-center">
                {isEdit ? (<div> <Button type="button" color="secondary" onClick={() => validar()} className="mr-2">
                    Guardar
                </Button>
                    <Button type="button" color="secondary" onClick={() => cancelButtonFn()} className="mr-2">
                        Salir
                    </Button></div>) :
                    <Button type="button" color="secondary" onClick={() => returnButtonFn()} className="mr-2">
                        Volver
                    </Button>}
            </div>
        </div>

    );
};

export default CalificarComponent;