import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Permiso } from "../../types/Permiso";


interface permisoState {
    permisoList: Permiso[];
    permisoSelected: Permiso;
    loading: boolean;
    errors: any;
    error: boolean;
    guardado: boolean;
    alta: boolean;
}

export const permisoInitial: Permiso = {
    id: 0,
    descripcion: '',
    acronimo: '',
    estado: false,
    menuNombre: '',
    url: '',
    visible: false
}

const initialState: permisoState = {
    permisoList: [],
    permisoSelected: permisoInitial,
    loading: false,
    errors: [],
    error: false,
    guardado: false,
    alta: false
}

const PermisoSlice = createSlice({
    name: 'permiso',
    initialState,
    reducers: {
        addPermiso: (state, data: PayloadAction<Permiso>): void => {
            state.permisoSelected = permisoInitial;
            state.error = false;
            state.guardado = true;
        },
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataPermiso: (state, data: PayloadAction<Permiso>): void => {
            state.permisoSelected = data.payload;
            state.error = false;
        },
        loadListPermiso: (state, data: PayloadAction<Permiso[]>): void => {
            state.permisoList = data.payload;
        },
        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        removePermiso: (state, data: PayloadAction<number>): void => {
            state.permisoList = state.permisoList.filter(p => p.id !== data.payload)
        },
        setGuardado: (state, data: PayloadAction<boolean>): void => {
            state.guardado = data.payload;
        },
        isAlta: (state, data: PayloadAction<boolean>): void => {
            state.alta = data.payload;
        },
    },
});

export const {
    isLoading,
    loadDataPermiso,
    loadListPermiso,
    addPermiso,
    loadingError,
    removePermiso,
    visibleError,
    setGuardado,
    isAlta
} = PermisoSlice.actions;

export default PermisoSlice.reducer;