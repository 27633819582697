/* eslint-disable jsx-a11y/anchor-is-valid */
import { usePersonalAfectado } from '../../../../../hooks/usePersonalAfectado';
import Table, { ColumnDefinitionType } from '../../../../../components/Table';
import { PersonalPropio } from '../../../../../types/PersonalPropio';
import moment from 'moment'
import { Link } from 'react-router-dom';
import DetailPersonalPropio from '../DetailPersonalPropio';
import MyModal from '../../../../../components/Modal';
import { useEffect, useState } from 'react';
import { useDocumentoPersAfectado } from '../../../../../hooks/useDocumentoPersAfectado';
import EditPersonalPropio from '../DetailPersonalPropio/EditPersonalPropio';
import { useSolicitud } from '../../../../../hooks/useSolicitud';
import { uploadFile, uploadFiles } from '../../../../../services/Service';
import { APROBADA_STATUS_ID, EN_ACTUALIZACION_STATUS_ID, OBSERVADA_STATUS_ID, REVISION_STATUS_ID } from '../../../../../constants/constants';
import { useUserLogin } from '../../../../../hooks/useUserLogin';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

interface TablePersonalPropioProps {
    tiposDocumentosPers: any;
    onClose: () => void;
    onModalAction: (isOpen: boolean) => void;
    isEditing: boolean;
    modalOpen: boolean;
    onIsEditing: (updateEditable: boolean) => void;
}


const TablePersonalPropio: React.FC<TablePersonalPropioProps> = ({ onClose, isEditing, onIsEditing, tiposDocumentosPers, modalOpen, onModalAction }) => {
    const [actividades, setActividades] = useState<any>([]);
    const { personalPropioList, handlePersonalPropioSelected, personalPropioSelected, saveOrUpdatePersonalAfectado, eliminarPersonalPropioById } = usePersonalAfectado();
    const { buscarDocPersByIdPersPropio, setEmptyListAllDocumentoPersonalAfectado, documentoPersonalPropioList, deleteDocPersById } = useDocumentoPersAfectado();
    const { cargarCombo, solicitudSelected, buscarSolicitud } = useSolicitud();
    const [isConfirmDeletePersonalProp, setIsConfirmDeletePersonalProp] = useState(false);
    const [personIdSelect, setPersonIdSelect] = useState<number>();
    const [isVisualizar, setIsVisualizar] = useState(false);
    const { isContratistaTipoUsuario, isEmpresaGestoraTipoUsuario } = useUserLogin()

    useEffect(() => {
        cargarCombo(`actividad/activasPorContratista/${solicitudSelected.idContratistaTrabajoEmpresa}`, setActividades);
    }, []);

    const columns: ColumnDefinitionType<PersonalPropio, keyof PersonalPropio>[] = []
    columns.push({
        key: 'apellido',
        header: 'Apellido',
    });
    columns.push({
        key: 'nombre',
        header: 'Nombre',
    });
    columns.push({
        key: 'tipoDocumento',
        header: 'Tipo Doc Persona',
    });
    columns.push({
        key: 'nroDocumento',
        header: 'Nro Documento',
    });
    columns.push({
        key: 'actividad',
        header: 'Actividad',
    });
    columns.push({
        key: 'fechaIngreso',
        header: 'Fecha Ingreso',
        cell: (data: PersonalPropio) => <>{data.fechaIngreso ? moment(data.fechaIngreso).format('DD/MM/YYYY') : ''}</>
    });
    columns.push({
        key: 'observaciones',
        header: 'Observaciones',
    });
    columns.push({
        key: 'id',
        header: 'Acciones',
        cell: (data: PersonalPropio) =>
            <>
                <Link title='Visualizar' to="/exportar" onClick={(e) => {
                    buscarDocPersByIdPersPropio(data.id)
                    handlePersonalPropioSelected(data)
                    setIsVisualizar(true);
                    onModalAction(true);
                    e.preventDefault();
                }} target="_blank" >
                    <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                </Link>
                {isEditing && <Link title='Editar' to="" onClick={(e) => {
                    buscarDocPersByIdPersPropio(data.id)
                    handlePersonalPropioSelected(data)
                    onIsEditing(true);
                    onModalAction(true);
                    e.preventDefault();
                }} target="_blank" >
                    <i className="fa fa-pencil text-secondary me-2" aria-hidden="true"></i>
                </Link>}
                {isEditing && solicitudSelected.idTipoEstado !== REVISION_STATUS_ID && solicitudSelected.idTipoEstado !== APROBADA_STATUS_ID && solicitudSelected.idTipoEstado !== EN_ACTUALIZACION_STATUS_ID && <Link title='Eliminar' to="" onClick={(e) => {
                    setPersonIdSelect(data.id)
                    setIsConfirmDeletePersonalProp(true)
                    e.preventDefault();
                }} target="_blank" >
                    <i className="fa fa-trash text-secondary me-2" aria-hidden="true"></i>
                </Link>}
            </>
    });
    if (isContratistaTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID) {
        columns.push({
            key: 'statusDocumento',
            header: '',
            // isEnabledCheckStatusDocument: isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID,
        });
    }


    const handlePersonalPropioSave = (data: any, currentSave: HTMLButtonElement | null) => {
        let dataForm = {
            actividad: data.actividad,
            apellido: data.apellido,
            fechaIngreso: data.fechaIngreso,
            nombre: data.nombre,
            idTipoDocumento: data.tipoDocumento,
            tipoDocumento: data.tipoDocumento,
            observaciones: data.observaciones,
            nroDocumento: data.nroDocumento,
            vigente: data.vigente,
            idActividad: data.actividad,
            idSolicitud: solicitudSelected.id,
            id: data.id,
            statusDocumento: ''
        }
        saveOrUpdatePersonalAfectado('personal-propio', dataForm)
            .then(result => {
                // Maneja el resultado si es necesario
                console.log('Operación completada:', result);
                if (result) {
                    buscarSolicitud(dataForm.idSolicitud.toString());
                    findDeletedDocuments(data.documentosPersPropio).forEach(documentDelete => deleteDocPersById('documento-personal', documentDelete.id));
                    let documentUpload = data.documentosPersPropio.filter((documentPersPropio: any) => documentPersPropio.archivoSeleccionado)
                    if (!documentUpload || documentUpload.length < 1) {
                        onClose()
                        return;
                    }
                    let idPersonalPropio = result.data.id;
                    let fileDataArray = documentUpload.map((documentPersPropio: any) => {
                        const uniqueId: string = uuidv4();
                        return {
                            file: documentPersPropio.archivoSeleccionado, parameters: {
                                idTipoDocumento: documentPersPropio.idTipoDocumento, idDocumento: documentPersPropio.id,
                                fileId: uniqueId, sizeMaximo: documentPersPropio.sizeMaximo
                            }
                        }
                    })
                    uploadFiles(`documento-personal/subirArchivoPersonal/${idPersonalPropio}`, fileDataArray)
                        .then(result => {
                            if (result) {
                                onClose()
                            }
                            currentSave!.disabled = false
                        }).catch((error) => {
                            currentSave!.disabled = false
                            if (error.response.status === 413) {
                                toast.error(error.response.data.message)
                            } else {
                                toast.error('No se pudo adjuntar el archivo seleccionado')
                            }
                        });
                }

                //uploadFiles(`${result}`, [])
            })
            .catch(error => {
                // Maneja los errores si ocurren
                console.error('Error:', error);
            });
    }
    const findDeletedDocuments = (newList: any[]): any[] => {
        const deletedDocuments: any[] = [];

        for (const existingDocument of documentoPersonalPropioList) {
            const existsInNewList = newList.some((newDocument) => newDocument.idTipoDocumento === existingDocument.idTipoDocumento &&
                newDocument.id === existingDocument.id);

            if (!existsInNewList) {
                deletedDocuments.push(existingDocument);
            }
        }

        return deletedDocuments;
    }

    const handleConfirmDeletePerson = () => {
        eliminarPersonalPropioById(`${personIdSelect}`).finally(() => setIsConfirmDeletePersonalProp(false))
    }

    return (
        <>
            <MyModal isOpen={isConfirmDeletePersonalProp} toggle={() => setIsConfirmDeletePersonalProp(false)} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} secondButtonFn={handleConfirmDeletePerson} >
                <label>Desea confirmar eliminar el Personal?</label>
            </MyModal>
            <MyModal size='lg' isOpen={modalOpen} toggle={() => { onClose(); setEmptyListAllDocumentoPersonalAfectado(); setIsVisualizar(false); }} cancelButton={false} secondButton={false}
                title={(isVisualizar ? 'Vista' : personalPropioSelected.id === 0 ? 'Agregar' : 'Editar') + ' Personal Propio'} >
                {isVisualizar ? <DetailPersonalPropio onClose={() => { onModalAction(false); setEmptyListAllDocumentoPersonalAfectado(); setIsVisualizar(false); }} /> : <EditPersonalPropio tiposDocumentosPers={tiposDocumentosPers}
                    isEditing={isEditing} actividades={actividades} onClose={() => { onClose(); setEmptyListAllDocumentoPersonalAfectado() }}
                    onSave={(data, currentSave) => { handlePersonalPropioSave(data, currentSave); setEmptyListAllDocumentoPersonalAfectado() }} />}
            </MyModal>
            <Table data={personalPropioList} columns={columns} ></Table>
        </>
    )
}

export default TablePersonalPropio