import {
  BrowserRouter
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import './resources/css/metisMenu.min.css';
import './resources/css/icons.css';
import './resources/css/style.css';
import './resources/css/custom-ypf.css';
import './resources/css/custom.css';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from './components/Loading';
import { RootState } from './app/store';
import { AppRutas } from './router/appRoutes';
import { UserRutas } from './router/userRoutes';
import { setAuth } from './context/Auth/UserLoginReducer';


function App() {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useMemo(() => {
    //Implementacion para mantener la session al abrir otros tabs
    if (localStorage.getItem("token") !== null) {
      dispatch(setAuth(true))
    }
    if (!localStorage.length) {
      if (localStorage.getItem("token") !== null) {
        dispatch(setAuth(true))
      }
      //Consultar otros tabs por session storage
      localStorage.setItem('getSessionStorage', Date.now().toLocaleString());
    };
    window.addEventListener('storage', function (event) {

      if (event.key === 'getSessionStorage') {
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        localStorage.removeItem('sessionStorage');
      } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        var data = JSON.parse(event.newValue || '{}');
        for (const key in data) {
          localStorage.setItem(key, data[key]);
        }
        if (localStorage.getItem("token") !== null) {
          dispatch(setAuth(true))
        }
      }
    });
  }, [])

  useEffect(() => {

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, [])
  return (
    <BrowserRouter>
      {
        isAuth
          ?
          <UserRutas />
          :
          <AppRutas />
      }
    </BrowserRouter>
  );
}

export default App;
