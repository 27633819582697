import { useEffect, useState } from "react";
import { useSolicitud } from "../../../hooks/useSolicitud";
import FormSelectBox from "../../../components/Form/FormSelectBox";
import Confirm from "../../../components/Confirm";
import { Solicitud } from "../../../classes/Solicitud";
import { useAuth } from "../../../hooks/useAuth";
import { Usuario } from "../../../types/Usuario";
import { Breadcrumbs } from "../../Home/Breadcrumb";


export const CrearSolicitud = () => {

    const { getUsuarioLogueado } = useAuth()
    const [empresasGestoras, setEmpresasGestoras] = useState<any>({});
    const [tiposTrabajos, setTiposTrabajos] = useState<any>([]);
    const [idEmpresaGestora, setIdEmpresaGestora] = useState<string>('');
    const [idContratista, setIdContratista] = useState<string>('')
    const [contratistas, setContratistas] = useState<any>({});
    const [setTiposDocumentos] = useState<any>({});
    const [showModal, setShowModal] = useState<boolean>(false);
    const [tipoTrabajoSelected, setTipoTrabajoSelected] = useState<any>({});
    const [usuarioLogin] = useState<Usuario>(getUsuarioLogueado());

    const { cargarCombo, cargarTipoTrabajo, handleInitSolicitud, cargarEmpresasGestoras, cargarContratistas } = useSolicitud();

    useEffect(() => {

        // cargarCombo('contratistas', setContratistas);
        cargarCombo('tiposDocumentos', setTiposDocumentos);
        cargarEmpresasGestoras('empresasGestoras', `${usuarioLogin.idContratista}`, setEmpresasGestoras);
        cargarContratistas('contratistas', `${usuarioLogin.idEmpresaGestora}`, setContratistas)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleChangeGestora = (e: any) => {
        e.preventDefault();
        const eg = e.target.value;
        setIdEmpresaGestora(eg);
        setIdContratista(`${usuarioLogin.idContratista}`)
        cargarTipoTrabajo('tiposTrabajos', eg, `${usuarioLogin.idContratista}`, setTiposTrabajos)

    }
    const handleChangeContratista = (e: any) => {
        e.preventDefault();
        const contratistaID = e.target.value;
        setIdContratista(contratistaID);
        setIdEmpresaGestora(`${usuarioLogin.idEmpresaGestora}`)
        cargarTipoTrabajo('tiposTrabajos', `${usuarioLogin.idEmpresaGestora}`, contratistaID, setTiposTrabajos)
    }
    const toggle = () => setShowModal(!showModal);

    const iniciarSolicitud = () => {
        const solicitud: Solicitud = {} as Solicitud;
        solicitud.idContratista = Number(idContratista);
        solicitud.idEmpresaGestora = Number(idEmpresaGestora);
        solicitud.idTipoTrabajo = tipoTrabajoSelected.id
        handleInitSolicitud(solicitud);
    };


    return (
        <>
            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title={'Alta de Solicitud'}
                            nameFirst={'Consulta de Solicitudes'}
                            urlFirst={'/consultaSolicitudes'}
                        />
                        <div className="form-horizontal">
                            <div className="row">
                                <Confirm isOpen={showModal} toggle={toggle} title='Confirmar' yesFn={iniciarSolicitud}>
                                    <div className="form-horizontal">
                                        <div className="row">
                                            {<p>Está seguro que desea iniciar la solicitud?</p>}
                                        </div>
                                    </div>
                                </Confirm>
                                <div className="card">
                                    <div className="card-body">

                                        <div className="row">
                                            <div className="d-flex justify-content-center">
                                                {usuarioLogin.tipoUsuarioValor === 'CONTRATISTA' ? (
                                                    <FormSelectBox
                                                        title={'Empresa Gestora'}
                                                        label={'empresa gestora'}
                                                        id={'empresaGestora'}
                                                        name={'idEmpresaGestora'}
                                                        onChange={handleChangeGestora}
                                                        optionId={'id'}
                                                        optionLabel={'nombreEmpresa'}
                                                        value={idEmpresaGestora}
                                                        options={empresasGestoras}
                                                        classLabel="col-form-label col-2 text-left"
                                                        classDivSelect="col-8"
                                                        styleLabelColor="black"
                                                    />
                                                ) : (
                                                    <FormSelectBox
                                                        title={'Contratista'}
                                                        label={'contratista'}
                                                        id={'contratista'}
                                                        name={'idContratista'}
                                                        onChange={handleChangeContratista}
                                                        optionId={'id'}
                                                        optionLabel={'razonSocial'}
                                                        value={idContratista}
                                                        options={contratistas}
                                                        classLabel="col-form-label col-2 text-left"
                                                        classDivSelect="col-8"
                                                        styleLabelColor="black"
                                                    />
                                                )}

                                            </div>
                                            <div className="row mt-3">
                                                <label className="col-form-label">Tipos de Trabajo</label>

                                                <div className="ms-2 p-3 card p-5">
                                                    <div className="row">
                                                        {tiposTrabajos?.map((tipoTrabajo: any) => {
                                                            return (<div className="col-3 text-center">
                                                                <a href="/tipoTrabajo" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowModal(true);
                                                                    setTipoTrabajoSelected(tipoTrabajo)

                                                                }}>
                                                                    <img src={`data:image/jpeg;base64,${tipoTrabajo.logo}`} alt={tipoTrabajo.id} width="100" height="100" /><br />
                                                                    <span className="fs-9">{tipoTrabajo.descripcion}</span>
                                                                </a>
                                                            </div>)

                                                        })}

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}