import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Rol } from "../../types/Rol";

interface rolState {
    rolList: Rol[];
    rolSelected: Rol;
    loading: boolean;
    permisos: any[];
    permisosSelected: any[];
    errors: any;
    error: boolean,
    guardado: boolean,
    alta: boolean;

}

export const rolInitial: Rol = {
    id: 0,
    descripcion: '',
    estado: false,
    orden: 0,
    permisosSelected: [],
    permisosNoAsignados: [],
    guardado: false,
}

const initialState: rolState = {
    rolList: [],
    permisos: [],
    permisosSelected: [],
    rolSelected: rolInitial,
    loading: false,
    errors: [],
    error: false,
    guardado: false,
    alta: false,
}

const RolSlice = createSlice({
    name: 'rol',
    initialState,
    reducers: {
        addRol: (state, data: PayloadAction<Rol>): void => {
            state.rolSelected = rolInitial;
            state.error = false;
            state.guardado = true;
        },
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataRol: (state, data: PayloadAction<Rol>): void => {
            state.rolSelected = data.payload;
            state.error = false;
        },
        loadListRol: (state, data: PayloadAction<Rol[]>): void => {
            state.rolList = data.payload;
        },
        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        removeRol: (state, data: PayloadAction<number>): void => {
            state.rolList = state.rolList.filter(p => p.id !== data.payload)
        },
        setPermisos: (state, data: PayloadAction<any[]>): void => {
            state.permisos = data.payload;
        },
        setPermisosSelected: (state, data: PayloadAction<any[]>): void => {
            state.permisosSelected = data.payload;
        },
        setGuardado: (state, data: PayloadAction<boolean>): void => {
            state.guardado = data.payload;
        },
        isAlta: (state, data: PayloadAction<boolean>): void => {
            state.alta = data.payload;
        },

    },
});

export const {
    isLoading,
    loadDataRol,
    loadListRol,
    addRol,
    loadingError,
    removeRol,
    visibleError,
    setPermisos,
    setPermisosSelected,
    setGuardado,
    isAlta
} = RolSlice.actions;

export default RolSlice.reducer;