import { Link } from "react-router-dom"


export const NotFound = () => {
  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div>
              <div className="centrado-vertical-error">
                <div className="col-md-12 text-center" style={{ paddingTop: "60px" }}>
                  <h1>404</h1>
                  <h2>Página no encontrada</h2>
                  <p>Lo sentimos, no se ha podido encontrar la página solicitada.</p>
                  <Link to="/" className="btn btn-primary text-white boton-nuevo">Ir al Inicio</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
