import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { Actividad } from "../types/Actividad";
import { findAll, get, remove, save, update } from "../services/Service";
import { loadListActividad, actividadInitial, addActividad, loadDataActividad, loadListDocumento, loadDataFiltroActividad } from "../context/Actividad/ActividadReducer";


export const useActividad = () => {
    const BASE_URL = 'actividad';
    const { loading, actividadSelected, errors, error, guardado, actividadList, documentoList, filtro } = useSelector(
        (state: RootState) => state.actividad
    );

    const dispatch = useDispatch();

    const buscarActividades = async (actividad: Actividad) => {
        let url = actividad.activo === null || actividad.activo === '0' ? `${BASE_URL}/findBy?nombre=${actividad.nombreActividad}` : `${BASE_URL}/findBy?nombre=${actividad.nombreActividad}&activo=${actividad.activo}`

        await findAll(url)
            .then((response) => {
                dispatch(loadListActividad(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const guardarActividad = async (actividad: Actividad) => {
        let response;
        try {
            if (actividad.id === 0) {
                response = await save(BASE_URL, actividad);
                dispatch(addActividad(response.data));
            } else {
                response = await update(BASE_URL, actividad);
                dispatch(addActividad(response.data));
            }
            // navigate('/tipoTrabajo');
            return response;
        } catch (error: any) {
            throw error;
        }
    }

    const buscarActividad = async (id: string) => {
        try {
            const response = await get(BASE_URL, id);
            dispatch(loadDataActividad(response.data));
            return response.data;
        } catch (error) {
            console.log(error);
        }

    }

    const eliminarActividad = async (id: string) => {
        try {
            const response = await remove(BASE_URL, id)
            return response;

        } catch (error) {
            console.log(error);
        }
    }


    const handleActividadSelected = (actividad: Actividad) => {
        dispatch(loadDataActividad(actividad))
    }

    const handleActividadList = (actividades: Actividad[]) => {
        dispatch(loadListActividad(actividades))
    }

    const handleFiltroActividad = (actividad: Actividad) => {
        dispatch(loadDataFiltroActividad(actividad))
    }
    return {
        buscarActividades,
        guardarActividad,
        handleActividadSelected,
        buscarActividad,
        eliminarActividad,
        handleActividadList,
        handleFiltroActividad,
        actividadInitial,
        actividadSelected,
        actividadList,
        documentoList,
        loading,
        errors,
        error,
        guardado,
        filtro
    }
}