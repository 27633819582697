import { useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../Home/Breadcrumb';
import { BuscarSolicitud } from '../Buscar';
import { TablaSolicitud } from '../Table';
import { useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSolicitud } from '../../../../hooks/useSolicitud';
// import { BuscarEstado } from "./Buscar"


export const Solicitudes = () => {
  const [searchParams] = useSearchParams();

  const { handleShowMessage, handleAnulada, message, anulada } = useSolicitud()
  useEffect(() => {

    if (message) {
      toast.success("Solicitud enviada correctamente");
      handleShowMessage(false);
    }
    if (anulada) {
      toast.success("Solicitud anulada correctamente");
      handleAnulada(false)
    }

  });
  return (
    <>
      <ToastContainer />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Breadcrumbs
              title={'Consulta de Solicitudes'}
            />
            <BuscarSolicitud />
            <br />
            <TablaSolicitud />
          </div>
        </div>
      </div>

    </>
  );
};
