import { Link } from "react-router-dom";
import Table, { ColumnDefinitionType } from '../../../../components/Table'
import { Loading } from "../../../../components/Loading";
import { useState } from "react";
import Confirm from "../../../../components/Confirm";
import { toast } from "react-toastify";
import { useTipoDocumento } from "../../../../hooks/useTipoDocumento";
import { TipoDocumento } from "../../../../types/TipoDocumento";
import { downloadFileParam } from "../../../../services/Service";



const TableTipoDocumento = () => {
    const { tipoDocumentoList, loading, tipoDocumentoInitial, buscarTiposDocumentos, eliminarTipoDocumento, handleTipoDocumentoList } = useTipoDocumento();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [data, setData] = useState<TipoDocumento>(tipoDocumentoInitial);
    const toggle = () => setShowModal(!showModal);


    const eliminar = () => {
        eliminarTipoDocumento(data.id as unknown as string)
            .then(response => {
                buscarTiposDocumentos({
                    id: 0,
                    nombre: "",
                    descripcion: "",
                    activo: null,
                    sizeMaximo: 0,
                    formatoArchivo: "",
                    contenidoPlantilla: null,
                    nombrePlantilla: null,
                    formatos: []

                })
            })
            .catch(err => toast.error("Hubo un error al eliminar el Tipo de Trabajo"))
        toggle();
    }
    const columns: ColumnDefinitionType<TipoDocumento, keyof TipoDocumento>[] = [
        {
            key: 'nombre',
            header: 'Nombre',
        },
        {
            key: 'nombrePlantilla',
            header: 'Plantilla',
            cell: (data: TipoDocumento) =>
                <>

                    <Link to="/exportar" onClick={(e) => {
                        e.preventDefault();
                        downloadFileParam('tiposDocumentos/descargarArchivo', data.contenidoPlantilla!, (data.nombrePlantilla) || '')
                    }} target="_blank" >
                        <u>{data.nombrePlantilla}</u>
                    </Link>
                </>
        },

        {
            key: 'activo',
            header: 'Estado',
            cell: (data: TipoDocumento) => <>{data.activo ? 'Activo' : 'Inactivo'}</>
        },

        {
            key: 'id',
            header: 'Acciones',
            cell: (data: TipoDocumento) =>
                <>
                    <Link to={`/tipoDocumento/editar/${data.id}?action=view`} title="Visualizar">
                        <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                    </Link>
                    <Link to={`/tipoDocumento/editar/${data.id}`} title="Editar" >
                        <i className="fa fa-pencil text-custom" aria-hidden="true"></i>
                    </Link>
                    {data.activo &&
                        <Link to="#" title="eliminar"
                            onClick={(e) => {
                                e.stopPropagation();
                                setData(data);
                                setShowModal(true)

                            }}
                        >
                            <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                        </Link>
                    }

                </>
        },
    ]

    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar el Tipo de Documento {data.nombre}?</p>}
                    </div>
                </div>
            </Confirm>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h4 className="m-t-0 header-title">
                                        <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                        Listado de Tipos de Documentos
                                        <Link to={`/tipoDocumento/nuevo`} title="Nuevo" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}>
                                            <i className="fa fa-plus margin-right-10"></i> Nuevo
                                        </Link>

                                    </h4>

                                </div>

                                <div className="table-responsive">
                                    <br />

                                    {loading ? <Loading /> : <Table data={tipoDocumentoList || []} columns={columns} tabLayoutFixed={true}></Table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default TableTipoDocumento;