/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Accordion from '../../../../components/Accordion'
import TablePersonalSubContratado from './TablePersonalSubContratado'
import { usePersonalAfectado } from '../../../../hooks/usePersonalAfectado'
import { useSolicitud } from '../../../../hooks/useSolicitud'
import TablePersonalPropio from './TablePersonalPropio'
import TablePersonalAutonomo from './TablePersonalAutonomo'
import { Button } from 'react-bootstrap'
import { useUserLogin } from '../../../../hooks/useUserLogin'
import { APROBADA_STATUS_ID, EN_ACTUALIZACION_STATUS_ID, EN_PROCESO_STATUS_ID, OBSERVADA_STATUS_ID, REVISION_STATUS_ID } from '../../../../constants/constants'

type PersonalAfectadoProps = {
    isDisabled?: boolean,
    isEdit?: boolean
};

const PersonalAfectado: React.FC<PersonalAfectadoProps> = ({ isDisabled = false, isEdit = true }) => {
    const [modalOpenPersPropio, setModalOpenPersPropio] = useState(false);
    const [modalOpenPersAutonomo, setModalOpenPersAutonomo] = useState(false);
    const [modalOpenPersSubContratado, setModalOpenPersSubContratado] = useState(false);
    const { isContratistaTipoUsuario, isEmpresaGestoraTipoUsuario } = useUserLogin();
    const [isEmpresaGestora] = useState<boolean>(isEmpresaGestoraTipoUsuario())
    const [isContratista] = useState<boolean>(isContratistaTipoUsuario())

    const [tiposDocumentosPers, setTiposDocumentosPers] = useState<any>({});
    const { solicitudSelected, cargarCombo } = useSolicitud();
    const [isEditing, setIsEditing] = useState(isEdit && ((isEmpresaGestora && solicitudSelected.idTipoEstado == REVISION_STATUS_ID) || (isContratista && (solicitudSelected.idTipoEstado == EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado == OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado == EN_ACTUALIZACION_STATUS_ID))));

    const { buscarPersonalAutonomaByIdSolicitud, buscarPersonalPropioByIdSolicitud, buscarPersonalSubContratadoByIdSolicitud,
        existePersonalAfectadoByIdContTrabajoEmpresa, isPersonalAfectado, notSelectAllPersonalAfectado } = usePersonalAfectado();
    const [cargarPersonalAfecto, setCargarPersonalAfecto] = useState(true);


    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (cargarPersonalAfecto) {
            buscarPersonalAutonomaByIdSolicitud(solicitudSelected.id);
            buscarPersonalPropioByIdSolicitud(solicitudSelected.id);
            buscarPersonalSubContratadoByIdSolicitud(solicitudSelected.id);
            setCargarPersonalAfecto(false);
            setIsEditing(isEdit && ((isEmpresaGestora && solicitudSelected.idTipoEstado == REVISION_STATUS_ID) || (isContratista && (solicitudSelected.idTipoEstado == EN_PROCESO_STATUS_ID || solicitudSelected.idTipoEstado == OBSERVADA_STATUS_ID || solicitudSelected.idTipoEstado === APROBADA_STATUS_ID || solicitudSelected.idTipoEstado == EN_ACTUALIZACION_STATUS_ID))));
        }
        // existePersonalAfectadoByIdContTrabajoEmpresa(solicitudSelected.idContratistaTrabajoEmpresa);
    }

    useEffect(() => {
        cargarCombo('tiposDocPersona', setTiposDocumentosPers);
    }, [])

    useEffect(() => {
        existePersonalAfectadoByIdContTrabajoEmpresa(solicitudSelected.idContratistaTrabajoEmpresa);
    }, [solicitudSelected.idContratistaTrabajoEmpresa])


    const handelOnClose = () => {
        buscarPersonalAutonomaByIdSolicitud(solicitudSelected.id);
        buscarPersonalPropioByIdSolicitud(solicitudSelected.id);
        buscarPersonalSubContratadoByIdSolicitud(solicitudSelected.id);
        notSelectAllPersonalAfectado();
        //setIsEditing(false);
    };
    return (
        <>
            {isPersonalAfectado &&
                <Accordion id={'PersonalAfectado'} title="Personal Afectado" collapsed={true} handleClick={handleClick}>

                    <div>
                        <div className='p-3'>
                            <div className="row">
                                <div className="col-md-6 text-start align-bottom" style={{ alignSelf: 'flex-end' }}>
                                    <span>Personal Propio</span>
                                </div>
                                <div className="col-md-6 text-end">
                                    {!isDisabled && <Button
                                        className="btn-secondary"
                                        variant="secondary"
                                        onClick={() => { setIsEditing(true); setModalOpenPersPropio(true) }}
                                    > Agregar Persona
                                    </Button>}
                                </div>
                            </div>
                            <div className="table-responsive ">
                                <TablePersonalPropio isEditing={isEditing}
                                    onClose={() => { handelOnClose(); setModalOpenPersPropio(false) }}
                                    onModalAction={(isOpenModal) => setModalOpenPersPropio(isOpenModal)}
                                    modalOpen={modalOpenPersPropio}
                                    onIsEditing={(isEdit) => setIsEditing(isEdit)}
                                    tiposDocumentosPers={tiposDocumentosPers} />
                            </div>
                        </div>
                        <div className='p-3'>
                            <div className="row">
                                <div className="col-md-6 text-start align-bottom" style={{ alignSelf: 'flex-end' }}>
                                    <span>Personal SubContratado</span>
                                </div>
                                <div className="col-md-6 text-end">
                                    {!isDisabled && <Button
                                        className="btn-secondary"
                                        variant="secondary"
                                        onClick={() => { setIsEditing(true); setModalOpenPersSubContratado(true) }}
                                    > Agregar Persona
                                    </Button>}
                                </div>
                            </div>
                            <div className="table-responsive ">
                                <TablePersonalSubContratado isEditing={isEditing}
                                    onClose={() => { handelOnClose(); setModalOpenPersSubContratado(false) }}
                                    onModalAction={(isOpenModal) => setModalOpenPersSubContratado(isOpenModal)}
                                    modalOpen={modalOpenPersSubContratado}
                                    onIsEditing={(isEdit) => setIsEditing(isEdit)}
                                    tiposDocumentosPers={tiposDocumentosPers} />
                            </div>
                        </div>
                        <div className='p-3'>
                            <div className="row">
                                <div className="col-md-6 text-start align-bottom" style={{ alignSelf: 'flex-end' }}>
                                    <span>Personal Autonomo</span>
                                </div>
                                <div className="col-md-6 text-end">
                                    {!isDisabled && <Button
                                        className="btn-secondary"
                                        variant="secondary"
                                        onClick={() => { setIsEditing(true); setModalOpenPersAutonomo(true) }}
                                    > Agregar Persona
                                    </Button>}
                                </div>
                            </div>
                            <div className="table-responsive ">
                                <TablePersonalAutonomo isEditing={isEditing}
                                    onClose={() => { handelOnClose(); setModalOpenPersAutonomo(false) }}
                                    onModalAction={(isOpenModal) => setModalOpenPersAutonomo(isOpenModal)}
                                    modalOpen={modalOpenPersAutonomo}
                                    onIsEditing={(isEdit) => setIsEditing(isEdit)}
                                    tiposDocumentosPers={tiposDocumentosPers} />
                            </div>
                        </div>
                    </div>
                    {/* ) : <div>El tipo de trabajo seleccionado no requiere detallar el personal afectado</div>} */}
                </Accordion>
            }
        </>
    )
}

export default PersonalAfectado