import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, get, save, update } from "../services/Service";
import { loadData, loadList, isLoading } from "../context/Observacion/ObservacionReducer";
import { ObservacionSolicitud } from "../types/ObservacionSolicitud";

export const useObservacion = () => {
    const BASE_URL = 'observacion';

    const { observaciones, observacionSelected, loading } = useSelector(
        (state: RootState) => state.observacion
    );
    const dispatch = useDispatch();

    const buscarObservacionesByIdSolicitud = async (id: number) => {
        dispatch(isLoading(true))
        await findAll(`${BASE_URL}/solicitud/${id}`)
            .then((response) => {
                dispatch(loadList(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const buscarObservacionByIdSolicitud = async (id: number) => {
        dispatch(isLoading(true))
        await findAll(`${BASE_URL}/solicitud/current/${id}`)
            .then((response) => {
                dispatch(loadData(response.data))
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const saveObservacion = async (observacionSolicitud: ObservacionSolicitud) => {

        try {
            if (observacionSolicitud.id === 0 || observacionSolicitud.id === null) {
                const result = await save(`${BASE_URL}`, observacionSolicitud);
                dispatch(loadData(result.data))
                return result;
            } else {
                const result = await update(`${BASE_URL}`, observacionSolicitud);
                dispatch(loadData(result.data))
                return result;
            }
        } catch (error) {
            console.log(error);
        }

    }
    const existeCalificacion = async (idSolicitud: number) => {
        try {
            const response = await get(BASE_URL, `existeCalificacion?solicitudId=${idSolicitud}`);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    const setObservacionSelected = (data: ObservacionSolicitud) => {
        dispatch(loadData(data))
    }

    const handleLoading = (load: boolean) => {
        dispatch(isLoading(load))
    }

    return {
        buscarObservacionesByIdSolicitud,
        setObservacionSelected,
        saveObservacion,
        buscarObservacionByIdSolicitud,
        existeCalificacion,
        observaciones,
        observacionSelected,
        handleLoading,
        loading
    }

}