import { Link } from "react-router-dom";

export const Forbidden = () => {

    return (
        <>

            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <div>
                            <div className="centrado-vertical-error">
                                <div className="col-md-12 text-center" style={{ paddingTop: "60px" }}>
                                    <h1>403</h1>
                                    <h3>Ud. no tiene los permisos para realizar la acción seleccionada.</h3>
                                    <p>Disculpe las molestias.</p>
                                    <Link to="/" className="btn btn-primary text-white boton-nuevo">Ir al Inicio</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};