import { Link, useLocation } from 'react-router-dom';

export const Breadcrumbs = ({
  nameFirst = '',
  urlFirst = '',
  nameSecond = '',
  urlSecond = '',
  title = '',
  id = '',
  titlePage = '',
}: {
  nameFirst?: string;
  urlFirst?: string;
  nameSecond?: string;
  urlSecond?: string;
  title?: string;
  id?: string;
  titlePage?: string;
}) => {
  const location = useLocation();
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <h4 className="page-title">{titlePage ? titlePage : title}</h4>
          <ol className="breadcrumb p-0 m-0">
            <li>
              <Link
                to="/"
                className={
                  location.pathname === '/'
                    ? 'breadcrumb-active'
                    : 'breadcrumb-not-active'
                }
              >
                Inicio
              </Link>
            </li>
            {'' !== urlFirst ? (
              <li>
                <Link
                  to={urlFirst}
                  className={
                    !location.pathname.startsWith(urlFirst + '/')
                      ? 'breadcrumb-active'
                      : 'breadcrumb-not-active'
                  }
                >
                  {nameFirst}
                </Link>
              </li>
            ) : (
              ''
            )}
            {'' !== urlSecond ? (
              <li>
                <Link
                  to={urlSecond}
                  className={
                    !location.pathname.startsWith(urlSecond + '/')
                      ? 'breadcrumb-active'
                      : 'breadcrumb-not-active'
                  }
                >
                  {nameSecond}
                </Link>
              </li>
            ) : (
              ''
            )}

            <li>{title}</li>

          </ol>
          <div className="clearfix"></div>
          <hr />
        </div>
      </div>
    </div>
  );
};
