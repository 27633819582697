import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { findAll, loginUser } from "../services/Service";
import { RootState } from "../app/store";
import { clearToken, loadingError, onLogin, onLogout, setRoles, setToken, visibleError, setLinkPermisos, setPermisos, setAuth, urlRedirect } from "../context/Auth/UserLoginReducer";


export const useAuth = () => {

    const dispatch = useDispatch();
    const { usuarioLogin, isAuth, errors, error, redirect } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    const handlerLogin = async ({ username, password }: { username: string, password: string }) => {

        try {
            const response = await loginUser({ username, password });
            const token = response.data.token;
            const claims = JSON.parse(window.atob(token.split(".")[1]));
            handlePermisos(claims.authorities);

            localStorage.setItem('roles', claims.authorities);
            dispatch(setRoles(true))
            localStorage.setItem('token', `Bearer ${token}`);
            dispatch(setToken(`Bearer ${token}`))

            // getUsuarioByUsername();
            await findAll(`usuarios/username`)
                .then((response) => {
                    localStorage.setItem('login', JSON.stringify({
                        isAuth: true,
                        usuario: response.data
                    }));
                    dispatch(onLogin(response.data));
                    if (redirect) {
                        navigate(redirect);
                    } else {
                        navigate('/consultaSolicitudes');
                    }
                })
                .catch((error) => {
                    console.log(error);
                })


        } catch (error: any) {
            if (error.response?.status === 401) {
                const errors = [error.response.data.message]
                dispatch(loadingError(errors));
            } else if (error.response?.status === 403) {
                dispatch(loadingError(error.response.data.errors));
            } else {
                throw error;
            }
        }
    }

    const showError = (load: boolean) => {
        dispatch(visibleError(load))
    }

    const handlerLogout = () => {
        dispatch(onLogout());
        dispatch(clearToken());
        dispatch(urlRedirect(null));
        localStorage.removeItem('token');
        localStorage.removeItem('login');
        localStorage.clear();
    }
    const handleRedirect = (url: string | null) => {
        dispatch(urlRedirect(url));
    }

    const handlePermisos = (authorities: any) => {
        const rolesPoncho = JSON.parse(authorities);
        if (Object.keys(rolesPoncho).length !== 0) {
            const auth = JSON.parse(rolesPoncho[0].authority);

            const nombres: string[] = [];
            const links: string[] = [];
            auth && auth.menu.forEach((rol: { name: any; sub: any[]; }) => {
                nombres.push(rol.name);
                if (rol.sub) {
                    rol.sub.forEach(rolSub => {
                        nombres.push(rolSub.acro);
                        links.push(rolSub.link);
                    });
                }
            });

            dispatch(setPermisos(nombres))
            dispatch(setLinkPermisos(links))
        } else {
            handlerLogout();


        }
    }

    const getUsuarioByUsername = async () => {

        await findAll(`usuarios/username`)
            .then((response) => {
                localStorage.setItem('login', JSON.stringify({
                    isAuth: true,
                    usuario: response.data
                }));
                dispatch(onLogin(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getUsuarioLogueado = () => {
        const login = JSON.parse(localStorage.getItem('login') || '{}')
        return login?.usuario;
    }


    return {
        login: {
            usuarioLogin,
            isAuth,
        },
        handlerLogin,
        handlerLogout,
        showError,
        handlePermisos,
        getUsuarioLogueado,
        handleRedirect,
        isAuth,
        errors,
        error,
        redirect

    }
}