/* eslint-disable react/jsx-no-undef */
// ajustar
import { Routes, Route } from 'react-router';

import { NotFound } from '../../modules/Error/NotFound';
import { Home } from '../../modules/Home';
import { Solicitudes } from '../../modules/Pages/Solicitud/Consulta';
import { Solicitud } from '../../modules/Pages/Solicitud';
import { CrearSolicitud } from '../../modules/Pages/CrearSolicitud/Index';
import { Permiso } from '../../modules/Pages/Seguridad/Permiso/index';
import { CrearPermiso } from '../../modules/Pages/Seguridad/Permiso/Crear';
import { Rol } from '../../modules/Pages/Seguridad/Rol';
import { CrearRol } from '../../modules/Pages/Seguridad/Rol/Crear';
import { Perfil } from '../../modules/Pages/Seguridad/Perfil';
import { CrearPerfil } from '../../modules/Pages/Seguridad/Perfil/Crear';
import { TipoUsuario } from '../../modules/Pages/Seguridad/TipoUsuario';
import { CrearTipoUsuario } from '../../modules/Pages/Seguridad/TipoUsuario/Crear';
import { Usuario } from '../../modules/Pages/Seguridad/Usuario';
import { CrearUsuario } from '../../modules/Pages/Seguridad/Usuario/Crear';
import { NoAuthorized } from '../../modules/Error/NoAuthorized';
import { Forbidden } from '../../modules/Error/Forbidden';
import { InternalServerError } from '../../modules/Error/InternalServerError';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import ProtectedRoutes from '../protectedRoutes';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BandejaTrabajo } from '../../modules/Pages/BandejaTrabajo';
import { TipoTrabajo } from '../../modules/Pages/TipoTrabajo';
import { CrearTipoTrabajo } from '../../modules/Pages/TipoTrabajo/Crear';
import { Actividad } from '../../modules/Pages/Actividad';
import { CrearActividad } from '../../modules/Pages/Actividad/Crear/Index';
import { Contratista } from '../../modules/Pages/Contratista';
import { CrearContratista } from '../../modules/Pages/Contratista/Crear/Index';
import DatosSolicitud from '../../modules/Pages/Solicitud/Datos';
import { TipoDocumento } from '../../modules/Pages/TipoDocumento';
import { CrearTipoDocumento } from '../../modules/Pages/TipoDocumento/Crear/Index';


export const Rutas = () => {

  const { permisos } = useSelector((state: RootState) => state.auth);
  const [searchParams] = useSearchParams();
  const [permisoByAction, setPermisoByAction] = useState<string | null>(null);

  useEffect(() => {
    const TipoAction = searchParams.get("action");

    if (TipoAction === 'edit') {
      setPermisoByAction('EDICION_SOLICITUD')
    } else {
      setPermisoByAction('VISTA_SOLICITUD')
    }
  }, [])

  const checkPermisoByAction = () => {
    const TipoAction = searchParams.get("action");
    if (TipoAction === 'edit') {
      return userHasRole('EDICION_SOLICITUD');
    } else {
      return (userHasRole('VISTA_SOLICITUD') && userHasRole('CONSULTA_SOLICITUD'));
    }
  }

  const userHasRole = (role: string) => {
    return permisos.find(nombre => nombre === role) !== undefined;
  }

  return (


    <Routes>
      {permisos.length > 0 &&
        <>
          <Route path="/" element={<Home />} />
          <Route path="/consultaSolicitudes" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONSULTA_SOLICITUD')}>
              <Solicitudes />
            </ProtectedRoutes>
          } />
          <Route path="/solicitud/:id" element={
            permisoByAction && <>
              <ProtectedRoutes tienePermiso={checkPermisoByAction()}>
                <Solicitud />
              </ProtectedRoutes>
            </>
          } />
          <Route path="/crearSolicitud" element={
            <ProtectedRoutes tienePermiso={userHasRole('ALTA_SOLICITUD')}>
              <CrearSolicitud />
            </ProtectedRoutes>
          } />
          <Route path="/permisos" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_PERMISO')}>
              <Permiso />
            </ProtectedRoutes>
          } />
          <Route path="/permisos/nuevo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_PERMISO')}>
              <CrearPermiso />
            </ProtectedRoutes>
          } />
          <Route path="/permisos/editar/:id" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_PERMISO')}>
              <CrearPermiso />
            </ProtectedRoutes>
          } />
          <Route path="/roles" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_ROL')}>
              <Rol />
            </ProtectedRoutes>
          } />
          <Route path="/roles/nuevo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_ROL')}>
              <CrearRol />
            </ProtectedRoutes>
          } />
          <Route path="/roles/editar/:id" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_ROL')}>
              <CrearRol />
            </ProtectedRoutes>
          } />
          <Route path="/perfiles" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_PERFIL')}>
              <Perfil />
            </ProtectedRoutes>
          } />
          <Route path="/perfiles/nuevo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_PERFIL')}>
              <CrearPerfil />
            </ProtectedRoutes>
          } />
          <Route path="/perfiles/editar/:id" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_PERFIL')}>
              <CrearPerfil />
            </ProtectedRoutes>
          } />
          <Route path="/tiposUsuarios" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_TIPO_USUARIO')}>
              <TipoUsuario />
            </ProtectedRoutes>
          } />
          <Route path="/tiposUsuarios/nuevo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_TIPO_USUARIO')}>
              <CrearTipoUsuario />
            </ProtectedRoutes>
          } />
          <Route path="/tiposUsuarios/editar/:id" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_TIPO_USUARIO')}>
              <CrearTipoUsuario />
            </ProtectedRoutes>
          } />
          <Route path="/usuarios" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_USUARIO')}>
              <Usuario />
            </ProtectedRoutes>
          } />
          <Route path="/usuarios/nuevo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_USUARIO')}>
              <CrearUsuario />
            </ProtectedRoutes>
          } />
          <Route path="/usuarios/editar/:id" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_USUARIO')}>
              <CrearUsuario />
            </ProtectedRoutes>
          } />
          <Route path="/bandejaTrabajo" element={
            <ProtectedRoutes tienePermiso={userHasRole('BANDEJA_TRABAJO')}>
              <BandejaTrabajo />
            </ProtectedRoutes>
          } />
          <Route path="/tipoTrabajo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_TIPO_TRABAJO')}>
              <TipoTrabajo />
            </ProtectedRoutes>
          } />

          <Route path="/tipoTrabajo/nuevo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_TIPO_TRABAJO')}>
              <CrearTipoTrabajo />
            </ProtectedRoutes>
          } />
          <Route path="/tipoTrabajo/editar/:id" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_TIPO_TRABAJO')}>
              <CrearTipoTrabajo />
            </ProtectedRoutes>
          } />
          <Route path="/actividades" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_ACT')}>
              <Actividad />
            </ProtectedRoutes>
          } />
          <Route path="/actividades/nuevo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_ACT')}>
              <CrearActividad />
            </ProtectedRoutes>
          } />
          <Route path="/actividades/editar/:id" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_ACT')}>
              <CrearActividad />
            </ProtectedRoutes>
          } />
          <Route path="/contratistas" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_CONTRATISTA')}>
              <Contratista />
            </ProtectedRoutes>
          } />
          <Route path="/contratistas/nuevo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_CONTRATISTA')}>
              <CrearContratista />
            </ProtectedRoutes>
          } />
          <Route path="/contratistas/editar/:id" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_CONTRATISTA')}>
              <CrearContratista />
            </ProtectedRoutes>
          } />
          <Route path="/tipoDocumento" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_TIPO_DOC')}>
              <TipoDocumento />
            </ProtectedRoutes>
          } />
          <Route path="/tipoDocumento/nuevo" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_TIPO_DOC')}>
              <CrearTipoDocumento />
            </ProtectedRoutes>
          } />
          <Route path="/tipoDocumento/editar/:id" element={
            <ProtectedRoutes tienePermiso={userHasRole('CONFIG_TIPO_DOC')}>
              <CrearTipoDocumento />
            </ProtectedRoutes>
          } />
          <Route path='/solicitud/:id/datos' element={<DatosSolicitud />} />


        </>
      }

      <Route path="/error401" element={<NoAuthorized />} />
      <Route path="/error403" element={<Forbidden />} />
      <Route path="/error404" element={<NotFound />} />
      <Route path="/error500" element={<InternalServerError />} />
      <Route path="*" element={<Home />} />

    </Routes>

  );
}

