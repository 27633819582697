import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Contratista } from "../../types/Contratista";
import { TrabajoEmpresa } from "../../types/TrabajoEmpresa";


interface ContratistaState {
    trabajoEmpresaList: TrabajoEmpresa[];
    contratistaList: Contratista[];
    contratistaSelected: Contratista;
    filtro: Contratista;
    loading: boolean;
    errors: any;
    error: boolean,
}

export const contratistaInitial: Contratista = {
    id: 0,
    razonSocial: "",
    direccion: "",
    email: "",
    tipoDocumento: "",
    activo: true,
    idEmpresaGestora: null,
    idTipoTrabajo: null,
    idTipoDocumento: null,
    nroIdentificacion: "",
    telefono: "",
    logo: null,
    logoNuevo: null,
    trabajosEmpresas: []
}

const initialState: ContratistaState = {
    contratistaList: [],
    contratistaSelected: contratistaInitial,
    trabajoEmpresaList: [],
    filtro: {
        id: 0,
        razonSocial: "",
        direccion: "",
        email: "",
        tipoDocumento: "",
        activo: null,
        idEmpresaGestora: null,
        idTipoTrabajo: null,
        idTipoDocumento: null,
        nroIdentificacion: "",
        telefono: "",
        logo: null,
        logoNuevo: null,
        trabajosEmpresas: []
    },
    loading: false,
    errors: undefined,
    error: false
}

const contratistaSlice = createSlice({
    name: 'contratista',
    initialState,
    reducers: {
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataContratista: (state, data: PayloadAction<Contratista>): void => {
            state.contratistaSelected = data.payload;
            state.error = false;
        },
        loadDataFiltroContratista: (state, data: PayloadAction<Contratista>): void => {
            state.filtro = data.payload;
        },

        loadListContratista: (state, data: PayloadAction<Contratista[]>): void => {
            state.contratistaList = data.payload;
        },
        loadListTrabajoEmpresa: (state, data: PayloadAction<TrabajoEmpresa[]>): void => {
            state.trabajoEmpresaList = data.payload;
        },

        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        removeActividad: (state, data: PayloadAction<number>): void => {
            state.contratistaList = state.contratistaList.filter(p => p.id !== data.payload)
        }

    }
});

export const {
    isLoading,
    loadDataContratista,
    loadDataFiltroContratista,
    loadListContratista,
    loadListTrabajoEmpresa,
    loadingError,
    visibleError

} = contratistaSlice.actions;

export default contratistaSlice.reducer;