import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, get, remove, save, update } from "../services/Service";
import { useNavigate } from "react-router-dom";
import { addPerfil, loadDataPerfil, loadListPerfil, loadingError, removePerfil, visibleError, perfilInitial, setPermisos, setPermisosSelected, setGuardado, setRoles, setExpandedKeys } from "../context/Seguridad/PerfilReducer";
import { Perfil } from "../types/Perfil";


export const usePerfil = () => {
    const BASE_URL = 'perfiles';
    const { loading, perfilSelected, perfilList, permisos, errors, error, permisosSelected, guardado, roles, expandedKeys } = useSelector(
        (state: RootState) => state.perfil
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const buscarPerfiles = async (perfil: Perfil) => {

        await findAll(`${BASE_URL}/find?descripcion=${perfil.descripcion}`)
            .then((response) => {
                dispatch(loadListPerfil(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const buscarRoles = async () => {
        let response;
        try {
            response = await findAll('perfiles/roles');
            const rolesByPerfil: any = defineExpandedKeys(response.data);

            const treeData = [
                {
                    descripcion: 'Roles',
                    id: 'parent',
                    child: [
                        ...rolesByPerfil
                    ],
                },
            ];

            dispatch(setRoles(treeData));

        } catch (error: any) {
            console.log(error);
        }
    }

    const guardarPerfil = async (perfil: Perfil) => {
        let response;
        try {
            if (perfil.id === 0) {
                response = await save(BASE_URL, perfil);
                dispatch(addPerfil(response.data));
            } else {
                response = await update(BASE_URL, perfil);
                dispatch(addPerfil(response.data));
            }
            navigate('/perfiles');
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                dispatch(loadingError(error.response.data.errors));
            } else if (error.response && error.response.status === 500) {
                dispatch(loadingError(error.response.data.errors));
            } else if (error.response?.status === 401) {
                //handlerLogout();
            } else {
                throw error;
            }
        }

    }
    const buscarPerfil = async (id: string) => {
        try {
            const response = await get(BASE_URL, id)
            dispatch(loadDataPerfil(response.data));
            dispatch(setPermisosSelected(response.data.permisosSelected?.map((p: any) => (p.id))));

            const rolesByPerfil: any = defineExpandedKeys(response.data.roles);

            const treeData = [
                {
                    descripcion: 'Roles',
                    id: 'parent',
                    child: [
                        ...rolesByPerfil
                    ],
                },
            ];

            dispatch(setRoles(treeData));
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    const eliminarPerfil = async (id: string) => {
        try {
            const response = await remove(BASE_URL, id)
            dispatch(removePerfil(Number(id)));

        } catch (error) {
            console.log(error);
        }
    }

    const handlePerfilSelected = (perfil: Perfil) => {
        dispatch(loadDataPerfil(perfil))
    }
    const showError = (load: boolean) => {
        dispatch(visibleError(load))
    }
    const setMsgError = (error: string[]) => {
        dispatch(loadingError(error));
    }
    const handlePermisosSelected = (permisos: any) => {
        dispatch(setPermisosSelected(permisos))
    }
    const handleGuardado = (guardado: boolean) => {
        dispatch(setGuardado(guardado))
    }
    const handleExpandedKey = (expanded: any[]) => {
        dispatch(setExpandedKeys(expanded));
    }
    const defineExpandedKeys = (roles: any[]) => {

        const rolesByPerfil: any = [];
        roles.forEach((r: any, index: number) => {
            rolesByPerfil[index] = { ...r }
            rolesByPerfil[index].id = 'rol-' + r.id

        });
        const expandedKeys = [
            'parent',
            ...rolesByPerfil.map((p: any) => (p.id)),
        ]
        dispatch(setExpandedKeys(expandedKeys));
        return rolesByPerfil;
    }

    return {
        buscarPerfiles,
        buscarRoles,
        guardarPerfil,
        buscarPerfil,
        eliminarPerfil,
        handlePerfilSelected,
        showError,
        setMsgError,
        handlePermisosSelected,
        handleGuardado,
        handleExpandedKey,
        perfilInitial,
        perfilList,
        perfilSelected,
        permisos,
        roles,
        permisosSelected,
        loading,
        errors,
        error,
        guardado,
        expandedKeys
    }
}