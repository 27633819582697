import { configureStore } from "@reduxjs/toolkit";
import SolicitudReducer from "../context/Solicitud/SolicitudReducer";
import DocumentoReducer from "../context/Documento/DocumentoReducer";
import PersonalAfectadoReducer from "../context/PersonalAfectado/PersonalAfectadoReducer";
import DocumentoPersonalAfectadoReducer from "../context/DocumentoPersonalAfectado/DocumentoPersonalAfectadoReducer";
import EquipoAndMaquinariaReducer from '../context/EquiposAndMaquinaria/EquipoAndMaquinariaReducer';
import ObservacionReducer from '../context/Observacion/ObservacionReducer';
import MensajeReducer from '../context/Mensaje/MensajeReducer';
import HistorialReducer from '../context/Historial/HistorialReducer';
import PermisoReducer from '../context/Seguridad/PermisoReducer';
import RolReducer from '../context/Seguridad/RolReducer';
import UserLoginReducer from '../context/Auth/UserLoginReducer';
import PerfilReducer from '../context/Seguridad/PerfilReducer';
import TipoUsuarioReducer from '../context/Seguridad/TipoUsuarioReducer';
import DocumentoTrabajoReducer from '../context/DocumentoTrabajo/DocumentoTrabajoReducer';
import UsuarioReducer from "../context/Seguridad/UsuarioReducer";
import TipoTrabajoReducer from "../context/TipoTrabajo/TipoTrabajoReducer";
import ActividadReducer from "../context/Actividad/ActividadReducer";
import ContratistaReducer from "../context/Contratista/ContratistaReducer";
import TipoDocumentoReducer from "../context/TipoDocumento/TipoDocumentoReducer";

export const store = configureStore({
    reducer: {
        solicitud: SolicitudReducer,
        documento: DocumentoReducer,
        personalAfectado: PersonalAfectadoReducer,
        documentoPersAfectado: DocumentoPersonalAfectadoReducer,
        equipoAndMaquinaria: EquipoAndMaquinariaReducer,
        observacion: ObservacionReducer,
        mensaje: MensajeReducer,
        historial: HistorialReducer,
        permiso: PermisoReducer,
        rol: RolReducer,
        perfil: PerfilReducer,
        tipoUsuario: TipoUsuarioReducer,
        auth: UserLoginReducer,
        documentoTrabajo: DocumentoTrabajoReducer,
        usuario: UsuarioReducer,
        tipoTrabajo: TipoTrabajoReducer,
        actividad: ActividadReducer,
        contratista: ContratistaReducer,
        tipoDocumento: TipoDocumentoReducer,

    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch