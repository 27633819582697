//Queda a futuro cuando se defina un id o valor unico para cada estado, se usa el ID de los estados mas abajo
export const EN_PROCESO_STATUS : string = 'En Proceso';
export const APROBADA_STATUS : string = 'Aprobada';
export const RECHAZADA_STATUS : string = 'Rechazada';
export const INICIO_STATUS : string = 'Inicio Solicitud';
export const ANULADA_STATUS : string = 'Anulada';
export const REVISION_STATUS : string = 'Revision';
export const FINALIZADA_STATUS : string = 'Finalizada';
export const OBSERVADA_STATUS : string = 'Observada';
export const EN_ACTUALIZACION_STATUS: string = 'Actualizacion'

export const EN_PROCESO_STATUS_ID : number = 10;
export const OBSERVADA_STATUS_ID : number = 11;
export const APROBADA_STATUS_ID : number = 12;
export const RECHAZADA_STATUS_ID : number = 13;
export const INICIO_STATUS_ID : number = 14;
export const ANULADA_STATUS_ID : number = 15;
export const REVISION_STATUS_ID : number = 16;
export const EN_ACTUALIZACION_STATUS_ID: number = 17
export const FINALIZADA_STATUS_ID : number = 18;

export const APROBADO_DOCUMENTO_STATUS: string = 'APROBADO';
export const NO_APROBADO_DOCUMENTO_STATUS: string = 'NO_APROBADO'
export const SIN_VISAR_DOCUMENTO_STATUS: string = 'SIN_VISAR'