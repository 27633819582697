import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, get, remove, save, update } from "../services/Service";
import { useNavigate } from "react-router-dom";

import { TipoUsuario } from "../types/TipoUsuario";
import { addTipoUsuario, loadDataTipoUsuario, loadListTipoUsuario, removeTipoUsuario, setPerfiles, setPerfilesSelected, tipoUsuarioInitial, loadingError, visibleError, setGuardado } from "../context/Seguridad/TipoUsuarioReducer";

export const useTipoUsuario = () => {
    const BASE_URL = 'tiposUsuarios';
    const { loading, tipoUsuarioSelected, tipoUsuarioList, perfiles, errors, error, perfilesSelected, guardado } = useSelector(
        (state: RootState) => state.tipoUsuario
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const buscarTiposUsuarios = async (tipoUsuario: TipoUsuario) => {

        await findAll(`${BASE_URL}/find?nombre=${tipoUsuario.nombre}&acronimo=${tipoUsuario.valor}`)
            .then((response) => {
                dispatch(loadListTipoUsuario(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }



    const guardarTipoUsuario = async (tipoUsuario: TipoUsuario) => {
        let response;
        try {
            if (tipoUsuario.id === 0) {
                response = await save(BASE_URL, tipoUsuario);
                dispatch(addTipoUsuario(response.data));
            } else {
                response = await update(BASE_URL, tipoUsuario);
                dispatch(addTipoUsuario(response.data));
            }
            navigate('/tiposUsuarios');
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                dispatch(loadingError(error.response.data.errors));
            } else if (error.response && error.response.status === 500) {
                dispatch(loadingError(error.response.data.errors));
            } else if (error.response?.status === 401) {
                //handlerLogout();
            } else {
                throw error;
            }
        }

    }
    const buscarTipoUsuario = async (id: string) => {
        try {
            const response = await get(BASE_URL, id)
            dispatch(loadDataTipoUsuario(response.data));
            dispatch(setPerfilesSelected(response.data.perfilesSelected?.map((p: any) => (p.id))));
            const treeData = [
                {
                    descripcion: response.data.nombre,
                    id: 'parent',
                    child: [
                        ...response.data.perfiles
                    ],
                },
            ];
            dispatch(setPerfiles(treeData));
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    const eliminarTipoUsuario = async (id: string) => {
        try {
            const response = await remove(BASE_URL, id)
            dispatch(removeTipoUsuario(Number(id)));

        } catch (error) {
            console.log(error);
        }
    }

    const handleTipoUsuarioSelected = (tipoUsuario: TipoUsuario) => {
        dispatch(loadDataTipoUsuario(tipoUsuario))
    }
    const showError = (load: boolean) => {
        dispatch(visibleError(load))
    }
    const setMsgError = (error: string[]) => {
        dispatch(loadingError(error));
    }
    const handlePerfilesSelected = (perfiles: any) => {
        dispatch(setPerfilesSelected(perfiles))
    }
    const handleGuardado = (guardado: boolean) => {
        dispatch(setGuardado(guardado))
    }

    return {
        buscarTiposUsuarios,
        guardarTipoUsuario,
        buscarTipoUsuario,
        eliminarTipoUsuario,
        handleTipoUsuarioSelected,
        showError,
        setMsgError,
        handlePerfilesSelected,
        handleGuardado,
        tipoUsuarioInitial,
        tipoUsuarioList,
        tipoUsuarioSelected,
        perfiles,
        perfilesSelected,
        loading,
        errors,
        error,
        guardado
    }
}