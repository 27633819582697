import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TipoUsuario } from "../../types/TipoUsuario";

interface tipoUsuarioState {
    tipoUsuarioList: TipoUsuario[];
    tipoUsuarioSelected: TipoUsuario;
    loading: boolean;
    perfiles: any[];
    perfilesSelected: any[];
    errors: any;
    error: boolean,
    guardado: boolean,

}

export const tipoUsuarioInitial: TipoUsuario = {
    id: 0,
    nombre: '',
    apellido: '',
    tipoResponsable: '',
    estado: false,
    nivel: 0,
    perfilesSelected: [],
    valor: '',
    guardado: false,
}

const initialState: tipoUsuarioState = {
    tipoUsuarioList: [],
    perfiles: [],
    perfilesSelected: [],
    tipoUsuarioSelected: tipoUsuarioInitial,
    loading: false,
    errors: [],
    error: false,
    guardado: false,
}

const TipoUsuarioSlice = createSlice({
    name: 'tipoUsuario',
    initialState,
    reducers: {
        addTipoUsuario: (state, data: PayloadAction<TipoUsuario>): void => {
            state.tipoUsuarioSelected = tipoUsuarioInitial;
            state.error = false;
            state.guardado = true;
        },
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataTipoUsuario: (state, data: PayloadAction<TipoUsuario>): void => {
            state.tipoUsuarioSelected = data.payload;
            state.error = false;
        },
        loadListTipoUsuario: (state, data: PayloadAction<TipoUsuario[]>): void => {
            state.tipoUsuarioList = data.payload;
        },
        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        removeTipoUsuario: (state, data: PayloadAction<number>): void => {
            state.tipoUsuarioList = state.tipoUsuarioList.filter(p => p.id !== data.payload)
        },
        setPerfiles: (state, data: PayloadAction<any[]>): void => {
            state.perfiles = data.payload;
        },
        setPerfilesSelected: (state, data: PayloadAction<any[]>): void => {
            state.perfilesSelected = data.payload;
        },
        setGuardado: (state, data: PayloadAction<boolean>): void => {
            state.guardado = data.payload;
        },

    },
});

export const {
    isLoading,
    loadDataTipoUsuario,
    loadListTipoUsuario,
    addTipoUsuario,
    loadingError,
    removeTipoUsuario,
    visibleError,
    setPerfiles,
    setPerfilesSelected,
    setGuardado
} = TipoUsuarioSlice.actions;

export default TipoUsuarioSlice.reducer;