import { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const initialLoginForm = {
    username: '',
    password: '',
}
export const LoginPage = () => {

    const { handlerLogin, error, errors } = useAuth();

    const [loginForm, setLoginForm] = useState(initialLoginForm);
    const { username, password } = loginForm;
    const navigate = useNavigate();


    const handleChange = (e: any) => {
        e.preventDefault();
        const { name, value } = e.target;
        setLoginForm({
            ...loginForm,
            [name]: value,
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        handlerLogin({ username, password });
        setLoginForm(initialLoginForm);


    }

    return (

        <>


            <div className="mainmenu-area">
                <div className="container text-left">

                    <img id="imageLogoApp" alt="LogoContratistas"
                        // src={require('../../../resources/images/poncho-instalaciones.png')}
                        src={
                            process.env.PUBLIC_URL + '/poncho-instalaciones.png'
                        }
                        className="logo-img" />

                </div>
            </div>

            <section className="login-area overlay center-block" id="login-page">
                <div className="container" style={{ position: 'relative' }}>
                    <div className="row">
                        <div className="col-md-10 col-md-offset-2 pt-5">
                            <form onSubmit={handleSubmit} className="login-box">


                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="username" className="label-log" >Nombre de Usuario</label>
                                            <input type="text" onChange={handleChange} value={loginForm.username}
                                                className="form-control" id="username" name="username" required
                                                autoFocus aria-describedby="emailHelp" placeholder="Usuario"
                                                onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Por favor Ingrese Nombre de Usuario')}
                                                onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="password" className="label-log">Clave</label>
                                            <input type="password" onChange={handleChange} value={loginForm.password}
                                                className="form-control" id="password" required name="password" placeholder="Clave"
                                                onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Por favor Ingrese Clave')}
                                                onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {error ?

                                    errors?.map((e: any, index: number) => (
                                        // <p className="alert-danger" key={index}
                                        //     style={{ height: "10px", display: "grid" }}
                                        // > {e}
                                        // </p>
                                        <div key={index}>
                                            <label style={{ color: "orangered", fontSize: "14px" }}>{e}</label>

                                        </div>
                                    ))

                                    : ''
                                }

                                &nbsp;
                                <div className="row">
                                    <div className="col-xs-12 col-md-4 col-md-offset-4">
                                    </div>
                                    <div className="col-xs-12 col-md-4 col-md-offset-4">
                                        <button type="submit" className="btn btn-primary btn-block" >
                                            Login
                                        </button>
                                    </div>
                                    <div className="col-xs-12 col-md-12 col-md-offset-4 text-center">
                                        <a href="recuperarClave" className="loginRecuperarClave"> Recuperar Clave
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </section >

            <footer className="footer-area relative" id="contact-page">

                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 text-right-log pt-4">
                                <img
                                    src={require('../../../resources/images/logo-poncho.png')}
                                    alt="" style={{ width: "215px" }} />
                            </div>
                            <div className="col-sm-6">
                                <p className="text-footer" style={{ color: "#51565" }}>Plataforma <strong>COMPLIANCE LEGAL y CORPORATIVO</strong> en materia de <strong>SEGURIDAD INDUSTRIAL, SEGURIDAD OPERATIVA y MEDIO AMBIENTE</strong>, 100% configurable.</p>
                                <p style={{ margin: "0px", padding: "0px" }}><a href="https://poncho.grupomost.com/" style={{ color: "#007cb3", fontSize: "12px" }} target="_blank" rel="noreferrer">poncho.grupomost.com</a></p>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 text-center">
                                <p style={{ fontSize: "12px" }}>
                                    &copy; Copyright 2020 GrupoMost
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>





    );

}