
import { useSearchParams } from "react-router-dom";
import { useUsuarios } from "../../../../hooks/useUsuarios";
import { Breadcrumbs } from "../../../Home/Breadcrumb";
import { BuscarUsuario } from "./Buscar";
import TableUsuario from "./Table";
import { useEffect, useState } from "react";


export const Usuario = () => {
    const [searchParams] = useSearchParams();
    const [guardado, setGuardado] = useState<boolean | null>(null);

    useEffect(() => {
        const TipoAction = searchParams.get("updated");

        if (TipoAction === 'true') {
            setGuardado(true)
        } else {
            setGuardado(false)
        }
    }, [])

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={'Usuarios'}
                        nameFirst={'Seguridad'}
                        urlFirst={'/'}
                    />
                    {guardado &&
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <strong>Actualización exitosa.</strong>
                        </div>
                    }
                    <BuscarUsuario setGuardado={setGuardado} />
                    <TableUsuario />

                </div>
            </div >
        </div >

    );
}
