import React, { useEffect, useState } from 'react'
import FormInput from '../../../../components/Form/FormInput'
import { Actividad } from '../../../../types/Actividad'
import { useActividad } from '../../../../hooks/useActividad'
import { ToastContainer, toast } from 'react-toastify'
import { Breadcrumbs } from '../../../Home/Breadcrumb'
import FormSelectBox from '../../../../components/Form/FormSelectBox'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import SeccionTipoDocumentos from '../../TipoTrabajo/Crear/TipoDocumento'
import { DocumentoTrabajo } from '../../../../types/DocumentoTrabajo'
import { cargarCombo } from '../../../../services/Service'
import Accordion from '../../../../components/Accordion'
import { useTipoTrabajo } from '../../../../hooks/useTipoTrabajo'
import Confirm from '../../../../components/Confirm'

type FormActividadProps = {}

export type FormActividadErrors = {
    nombre?: string;
    descripcion?: string;
};

export const CrearActividad = (props: FormActividadProps) => {
    const { actividadInitial, actividadSelected, guardarActividad, buscarActividad, handleActividadSelected } = useActividad();
    const { buscarDocumentosActividades, handleDocumentoList, documentoList } = useTipoTrabajo()
    const [actividadForm, setActividadForm] = useState<Actividad>(actividadInitial);
    const [documentos, setDocumentos] = useState<DocumentoTrabajo[]>([]);
    const [formErrors, setFormErrors] = useState<FormActividadErrors>({
        nombre: '',
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);
    const [edit, setEdit] = useState<boolean>(false);
    const [view, setView] = useState<boolean>(false);
    const [estados] = useState<any>(
        [

            {
                'id': true,
                'valor': 'ACTIVO'
            },
            {
                'id': false,
                'valor': 'INACTIVO'
            }
        ]);

    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        cargarCombo(`documentoActividad`, setDocumentos);
        if (id) {
            buscarActividad(id);
            buscarDocumentosActividades(+id);
            setEdit(true);
            const TipoAction = searchParams.get("action");
            if (TipoAction === 'view') {
                setView(true);
            }

        } else {
            handleActividadSelected(actividadInitial);
            handleDocumentoList([])
        }

    }, [])

    useEffect(() => {
        setActividadForm(actividadSelected);

    }, [actividadSelected])

    const onChange = (e: any) => {
        const { name, value } = e.target;
        setActividadForm({
            ...actividadForm,
            [name]: value
        })
        const errors = validateForm({
            ...actividadForm,
            [name]: value
        })
        setFormErrors(errors)
    }

    const validateForm = (actividad: Actividad) => {
        const errors: FormActividadErrors = {};
        if (!actividad.nombreActividad) {
            errors.nombre = "El campo nombre es obligatorio"
        }
        setFormErrors(errors);
        return errors;
    }

    const handleGuardar = () => {
        const errores = validateForm({
            ...actividadForm,
        });
        if (Object.keys(errores).length === 0) {
            guardarActividad({
                ...actividadForm,
                documentos: documentoList
            })
                .then(response => {
                    if (response.data) {
                        toast.success("Se guardó la Actividad correctamente")
                        navigate('/actividades');

                    }
                }
                ).catch(err => toast.error(err.response.data.message))
        }
    }
    const handleCancelar = (e: any) => {
        e.preventDefault();
        setShowModal(true)
    }
    const cancelar = () => {
        toggle();
        navigate('/actividades')
    }
    const handleVolver = () => {
        navigate('/actividades')
    }

    return (
        <>
            <ToastContainer />

            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title={edit ? (view ? 'Vista' : 'Editar') : 'Nuevo'}
                            titlePage={view ? 'Vista Actividad' : 'Actividad'}
                            nameFirst={'Configuración'}
                            urlFirst={'/'}
                            nameSecond={'Actividad'}
                            urlSecond={'/actividades'}
                        />
                        <Confirm isOpen={showModal} toggle={toggle} title='Cancelar' yesFn={cancelar}>
                            <div className="form-horizontal">
                                <div className="row">
                                    {<p>Desea salir sin guardar la Actividad?</p>}
                                </div>
                            </div>
                        </Confirm>
                        <Accordion id={'actividad'} title="Actividad">
                            <div className="row mt-4">
                                <FormInput
                                    id={'nombre'}
                                    label={'Nombre'}
                                    name={'nombreActividad'}
                                    // placeholder={'Nro. Solicitud'}
                                    value={actividadForm.nombreActividad || ''}
                                    isDisabled={edit || view}
                                    onChange={onChange}
                                    errorText={formErrors.nombre}
                                    maxLength={255}
                                />
                            </div>
                            <div className="row mt-3">
                                <FormInput
                                    id={'descripcion'}
                                    label={'Descripción'}
                                    name={'descripcion'}
                                    // placeholder={'Nro. Solicitud'}
                                    value={actividadForm.descripcion || ''}
                                    isDisabled={edit && view}
                                    onChange={onChange}
                                    maxLength={255}
                                />
                            </div>
                            <div className="row mt-2">
                                <FormSelectBox
                                    title={'Estado'}
                                    label={'estado'}
                                    id={'activo'}
                                    name={'activo'}
                                    onChange={onChange}
                                    optionId={'id'}
                                    optionLabel={'valor'}
                                    value={actividadForm.activo || ''}
                                    options={estados}
                                    isDisabled={edit && view}
                                />
                                <br />
                                <br /><br />

                            </div>
                        </Accordion>

                        <SeccionTipoDocumentos
                            documentos={documentos}
                            setDocumentos={setDocumentos}
                            edit={edit}
                            view={view} />
                        <br />

                        <div className="text-center">
                            {!view &&
                                <>
                                    <button className="btn btn-secondary mx-2" onClick={handleCancelar}>Cancelar</button>
                                    <button className="btn btn-success mx-2" onClick={handleGuardar}>Guardar</button>
                                </>
                            }
                            {view &&
                                <button className="btn btn-secondary mx-2" onClick={handleVolver}>Volver</button>
                            }
                        </div>

                    </div>
                </div>

            </div>

        </>
    )

}