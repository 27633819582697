import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, get, remove, save, update } from "../services/Service";
import { TipoDocumento } from "../types/TipoDocumento";
import { addTipoDocumento, loadDataFiltroTipoDocumento, loadDataTipoDocumento, loadListTipoDocumento, setFormatos, setFormatosSelected, tipoDocumentoInitial } from "../context/TipoDocumento/TipoDocumentoReducer";


export const useTipoDocumento = () => {
    const BASE_URL = 'tiposDocumentos';
    const { loading, tipoDocumentoSelected, errors, error, tipoDocumentoList, filtro, formatos, formatosSelected } = useSelector(
        (state: RootState) => state.tipoDocumento
    );

    const dispatch = useDispatch();

    const buscarTiposDocumentos = async (tipoDocumento: TipoDocumento) => {
        let url = tipoDocumento.activo === null ? `${BASE_URL}/findBy?nombre=${tipoDocumento.nombre}` : `${BASE_URL}/findBy?nombre=${tipoDocumento.nombre}&activo=${tipoDocumento.activo}`

        await findAll(url)
            .then((response) => {
                dispatch(loadListTipoDocumento(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const guardarTipoDocumento = async (tipoDocumento: TipoDocumento) => {
        let response;
        try {
            if (tipoDocumento.id === 0) {
                response = await save(BASE_URL, tipoDocumento);
                dispatch(addTipoDocumento(response.data));
            } else {
                response = await update(BASE_URL, tipoDocumento);
                dispatch(addTipoDocumento(response.data));
            }

            return response;
        } catch (error: any) {
            throw error;
        }
    }

    const buscarFormatos = async () => {
        let response;
        try {
            response = await findAll(`${BASE_URL}/tipos-formato`);

            const treeData = [
                {
                    nombre: 'Extensiones Archivos',
                    id: 'parent',
                    child: [
                        ...response.data
                    ],
                },
            ];
            dispatch(setFormatos(treeData));

        } catch (error: any) {
            console.log(error);
        }
    }


    const buscarTipoDocumento = async (id: string) => {
        try {
            const response = await get(BASE_URL, id);
            console.log(response.data)
            dispatch(loadDataTipoDocumento(response.data));
            dispatch(setFormatosSelected(response.data.formatos?.map((p: any) => (p.id))));
            // dispatch(setFormatosSelected(response.data.))
            return response.data;
        } catch (error) {
            console.log(error);
        }

    }

    const eliminarTipoDocumento = async (id: string) => {
        try {
            const response = await remove(BASE_URL, id)
            return response;

        } catch (error) {
            console.log(error);
        }
    }

    const handleFormatosSelected = (formatos: any) => {
        dispatch(setFormatosSelected(formatos))
    }

    const handleTipoDocumentodSelected = (tipoDocumento: TipoDocumento) => {
        dispatch(loadDataTipoDocumento(tipoDocumento))
    }

    const handleTipoDocumentoList = (tipos: TipoDocumento[]) => {
        dispatch(loadListTipoDocumento(tipos))
    }

    const handleFiltroTipoDocumento = (tipoDocumento: TipoDocumento) => {
        dispatch(loadDataFiltroTipoDocumento(tipoDocumento))
    }
    return {
        buscarTipoDocumento,
        guardarTipoDocumento,
        handleTipoDocumentodSelected,
        buscarTiposDocumentos,
        eliminarTipoDocumento,
        handleTipoDocumentoList,
        handleFiltroTipoDocumento,
        buscarFormatos,
        handleFormatosSelected,
        tipoDocumentoInitial,
        tipoDocumentoSelected,
        tipoDocumentoList,
        loading,
        errors,
        error,
        filtro,
        formatos,
        formatosSelected
    }
}