import React, { useEffect, useRef, useState } from 'react'
import FormInput from '../../../../components/Form/FormInput'
import { ToastContainer, toast } from 'react-toastify'
import { Breadcrumbs } from '../../../Home/Breadcrumb'
import FormSelectBox from '../../../../components/Form/FormSelectBox'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Accordion from '../../../../components/Accordion'
import Confirm from '../../../../components/Confirm'
import { useTipoDocumento } from '../../../../hooks/useTipoDocumento'
import { TipoDocumento } from '../../../../types/TipoDocumento'
import { Button } from 'react-bootstrap'
import { downloadFile, uploadFile } from '../../../../services/Service'
import { v4 as uuidv4 } from 'uuid';
import Tree from 'rc-tree'
import { TipoFormato } from '../../../../types/TipoFormato'
import { Documento } from '../../../../types/Documento'


export type FormTipoDocErrors = {
    nombre?: string;
    descripcion?: string,
    sizeMaximo?: string,
    telefono?: string,
    idTipoDocumento?: string,
    nombrePlantilla?: string,
    plantilla?: string,
    formatos?: string
};

const Icon = () => (
    <span className='fa fa-folder' style={{ color: 'gray' }} />
);

export const CrearTipoDocumento = (props: FormTipoDocErrors) => {
    const { tipoDocumentoInitial, tipoDocumentoSelected, guardarTipoDocumento, buscarTipoDocumento, handleTipoDocumentodSelected, handleFormatosSelected,
        buscarTiposDocumentos, buscarFormatos, formatos, formatosSelected, filtro } = useTipoDocumento();

    const [tipoDocumentoForm, setTipoDocumentoForm] = useState<TipoDocumento>(tipoDocumentoInitial);
    const [formErrors, setFormErrors] = useState<FormTipoDocErrors>({
        nombre: '',
        descripcion: '',
        sizeMaximo: '',
        telefono: '',
        idTipoDocumento: '',
        nombrePlantilla: '',
        plantilla: '',
        formatos: ''
    });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [edit, setEdit] = useState<boolean>(false);
    const [view, setView] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);
    const [estados] = useState<any>(
        [

            {
                'id': true,
                'valor': 'ACTIVO'
            },
            {
                'id': false,
                'valor': 'INACTIVO'
            }
        ]);
    const [sizes] = useState<any>(
        [
            { 'id': 1, 'valor': '1' },
            { 'id': 2, 'valor': '2' },
            { 'id': 3, 'valor': '3' },
            { 'id': 4, 'valor': '4' },
            { 'id': 5, 'valor': '5' },
            { 'id': 6, 'valor': '6' },
            { 'id': 7, 'valor': '7' },
            { 'id': 8, 'valor': '8' },
            { 'id': 9, 'valor': '9' },
            { 'id': 10, 'valor': '10' }
        ]);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        buscarFormatos();
        if (id) {
            buscarTipoDocumento(id);
            setEdit(true);
            const TipoAction = searchParams.get("action");
            if (TipoAction === 'view') {
                setView(true);
            }
        } else {
            handleTipoDocumentodSelected(tipoDocumentoInitial);
            handleFormatosSelected([]);

        }

    }, [])

    useEffect(() => {
        setTipoDocumentoForm(tipoDocumentoSelected);

    }, [tipoDocumentoSelected])


    const onChange = (e: any) => {
        const { name, value } = e.target;
        setTipoDocumentoForm({
            ...tipoDocumentoForm,
            [name]: value
        })
        const errors = validateForm({
            ...tipoDocumentoForm,
            [name]: value
        })
        setFormErrors(errors)
    }

    const validateForm = (tipoDocumento: TipoDocumento, isGuardar: boolean = false) => {
        const errors: FormTipoDocErrors = {};
        if (!tipoDocumento.nombre) {
            errors.nombre = "El campo Nombre es obligatorio"
        }
        if (!tipoDocumento.descripcion) {
            errors.descripcion = "El campo Descripción es obligatorio"
        }
        if (!tipoDocumento.sizeMaximo) {
            errors.sizeMaximo = "El campo Tamaño máximo del archivo es obligatorio"
        }
        if (isGuardar) {
            if (tipoDocumento.formatos.length === 0) {
                errors.formatos = 'Debe indicar al menos un tipo de formato';
                toast.error('Debe indicar al menos un tipo de formato');
            }
        }

        // if (selectedFile) {
        //     var totalSizeMB = selectedFile.size / Math.pow(1024, 2);
        //     if (totalSizeMB > tipoDocumento.sizeMaximo) {
        //         toast.error(`El tamaño del archivo ${selectedFile.name} no puede superar los ${tipoDocumento.sizeMaximo} MB`)
        //         errors.plantilla = `El tamaño del archivo ${selectedFile.name} no puede superar los ${tipoDocumento.sizeMaximo} MB`
        //     }
        // }

        setFormErrors(errors);
        return errors;
    }

    const handleGuardar = () => {
        const tiposFormatos: TipoFormato[] = formatosSelected.map((f) => ({
            id: f,
            nombre: ''

        })).filter(f => f.id !== 'parent');

        const errores = validateForm({
            ...tipoDocumentoForm,
            formatos: tiposFormatos,
        }, true);
        if (Object.keys(errores).length === 0) {

            guardarTipoDocumento({
                ...tipoDocumentoForm,
                formatos: tiposFormatos,

            }).then(response => {
                if (response.data) {

                    if (selectedFile) {
                        const uniqueId: string = uuidv4();
                        uploadFile('tiposDocumentos/subirPlantilla',
                            selectedFile, { idTipoDocumento: Number(response.data.id), fileId: uniqueId, sizeMaximo: 20 })
                            .then((result: any) => {
                                if (result) {
                                    toast.success("Se guardó el Tipo de Documento correctamente")
                                    buscarTiposDocumentos(filtro);
                                    navigate('/tipoDocumento');

                                }

                            }).catch((error: any) => {
                                if (error.response.status === 413) {
                                    toast.error(error.response.data.message)
                                }
                            });
                    } else {
                        toast.success("Se guardó el Tipo de Documento correctamente");
                        buscarTiposDocumentos(filtro);
                        navigate('/tipoDocumento');
                    }

                }
            }
            ).catch((err: any) => toast.error(err.response.data.message))
        }
    }
    const handleCancelar = (e: any) => {
        e.preventDefault();
        setShowModal(true)
    }
    const handleVolver = () => {
        navigate('/tipoDocumento')
    }
    const cancelar = () => {
        toggle();
        navigate('/tipoDocumento')
    }
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Simula hacer clic en el input file
        }
    };
    const handleButtonDescargar = () => {
        let data: Documento = {
            id: 0,
            idTipoDocumento: 0,
            nombreTipoDocumento: '',
            idSolicitud: 0,
            fileId: tipoDocumentoSelected.contenidoPlantilla!,
            subido: false,
            ubicacion: '',
            nombreArchivo: tipoDocumentoSelected.nombrePlantilla!,
            fechaVencimiento: '',
            formatoArchivo: '',
            statusDocumento: '',
            observacion: ''
        }
        downloadFile('tiposDocumentos/descargarArchivo', data)

    }
    const fieldNames = {
        children: 'child',
        title: 'nombre',
        key: 'id',
    };

    const handleFileChange = () => {
        if (fileInputRef.current?.files && fileInputRef.current.files[0]) {
            const file = fileInputRef.current.files[0];
            setSelectedFile(file);
        }
    };
    const onCheck = (checkedKeys: any, info: any) => {
        handleFormatosSelected([...checkedKeys])

    };

    return (
        <>
            <ToastContainer />

            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title={edit ? (view ? 'Vista' : 'Editar') : 'Nuevo'}
                            titlePage={view ? 'Vista Tipos de Documentos' : 'Tipos de Documentos'}
                            nameFirst={'Configuración'}
                            urlFirst={'/'}
                            nameSecond={'Tipos de Documentos'}
                            urlSecond={'/tipoDocumento'}
                        />
                        <Confirm isOpen={showModal} toggle={toggle} title='Cancelar' yesFn={cancelar}>
                            <div className="form-horizontal">
                                <div className="row">
                                    {<p>Desea salir sin guardar el Tipo de Documento?</p>}
                                </div>
                            </div>
                        </Confirm>
                        <Accordion id={'tipoDocumento'} title="Tipo de Documento">
                            <div className="row mt-4">
                                <FormInput
                                    id={'nombre'}
                                    label={'Nombre'}
                                    name={'nombre'}
                                    // placeholder={'Razón Social'}
                                    value={tipoDocumentoForm.nombre || ''}
                                    isDisabled={edit || view}
                                    onChange={onChange}
                                    errorText={formErrors.nombre}
                                    maxLength={255}
                                />
                                <FormInput
                                    id={'descripcion'}
                                    label={'Descripción'}
                                    name={'descripcion'}
                                    // placeholder={'Razón Social'}
                                    value={tipoDocumentoForm.descripcion || ''}
                                    isDisabled={edit && view}
                                    onChange={onChange}
                                    errorText={formErrors.descripcion}
                                    maxLength={255}
                                />

                            </div>
                            <div className="row mt-2">
                                <FormSelectBox
                                    title={'Tamaño máximo del archivo (en Mb)'}
                                    label={'Tamaño máximo del archivo (en Mb)'}
                                    id={'sizeMaximo'}
                                    name={'sizeMaximo'}
                                    onChange={onChange}
                                    optionId={'id'}
                                    optionLabel={'valor'}
                                    value={tipoDocumentoForm.sizeMaximo || ''}
                                    options={sizes}
                                    isDisabled={edit && view}
                                    errorText={formErrors.sizeMaximo}
                                />

                                <FormSelectBox
                                    title={'Estado'}
                                    label={'estado'}
                                    id={'activo'}
                                    name={'activo'}
                                    onChange={onChange}
                                    optionId={'id'}
                                    optionLabel={'valor'}
                                    value={tipoDocumentoForm.activo || ''}
                                    options={estados}
                                    isDisabled={edit && view}
                                />

                            </div>
                            <div className="row mt-2">
                                {/* <div className="col-md-4 text-end"> */}
                                <label className={`col-form-label col-2 text-end`}>
                                    Plantilla
                                </label>
                                <input
                                    type="file"
                                    accept={'.jpg,.png,.pdf,.doc,.xls'}
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                />

                                <div className='col-4'>
                                    <div className='input-group'>
                                        {selectedFile ? (

                                            <input
                                                type="text"
                                                // style={{ width: '200px', height: '35px' }}
                                                value={selectedFile.name}
                                                className='form-control'
                                                readOnly // Esto hace que el campo sea no editable
                                            />
                                        ) : (

                                            <input
                                                type="text"
                                                // style={{ width: '200px', height: '35px' }}
                                                value={tipoDocumentoForm.nombrePlantilla ? tipoDocumentoForm.nombrePlantilla : "Ningún archivo seleccionado"} // Texto por defecto
                                                readOnly // Esto hace que el campo sea no editable
                                                className='form-control'
                                            />

                                        )}
                                        {!view &&
                                            <Button
                                                className="btn btn-outline-dark"
                                                variant="light"
                                                onClick={handleButtonClick}
                                            >
                                                Examinar
                                            </Button>}
                                        {view &&
                                            <Button
                                                className="btn btn-outline-dark"
                                                variant="light"
                                                onClick={handleButtonDescargar}
                                                disabled={tipoDocumentoForm.nombrePlantilla === null || tipoDocumentoForm.nombrePlantilla === ''}
                                            >
                                                Descargar
                                            </Button>}


                                    </div>
                                </div>
                                {/* <div className="col-md-6" style={{ float: 'none', paddingLeft: '120px' }}> */}
                                <label htmlFor="url" style={{ color: "grey" }} className={`col-form-label col-2 text-end`}>
                                    Extensiones archivos
                                </label>
                                <div className='col-4'>
                                    <div className='input-group'>
                                        <Tree
                                            checkable
                                            fieldNames={fieldNames}
                                            defaultExpandedKeys={['parent']}

                                            onCheck={onCheck}
                                            treeData={formatos}
                                            icon={Icon}
                                            checkedKeys={formatosSelected}
                                            expandedKeys={['parent']}
                                            disabled={view}
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">

                            </div>

                        </Accordion>
                        <br />

                        <div className="text-center">
                            {!view &&
                                <>
                                    <button className="btn btn-secondary mx-2" onClick={handleCancelar}>Cancelar</button>
                                    <button className="btn btn-success mx-2" onClick={handleGuardar}>Guardar</button>
                                </>
                            }
                            {view &&
                                <button className="btn btn-secondary mx-2" onClick={handleVolver}>Volver</button>
                            }
                        </div>

                    </div>
                </div>

            </div>

        </>
    )

}