import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MyModal from '../../../../../components/Modal'
import { useContratista } from '../../../../../hooks/useContratista'
import { Loading } from '../../../../../components/Loading'
import Table, { ColumnDefinitionType } from '../../../../../components/Table'
import { TrabajoEmpresa } from '../../../../../types/TrabajoEmpresa'
import FormSelectBox from '../../../../../components/Form/FormSelectBox'
import { cargarCombo } from '../../../../../services/Service'
import Confirm from '../../../../../components/Confirm'
import { toast } from 'react-toastify'


type DocumentosProps = {
    trabajosEmpresas: TrabajoEmpresa[],
    setTrabajosEmpresas: (value: TrabajoEmpresa[]) => void;
    edit: boolean,
    view: boolean
}

const filtroInitial: TrabajoEmpresa = {
    id: 0,
    idEmpresaGestora: 0,
    idTipoTrabajo: 0,
    empresaGestora: '',
    tipoTrabajo: ''

}


export const SeccionTrabajoEmpresa = ({ edit, view, trabajosEmpresas, setTrabajosEmpresas }: DocumentosProps) => {
    const { loading } = useContratista();
    const [formTrabajoEmpresa, setFormTrabajoEmpresa] = useState<TrabajoEmpresa>(filtroInitial);
    const [empresasGestoras, setEmpresasGestoras] = useState<any>({});
    const [tiposTrabajo, setTiposTrabajo] = useState<any>({});
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);
    const [data, setData] = useState<TrabajoEmpresa>(filtroInitial);

    useEffect(() => {
        cargarCombo('empresasGestoras/current', setEmpresasGestoras);
        // cargarCombo('tiposTrabajos', setTiposTrabajo);

    }, []);

    const eliminar = () => {

        let trabajos: TrabajoEmpresa[] = []
        for (const existingTrabajo of trabajosEmpresas) {
            const trabajoAEliminar = existingTrabajo.idEmpresaGestora === data.idEmpresaGestora && existingTrabajo.idTipoTrabajo === data.idTipoTrabajo;

            if (!trabajoAEliminar) {
                trabajos.push(existingTrabajo);
            }
        }

        setTrabajosEmpresas(trabajos)
        toggle();
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name === 'idEmpresaGestora') {
            cargarCombo(`trabajosEmpresa/empresaGestora/${value}`, setTiposTrabajo)
        }
        setFormTrabajoEmpresa({
            ...formTrabajoEmpresa,
            [name]: value,
        });
    };

    const columns: ColumnDefinitionType<TrabajoEmpresa, keyof TrabajoEmpresa>[] = []
    columns.push({
        key: 'tipoTrabajo',
        header: 'Tipo de Trabajo',
    });

    columns.push({
        key: 'empresaGestora',
        header: 'Empresa Gestora',
    });
    if (!view) {
        columns.push({
            key: 'id',
            header: 'Acciones',
            cell: (data: TrabajoEmpresa) =>
                <>

                    <Link to="#" title="eliminar"
                        onClick={(e) => {
                            e.stopPropagation();
                            setData(data);
                            setShowModal(true);

                        }}
                    >
                        <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                    </Link>

                </>
        });
    }

    const actualizarTrabajosEmpresas = () => {

        if (formTrabajoEmpresa.idEmpresaGestora != 0 && formTrabajoEmpresa.idTipoTrabajo != 0) {

            const trabajoEmpresa: TrabajoEmpresa | undefined = trabajosEmpresas.find(trabajoEmpresa => trabajoEmpresa.idEmpresaGestora == formTrabajoEmpresa.idEmpresaGestora && trabajoEmpresa.idTipoTrabajo == formTrabajoEmpresa.idTipoTrabajo)

            if (!trabajoEmpresa) {

                const trabajoEmp = tiposTrabajo.find((tt: { idTipoTrabajo: number }) => tt.idTipoTrabajo == formTrabajoEmpresa.idTipoTrabajo)
                // const empresaGestora = empresasGestoras.find((eg: { id: number }) => eg.id == formTrabajoEmpresa.idEmpresaGestora)

                setTrabajosEmpresas([...trabajosEmpresas, trabajoEmp])
            } else {
                toast.error("No se puede duplicar el Tipo de Trabajo");
            }
        }
    }


    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar el tipo de trabajo {data.tipoTrabajo}?</p>}
                    </div>
                </div>
            </Confirm>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        {!view &&
                            <>
                                <div className="row">
                                    <FormSelectBox
                                        title={'Empresa Gestora'}
                                        label={'empresa gestora'}
                                        id={'empresaGestora'}
                                        name={'idEmpresaGestora'}
                                        onChange={handleChange}
                                        optionId={'id'}
                                        optionLabel={'nombreEmpresa'}
                                        value={formTrabajoEmpresa.idEmpresaGestora || ''}
                                        options={empresasGestoras}
                                        isDisabled={edit && view}

                                    />
                                </div>
                                <div className="row mt-3">
                                    <FormSelectBox
                                        title={'Tipo de Trabajo'}
                                        label={'Tipo de Trabajo'}
                                        id={'tipoTrabajo'}
                                        name={'idTipoTrabajo'}
                                        onChange={handleChange}
                                        optionId={'idTipoTrabajo'}
                                        optionLabel={'tipoTrabajo'}
                                        value={formTrabajoEmpresa.idTipoTrabajo || ''}
                                        options={tiposTrabajo}
                                        isDisabled={edit && view}
                                    />

                                </div>
                            </>
                        }


                        <h4 className="m-t-0 header-title">
                            {/* <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i> */}


                            {!view &&
                                <Link to="#" title="Agregar" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        actualizarTrabajosEmpresas();
                                    }}>
                                    <i className="fa fa-plus margin-right-10"></i> Agregar
                                </Link>
                            }
                        </h4>

                        <div className="table-responsive">
                            <br />

                            {loading ? <Loading /> : <Table data={trabajosEmpresas || []} columns={columns}></Table>}
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}
