import React, { Dispatch, useEffect, useState } from 'react'
import { useUsuarios } from '../../../../../hooks/useUsuarios';
import { cargarCombo } from '../../../../../services/Service';
import FormInput from '../../../../../components/Form/FormInput';
import FormSelectBox from '../../../../../components/Form/FormSelectBox';


const usuarioFilterIni = {
    nombre: '',
    apellido: '',
    nombreUsuario: '',
    tipoDocumento: '',
    numeroDocumento: '',
    mail: '',
    tipoUsuarioValor: '',
    tipoUsuarioId: '',
    idContratista: '',
    contratista: '',
    idEmpresaGestora: '',
    idTipoDocumento: '',
    idTipoUsuario: '',
    empresaGestora: '',
    estado: 'true'
}

interface BuscarProps {
    setGuardado: React.Dispatch<React.SetStateAction<any>>;
}
export const BuscarUsuario: React.FC<BuscarProps> = ({ setGuardado }) => {
    const { buscarUsuarios } = useUsuarios();
    const [filtroUsuario, setFiltroUsuario] = useState<any>(usuarioFilterIni);
    const [tiposDocumentos, setTiposDocumentos] = useState<any>([]);
    const [tiposUsuarios, setTiposUsuarios] = useState<any>([]);
    const [disableEG, setDisableEG] = useState<boolean>(true);
    const [disableContratista, setDisableContratista] = useState<boolean>(true);
    const [estados] = useState<any>(
        [
            {
                'id': 'true',
                'valor': 'Activo'
            },
            {
                'id': 'false',
                'valor': 'Inactivo'
            }
        ]);
    const [empresasGestoras, setEmpresasGestoras] = useState<any>({});
    const [contratistas, setContratistas] = useState<any>({});

    useEffect(() => {
        buscarUsuarios(filtroUsuario);
        cargarCombo('tiposDocPersona', setTiposDocumentos);
        cargarCombo('tiposUsuarios', setTiposUsuarios);
        cargarCombo('empresasGestoras/current', setEmpresasGestoras);
        cargarCombo('contratistas/current', setContratistas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChange = (e: any) => {
        setGuardado(false);
        e.preventDefault();
        setFiltroUsuario({
            ...filtroUsuario,
            [e.target.name]: e.target.value,
        });

        handleTipoUsuarioSelected(e);
    };

    const handleSubmit = (e: any) => {
        setGuardado(false);
        e.preventDefault();
        buscarUsuarios(filtroUsuario);

    }

    const handleClean = () => {
        setFiltroUsuario(usuarioFilterIni);
        setGuardado(false);
    };

    function handleTipoUsuarioSelected(e: any) {
        if (e.target.name === 'idTipoUsuario') {
            setFiltroUsuario({
                ...filtroUsuario,
                "idEmpresaGestora": '',
                "idContratista": '',
                [e.target.name]: e.target.value,
            });

            setDisableContratista(true);
            setDisableEG(true);
            const tipoUsuario = tiposUsuarios.filter((tu: any) => tu.id == e.target.value);
            if (tipoUsuario.length > 0) {

                if (tipoUsuario[0].valor === 'EMPRESA_GESTORA') {
                    setDisableContratista(true);
                    setDisableEG(false);
                } else if (tipoUsuario[0].valor === 'CONTRATISTA') {
                    setDisableContratista(false);
                    setDisableEG(true);
                }
            }
        }
    }



    return (
        <div className="card">
            <form id="dto" className="form-horizontal" action="buscarUsuarios" method="post">
                <div className="card-body">
                    <h4 className="m-t-0 header-title">
                        <i className="fa fa-search margin-right-7" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                        Buscar
                    </h4>
                    <hr />
                    <div className="row mt-3">
                        <FormInput
                            id={'nombre'}
                            label={'Nombre'}
                            name={'nombre'}
                            placeholder={'Nombre'}
                            onChange={handleChange}
                            value={filtroUsuario.nombre}


                        />
                        <FormInput
                            id={'apellido'}
                            label={'Apellido'}
                            name={'apellido'}
                            placeholder={'Apellido'}
                            onChange={handleChange}
                            value={filtroUsuario.apellido}

                        />
                    </div>
                    <div className="row mt-2">
                        <FormSelectBox
                            title={'Tipo documento'}
                            label={'tipo documento'}
                            id={'tipoDocumento'}
                            name={'idTipoDocumento'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'tipoDocumento'}
                            value={filtroUsuario.idTipoDocumento}
                            options={tiposDocumentos}
                        />
                        <FormInput
                            id={'numeroDocumento'}
                            label={'Nro. Documento'}
                            name={'numeroDocumento'}
                            placeholder={'Nro. Documento'}
                            onChange={handleChange}
                            value={filtroUsuario.numeroDocumento}

                        />
                    </div>
                    <div className="row mt-2">
                        <FormInput
                            id={'nombreUsuario'}
                            label={'Nombre Usuario'}
                            name={'nombreUsuario'}
                            placeholder={'Nombre Usuario'}
                            onChange={handleChange}
                            value={filtroUsuario.nombreUsuario}
                        />
                        <FormSelectBox
                            title={'Estado'}
                            label={'estado'}
                            id={'idEstado'}
                            name={'estado'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'valor'}
                            value={filtroUsuario.estado}
                            options={estados}
                        />


                    </div>
                    <div className="row mt-2">
                        <FormSelectBox
                            title={'Tipo de Usuario'}
                            label={'tipo de usuario'}
                            id={'tipoUsuario'}
                            name={'idTipoUsuario'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'nombre'}
                            value={filtroUsuario.idTipoUsuario}
                            options={tiposUsuarios}
                        />
                    </div>

                    <div className="row mt-2">
                        <FormSelectBox
                            title={'Empresa Gestora'}
                            label={'empresa gestora'}
                            id={'empresaGestora'}
                            name={'idEmpresaGestora'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'nombreEmpresa'}
                            value={filtroUsuario.idEmpresaGestora}
                            options={empresasGestoras}
                            isDisabled={disableEG}
                        />
                        <FormSelectBox
                            title={'Contratista'}
                            label={'contratista'}
                            id={'contratista'}
                            name={'idContratista'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'razonSocial'}
                            value={filtroUsuario.idContratista}
                            options={contratistas}
                            isDisabled={disableContratista}
                        />
                    </div>

                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-center">

                            <button className="btn btn-secondary btn-rounded waves-effect waves-light mr-1" type="button" id="limpiarFiltro" onClick={handleClean}>
                                <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                                Limpiar
                            </button>
                            <button className="btn btn-success btn-rounded waves-effect waves-light " type="submit" onClick={handleSubmit}>
                                <i className="fa fa-search mr-1"></i>
                                Buscar
                            </button>

                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}


