import FormInput from "../../../../../components/Form/FormInput"
import moment from 'moment'
import Table, { ColumnDefinitionType } from "../../../../../components/Table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyModal from "../../../../../components/Modal";
import { useDocumento } from "../../../../../hooks/useDocumento";
import { Historial } from "../../DocumentosAdjuntos/historial";
import { Button } from "react-bootstrap";
import { useEquipoAndMaquinaria } from "../../../../../hooks/useEquipoAndMaquinaria";
import { DocEquipamientoSolicitud } from "../../../../../types/DocEquipamientoSolicitud";
import { downloadAndOpenFile, downloadFile } from '../../../../../services/Service'
import ObservacionComponent from "../../../../../components/Observaciones";
import { OBSERVADA_STATUS_ID } from "../../../../../constants/constants";
import { useUserLogin } from "../../../../../hooks/useUserLogin";
import { useSolicitud } from "../../../../../hooks/useSolicitud";

interface DetailEquipamientoSolicitudProps {
    onClose: () => void;
}
const DetailEquipamientoSolicitud: React.FC<DetailEquipamientoSolicitudProps> = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIdTipoDocumento, setSelectedIdTipoDocumento] = useState<number>(0); // Estado para mantener el ID seleccionado
    const toggle = () => setIsOpen(!isOpen);
    const { documentEquipSolicitudes, equipamientoSolicitudSelected } = useEquipoAndMaquinaria();
    const { historialDocumentos, buscarDocumentosByIdTipoDocumento, handleDocumentoSelected, documentoInitial, documentoSelected } = useDocumento();
    const { isContratistaTipoUsuario } = useUserLogin();
    const { solicitudSelected } = useSolicitud();
    const handleDocumento = async (id: number) => {
        setSelectedIdTipoDocumento(id);
        toggle();
    }
    const getDocumentoSelected = (id: number) => {
        if (id) {
            const doc = historialDocumentos.find(d => d.idTipoDocumento === id) || documentoInitial;
            handleDocumentoSelected(doc);
            toggle();
            setSelectedIdTipoDocumento(0);
        }
    }

    const [isOpenModalObservacionDocument, setIsOpenModalObservacionDocument] = useState(false)

    /*useEffect(() => {
        // Dependiendo de cómo se use historialDocumentos, podrías necesitar un efecto para manejar su actualización
        // Realiza las acciones que necesites con el historialDocumentos actualizado aquí
        getDocumentoSelected(selectedIdTipoDocumento);
    }, [historialDocumentos]);*/

    const columns: ColumnDefinitionType<DocEquipamientoSolicitud, keyof DocEquipamientoSolicitud>[] = []
    columns.push({
        key: 'tipoDocumento',
        header: 'Tipo de Documento',
    });
    columns.push({
        key: 'nombreArchivo',
        header: 'Nombre',
    });
    columns.push({
        key: 'fechaVencimiento',
        header: 'F. Vencimiento',
        cell: (data: DocEquipamientoSolicitud) => <>{data.fechaVencimiento ? moment(data.fechaVencimiento).format('DD/MM/YYYY') : ''}</>
    });
    if ((isContratistaTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID)) {
        columns.push({
            key: 'observacion',
            header: 'Observación',
            cell: (data: DocEquipamientoSolicitud) => <>
                <a href="#" title="Observaciones" onClick={() => {
                    handleDocumentoSelected(data);
                    setIsOpenModalObservacionDocument(true)
                }}>
                    <i className="fa fa-search me-2" style={{ color: 'red' }} aria-hidden="true"></i>
                </a>
            </>
        });
    }
    columns.push({
        key: 'id',
        header: 'Acciones',
        cell: (data: DocEquipamientoSolicitud) =>
            <>
                <Link title="Visualizar" to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadAndOpenFile('documento-equipamiento-solicitud/visualizarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                </Link>
                <Link title="Descargar" to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadFile('documento-equipamiento-solicitud/descargarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-arrow-down text-secondary me-2" aria-hidden="true"></i>
                </Link>
                <a href="#" title="Historial de Documentos" onClick={() => handleDocumento(data.idTipoDocumento)}>
                    <i className="fa fa-history text-secondary " aria-hidden="true"></i>
                </a>
            </>
    });
    columns.push({
        key: 'statusDocumento',
        header: '',
    });


    return (
        <>
            <MyModal
                isOpen={isOpenModalObservacionDocument} toggle={() => setIsOpenModalObservacionDocument(false)} title='Observaciones' cancelButton={false} secondButton={false}
            >
                <ObservacionComponent isEdit={false} observacion={documentoSelected.observacion} returnButtonFn={() => setIsOpenModalObservacionDocument(false)}
                ></ObservacionComponent>
            </MyModal>
            <MyModal isOpen={isOpen} toggle={toggle} title='Historial de Documentos'>
                <Historial tipoDocumentoDisabled={true}
                    pathService={`documento-equipamiento-solicitud/equipamiento-solicitud/${equipamientoSolicitudSelected.id}`}
                    idTipoDocumentoSelected={selectedIdTipoDocumento} />
            </MyModal>
            <div>
                <div className="row mt-12">
                    <FormInput
                        id={'nombre'}
                        label={'Equipo / Maquinaria'}
                        name={'nombre'}
                        value={equipamientoSolicitudSelected.nombre || ''}
                        isDisabled={true}
                        classColumn={'col-10'}
                    />

                </div>
                <div className="row mt-2">
                    <FormInput
                        id={'fechaIngreso'}
                        label={'Fecha Ingreso'}
                        name={'fechaIngreso'}
                        value={equipamientoSolicitudSelected.fechaIngreso ? moment(equipamientoSolicitudSelected.fechaIngreso).format('DD/MM/YYYY') : ''}
                        isDisabled={true}
                    />
                    <FormInput
                        id={'fechaEgreso'}
                        label={'Fecha Egreso'}
                        name={'fechaEgreso'}
                        value={equipamientoSolicitudSelected.fechaEgreso ? moment(equipamientoSolicitudSelected.fechaEgreso).format('DD/MM/YYYY') : ''}
                        isDisabled={true}
                    />
                </div>
            </div>
            <div className='p-3'>
                <div className="row">
                    <div className="col-md-12 text-end">
                        {/* <Button
                            disabled={true}
                            className="btn-secondary"
                            variant="secondary"
                        > Agregar Documentos
                        </Button> */}
                    </div>
                </div>
                <div className="table-responsive ">
                    <Table data={documentEquipSolicitudes} columns={columns} ></Table>
                </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
                <Button color="btn-info" onClick={onClose} className="mr-2">
                    Volver
                </Button>
            </div>
        </>
    )
}

export default DetailEquipamientoSolicitud