import { useState } from "react";
import { Usuario } from "../types/Usuario";
import { RootState } from "../app/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { findAll, get, remove, save, update, recuperarClave } from "../services/Service";
import { addUsuario, loadDataUsuario, loadListUsuario, loadingError, removeUsuario, setGuardado, setPerfiles, setPerfilesSelected, visibleError, usuarioInitial, isLoading } from "../context/Seguridad/UsuarioReducer";




export const useUsuarios = () => {
  const [usuarios] = useState<Usuario[]>([]);

  const BASE_URL = 'usuarios';
  const { loading, usuarioSelected, usuarioList, perfiles, errors, error, perfilesSelected, tiposUsuarios, guardado } = useSelector(
    (state: RootState) => state.usuario
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const buscarUsuarios = async (usuario: Usuario) => {
    await findAll(`${BASE_URL}/find?nombre=${usuario.nombre}&apellido=${usuario.apellido}&nombreUsuario=${usuario.nombreUsuario}&numeroDocumento=${usuario.numeroDocumento}&idTipoUsuario=${usuario.idTipoUsuario}&estado=${usuario.estado}&idTipoDocumento=${usuario.idTipoDocumento}&idEmpresaGestora=${usuario.idEmpresaGestora}&idContratista=${usuario.idContratista}`)
      .then((response) => {
        dispatch(loadListUsuario(response.data));
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const guardarUsuario = async (usuario: Usuario) => {
    let response;
    try {
      dispatch(isLoading(true))
      if (usuario.id === 0) {
        usuario.url = window.location.protocol + "//" + window.location.host
        response = await save(BASE_URL, usuario);
        dispatch(addUsuario(response.data));
      } else {
        response = await update(BASE_URL, usuario);
        dispatch(addUsuario(response.data));
      }
      navigate('/usuarios?updated=true')
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        dispatch(loadingError(error.response.data.errors));
      } else if (error.response && error.response.status === 500) {
        dispatch(loadingError(error.response.data.errors));
      } else {
        throw error;
      }
    }
  }

  const buscarUsuario = async (id: string, setCombos: (tipoUsuarioValor: string) => void) => {
    try {
      const response = await get(BASE_URL, id)
      dispatch(loadDataUsuario(response.data));
      dispatch(setPerfilesSelected(response.data.perfilesSelected?.map((p: any) => (p.id))));

      const treeData = [
        {
          descripcion: 'Perfiles',
          id: 'parent',
          child: [
            ...response.data.perfiles
          ],
        },
      ];
      dispatch(setPerfiles(treeData));
      setCombos(response.data?.tipoUsuarioValor);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  const eliminarUsuario = async (id: string) => {
    try {
      await remove(BASE_URL, id)
      dispatch(removeUsuario(Number(id)));

    } catch (error) {
      console.log(error);
    }
  }


  const buscarArbolPerfiles = async (idTipoUsuario: number) => {
    let response;
    try {
      response = await get('perfiles/tiposUsuarios', idTipoUsuario as unknown as string);
      // const tipoUsuario = response.data.filter((t: { id: number; }) => t.id === idTipoUsuario);

      const treeData = [
        {
          descripcion: 'Perfiles',
          id: 'parent',
          child: [
            ...response.data
          ],
        },
      ];
      dispatch(setPerfiles(treeData));

    } catch (error: any) {
      console.log(error);
    }
  }

  const recuperaClave = async (email: string) => {
    try {
      await recuperarClave(`${BASE_URL}/recuperarClave`, email);
      navigate("/login")

    } catch (error: any) {
      dispatch(loadingError([error.response.data.message]));
    }
  }

  const handleUsuarioSelected = (usuario: Usuario) => {
    dispatch(loadDataUsuario(usuario))
  }
  const showError = (load: boolean) => {
    dispatch(visibleError(load))
  }
  const setMsgError = (error: string[]) => {
    dispatch(loadingError(error));
  }
  const handlePerfilesSelected = (perfiles: any) => {
    dispatch(setPerfilesSelected(perfiles))
  }
  const handleGuardado = (guardado: boolean) => {
    dispatch(setGuardado(guardado))
  }


  return {
    usuarios,
    buscarUsuarios,
    guardarUsuario,
    buscarUsuario,
    eliminarUsuario,
    handleUsuarioSelected,
    showError,
    setMsgError,
    handlePerfilesSelected,
    handleGuardado,
    buscarArbolPerfiles,
    recuperaClave,
    usuarioInitial,
    usuarioList,
    usuarioSelected,
    perfiles,
    perfilesSelected,
    loading,
    errors,
    error,
    tiposUsuarios,
    guardado
  };
};
