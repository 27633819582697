import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll } from "../services/Service";
import { loadData, loadList, isLoading } from "../context/Historial/HistorialReducer";
import { HistorialSolicitud } from "../types/HistorialSolicitud";

export const useHistorial = () => {
    const BASE_URL = 'historial';

    const { historiales, historialSelected ,loading } = useSelector(
        (state: RootState) => state.historial
    );
    const dispatch = useDispatch();

    const buscarHistorialesByIdSolicitud = async (id: number) => {
        dispatch(isLoading(true))
        await findAll(`${BASE_URL}/solicitud/${id}`)
            .then((response) => {
                dispatch(loadList(response.data));
            })
            .catch((error) => {
                if (error.response?.status === 401) {

                }
            })
    }

    const setHistorialSelected = (data: HistorialSolicitud) => {
        dispatch(loadData(data))
    }

    const handleLoading = (load: boolean) => {
        dispatch(isLoading(load))
    }

    return {
        buscarHistorialesByIdSolicitud,
        setHistorialSelected,
        historiales,
        historialSelected,
        handleLoading,
        loading
    }

}