/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Accordion from '../../../../components/Accordion'
import Table, { ColumnDefinitionType } from '../../../../components/Table'
import { Link } from 'react-router-dom'
import { Documento } from '../../../../types/Documento'
import { useDocumento } from '../../../../hooks/useDocumento'
import { useSolicitud } from '../../../../hooks/useSolicitud'
import MyModal from '../../../../components/Modal'
import { Historial } from './historial'
import { downloadAndOpenFile, downloadFile, downloadFileParam } from '../../../../services/Service'
import moment from 'moment'
import { Loading } from '../../../../components/Loading'
import { useDocumentoTrabajo } from '../../../../hooks/useDocumentoTrabajo'
import { Button } from 'react-bootstrap'
import { DocumentoTrabajo } from '../../../../types/DocumentoTrabajo'
import { AgregarDocumento } from './agregar'
import { toast } from 'react-toastify';
import ObservacionComponent from '../../../../components/Observaciones'
import { APROBADA_STATUS_ID, EN_ACTUALIZACION_STATUS_ID, OBSERVADA_STATUS_ID, REVISION_STATUS_ID, SIN_VISAR_DOCUMENTO_STATUS } from '../../../../constants/constants'
import { useUserLogin } from '../../../../hooks/useUserLogin'



type DocumentosAdjuntosProps = {
    isDisabled?: boolean,
    isEdit?: boolean
};

const DocumentosAdjuntos: React.FC<DocumentosAdjuntosProps> = ({ isDisabled = false, isEdit = true }) => {
    const { solicitudSelected } = useSolicitud();
    const { buscarDocumentosTrabajoByTipoTrabajo, documentosTrabajo } = useDocumentoTrabajo();
    const { documentos, documentoSelected, buscarDocumentosByIdSolicitud, handleDocumentoSelected,
        documentoInitial, handleLoading, loading, deleteDocumento, updateDocumento, actualizarDocumentos } = useDocumento();
    const [cargarDocumento, setCargarDocumento] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAddNewDocument, setIsOpenAddNewDocument] = useState(false);
    const [isOpenModalObservacionDocument, setIsOpenModalObservacionDocument] = useState(false)
    const toggle = () => setIsOpen(!isOpen);
    const toggleAddNewDocument = () => setIsOpenAddNewDocument(!isOpenAddNewDocument);
    const [selectedIdTipoDocumento, setSelectedIdTipoDocumento] = useState<number>(0);
    const [isOpenModalDeleteDocument, setIsOpenModalDeleteDocument] = useState(false);
    const toggleModalDeleteDocument = () => setIsOpenModalDeleteDocument(!isOpenModalDeleteDocument);
    const [documentosListLocal, setDocumentosListLocal] = useState<any>([]);
    const { isContratistaTipoUsuario, isEmpresaGestoraTipoUsuario } = useUserLogin();
    const [saveRevision, setSaveRevision] = useState(false);

    useEffect(() => {
        if (documentos.length > 0) {
            setDocumentosListLocal(documentos.map(documento => ({
                id: documento.id,
                idTipoDocumento: documento.idTipoDocumento,
                tipoDocumento: documento.idTipoDocumento,
                nombreArchivo: documento.nombreArchivo,
                fileId: documento.fileId,
                subido: documento.subido,
                ubicacion: documento.ubicacion,
                fechaVencimiento: documento.fechaVencimiento,
                formatoArchivo: documento.formatoArchivo,
                nombreTipoDocumento: documento.nombreTipoDocumento,
                idSolicitud: solicitudSelected.id,
                archivoSeleccionado: null,
                statusDocumento: documento.statusDocumento,
                observacion: documento.observacion
            })));
        } else {
            setDocumentosListLocal([])
        }
    }, [documentos])



    useEffect(() => {
        if (saveRevision) {
            saveRevisionButtonFn();
        }

    }, [documentosListLocal])

    useEffect(() => {
        buscarDocumentosTrabajoByTipoTrabajo(solicitudSelected.idTipoTrabajo);
    }, [solicitudSelected.idTipoTrabajo])

    const handleDocumento = (id: number) => {
        toggle();
        setSelectedIdTipoDocumento(id);
        // buscarDocumentosByIdTipoDocumento(id);
        //getDocumentoSelected(id);
    }
    const getDocumentoSelected = (id: number) => {
        if (id) {
            const doc = documentos.find(d => d.idTipoDocumento === id) || documentoInitial;
            handleDocumentoSelected(doc);
        }
    }

    const handleaAddNewDocumentClick = () => {
        toggleAddNewDocument()
    }

    const handleConfirmDeleteDocument = () => {
        deleteDocumento(documentoSelected.id.toString())
            .then(() => {
                toast.success("se elimina correctamente el documento");
                buscarDocumentosByIdSolicitud(solicitudSelected.id);
                toggleModalDeleteDocument()
            })
    }

    const columns: ColumnDefinitionType<Documento, keyof Documento>[] = []
    columns.push({
        key: 'nombreTipoDocumento',
        header: 'Tipo de Documento',
    });

    columns.push({
        key: 'nombreArchivo',
        header: 'Nombre',
    });
    columns.push({
        key: 'fechaVencimiento',
        header: 'Vencimiento',
        cell: (data: Documento) => <>{data.fechaVencimiento ? moment(data.fechaVencimiento).format('DD/MM/YYYY') : ''}</>
    });
    if ((isContratistaTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID)) {
        columns.push({
            key: 'observacion',
            header: 'Observaciones',
            cell: (data: Documento) => <>
                {
                    data.observacion &&
                    <a href="#" title="Observaciones" onClick={() => {
                        handleDocumentoSelected(data);
                        setIsOpenModalObservacionDocument(true)
                    }}>
                        <i className="fa fa-search me-2" style={{ color: 'red' }} aria-hidden="true"></i>
                    </a>
                }
            </>
        });
    }

    columns.push({
        key: 'id',
        header: 'Acciones',
        cell: (data: Documento) =>
            <>
                <Link title='Visualizar' to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadAndOpenFile('documentos/visualizarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                </Link>
                <Link title='Descargar' to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadFile('documentos/descargarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-arrow-down text-secondary me-2" aria-hidden="true"></i>
                </Link>
                <a href="#" title="Historial de Documentos" onClick={() => handleDocumento(data.idTipoDocumento)}>
                    <i className="fa fa-history text-secondary me-2 " aria-hidden="true"></i>
                </a>
                {!isDisabled && solicitudSelected.idTipoEstado !== OBSERVADA_STATUS_ID && solicitudSelected.idTipoEstado !== APROBADA_STATUS_ID && solicitudSelected.idTipoEstado !== EN_ACTUALIZACION_STATUS_ID && <a href="#" title="Borrar Documento" onClick={() => {
                    handleDocumentoSelected(data);
                    toggleModalDeleteDocument()
                }}>
                    <i className="fa fa-trash text-secondary me-2" aria-hidden="true"></i>
                </a>}
                {((isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID)) &&
                    <a href="#" title="Observaciones" onClick={() => {
                        handleDocumentoSelected(data);
                        setIsOpenModalObservacionDocument(true)
                    }}>
                        <i className="fa fa-search text-secondary me-2" aria-hidden="true"></i>
                    </a>}
            </>
    });
    columns.push({
        key: 'statusDocumento',
        header: '',
        isEnabledCheckStatusDocument: isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID && isEdit,
    });


    const columnsDocumentoTrabajo: ColumnDefinitionType<DocumentoTrabajo, keyof DocumentoTrabajo>[] = []
    columnsDocumentoTrabajo.push({
        key: 'tipoDocumentoNombre',
        header: 'Tipo de Documento',
    });
    columnsDocumentoTrabajo.push({
        key: 'obligatorio',
        header: 'Obligatorio',
        customBooleanRender: (value) => {
            return value ? "Si" : "No";
        },
    });

    if (documentosTrabajo.some((el) => el.plantilla && el.plantilla !== '')) {

        columnsDocumentoTrabajo.push({
            key: 'id',
            header: 'Plantilla',
            cell: (data: DocumentoTrabajo) =>

                <>
                    {data.nombrePlantilla && data.nombrePlantilla !== '' &&
                        <Link to="/exportar" onClick={(e) => {
                            e.preventDefault();
                            downloadFileParam('tiposDocumentos/descargarArchivo', data.plantilla, (data.nombrePlantilla) || '')
                        }} target="_blank" >
                            <i className="fa fa-arrow-down text-secondary me-2" aria-hidden="true"></i>
                        </Link>
                    }
                </>
        });
    }


    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        if (cargarDocumento) {
            handleLoading(true);
            buscarDocumentosByIdSolicitud(solicitudSelected.id);
            setCargarDocumento(false);
            // buscarDocumentosTrabajoByTipoTrabajo(solicitudSelected.idTipoTrabajo)
        }

    }
    const onCheckboxChange = (data: Documento, status: string) => {

        setSaveRevision(true);
        setDocumentosListLocal((prevList: any) => {
            return prevList.map((item: any) => {
                if (data.id === item.id) {
                    // Modificamos solo el elemento deseado
                    return { ...item, statusDocumento: status };
                }
                // Mantenemos los demás elementos sin cambios
                return item;
            });
        });

    }

    const onObservacionChange = (observacion: string) => {
        setIsOpenModalObservacionDocument(false);
        let seleccionado: any = {};
        setDocumentosListLocal((prevList: any) => {
            return prevList.map((item: any) => {
                if (documentoSelected.id === item.id) {
                    // Modificamos solo el elemento deseado
                    return { ...item, observacion: observacion };
                }
                // Mantenemos los demás elementos sin cambios
                return item;
            });
        });

        updateDocumento('documentos', { ...documentoSelected, observacion: observacion })

    }

    const saveRevisionButtonFn = () => {
        const documentoUpdateStatus = [];
        for (const documento of documentosListLocal) {
            if (!documento.statusDocumento || documento.statusDocumento.trim() === SIN_VISAR_DOCUMENTO_STATUS) {
                // Si encuentra un documento sin statusDocumento, detener el bucle
                // toast.error("Debe chequear los documentos sin revisar");
                break;
            }
            documentoUpdateStatus.push({ id: documento.id, statusDocumento: documento.statusDocumento, observacion: documento.observacion });
        };

        actualizarDocumentos(documentoUpdateStatus)
            .then(respuesta => {
                //toast.success("Se actualizo los documentos revisados");
            })
            .catch(error => {
                toast.error('Error al actualizar los documentos revisados');
            });

    }
    const cancelRevisionButtonFn = () => {

    }
    return (
        <>
            <MyModal
                isOpen={isOpenModalObservacionDocument} toggle={() => setIsOpenModalObservacionDocument(false)} title='Observaciones' cancelButton={false} secondButton={false}
            >
                <ObservacionComponent observacion={documentoSelected.observacion} saveButtonFn={onObservacionChange} isEdit={isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID}
                    cancelButtonFn={() => setIsOpenModalObservacionDocument(false)} returnButtonFn={() => setIsOpenModalObservacionDocument(false)}
                ></ObservacionComponent>
            </MyModal>
            <MyModal isOpen={isOpenModalDeleteDocument} toggle={toggleModalDeleteDocument} title='Advertencia' cancelButton={true}
                secondButton={true} secondButtonTitle={'Aceptar'} titleCancelar={'Cancelar'} secondButtonFn={handleConfirmDeleteDocument} >
                <label>Confirma la eliminación del registro?</label>
            </MyModal>
            <MyModal isOpen={isOpen} toggle={toggle} title='Historial de Documentos' titleCancelar='Volver'>
                <Historial tipoDocumentoDisabled={true}
                    pathService={`documentos/solicitud/${solicitudSelected.id}`}
                    idTipoDocumentoSelected={selectedIdTipoDocumento} />
            </MyModal>
            <MyModal isOpen={isOpenAddNewDocument} toggle={toggleAddNewDocument} title='Agregar Documento' enabledFooter={false}>
                <AgregarDocumento cancelButtonFn={toggleAddNewDocument} saveCompletedButtonFn={toggleAddNewDocument} />
            </MyModal>
            {documentosTrabajo && documentosTrabajo.length > 0 &&
                <Accordion id={'documentosAdjuntos'} title="Documentos Adjuntos" collapsed={true} handleClick={handleClick} >

                    <div>
                        <div className='p-3'>
                            <div className="row">
                                <div className="col-md-12 text-start align-bottom" style={{ alignSelf: 'flex-end' }}>
                                    <span>Documentos Requeridos para la Solicitud</span>
                                </div>
                            </div>
                            <div className="table-responsive ">
                                {loading ? <Loading /> : <Table data={documentosTrabajo} columns={columnsDocumentoTrabajo} ></Table>}
                            </div>
                        </div>
                        <div className='p-3'>
                            <div className="row">
                                <div className="col-md-6 text-start align-bottom" style={{ alignSelf: 'flex-end' }}>
                                    <span>Documentos Adjuntados</span>
                                </div>
                                <div className="col-md-6 text-end">

                                    {!isDisabled && <Button
                                        className="btn-secondary"
                                        variant="secondary"
                                        onClick={() => handleaAddNewDocumentClick()}
                                    > Agregar Documento
                                    </Button>}
                                </div>
                            </div>
                            <div className="table-responsive ">
                                {loading ? <Loading /> : <Table data={documentosListLocal} columns={columns}
                                    onCheckboxChange={onCheckboxChange}></Table>}
                                {/* <div className="col-md-12 d-flex justify-content-end mt-3">
                                    {isEmpresaGestoraTipoUsuario() && solicitudSelected.idTipoEstado === REVISION_STATUS_ID && <div>
                                        <Button type="button" color="secondary" onClick={() => saveRevisionButtonFn()} className="mr-2">
                                            Guardar
                                        </Button>
                                        <Button type="button" color="secondary" onClick={() => cancelRevisionButtonFn()} className="mr-2">
                                            Cancelar
                                        </Button></div>}
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* ) : (<div>El tipo de trabajo seleccionado no requiere documentación</div>) */}

                </Accordion>
            }
        </>
    )
}

export default DocumentosAdjuntos

