import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { findAll, get, remove, update } from "../services/Service";
import { loadData, loadList, loadListHistorial, documentoInitial, isLoading } from "../context/Documento/DocumentoReducer";
import { Documento } from "../types/Documento";

export const useDocumento = () => {
    const BASE_URL = 'documentos';

    const { documentos, documentoSelected, historialDocumentos, loading } = useSelector(
        (state: RootState) => state.documento
    );
    const dispatch = useDispatch();


    const buscarDocumentos = async () => {
        await findAll(BASE_URL)
            .then((response) => {
                dispatch(loadList(response.data));
            })
            .catch((error) => {
                throw (error)
            })
    }

    const buscarDocumentosByIdSolicitud = async (id: number) => {
        dispatch(isLoading(true))
        await findAll(`${BASE_URL}/solicitud/${id}`)
            .then((response) => {
                dispatch(loadList(response.data));
            })
            .catch((error) => {
                throw (error)
            })
    }

    const buscarDocumentosByIdTipoDocumento = async (id: number) => {
        await findAll(`${BASE_URL}/tipoDocumento/${id}`)
            .then((response) => {
                dispatch(loadListHistorial(response.data));
            })
            .catch((error) => {
                throw (error)
            })
    }

    const buscarDocumentosByPathAndIdTipoDocumento = async (path: string, id: number) => {
        dispatch(loadListHistorial([]));
        await findAll(`${path}/tipoDocumento/${id}`)
            .then((response) => {
                dispatch(loadListHistorial(response.data));
            })
            .catch((error) => {
                throw (error)
            })
    }

    const buscarDocumento = async (id: string) => {
        await get(BASE_URL, id)
            .then((response) => {
                dispatch(loadData(response.data));
            })
            .catch((error) => {
                console.log(error.response?.status);
                throw (error)
            })
    }

    const deleteDocumento = async (id: string) => {
        await remove(BASE_URL, id)
            .then((response) => {
                return true;
            })
            .catch((error) => {
                console.log(error.response?.status);
                if (error.response?.status === 500) {

                }
                return false;
            })
    }


    const updateDocumento = async (path: string, documento: any) => {
        try {
            const result = await update(path, documento);
            return result;
        } catch (error) {
            console.log(error);
        }
    };

    const actualizarDocumentos = async (documentosListLocal: any[], path: string = 'documentos') => {
        try {
            const promesas = documentosListLocal.map(documento => updateDocumento(path, documento));

            // Esperar a que todas las actualizaciones se completen
            const resultados = await Promise.all(promesas);

            // Hacer algo con los resultados, si es necesario
            console.log('Resultados de las actualizaciones:', resultados);

            // Devolver una respuesta única o realizar otras operaciones
            return 'Todas las actualizaciones se completaron correctamente';
        } catch (error) {
            // Manejar errores generales aquí
            console.error('Error al actualizar documentos:', error);
            return 'Ocurrió un error al actualizar documentos';
        }
    };


    const handleDocumentoSelected = (documento: Documento) => {
        dispatch(loadData(documento))
    }

    const handleLoading = (load: boolean) => {
        dispatch(isLoading(load))
    }

    const existDocumentoByIdTypeDocumentAndIdSolicitud = async (idTypeTrabajo: number, idSolicitud: number) => {
        try {
            const response = await get(BASE_URL, `existeDocumento?tipoDocumentoId=${idTypeTrabajo}&solicitudId=${idSolicitud}`);
            return response.data;
        } catch (error) {
            //console.log(error.response?.status);
            return false;
        }
    }

    const puedeAdjuntar = async (idTypeTrabajo: number, idSolicitud: number) => {
        try {
            const response = await get(BASE_URL, `puedeAdjuntar?tipoDocumentoId=${idTypeTrabajo}&solicitudId=${idSolicitud}`);
            return response.data;
        } catch (error) {
            //console.log(error.response?.status);
            return false;
        }
    }


    return {
        buscarDocumentosByIdTipoDocumento,
        buscarDocumentosByPathAndIdTipoDocumento,
        historialDocumentos,
        documentos,
        buscarDocumentos,
        buscarDocumento,
        documentoSelected,
        handleDocumentoSelected,
        buscarDocumentosByIdSolicitud,
        documentoInitial,
        handleLoading,
        existDocumentoByIdTypeDocumentAndIdSolicitud,
        deleteDocumento,
        updateDocumento,
        actualizarDocumentos,
        loading,
        puedeAdjuntar
    }

}