import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import Table, { ColumnDefinitionType } from '../../../../../components/Table'
import { Loading } from '../../../../../components/Loading'
import { useUsuarios } from '../../../../../hooks/useUsuarios'
import { Usuario } from '../../../../../types/Usuario'
import Confirm from '../../../../../components/Confirm'


const TableUsuario = () => {
    const { usuarioList, loading, usuarioInitial, eliminarUsuario } = useUsuarios();
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggle = () => setShowModal(!showModal);
    const [data, setData] = useState<Usuario>(usuarioInitial);

    const eliminar = () => {
        eliminarUsuario(data.id as unknown as string);
        toggle();
    }

    const columns: ColumnDefinitionType<Usuario, keyof Usuario>[] = [
        {
            key: 'nombre',
            header: 'Nombre y Apellido',
            cell: (data: Usuario) => <>{data.nombre + ' ' + data.apellido}</>
        },
        {
            key: 'nombreUsuario',
            header: 'Nombre de Usuario',
        },
        {
            key: 'tipoDocumento',
            header: 'Tipo de Documento',
        },
        {
            key: 'numeroDocumento',
            header: 'Nro. Documento',
        },
        {
            key: 'tipoUsuarioValor',
            header: 'Tipo de Usuario',
        },
        {
            key: 'empresaGestora',
            header: 'Empresa Gestora',
        },
        {
            key: 'contratista',
            header: 'Contratista',
        },
        // {
        //     key: 'estado',
        //     header: 'Estado',
        //     cell: (data: TipoUsuario) => <>{data.estado ? 'Activo' : 'Inactivo'}</>
        // },

        {
            key: 'id',
            header: 'Acciones',
            cell: (data: Usuario) =>
                <>
                    <Link to={`/usuarios/editar/${data.id}`} title="Editar" >
                        <i className="fa fa-pencil text-custom" aria-hidden="true"></i>
                    </Link>
                    <Link to="#" title="eliminar"
                        onClick={(e) => {
                            e.stopPropagation();
                            setData(data);
                            setShowModal(true)

                        }}
                    >
                        <i className="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
                    </Link>

                </>
        },
    ]

    return (
        <>
            <Confirm isOpen={showModal} toggle={toggle} title='Eliminar' yesFn={eliminar}>
                <div className="form-horizontal">
                    <div className="row">
                        {<p>Está seguro que desea eliminar el Usuario {data.nombre + ' ' + data.apellido}?</p>}
                    </div>
                </div>
            </Confirm>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h4 className="m-t-0 header-title">
                                        <i className="fa fa-list-ul" aria-hidden="true" style={{ marginRight: "5px" }}></i>

                                        Listado de Usuarios
                                        <Link to={`/usuarios/nuevo`} title="Nuevo" className="btn-custom btn-rounded waves-effect waves-light pull-right btn-sm" style={{ marginTop: '-8px' }}>
                                            <i className="fa fa-plus margin-right-10"></i> Nuevo
                                        </Link>

                                    </h4>

                                </div>

                                <div className="table-responsive">
                                    <br />
                                    {loading ? <Loading /> : <Table data={usuarioList || []} columns={columns}></Table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default TableUsuario;