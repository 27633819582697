import { useEffect, useState } from "react";
import FormSelectBox from "../../../../components/Form/FormSelectBox";
import FormInput from "../../../../components/Form/FormInput";
import { useActividad } from "../../../../hooks/useActividad";
import { Actividad } from "../../../../types/Actividad";
import { Contratista } from "../../../../types/Contratista";
import { useContratista } from "../../../../hooks/useContratista";
import { cargarCombo } from "../../../../services/Service";

const filtroInitial: Contratista = {
    id: 0,
    razonSocial: "",
    direccion: "",
    email: "",
    tipoDocumento: "",
    activo: null,
    idEmpresaGestora: null,
    idTipoTrabajo: null,
    idTipoDocumento: null,
    nroIdentificacion: "",
    telefono: "",
    logo: null,
    logoNuevo: null,
    trabajosEmpresas: []
}
export const BuscarContratista = () => {
    const { contratistaInitial, buscarContratistas, handleFiltroContratista, filtro } = useContratista();
    const [filtroContratista, setFiltroContratista] = useState<Contratista>(filtro);
    const [empresasGestoras, setEmpresasGestoras] = useState<any>({});
    const [tiposTrabajo, setTiposTrabajo] = useState<any>({});
    const [tiposDocumentos, setTiposDocumentos] = useState<any>({});
    const [estados] = useState<any>(
        [

            {
                'id': 'true',
                'valor': 'ACTIVO'
            },
            {
                'id': 'false',
                'valor': 'INACTIVO'
            }
        ]);

    useEffect(() => {
        buscarContratistas(filtro);
        cargarCombo('empresasGestoras/current', setEmpresasGestoras);
        cargarCombo('tiposTrabajos', setTiposTrabajo);
        cargarCombo('tiposDocPersona', setTiposDocumentos)
    }, [])



    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault();
        setFiltroContratista({
            ...filtroContratista,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        buscarContratistas(filtroContratista);
        handleFiltroContratista(filtroContratista)
    }

    const handleClean = () => {
        setFiltroContratista(filtroInitial);
        handleFiltroContratista(filtroInitial);
    };

    return (

        <div className="card">
            <form id="dto" className="form-horizontal" action="buscar" method="post">
                <div className="card-body">
                    <h4 className="m-t-0 header-title">
                        <i className="fa fa-search margin-right-7" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                        Buscar
                    </h4>
                    <hr />

                    <div className="row mt-2">

                        <FormInput
                            id={'razonSocial'}
                            label={'Razón Social'}
                            name={'razonSocial'}
                            placeholder={'Razón Social'}
                            onChange={handleChange}
                            value={filtroContratista.razonSocial}
                        />

                        <FormSelectBox
                            title={'Estado'}
                            label={'estado'}
                            id={'activo'}
                            name={'activo'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'valor'}
                            value={filtroContratista.activo || ''}
                            options={estados}
                            seleccionarText="TODOS"
                        />
                    </div>
                    <div className="row mt-2">
                        <FormSelectBox
                            title={'Tipo de Identificación'}
                            label={'Tipo de Identificación'}
                            id={'tipoDocumento'}
                            name={'idTipoDocumento'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'tipoDocumento'}
                            value={filtroContratista.idTipoDocumento || ''}
                            options={tiposDocumentos}

                        />
                        <FormInput
                            id={'nroIdentificacion'}
                            label={'Nro de Identificación'}
                            name={'nroIdentificacion'}
                            placeholder={'Nro de Identificación'}
                            onChange={handleChange}
                            value={filtroContratista.nroIdentificacion}
                        />

                    </div>
                    <div className="row mt-2">

                        <FormSelectBox
                            title={'Empresa Gestora'}
                            label={'empresa gestora'}
                            id={'empresaGestora'}
                            name={'idEmpresaGestora'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'nombreEmpresa'}
                            value={filtroContratista.idEmpresaGestora || ''}
                            options={empresasGestoras}

                        />
                        <FormSelectBox
                            title={'Tipo de Trabajo'}
                            label={'tipo de trabajo'}
                            id={'tipoTrabajo'}
                            name={'idTipoTrabajo'}
                            onChange={handleChange}
                            optionId={'id'}
                            optionLabel={'descripcion'}
                            value={filtroContratista.idTipoTrabajo || ''}
                            options={tiposTrabajo}
                        />
                    </div>

                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-center">

                            <button className="btn btn-secondary btn-rounded waves-effect waves-light mr-1" type="button" id="limpiarFiltro" onClick={handleClean}>
                                <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                                Limpiar
                            </button>
                            <button className="btn btn-success btn-rounded waves-effect waves-light " type="submit" onClick={handleSubmit}>
                                <i className="fa fa-search mr-1"></i>
                                Buscar
                            </button>

                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}