import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FormTextArea from "../Form/FormTextArea";

type ObservacionesProps = {
    isEdit?: boolean;
    observacion?: string;
    saveButtonFn?: (observacion: string) => void;
    cancelButtonFn?: () => void;
    returnButtonFn?: () => void;
};

const ObservacionComponent: React.FC<ObservacionesProps> = ({ isEdit = true, observacion = '',
        saveButtonFn = () => true, 
        cancelButtonFn = () => true, 
        returnButtonFn = () => true }) => {
    const [observacionData, setObservacionData] = useState(observacion);
    return (
        <div>
            <div className="row mt-12 justify-content-center">
                <FormTextArea
                        id={'observacion'}
                        title={'Observación'}
                        name={'observacion'}
                        value={observacionData}
                        isDisabled={!isEdit}
                        onChange={(e:any) => setObservacionData(e.target.value)}
                        classDivTextArea={'col-12'}
                ></FormTextArea>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
                {isEdit  ?(<div> <Button type="button" color="secondary" onClick={() => saveButtonFn(observacionData)} className="mr-2">
                    Guardar
                </Button>
                <Button type="button" color="secondary" onClick={() => cancelButtonFn()} className="mr-2">
                    Salir
                </Button></div>):
                <Button type="button" color="secondary" onClick={() => returnButtonFn()} className="mr-2">
                    Volver
                </Button>}
            </div>
        </div>
    );
};

export default ObservacionComponent;