import { v4 as uuidv4 } from 'uuid';
import './FormTextArea.css';

const FormTextArea = ({
  title,
  id,
  name,
  value,
  isDisabled,
  onChange,
  rows,
  cols,
  classLabel = "col-form-label col-2 text-end",
  classDivTextArea = "col-4"
}:
{
  title: string,
  id: string,
  name: string,
  value: any,
  isDisabled?: boolean,
  onChange?: any,
  optionId?: string,
  optionLabel?: string,
  classLabel?: string,
  rows?: number,
  cols?: number,
  classDivTextArea?: string,
}) => {

  return (
    <>
     <div className={`custom-textarea ${classDivTextArea}`}>
      <label htmlFor={id} className={classLabel}>{title}</label>
      <div className={classDivTextArea}>
        <textarea
          id={id}
          name={name}
          className='form-control'
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          rows={rows}
          cols={cols}
        />
      </div>
     </div>
    </>
  );
}

export default FormTextArea;
