import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HistorialSolicitud } from '../../types/HistorialSolicitud';

interface HistorialState {
    historiales: HistorialSolicitud[];
    historialSelected: HistorialSolicitud;
    loading: boolean;
}
export const historialInitial: HistorialSolicitud = {
    id: 0,
    tipoEstado: '',
    fechaInicio: '',
    fechaFin: '',
    usuario: ''
}

const initialState: HistorialState = {
    historiales: [],
    historialSelected: historialInitial,
    loading: false,
};

export const historialSlice = createSlice({
    name: 'historial',
    initialState,
    reducers: {
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadData: (state, data: PayloadAction<HistorialSolicitud>): void => {
            state.historialSelected = data.payload;
            state.loading = false;
        },
        loadList: (state, data: PayloadAction<HistorialSolicitud[]>): void => {
            state.historiales = data.payload;
            state.loading = false;
        }
    },
})

export const { isLoading, loadData, loadList } = historialSlice.actions;
export default historialSlice.reducer;
