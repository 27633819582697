import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Actividad } from "../../types/Actividad";
import { DocumentoTrabajo } from "../../types/DocumentoTrabajo";


interface ActividadState {
    actividadList: Actividad[];
    actividadSelected: Actividad;
    documentoList: DocumentoTrabajo[];
    filtro: Actividad;
    loading: boolean;
    errors: any;
    error: boolean,
    guardado: boolean,
}

export const actividadInitial: Actividad = {
    id: 0,
    nombreActividad: "",
    descripcion: "",
    activo: true,
    documentos: []
}

const initialState: ActividadState = {

    actividadList: [],
    actividadSelected: actividadInitial,
    filtro: {
        id: 0,
        nombreActividad: "",
        descripcion: "",
        activo: null,
        documentos: []
    },
    loading: false,
    errors: [],
    documentoList: [],
    error: false,
    guardado: false
}

const actividadSlice = createSlice({
    name: 'actividad',
    initialState,
    reducers: {
        addActividad: (state, data: PayloadAction<Actividad>): void => {
            state.actividadSelected = data.payload;
            state.error = false;
            state.guardado = true;
        },
        isLoading: (state, data: PayloadAction<boolean>): void => {
            state.loading = data.payload;
        },
        loadDataActividad: (state, data: PayloadAction<Actividad>): void => {
            state.actividadSelected = data.payload;
            state.error = false;
        },
        loadDataFiltroActividad: (state, data: PayloadAction<Actividad>): void => {
            state.filtro = data.payload;
        },

        loadListActividad: (state, data: PayloadAction<Actividad[]>): void => {
            state.actividadList = data.payload;
        },

        loadingError: (state, data: PayloadAction<any>): void => {
            state.errors = data.payload;
            state.error = true;
        },
        visibleError: (state, data: PayloadAction<boolean>): void => {
            state.error = data.payload;
        },
        removeActividad: (state, data: PayloadAction<number>): void => {
            state.actividadList = state.actividadList.filter(p => p.id !== data.payload)
        },
        setGuardado: (state, data: PayloadAction<boolean>): void => {
            state.guardado = data.payload;
        },
        loadListDocumento: (state, data: PayloadAction<DocumentoTrabajo[]>): void => {
            state.documentoList = data.payload;
        },
    }
});
export const {
    addActividad,
    isLoading,
    loadDataActividad,
    loadListActividad,
    loadingError,
    visibleError,
    removeActividad,
    setGuardado,
    loadListDocumento,
    loadDataFiltroActividad
} = actividadSlice.actions;

export default actividadSlice.reducer;