/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Accordion from '../../../../components/Accordion'
import { useSolicitud } from '../../../../hooks/useSolicitud'
import { useEquipoAndMaquinaria } from '../../../../hooks/useEquipoAndMaquinaria'
import TableEquipamientoSolicitud from './TableEquipamientoSolicitud'
import { Button } from 'react-bootstrap'
import { cargarCombo } from '../../../../services/Service'

type EquiposAndMaquinariaProps = {
    isDisabled?: boolean,
    isEdit?: boolean
};


const EquiposAndMaquinaria: React.FC<EquiposAndMaquinariaProps> = ({ isDisabled = false, isEdit = false }) => {
    const { solicitudSelected } = useSolicitud();
    const [modalOpenEquipamiento, setModalOpenEquipamiento] = useState(false);
    const { buscarEquipacionSolicitudesByIdSolicitud, existeEquipoAfectadoByIdContTrabajoEmpresa, isEquipoAfectado,
        setEquipamientoSolicitud, equipamientoSolicitudInitial } = useEquipoAndMaquinaria();
    const [cargarEquipamientoSolicitud, setCargarEquipamientoSolicitud] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        existeEquipoAfectadoByIdContTrabajoEmpresa(solicitudSelected.idContratistaTrabajoEmpresa);

    }, [solicitudSelected.idContratistaTrabajoEmpresa])

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (cargarEquipamientoSolicitud) {
            buscarEquipacionSolicitudesByIdSolicitud(solicitudSelected.id);
            setCargarEquipamientoSolicitud(false);

        }
        // existeEquipoAfectadoByIdContTrabajoEmpresa(solicitudSelected.idContratistaTrabajoEmpresa);
    }

    const handelOnClose = () => {
        buscarEquipacionSolicitudesByIdSolicitud(solicitudSelected.id);
        setIsEditing(false);
    };

    return (
        <>
            {isEquipoAfectado &&
                (
                    <>
                        <Accordion id={'EquipoAndMaquinaria'} title="Equipos y Maquinaria" collapsed={true} handleClick={handleClick}>

                            <div className='p-3'>

                                <div>
                                    <div className="row">
                                        <div className="col-md-6 text-start align-bottom" style={{ alignSelf: 'flex-end' }}>

                                        </div>
                                        <div className="col-md-6 text-end">
                                            {!isDisabled && <Button
                                                className="btn-secondary"
                                                variant="secondary"
                                                onClick={() => { setIsEditing(true); setModalOpenEquipamiento(true); setEquipamientoSolicitud(equipamientoSolicitudInitial) }}
                                            > Agregar Equipo/Maquinaría
                                            </Button>}
                                        </div>
                                    </div>
                                    <div className="table-responsive ">
                                        <TableEquipamientoSolicitud
                                            actionEdit={!isDisabled}
                                            isEditing={isEditing}
                                            isEdit={isEdit}
                                            onClose={() => { handelOnClose(); setModalOpenEquipamiento(false) }}
                                            onModalAction={(isOpenModal) => setModalOpenEquipamiento(isOpenModal)}
                                            modalOpen={modalOpenEquipamiento}
                                            onIsEditing={(isEdit) => setIsEditing(isEdit)} ></TableEquipamientoSolicitud>
                                    </div></div>
                                {/* : <div>El tipo de trabajo asociado no requiere indicar información sobre Equipos y Maquinaria</div> */}

                            </div>
                        </Accordion>
                    </>)
            }
        </>
    )
}

export default EquiposAndMaquinaria