import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

type ConfirmProps = {
    isOpen: boolean;
    toggle: () => void;
    yesFn: () => void;
    children: any,
    title: string
};

const Confirm: React.FC<ConfirmProps> = ({ children, isOpen, toggle, title, yesFn }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={yesFn}>
                    Si
                </Button>
                <Button color="secondary" onClick={toggle}>
                    No
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default Confirm;