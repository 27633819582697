import FormInput from "../../../../../components/Form/FormInput"
import moment from 'moment'
import { usePersonalAfectado } from "../../../../../hooks/usePersonalAfectado";
import { useDocumentoPersAfectado } from "../../../../../hooks/useDocumentoPersAfectado";
import Table, { ColumnDefinitionType } from "../../../../../components/Table";
import { DocumentoPersAutonomo } from "../../../../../types/DocumentoPersAutonomo";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyModal from "../../../../../components/Modal";
import { Historial } from "../../DocumentosAdjuntos/historial";
import { useDocumento } from "../../../../../hooks/useDocumento";
import { downloadAndOpenFile, downloadFile } from '../../../../../services/Service'
import { Button } from "react-bootstrap";
import { useUserLogin } from "../../../../../hooks/useUserLogin";
import { useSolicitud } from "../../../../../hooks/useSolicitud";
import { OBSERVADA_STATUS_ID } from "../../../../../constants/constants";
import ObservacionComponent from "../../../../../components/Observaciones";

interface DetailPersonalAutonomoProps {
    onClose: () => void;
}


const DetailPersonalAutonomo: React.FC<DetailPersonalAutonomoProps> = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIdTipoDocumento, setSelectedIdTipoDocumento] = useState<number>(0); // Estado para mantener el ID seleccionado
    const toggle = () => setIsOpen(!isOpen);
    const { personalAutonomoSelected } = usePersonalAfectado();
    const { documentoPersonalAutonomoList, handleDocumentoPersAutonomoSelected, documentoPersonalAutonomoSelected } = useDocumentoPersAfectado();
    const { historialDocumentos, buscarDocumentosByIdTipoDocumento, handleDocumentoSelected, documentoInitial } = useDocumento();
    const { isContratistaTipoUsuario } = useUserLogin();
    const { solicitudSelected } = useSolicitud();
    const [isOpenModalObservacionDocument, setIsOpenModalObservacionDocument] = useState(false);

    const handleDocumento = async (id: number) => {
        setSelectedIdTipoDocumento(id);
        toggle();
    }
    const getDocumentoSelected = (id: number) => {
        if (id) {
            const doc = historialDocumentos.find(d => d.idTipoDocumento === id) || documentoInitial;
            handleDocumentoSelected(doc);
            toggle();
            setSelectedIdTipoDocumento(0);
        }
    }

    useEffect(() => {
        // Dependiendo de cómo se use historialDocumentos, podrías necesitar un efecto para manejar su actualización
        // Realiza las acciones que necesites con el historialDocumentos actualizado aquí
        // getDocumentoSelected(selectedIdTipoDocumento);
    }, [historialDocumentos]);

    const columns: ColumnDefinitionType<DocumentoPersAutonomo, keyof DocumentoPersAutonomo>[] = []
    columns.push({
        key: 'tipoDocumento',
        header: 'TIpo Documento',
    });
    columns.push({
        key: 'nombreArchivo',
        header: 'Nombre',
    });
    columns.push({
        key: 'fechaVencimiento',
        header: 'Fecha Vencimiento',
        cell: (data: DocumentoPersAutonomo) => <>{data.fechaVencimiento ? moment(data.fechaVencimiento).format('DD/MM/YYYY') : ''}</>
    });
    if ((isContratistaTipoUsuario() && solicitudSelected.idTipoEstado === OBSERVADA_STATUS_ID)) {
        columns.push({
            key: 'observacion',
            header: 'Observaciones',
            cell: (data: DocumentoPersAutonomo) => <>
                {
                    data.observacion &&
                    <a href="#" title="Observaciones" onClick={() => {
                        handleDocumentoPersAutonomoSelected(data);
                        setIsOpenModalObservacionDocument(true)
                    }}>
                        <i className="fa fa-search me-2" style={{ color: 'red' }} aria-hidden="true"></i>
                    </a>
                }
            </>
        });

    }
    columns.push({
        key: 'id',
        header: 'Acciones',
        cell: (data: DocumentoPersAutonomo) =>
            <>
                <Link title="Visualizar" to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadAndOpenFile('documento-personal-autonomo/visualizarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-eye text-secondary me-2" aria-hidden="true"></i>
                </Link>
                <Link title="Descarga" to="/exportar" onClick={(e) => {
                    e.preventDefault();
                    downloadFile('documento-personal-autonomo/descargarArchivo', data)
                }} target="_blank" >
                    <i className="fa fa-arrow-down text-secondary me-2" aria-hidden="true"></i>
                </Link>
                <a href="#" title="Historial de Documentos" onClick={() => handleDocumento(data.idTipoDocumento)}>
                    <i className="fa fa-history text-secondary " aria-hidden="true"></i>
                </a>
            </>
    });
    columns.push({
        key: 'statusDocumento',
        header: '',
    });

    return (
        <>
            <MyModal
                isOpen={isOpenModalObservacionDocument} toggle={() => setIsOpenModalObservacionDocument(false)} title='Observaciones' cancelButton={false} secondButton={false}
            >
                <ObservacionComponent observacion={documentoPersonalAutonomoSelected.observacion} isEdit={false}
                    cancelButtonFn={() => setIsOpenModalObservacionDocument(false)} returnButtonFn={() => setIsOpenModalObservacionDocument(false)}
                ></ObservacionComponent>
            </MyModal>
            <MyModal isOpen={isOpen} toggle={toggle} title='Historial de Documentos'>
                <Historial tipoDocumentoDisabled={true}
                    pathService={`documento-personal-autonomo/personal-autonomo/${personalAutonomoSelected.id}`}
                    idTipoDocumentoSelected={selectedIdTipoDocumento}
                />
            </MyModal>
            <span>Personal Autonomo</span>
            <div>
                <div className="row mt-2">
                    <FormInput
                        id={'tipoDocumento'}
                        label={'Tipo Doc Persona'}
                        name={'tipoDocumento'}
                        value={personalAutonomoSelected.tipoDocumento || ''}
                        isDisabled={true}
                    />
                    <FormInput
                        id={'nroDocumento'}
                        label={'Nro. Documento'}
                        name={'nroDocumento'}
                        value={personalAutonomoSelected.nroDocumento || ''}
                        isDisabled={true}
                    />
                </div>
                <div className="row mt-2">
                    <FormInput
                        id={'nombre'}
                        label={'Nombre'}
                        name={'nombre'}
                        value={personalAutonomoSelected.nombre || ''}
                        isDisabled={true}
                    />
                    <FormInput
                        id={'apellido'}
                        label={'Apellido'}
                        name={'apellido'}
                        value={personalAutonomoSelected.apellido || ''}
                        isDisabled={true}
                    />
                </div>
                <div className="row mt-2">
                    <FormInput
                        id={'fechaIngreso'}
                        label={'Fecha Ingreso Trabajo'}
                        name={'fechaIngreso'}
                        value={personalAutonomoSelected.fechaIngreso ? moment(personalAutonomoSelected.fechaIngreso).format('DD/MM/YYYY') : ''}
                        isDisabled={true}
                    />
                    <FormInput
                        id={'actividad'}
                        label={'Actividad'}
                        name={'actividad'}
                        value={personalAutonomoSelected.actividad || ''}
                        isDisabled={true}
                    />
                </div>
            </div>
            <div className='p-3'>
                <div className="row">
                    <div className="col-md-12 text-end">
                        {/* <Button
                            disabled={true}
                            className="btn-secondary"
                            variant="secondary"
                        > Agregar Documentos
                        </Button> */}
                    </div>
                </div>
                <div className="table-responsive ">
                    <Table data={documentoPersonalAutonomoList} columns={columns} ></Table>
                </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
                <Button color="btn-info" onClick={onClose} className="mr-2">
                    Volver
                </Button>
            </div>
        </>
    )
}

export default DetailPersonalAutonomo