import { Link, useNavigate } from 'react-router-dom';
import './topbar.css'
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';


export const HeaderComponent = () => {
  const [nombreUsuario, setNombreUsuario] = useState<string | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const login = JSON.parse(localStorage.getItem('login') || '{}')
    setNombreUsuario(login?.usuario?.nombreUsuario)

  }, [])

  const { handlerLogout } = useAuth();
  const elementoRef = useRef<HTMLLIElement | null>(null);

  const handleOnclick = () => {
    const body = document.querySelector('body');
    if (body !== null && body.className === 'enlarged') { // NO COLLAPSADO
      body.classList.remove('enlarged');
    } else { // COLLAPSADO
      body?.classList.add('enlarged');
    }
  }
  const handlerLogoutAndNavigate = () => {
    handlerLogout();
    navigate('/login');
  }

  return (
    <div className="topbar">

      {/* LOGO background-image: url(resources/images/geins-header.png);  va por default si tiene un logo asignado de un cliente lo debera reemplazar  */}
      <div className="topbar-left logo-header-menu">
        {/* <a href="index.html" class="logo"><span>Code<span>Fox</span></span><i className="mdi mdi-layers"></i></a> */}
        {/* Image logo */}
        <Link to="/" className="logo" >
          <span>
            <img
              id="imageLogoApp"
              src={
                process.env.PUBLIC_URL + '/poncho-instalaciones.png'
              }
              alt="Poncho Contratistas"
              style={{ margin: 'auto', paddingBottom: '22px' }}
            />
          </span>
          <i>

            <img
              src={require('../../../resources/images/ico-poncho.png')}

              style={{ width: 'auto', height: 'auto', marginBottom: '26px' }} />
          </i>
        </Link>
      </div>
      {/* Button mobile view to collapse sidebar menu  */}
      <div className="navbar navbar-default" role="navigation">
        <div className="container-fluid">
          {/* Navbar-left */}
          <ul className="nav navbar-left">
            <li>
              <button
                onClick={handleOnclick}
                type="button"
                className="button-menu-mobile open-left waves-effect"
              >
                <i className="dripicons-menu"></i>
              </button>
            </li>
          </ul>

          <ul className="nav navbar-right list-inline">
            {/*Se oculta por pedido de isa en un reporte de bug */}
            {false && <li>
              <a href="alertaDesvioSensores" id="alertIOT">
                <i
                  className="fa fa-bell icon-alert"
                  aria-hidden="true"
                  title="Alertas por desvíos en sensores"
                >
                  <label
                    className="icon-alert-num"
                    id="cantidadAlerta"
                  >
                    9
                  </label>
                </i>
              </a>
            </li>}

            <li className="dropdown user-box list-inline-item" ref={elementoRef}>
              {nombreUsuario &&
                <a
                  href=""
                  className="dropdown-toggle waves-effect user-link"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="fa fa-user-circle fa-2"></i> {nombreUsuario}
                </a>
              }
              <ul className="dropdown-menu dropdown-menu-right arrow-dropdown-menu arrow-menu-right user-list notify-list">
                <li>
                  <a href="editarPerfil" className="dropdown-item">
                    Datos Personales
                  </a>
                </li>
                {/* <li className="dropdown-divider"></li>
                <li>
                  <a href="cambiarClave" className="dropdown-item">
                    Cambiar Clave
                  </a>
                </li> */}
                <li className="dropdown-divider"></li>
                <li>

                </li>
                <li>
                  <a href="#" className="dropdown-item" onClick={handlerLogoutAndNavigate}>
                    Salir
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div >
    </div >
  );
};
